<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs class="mt-8, mb-6, px-4, py-2  title--text text-uppercase" divider=">" :items="breadItems">
          </v-breadcrumbs>
        </v-card>
      </v-col>

      <v-col>
        <data-table-suivi/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTableSuivi from "./dataTableSuivi.vue";

export default {
  name: "SuiviDoc",
  components: {
    dataTableSuivi,
  },
  metaInfo() {
    return {
      title: "SuiviDoc"
    }
  },
data() {
    return {
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Documentation',
          disabled: false,
        },
        {
          text: 'veille',
          disabled: false,
        },
        {
          text: 'Suivi de la doc',
          disabled: false,
        },
      ],
    };
  },
}
</script>