<template>
  <div>
    <administratif-component :OT="OT"></administratif-component>    
  </div>
</template>
<script>
import AdministratifComponent from "./AdministratifComponent.vue";

export default {
  name: "RetourAdministratif",
  props: ["OT"],
  components: {
    AdministratifComponent,    
  },
};
</script>