<template>
  <v-card outlined min-height="350px">
      <v-card-title class="primary--text text-uppercase">
        <v-row>
          <v-col align="center">
            <span>PROCHAINS PE</span>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- <div v-if="$apollo.loading">
        <v-row>
          <v-skeleton-loader
            ref="skeleton"
            type="table"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-row>
      </div> -->
			<v-data-table
        no-data-text="Aucun PE à réaliser"
        hide-default-footer
        disable-pagination
        fixed-header
        dense
         sort-by="expiredAt"
        class="mb-2" 
        locale="fr-FR"
        :items="nextPesToExpired"
        :headers="headers"
        height="320px"
      >
        <template v-slot:item.expiredAt="{ item }">
            <v-chip small outlined :color="linkColor(item)"> {{ item.expiredAt | moment("DD/MM/YYYY")}}</v-chip>
        </template>

        <template v-slot:item.aircraft.immatriculation="{ item }">
          <v-btn
            class="link--text"
            text
            small
            :to="{
            name: 'aeronef',
            params: { immatriculation: item.aircraft.immatriculation, page: 'info' },
            }"
          >
            {{ item.aircraft.immatriculation }}
          </v-btn>
        </template>

        <template v-slot:item.status="{ item }">
          <span v-if="item.aircraft.longMaintenance == true ">
          <v-chip small outlined color="blue" >
            Maint. Long.</v-chip
          ></span>
        </template>
  
        <template v-slot:item.sup="{ item }">          
          <span v-if="item.aircraft.supervisor && item.aircraft.supervisor.id == me.id" >
            <v-icon small color="red">mdi-star</v-icon>
          </span>
        </template>

			</v-data-table>
    </v-card>

</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { nextPesToExpiredQuery } from "../../graphql/aircraft/next_pes_to_expired";
import { DateTime } from "luxon";
import { meQuery } from "../../graphql/me.js"

export default {
  name: "NextPe",
  data() {
		return {
      nextPesToExpired: [],
			headers: [
        { text: "", value: "sup", width: "40px" },
        { text: "IMMAT", value: "aircraft.immatriculation" , width: "80px" },
        { text: "STATUT", value: "status" },
        { text: "EXPIRE Le", value: "expiredAt", width: "110px" },
			],
		};
  },
  methods: {
    linkColor(item) {
      if (item.expiredAt) {
        const diffDate = DateTime.now()
          .diff(DateTime.fromISO(item.expiredAt), "days")
          .toObject();
        if (diffDate["days"] > 1) {
          return "red";
          }
        if (diffDate["days"] > -15) {
          return "orange";
        }
        }
        return "green";
      } 
  },
  apollo: {
    nextPesToExpired: {
      query: nextPesToExpiredQuery,            
    },
    me: {
      query: meQuery,
    }
  },
};
</script>
