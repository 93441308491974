<template>
  <v-container>
    <v-row >
      <v-col cols="6" offset="3" class="align-center mt-16">
        <v-card outlined elevation="0" height="680px" color="colorcard" class="mt-4">
            <v-avatar id="wrapper" size="120" class="profile-card " >
              <v-avatar size="107" v-if="user" >
                <!-- <v-btn fab>
                  <router-link to="/NewUser.vue"> -->
                    <v-img  :src="user.avatar"></v-img>
                  <!-- </router-link>
                </v-btn> -->
              </v-avatar>
            </v-avatar>
            <v-card-title>
              <p class="text-h4 text-justify">{{ user.name }}</p>
            </v-card-title>
        
          <v-row>
            <v-list class="ml-8" >
              <v-list-item>              
                  <v-list-item-icon><v-icon>mdi-badge-account-outline</v-icon></v-list-item-icon>
                   <v-list-item-content>
                      <v-list-item-subtitle>{{ user.title }}</v-list-item-subtitle> 
                    </v-list-item-content>
              </v-list-item>

              <v-list-item>              
                  <v-list-item-icon><v-icon>mdi-at</v-icon></v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item>              
                  <v-list-item-icon><v-icon>mdi-alphabetical</v-icon></v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-subtitle v-if="user.trigram">Trigramme : {{ user.trigram }}</v-list-item-subtitle>
                     <v-list-item-subtitle v-else>Aucun Trigramme attribué</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item>              
                  <v-list-item-icon><v-icon>mdi-draw-pen</v-icon></v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-subtitle v-if="user.signature"><a :href="user.signature">Signature ajouté</a> </v-list-item-subtitle>
                     <v-list-item-subtitle v-else>Aucun signature ajouté</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item>              
                  <v-list-item-icon><v-icon>mdi-stamper</v-icon></v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-subtitle v-if="user.stamp"><a :href="user.stamp">Tampon ajouté</a></v-list-item-subtitle>
                     <v-list-item-subtitle v-else>Aucun tampon ajouté</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.pen == true">              
                  <v-list-item-icon><v-icon></v-icon></v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-subtitle v-if="user.penNumber">PEN N°{{ user.penNumber }}</v-list-item-subtitle>
                     <v-list-item-subtitle v-else>Aucun n° PEN attribué</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.pen == true">              
                  <v-list-item-icon><v-icon>mdi-stamper</v-icon></v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-subtitle v-if="user.stamp_pen"><a>Tampon CEN</a> </v-list-item-subtitle>
                     <v-list-item-subtitle v-else>Aucun tampon ajouté</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
#wrapper {
  border-radius: 50%;
  border-image-source: linear-gradient(to bottom, #3da8b0, #cde9eb);
  border: 7px solid #3da8b0;
  display: flex;
}
.profile-card {
  position: relative;
  bottom: 60px;
  margin: auto;
}
</style>

<script>
import { userQuery } from "../../graphql/user/user.js";

export default {
  name: "Profile",
  data: () => ({
    user: null,
  }),
  apollo: {
    user: {
      query: userQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>