import gql from 'graphql-tag';

export const createCenExtensionFormMutation = gql`
  mutation createCenExtensionForm($input: CreateCenExtensionFormInput!) {
    createCenExtensionForm(input: $input) {
      cenExtensionForm {
        id
        checkType
        checkValue
        checkText
        createdAt
        updatedAt
      }
    }
  }`
