<template>
  <v-sheet outlined flex>
    <v-card-title>
			<v-row>
				<v-col cols="3">
					<v-dialog v-model="modal" persistent width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="dateRangeText"
								label="choisir une période"
								prepend-icon="mdi-calendar-month"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="dates"
							:first-day-of-week="1"
							locale="fr"
							no-title
							range
							><v-spacer></v-spacer>
							<v-btn text color="primary" @click="modal = false">
								Cancel
							</v-btn>
							<v-btn text color="primary" @click="modal = false"> OK </v-btn>
						</v-date-picker>
					</v-dialog>
				</v-col>
				<v-col cols="3">
					<v-select
					dense
					label="client"
					v-model="contact"
					:items="
						contact.map(function (u) {
							return { text: u.name, value: u.id };
						}).sort((a, b) => a.text.localeCompare(b.text))
            "
					></v-select>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="3">
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Rechercher"
						single-line
						hide-details
					>
					</v-text-field>
				</v-col>	
			</v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="workOrders"
      :footer-props="{
              'items-per-page-options': [15, 50, 100],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :search="search"
			:loading="isLoading"
			loading-text="c'est long, patience..."
      dense
      locale="fr-FR"
      no-results-text="Aucun résultat"
    >
				<template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on">mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <span>Traiter l'OT</span>
          </v-tooltip>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span> {{ item.createdAt | moment("DD/MM/YYYY")}}</span>
        </template>
        <template v-slot:item.orderType="{ item }">
          <span v-if="item.orderType == 'visite'">Visite</span>
          <span v-if="item.orderType == 'travaux'">Travaux/dépannage</span>
          <span v-if="item.orderType == 'mb303_cen'">MB303/CEN</span>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status == 'close' && item.aprsDate">
            <v-chip
              class="ma-2"
              color="pink lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-archive-outline</v-icon>
              Clôturé
            </v-chip>          
          </span>
          <span v-if="item.status == 'sent'">
            <v-chip
              class="ma-2"
              color="orange lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-email-fast-outline</v-icon>
              En attente de retour
            </v-chip>
          </span>
          <span v-if="!item.exportedAt && item.status == 'new'">
            <v-chip
              class="ma-2"
              color="blue lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-pencil</v-icon>
              Elaboration
            </v-chip>
          </span>
        </template>
        <template v-slot:item.invoice="{ item }" >
          <span v-if="item.generateInvoice">
            <v-icon small color="red">mdi-currency-eur</v-icon>
          </span>
          <span v-else>
            <v-icon small color="green">mdi-currency-eur-off</v-icon>
          </span>
				</template>
				<template v-slot:item.action="{ item }" >	
          <span v-if="item.invoiced">          
            <v-icon color="blue">mdi-cash-register</v-icon>
          </span>
        </template>
        <template v-slot:item.aprsDate="{ item }">
          <span> {{ item.aprsDate | moment("DD/MM/YYYY")}}</span>
        </template>
      </v-data-table>

  </v-sheet>
</template>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
tbody td {
  white-space: nowrap;
}
</style>



<script>

import { workOrdersQuery } from "../../graphql/work_order/work_orders";

export default {
  name: "Workorder",
  components : { },  
  data: () => {
    return {
      search: "",
			isLoading: true,
			contact: [],
      headers: [
        { text: "CREE LE", value: "createdAt", width: "100px" },
        { text: "REFERENCE", value: "reference", width: "150px" },
        { text: "", value: "invoice", width: "50px"},
				{ text: "", value: "action", width: "50px"},
        { text: "STATUS", value: "status", width: "100px" },
        { text: "DATE APRS", value: "aprsDate", width: "100px" },
        { text: "TYPE", value: "orderType" },
				{ text: "IMMAT", value: "aircraft.immatriculation", width: "100px"},
        { text: "CLIENT", value: "contact.name"},
        { text: "FAIT PAR", value: "user.name" },
      ],
			modal: false,
      dates: [],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  apollo: {
    workOrders: {
      query: workOrdersQuery,
			result() {
        this.isLoading = false;
      },      
    },
  },
};
</script>