<template>
  <v-autocomplete
    label="Rechercher"
    prepend-icon="mdi-magnify"
    :items="searchRes"
    :search-input.sync="queryStr"
    ref="globalSearchSelect"
    hide-no-data
    no-filter
  >
    <!-- <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item @click="closeSearch()">
          <v-subheader v-text="data.item"
            ><v-icon>mdi-label</v-icon></v-subheader
          >
        </v-list-item>
      </template> -->
      <template v-slot:item="data">
        <v-list-item :to="data.item.url" @click="closeSearch()">
          <v-list-item-content>
            <v-list-item-title class="text-button" v-text="data.item.text"></v-list-item-title>
            <v-list-item-subtitle class="text-caption"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
   <!-- </template>-->
  </v-autocomplete>
 </template> 

<style>
.theme--light.v-subheader {
  
  font-weight: 600;
}
.v-subheader {
  font-size: 1rem;
  font-weight: 600;
}

</style>

<script>
import { searchQuery } from "../graphql/search";

export default {
  name: "GlobalSearch",
  data() {
    return {
      queryStr: "",
      searchRes: [],
    };
  },
  methods: {
    closeSearch() {
      this.$refs.globalSearchSelect.isMenuActive = false;
      this.queryStr = "";
      this.searchRes = [];
    },
    groupData(searchData) {
      var result = [];

      if (searchData.length > 0) {
        
        searchData.forEach((s) => {
          if (s.type == "aircraft") {
            result.push({
              text: "AERONEF : " + s.title,
              value: s.id,
              url: "/aeronef/" + s.title + "/info",
            });
          }
        });
        
        searchData.forEach((s) => {
          if (s.type == "tc_holder") {
            result.push({
              text: "TC HOLDER : " + s.title,
              value: s.id,
              url: "/tc_holder/" + s.id,
            });
          }
        });


        searchData.forEach((s) => {
          if (s.type == "document") {
            result.push({
              text: "DOCUMENT : " + s.title,
              value: s.id,
              url: "/document/" + s.id,
            });
          }
        });

        searchData.forEach((s) => {
          if (s.type == "contact") {
            result.push({
              text: "CONTACT : " + s.title,
              value: s.id,
              url: "/contact/" + s.id,
            });
          }
        });

        searchData.forEach((s) => {
          if (s.type == "invoice") {
            result.push({
              text: "FACTURE : " + s.title,
              value: s.id,
              url: "/facture/" + s.id,
            });
          }
        });
      }
      return result;
    },
    searchCall(queryValue) {
      if (queryValue) {
        this.$apollo
          .query({
            query: searchQuery,
            variables: {
              queryStr: queryValue,
            },
          })
          .then((ret) => {
            this.searchRes = this.groupData(ret.data.search);
          });
      }
    },
  },
  watch: {
    queryStr: function callback() {
      this.searchCall(this.queryStr);
    },
  },
};
</script>
