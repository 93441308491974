<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs class="mt-8, mb-6, px-4, py-2  title--text text-uppercase" divider=">" :items="breadItems">
          </v-breadcrumbs>
        </v-card>
      </v-col>
      <v-col cols="12">
        <data-table-immat />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import dataTableImmat from "./Components/dataTableImmat.vue";

export default {
  name: "ListeImmat",
  components: {
    dataTableImmat
  },
  metaInfo() {
    return {
      title: "liste Immat"
    }
  },
  data() {
    return {
      fab: false,
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Liste des immatriculations',
          disabled: false,
        },
        ],
    }
  },
};
</script>
