import gql from 'graphql-tag';

export const customQueriesQuery = gql`query ($type: String!) {
  customQueries(type: $type) {
    id
    name
    report
    edition
    customQueryType
    params
    updatedAt
    createdAt
  }
}`