<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn
        class="mr-4 mb-4"
        small
        rounded
        outlined
        color="red lighten-1"
        @click="showDialog"
      >
        <v-icon dark class="mr-2">mdi-file-pdf-box</v-icon>PREVISIONNEL</v-btn
      >
    </template>
    <v-card v-if="aircraft">
      <v-card-title
        >Prévisionnel pour {{ aircraft.immatriculation }}</v-card-title
      >
      <v-card-text class="">
        <v-container>
          <v-row>
            <v-col cols="4" offset="2">
              <v-text-field label="Heures" v-model="hours"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Mois" v-model="months"></v-text-field>
            </v-col>

          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn small class="mr-8 primary" dark  @click="generate()" :loading="generateLoading"> Générer </v-btn>
              <v-btn small outlined depressed color="primary" @click="cancel()"> Fermer </v-btn>
            </v-col>

          </v-row>
          <v-row v-if="projected">
            <v-col cols="12" align="center">
              <v-btn
                class="mr-4 mt-4"
                small
                rounded
                outlined
                color="red lighten-1"
                target="_blank"
                v-if="projected.projectedAttachment"
                :href="projected.projectedAttachment.fileUrl"
              >
                TELECHARGER
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { aircraftQuery } from "../../../graphql/aircraft/aircraft";
import { generateProjectedMutation } from "../../../graphql/aircraft/generate_projected";

export default {
  name: "ProjectedDialog",
  props: {
    aircraftId: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      months: 6,
      hours: 50,
      projected: null,
      generateLoading: false,
    };
  },
  computed: {},
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.months = 6;
      this.hours = 50;
      this.projected = null;
      this.generateLoading = false;
    },
    showDialog() {
      this.dialog = true;
    },
    generate() {
      this.generateLoading = true;

      this.$apollo
        .mutate({
          mutation: generateProjectedMutation,
          variables: {
            input: {
              parameter: { months: this.months, hours: this.hours },
              aircraftId: parseInt(this.aircraft.id, 10),
            },
          },
        })
        .then((data) => {
          if (data) {
            this.projected = data.data.generateProjected.projected;
            this.generateLoading = false;
          }
        });
    },
    cancel() {
      this.cleanDialog();
    },
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
    },
  },
};
</script>

