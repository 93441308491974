import gql from 'graphql-tag';
import { documentFragment } from './fragment_document';

export const deleteDocumentMutation = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      document {
        ...document
      }
    }
}
${documentFragment.fragments.document}`
