<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn block class="my-3" color="primary" @click="showDialog()"
        >Pose d'une pièce</v-btn
      >
    </template>
    <v-card>
      <v-card-title
        >Pose d'une pièce sur {{ aircraft.immatriculation }}</v-card-title
      >
      <v-card-text class="">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete :items="partItems" v-model="aircraftPartId"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="cancel()"> Annuler </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="save()"> Poser </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mountAircraftPartMutation } from "../../graphql/aircraft_part/mount_aircraft_part";
import { aircraftPartsQuery } from "../../graphql/aircraft_part/aircraft_parts";

export default {
  name: "PoseModal",
  props: {
    aircraft: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      aircraftPartId: null,
      aircraftParts: [],
    };
  },
  computed: {
    partItems() {
      var items = [];
      if (this.aircraftParts) {
        this.aircraftParts.map(function (ap) {
          var text = ap.partModel.category.name;
          if (ap.position) {
            text = text + " (" + ap.position + ")";
          }
          text =
            text +
            " - " +
            ap.partModel.reference +
            " (s/n: " +
            ap.serialNumber +
            ")";
          items.push({
            text: text,
            value: ap.id,
          });
        });
      }
      return items;
    },
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.aircraftPartId = null;
    },
    showDialog() {
      this.$apollo.queries.aircraftParts.refetch();
      this.dialog = true;
    },
    save() {
      this.$apollo
        .mutate({
          mutation: mountAircraftPartMutation,
          variables: {
            input: {
              aircraftPartId: parseInt(this.aircraftPartId, 10),
              aircraftId: parseInt(this.aircraft.id, 10),
            },
          },
        })
        .then(() => {
          this.cleanDialog();
        });
    },
    cancel() {
      this.cleanDialog();
    },
  },
  apollo: {
    aircraftParts: {
      query: aircraftPartsQuery,
      variables() {
        return {
          status: "drop",
        };
      },
    },
  },
};
</script>

