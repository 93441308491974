import gql from 'graphql-tag';


export const deleteAircraftMb303Mutation = gql`
  mutation deleteAircraftMb303($input: DeleteAircraftMb303Input!) {
    deleteAircraftMb303(input: $input) {
      aircraftMb303 {
        id
        reference
        inspector
        place
        mb303Date
        reportAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aircraft {
          id
          immatriculation
        }       
      }
    }
}`
