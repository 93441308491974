import gql from 'graphql-tag';

export const bookletQuery = gql`query Booklet($id: ID!) {
    booklet(id: $id) {
      id
      aircraft {
        id
        immatriculation
        contact {
          id
          name
        }
      }
      aircraftPart {
        id
        partModel {
          id
          reference
          type
        }
        serialNumber
      }
      volume
      status
      location
      bookletUpdatedAt
    }
  }`
