import gql from 'graphql-tag';

export const aircraftPhotoFragment = {
    fragments: {
      aircraftPhoto: gql`
        fragment aircraftPhoto on AircraftPhoto {
          id
          photo
          photoSignedId
          title
          isCover
          createdAt
          updatedAt
      }` 
    }
}