var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-row',[_c('v-col',{staticClass:"d-none d-sm-block",attrs:{"cols":"3","offset":"5"}},[_c('v-select',{attrs:{"label":"Client","items":_vm.contact},model:{value:(_vm.contactFilter),callback:function ($$v) {_vm.contactFilter=$$v},expression:"contactFilter"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aircrafts,"footer-props":{
            'items-per-page-options': [20, 50, 100, -1],
            'items-per-page-text': 'lignes par page',
            'items-per-page-all-text': 'Tous',
          },"search":_vm.search,"loading":_vm.isLoading,"loading-text":"99 ballons accrochés au mur !","dense":"","locale":"fr-FR","fixed-header":"","min-height":"650px","no-data-text":"y a que dalle ! ","no-results-text":"Aucun résultat"},scopedSlots:_vm._u([{key:"item.immatriculation",fn:function({ item }){return [_c('v-btn',{staticClass:"mr-4 link--text",attrs:{"text":"","small":"","to":{
                name: 'aeronef',
                params: { immatriculation: item.immatriculation, page: 'info' },
              }}},[_vm._v(_vm._s(item.immatriculation))])]}},{key:"item.cen",fn:function({ item }){return [(_vm.findDoc(item, 'Cen'))?_c('span',[_c('v-chip',{attrs:{"small":"","color":_vm.linkColor(_vm.findDoc(item, 'Cen').expiredAt)}},[_vm._v(_vm._s(_vm._f("moment")(_vm.findDoc(item, "Cen").expiredAt,"DD/MM/YYYY")))])],1):_vm._e()]}},{key:"item.pe",fn:function({ item }){return [(_vm.findDoc(item, 'Pe'))?_c('span',[_c('v-chip',{attrs:{"small":"","color":_vm.linkColor(_vm.findDoc(item, 'Pe').expiredAt)}},[_vm._v(_vm._s(_vm._f("moment")(_vm.findDoc(item, "Pe").expiredAt,"DD/MM/YYYY")))])],1):_vm._e()]}},{key:"item.ad",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.AdToProcess(item))+" ")]}},{key:"item.sb",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.SbToProcess(item))+" ")]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }