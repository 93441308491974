import gql from 'graphql-tag';

export const workshopToolModelsQuery = gql`
  query{
      workshopToolModels {
        id
        name
        periodicity       
        updatedAt
        createdAt
      }
}`