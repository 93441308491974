<template>
  <v-container fluid class="background">
    <v-col cols="10" offset="1">
      <v-sheet outlined >
        <v-card-title>
          <sbuttonAdd :to="{ name: 'ata_new' }"
            ><v-icon left dark> mdi-plus </v-icon>Nouvel ATA</sbuttonAdd
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :items="atas"
          :headers="header"
          :sort-by="['ATA']"
          :footer-props="{
            'items-per-page-options': [13, 50, 100, -1],
            'items-per-page-text': 'lignes par page',
            'items-per-page-all-text': 'Tous',
          }"
          :items-per-page="13"
          :search="search"
          dense
          no-results-text="Aucun résultat"
        >
          <template v-slot:item.chapter="{ item }">
            <span class="font-weight-bold" v-if="item.parent === null">
              {{ item.chapter }}</span
            >
            <span v-else>{{ item.chapter }}</span>
          </template>

          <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon :to="{ name: 'ata_edit', params: { id: item.id } }">
                  <v-icon v-on="on">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }" v-slot:item.id="{ item }">
                <v-btn icon @click="deleteAta(item)">
                  <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </template>
          <template v-slot:item.nameFr="{ item }">
            <span
              class="text-uppercase font-weight-bold"
              v-if="item.parent === null"
              >{{ item.nameFr }}</span
            >
            <span v-else>{{ item.nameFr }}</span>
          </template>
          <template v-slot:item.nameEn="{ item }">
            <span
              class="text-uppercase font-weight-bold"
              v-if="item.parent === null"
              >{{ item.nameEn }}</span
            >
            <span v-else>{{ item.nameEn }}</span>
          </template>
        </v-data-table>
      <div v-if="$apollo.loading" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue-grey"
          indeterminate
        ></v-progress-circular>
      </div>
        <confirm ref="confirm"></confirm>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<style scoped>
</style>

<script>
import { atasQuery } from "@/graphql/ata/atas";
import { deleteAtaMutation } from "@/graphql/ata/delete_ata";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from '../../../components/sbuttonAdd.vue';

export default {
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      header: [
        { text: "ATA", value: "chapter", width: "50px" },
        { text: "IntituléFR", value: "nameFr", width: "400px" },
        { text: "IntituléEN", value: "nameEn", width: "400px" },
        { text: " ", value: "id", width: "90px", filterable: false },
      ],
      atas: [],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    atas: {
      query: atasQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    deleteAta(ata) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            console.log(ata);
            this.$apollo
              .mutate({
                mutation: deleteAtaMutation,
                variables: {
                  input: {
                    id: parseInt(ata.id, 10),
                  },
                },
                update: (store, { data: { deleteAta } }) => {
                  const data = store.readQuery({ query: atasQuery });
                  const newData = {
                    atas: data.atas.filter((b) => b.id !== deleteAta.ata.id),
                  };
                  store.writeQuery({ query: atasQuery, data: newData });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
};
</script>