<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs class="mt-8, mb-6, px-4, py-2  title--text text-uppercase" divider=">" :items="breadItems">
                      </v-breadcrumbs>
        </v-card>
      </v-col>
      <v-col cols="12">
        <data-table-piece></data-table-piece>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTablePiece from "./Aeronef/Part/dataTablePieces.vue"

export default {
  name: "GestionPiece",
  components: {
    dataTablePiece
  },
    metaInfo() {
    return {
      title: "Gestion pièces"
    }
  },
  data() {
    return {
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Gestion de navigabilité',
          disabled: false,
        },
        {
          text: 'Gestion d\'aéronef',
          disabled: false,
        },
        {
          text: 'Gestion des pièces',
          disabled: false,
        },
],
    };
  },
};
</script>