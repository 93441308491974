export const permissions = {
    can: (user, module) => {
        var mods = [];
        if (user.role == 'admin') {
            mods.push('admin')
            mods.push('audit')
            mods.push('veille')
        }
        return mods.includes(module)
    }
}