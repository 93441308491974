import gql from 'graphql-tag';
import { workOrderFragment } from './fragment_work_order';

export const changeWorkOrderItemStopkMutation = gql`
  mutation changeWorkOrderItemStop($input: ChangeWorkOrderItemStopInput!) {
    changeWorkOrderItemStop(input: $input) {
      workOrder {
        ...workOrder
      }
    }
}
${workOrderFragment.fragments.workOrder}`