<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
          <v-card-title class="headline text-uppercase">
            <span class="primary--text">Nouveau Livret</span>
          </v-card-title>
          <v-divider style="padding-bottom: 50px"></v-divider>

          <v-row>
            <v-col cols="4" offset="2">
              <v-autocomplete
                v-model="aircraft"
                :items="
                  aircrafts.map(function (t) {
                    return { text: t.immatriculation, value: t.id };
                  }).sort((a, b) => a.text.localeCompare(b.text))
                "
                label="Aéronef"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="bookletUpdatedAt"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="bookletUpdatedAt"
                    label="Date de mise à jour"
                    prepend-icon="mdi-calendar-month"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    @blur="date = parseDate(dateFormatted)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bookletUpdatedAt"
                  :first-day-of-week="1"
                  locale="fr"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"
                    >Annuler</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(bookletUpdatedAt)"
                    >Ok</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8"  offset="2">
              <v-select
                v-model="aircraftPart"
                :items="
                  getprimaryPartForSelect(aircrafts[aircraft]).map(function (t) {
                    return {
                      text: t.partModel.reference + t.serialNumber,
                      value: t.id,
                    };
                  })
                "
                label="Modele"
                v-if="aircraft"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" offset="2" v-if="status === 'sega'">
              <v-select
                v-model="location"
                :items="locationItems"
                label="Localisation"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                dense
                v-model="volume"
                label="Tome"
                type="number"
                min="1"
                max="5"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" offset="2">
              <v-select
                v-model="status"
                :items="statusItems"
                label="Status"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <sbuttonCancel
                :to="{ name: 'livrets' }"
                >Annuler</sbuttonCancel
              >
              <v-btn
                class="mr-4  primary white--text"
                dark
                @click="createBooklet()"
                >Ajouter</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { createBookletMutation } from "../../graphql/booklet/create_booklet";
import { aircraftsimmatQuery } from "../../graphql/aircraft/aircrafts_immat";
import { bookletsQuery } from "../../graphql/booklet/booklets";
import sbuttonCancel from '../../components/sbuttonCancel.vue';

export default {
  name: "NewDocument",
  components :{
    sbuttonCancel,
  },
  data() {
    return {
      menu: false,
      snackbar: false,
      aircraft: null,
      aircraftPart: null,
      aircrats: [],
      volume: 1,
      bookletUpdatedAt: new Date().toISOString().substr(0, 10),
      location: "",
      locationItems: [
        { text: "Grande Armoire", value: "grande_armoire" },
        { text: "Petite Armoire", value: "petite_armoire" },
        { text: "Armoire archive", value: "archive" },
      ],
      status: "sega",
      statusItems: [
        { text: "Chez Sega", value: "sega" },
        { text: "Demandé", value: "demande" },
        { text: "Renvoyé", value: "renvoye" },
      ],
    };
  },
  apollo: {
    aircrafts: {
      query: aircraftsimmatQuery,
    },
  },
  methods: {
    getprimaryPartForSelect(aircraft) {
      var parts = [];
      parts.push(aircraft.cell);
      aircraft.motors.forEach((motor) => {
        parts.push(motor);
      });
      return parts;
    },
    createBooklet() {
      this.$apollo
        .mutate({
          mutation: createBookletMutation,
          variables: {
            input: {
              attributes: {
                aircraftId: this.aircraft,
                aircraftPartId: this.aircraftPart,
                volume: parseInt(this.volume, 10),
                bookletUpdatedAt: this.bookletUpdatedAt,
                status: this.status,
                location: this.location,
              },
            },
          },
          update: (store, { data: { createBooklet } }) => {
            console.log(createBooklet);
            const data = store.readQuery({ query: bookletsQuery });
            data.booklets.push(createBooklet);
            store.writeQuery({ query: bookletsQuery, data });
          },
        })
        .then(() => {
          this.$router.push({ name: "livrets" });
        });
    },
  },
};
</script>
