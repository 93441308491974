import gql from 'graphql-tag';

export const deleteManufacturerMutation = gql`
  mutation deleteManufacturer($input: DeleteManufacturerInput!) {
    deleteManufacturer(input: $input) {
      manufacturer {
        id
        name
        tcHolder {
          id
          name
        }
      }
    }
}`
