import gql from 'graphql-tag';
import { contractFragment } from './fragment_contract';


export const createContractMutation = gql`
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      contract {
        ...contract
      }
    }
}
${contractFragment.fragments.contract}`
