<template>
  <v-container fluid>
    <v-col cols="12">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-row>
          <v-col cols="6">
            <v-sheet style="padding-bottom: 30px">
              <v-form>
                <v-card-title class="headline text-uppercase">
                  <span class="primary--text"
                    >Modifier Information générique</span
                  >
                </v-card-title>
                <v-divider style="padding-bottom: 50px"></v-divider>

                <v-row>
                  <v-col cols="2" offset="1" class="pb-2">
                    <v-checkbox v-model="cell" label="Cellule"></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-checkbox v-model="motor" label="Moteur"></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-checkbox v-model="propeller" label="Hélice"></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-checkbox v-model="part" label="Pièce"></v-checkbox>
                  </v-col>
                  <v-col cols="10" offset="1" class="py-0">
                    <!-- société -->
                    <v-text-field
                      label="Nom"
                      v-model="name"
                      outlined
                      dense
                      prepend-icon="mdi-office-building"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" offset="1" class="py-0">
                    <!-- Pays -->
                    <v-text-field
                      v-model="country"
                      label="Pays"
                      outlined
                      dense
                      prepend-icon="mdi-map-marker"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" offset="1" class="py-0">
                    <!-- Nom de contact -->
                    <v-text-field
                      label="Nom du contact"
                      v-model="contact"
                      outlined
                      dense
                      prepend-icon="mdi-email-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" offset="1" class="py-0">
                    <!-- email -->
                    <v-text-field
                      label="Email de contact"
                      v-model="email"
                      :rules="[rules.email]"
                      outlined
                      dense
                      prepend-icon="mdi-card-account-details-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" offset="1" class="py-0">
                    <v-textarea
                      label="Observations"
                      rows="9"
                      auto-grow
                      outlined
                      prepend-icon="mdi-eye"
                      v-model="observation"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-col>
          <v-col cols="6">
            <v-sheet style="padding-bottom: 30px">
              <v-form>
                <v-card-title class="headline text-uppercase">
                  <span class="primary--text">Information Supplémentaire</span>
                </v-card-title>
                <v-divider style="padding-bottom: 50px"></v-divider>

                <v-row>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      v-model="website"
                      outlined
                      dense
                      label="SiteWeb"
                      prepend-icon="mdi-web"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      v-model="login"
                      outlined
                      dense
                      label="Identifiant"
                      prepend-icon="mdi-account"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      v-model="password"
                      outlined
                      dense
                      label="Mot de passe"
                      prepend-icon="mdi-account-edit-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      outlined
                      dense
                      label="Index des manuels"
                      v-model="manualIndex"
                      prepend-icon="mdi-format-list-checkbox"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      outlined
                      dense
                      label="Index des SBs"
                      v-model="sbIndex"
                      prepend-icon="mdi-format-list-checkbox"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      v-model="name_manufacturer"
                      outlined
                      dense
                      label="Constructeur lié"
                      prepend-icon="mdi-archive-outline"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-col>
          <v-col cols="12" align="center" class="mb-4">
            <sbuttonCancel to="/admin?tab=tcholder">Annuler</sbuttonCancel>
            <v-btn
              class="mr-4 primary primary-text"
              dark
              @click="updateTcHolder"
              >Modifier</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { tcHolderQuery } from "@/graphql/tc_holder";
import { tcHoldersQuery } from "@/graphql/tc_holders";
import { updateTcHolderMutation } from "@/graphql/update_tc_holder";
import sbuttonCancel from "../../../components/sbuttonCancel.vue";
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateTcHolder",
  components: {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      cell: false,
      motor: false,
      propeller: false,
      part: false,
      name: null,
      country: null,
      contact: null,
      email: null,
      website: null,
      login: null,
      password: null,
      name_manufacturer: null,
      sbIndex: null,
      manualIndex: null,
      observation: null,
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "e-mail invalide.";
        },
        required: (value) => !!value || "Required.",
      },
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    tcHolder: {
      query: tcHolderQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        this.cell = data.tcHolder.cell;
        this.motor = data.tcHolder.motor;
        this.propeller = data.tcHolder.propeller;
        this.part = data.tcHolder.part;
        this.name = data.tcHolder.name;
        this.country = data.tcHolder.country;
        this.contact = data.tcHolder.contactName;
        this.email = data.tcHolder.contactEmail;
        this.website = data.tcHolder.website;
        this.login = data.tcHolder.login;
        this.password = data.tcHolder.password;
        this.manualIndex = data.tcHolder.manualIndex;
        this.sbIndex = data.tcHolder.sbIndex;
        this.name_manufacturer = data.tcHolder.manufacturersInfo;
        this.observation = data.tcHolder.observation;
      },
    },
  },
  methods: {
    updateTcHolder() {
      this.$apollo
        .mutate({
          mutation: updateTcHolderMutation,
          variables: {
            input: {
              id: parseInt(this.tcHolder.id, 10),
              attributes: {
                cell: this.cell,
                motor: this.motor,
                propeller: this.propeller,
                part: this.part,
                name: this.name,
                country: this.country,
                contactName: this.contact,
                contactEmail: this.email,
                website: this.website,
                login: this.login,
                password: this.password,
                sbIndex: this.sbIndex,
                manualIndex: this.manualIndex,
                observation: this.observation,
                manufacturersInfo: this.name_manufacturer,
              },
            },
          },
          update: (store, { data: { updateTcHolder } }) => {
            const data = store.readQuery({ query: tcHoldersQuery });
            const currentTcHolder = data.tcHolders.find(
              (b) => b.id === updateTcHolder.tcHolder.id
            );
            currentTcHolder.tcHolderUpdatedAt =
              updateTcHolder.tcHolder.tcHolderUpdatedAt;
            store.writeQuery({ query: tcHoldersQuery, data });
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "tcholder" } });
        });
    },
  },
};
</script>