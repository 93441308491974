import gql from 'graphql-tag';
import { documentProcessingFragment } from './fragment_document_processing';


export const updateDocumentProcessingMutation = gql`
  mutation updateProcessingDocument($input: UpdateDocumentProcessingInput!) {
    updateDocumentProcessing(input: $input) {
      documentProcessing {
        ...documentProcessing
      }
    }
}
${documentProcessingFragment.fragments.documentProcessing}`
