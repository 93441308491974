<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <span v-html="workOrderItem.question"></span>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="action" :row="true">
              <v-radio label="Effectué" value="done"></v-radio>
              <v-radio label="Reporté" value="report"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="action">
            <tiptap-vuetify
              v-model="observation"
              :extensions="extensions"
              :card-props="{ outlined: true }"
              :toolbar-attributes="{
                dense: true,
                color: '#eceff1',
              }"
              placeholder="Observations/Actions"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <v-col cols="12" class="mt-8">
          <v-btn class="mr-4 primary" dark @click="save()">Enregistrer</v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import { updateWorkOrderItemMutation } from "../../graphql/work_order/update_work_order_item"

import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";

export default {
  name: "RetourTravauxComponent",
  props: ["workOrderItem"],
  components: { TiptapVuetify },
  data() {
    return {
      action: null,
      observation: null,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    };
  },
  methods: {
    save() {
      this.$apollo.mutate({
        mutation: updateWorkOrderItemMutation,
        variables: {
          input: {
            id: parseInt(this.workOrderItem.id, 10),
            attributes: {
              observation: this.observation,
              status: this.action,
            },
          },
        },
      });
    },
  },
  apollo: {},
  created() {
    this.observation = this.workOrderItem.observation;
    this.action = this.workOrderItem.status;
  },
};
</script>