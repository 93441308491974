import gql from 'graphql-tag';

export const nextPesToExpiredQuery = gql`
  query nextPesToExpired {
    nextPesToExpired {
      id
      number
      expiredAt
      manualExpiredAt
      aircraft {
        id
        immatriculation
        longMaintenance
        supervisor {
          id
          name
        }
      }
    }
      
  }`
