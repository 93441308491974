<template>
  <v-container fluid class="background">
  <v-col cols="8" offset="2">
    <v-sheet outlined>
      <v-card-title>
        <sbuttonAdd class="primary--text" dark outlined :to="{ name: 'manufacturer_new' }"
          ><v-icon left dark> mdi-plus </v-icon>Nouveau Constructeur</sbuttonAdd
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :items="manufacturers"
        :headers="header"
        :sort-by="['name']"
        :footer-props="{
          'items-per-page-options': [13, 50, 100, -1],
          'items-per-page-text': 'lignes par page',
          'items-per-page-all-text': 'Tous',
        }"
        :items-per-page="13"
        :search="search"
        :loading="isLoading"
        loading-text="Chargement... merci de patienter"
        dense
        width:400
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="{ name: 'manufacturer_edit', params: { id: item.id } }"
              >
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on" color="red" @click="deleteManufacturer(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
        <template v-slot:item.name="{ item }">
          <span class="font-weight-bold">{{ item.name }}</span>
        </template>
      </v-data-table>
    <div v-if="$apollo.loading" class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue-grey"
        indeterminate
      ></v-progress-circular>
    </div>
      <confirm ref="confirm"></confirm>
    </v-sheet>
  </v-col>
  </v-container>
</template>

<script>
import { manufacturersQuery } from "../../../graphql/manufacturer/manufacturers";
import { deleteManufacturerMutation } from "../../../graphql/manufacturer/delete_manufacturer";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from '../../../components/sbuttonAdd.vue';

export default {
  name: "dataTableManufacturer",
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      manufacturers: [],
      header: [
        { text: "Constructeur", value: "name" },
        { text: "TC Holder parent", value: "tcHolder.name" },
        { text: " ", value: "id", width: "150px", filterable: false },
      ],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    manufacturers: {
      query: manufacturersQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    deleteManufacturer(manufacturer) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteManufacturerMutation,
                variables: {
                  input: {
                    id: parseInt(manufacturer.id, 10),
                  },
                },
                update: (store, { data: { deleteManufacturer } }) => {
                  const data = store.readQuery({ query: manufacturersQuery });
                  const newData = {
                    manufacturers: data.manufacturers.filter(
                      (b) => b.id !== deleteManufacturer.manufacturer.id
                    ),
                  };
                  store.writeQuery({
                    query: manufacturersQuery,
                    data: newData,
                  });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
};
</script>