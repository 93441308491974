<template>
  <div v-if="workshopTools">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <new-tool
              :contactId="contactId"
              @refreshPage="refreshPage()"
            ></new-tool>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="header"
            :items="workshopTools"
            :items-per-page="15"
            :footer-props="{
              'items-per-page-options': [15, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            :loading="isLoading"
            dense
            loading-text="Chargement... merci de patienter"
            no-results-text="Aucun résultat"
          >            
            <template v-slot:item.actions="{ item }">
              <new-tool
                :contactId="contactId"
                :workshopTool="item"
                @refreshPage="refreshPage()"
              ></new-tool>
              <v-btn icon>
                <v-icon color="red" @click="deleteWorkshopTool(item)"
                  >mdi-delete-outline</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <confirm ref="confirm"></confirm>
    </v-row>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { workshopToolsQuery } from "../../graphql/workshop_tool/workshop_tools";
import NewTool from "./NewTool.vue";
import { deleteWorkshopToolMutation } from "../../graphql/workshop_tool/delete_workshop_tool";
import confirm from "../../components/confirm.vue";

export default {
  name: "dataTableTools",
  props: {
    contactId: {
      type: String,
      required: false,
    },
  },
  components: {
    NewTool,
    confirm,
  },
  computed: {},
  methods: {
    refreshPage() {
      this.$apollo.queries.workshopTools.refetch();
    },
    deleteWorkshopTool(workshop_tool) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteWorkshopToolMutation,
                variables: {
                  input: {
                    id: parseInt(workshop_tool.id, 10),
                  },
                },
              })
              .then(() => {
                this.refreshPage();
              });
          }
        });
    },
  },
  data() {
    return {
      workshopTools: [],
      search: "",
      isLoading: true,
      show: false,
      header: [
        { text: "Modéle", value: "workshopToolModel.name" },
        { text: "Numéro Série", value: "serialNumber" },
        { text: "Date d'expiration", value: "expiredAt"},
        { text: "", value: "actions", width: "170px" },
      ],
      breadItems: [
        {
          text: "Accueil",
          disabled: false,
          href: "root",
        },
        {
          text: "Gestion des contacts clients",
          disabled: false,
        },
      ],
    };
  },
  apollo: {
    workshopTools: {
      query: workshopToolsQuery,
      variables() {
        return {
          workshopId: this.contactId ? parseInt(this.contactId, 10) : null,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>

