import gql from 'graphql-tag';
import { aircraftDocumentFragment } from './fragment_aircraft_document';


export const createAircraftDocumentMutation = gql`
  mutation createAircraftDocument($input: CreateAircraftDocumentInput!) {
    createAircraftDocument(input: $input) {
      aircraftDocument {
        ...aircraftDocument
      }
    }
}
${aircraftDocumentFragment.fragments.aircraftDocument}`
