<template>
  <div v-if="contact">
    <v-row>
      <v-col cols="5">
        <v-list dense>
          <v-card-title class="text-uppercase"
            ><v-icon style="margin-right: 10px"
              >mdi-card-account-details-outline</v-icon
            >
            Coordonnées
            <v-spacer></v-spacer>
            <v-btn
              icon
              :to="{ name: 'contact_edit', params: { id: contact.id } }"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              <template v-if="contact.name">
                <v-col cols="4">Société</v-col>
                <v-col cols="8" v-if="contact.name"
                  ><span class="rens"> {{ contact.name }}</span></v-col
                >
              </template>
              <template v-if="contact.LastName">
                <v-col cols="4">Nom</v-col>
                <v-col cols="8"
                  ><span class="rens"
                    >{{ contact.LastName }}
                    {{ contact.FirstName }}
                  </span></v-col
                >
              </template>

              <v-col cols="4">Adresse</v-col>
              <v-col cols="8" v-if="contact.invoiceAddress"
                ><span class="rens">{{ contact.invoiceAddress }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <template v-if="contact.invoiceAddress2">
                <v-col cols="4">Complément</v-col>
                <v-col cols="8"
                  ><span class="rens">{{ contact.invoiceAddress2 }}</span>
                </v-col></template
              >
              <v-col cols="4">Code Postal</v-col>
              <v-col cols="8" v-if="contact.invoiceZipcode"
                ><span class="rens">{{ contact.invoiceZipcode }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <v-col cols="4">Ville</v-col>
              <v-col cols="8" v-if="contact.invoiceCity"
                ><span class="rens">{{ contact.invoiceCity }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <v-col cols="4">Pays</v-col>
              <v-col cols="8" v-if="contact.invoiceCountry"
                ><span class="rens">{{ contact.invoiceCountry }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <v-col cols="4">Téléphone</v-col>
              <v-col cols="8" v-if="contact.phone"
                ><span class="rens">{{ contact.phone }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <v-col cols="4">Portable</v-col>
              <v-col cols="8" v-if="contact.mobile"
                ><span class="rens">{{ contact.mobile }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <v-col cols="4">mail</v-col>
              <v-col cols="8" v-if="contact.email"
                ><span class="rens">{{ contact.email }}</span></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>
              <v-col cols="4">Site web</v-col>
              <v-col cols="8" v-if="contact.website"
                ><span class="rens"
                  ><a :href="contact.website" target="_blank">{{
                    contact.website
                  }}</a></span
                ></v-col
              >
              <v-col cols="8" v-else>
                <span class="nonrens">Non renseigné</span>
              </v-col>

              
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="7" v-if="!contact.isWorkshop">
        <v-list dense>
          <v-card-title class="text-uppercase">
            <v-icon style="margin-right: 10px"
              >mdi-card-account-details-outline</v-icon
            >
            Contrats
          </v-card-title>
          <v-simple-table v-if="contracts.length > 0">
            <thead>
              <tr>
                <th style="width: 200px">Référence</th>
                <th style="width: 100px">Type</th>
                <th style="width: 100px">Agrément</th>
                <th style="width: 150px">Début</th>
                <th style="width: 150px">Renouvellement</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr
                  v-for="contract in contracts"
                  :key="contract.id"
                  :style="colorStyle(contract)"
                >
                  <td style="width: 150px">
                    <v-btn
                      text
                      :to="{ name: 'contrat', params: { id: contract.id } }"
                      >{{ contract.number }}</v-btn
                    >
                  </td>
                  <td v-if="contract.register == true">Déposé</td>
                  <td v-else>Commercial</td>
                  <td>{{ contract.contractType }}</td>
                  <td>{{ contract.start | moment("DD/MM/YYYY") }}</td>
                  <td>
                    {{ contract.expire | moment("DD/MM/YYYY") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
          <div v-else>
            <p style="font-style: italic; padding-left: 30px">
              Aucun contrat en cours
            </p>
          </div>
        </v-list>
        <v-list>
          <v-card-title class="text-uppercase">
            <v-icon style="margin-right: 10px"
              >mdi-card-account-details-outline</v-icon
            >
            Immatriculation
          </v-card-title>

          <v-simple-table v-if="contractAircrafts.length > 0">
            <thead>
              <tr>
                <th>Immatriculation</th>
                <th>Cellule</th>
                <th>Début</th>
                <th>Départ</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="contractAircraft in contractAircrafts"
                :key="contractAircraft.id"
                :style="colorStyle(contractAircraft.aircraftContrat)"
              >
                <td style="width: 150px">
                  <v-btn
                    text
                    :to="{
                      name: 'aeronef',
                      params: {
                        immatriculation:
                          contractAircraft.aircraftContrat.aircraft
                            .immatriculation, page: 'info'
                      },
                    }"
                    >{{
                      contractAircraft.aircraftContrat.aircraft.immatriculation
                    }}</v-btn
                  >
                </td>
                <td>
                  {{
                    contractAircraft.aircraftContrat.aircraft.cell.partModel
                      .tcHolder.name
                  }}
                  -
                  {{
                    contractAircraft.aircraftContrat.aircraft.cell.partModel
                      .reference
                  }}
                </td>
                <td>
                  {{
                    contractAircraft.aircraftContrat.start
                      | moment("DD/MM/YYYY")
                  }}
                </td>
                <td>
                  {{
                    contractAircraft.aircraftContrat.closedAt
                      | moment("DD/MM/YYYY")
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div v-else>
            <p style="font-style: italic; padding-left: 30px">
              Aucun immatriculation en gestion
            </p>
          </div>
        </v-list>
      </v-col>
      <v-col cols="7" v-if="contact.isWorkshop">
        <v-list dense>
          <v-card-title class="text-uppercase">
            <v-icon style="margin-right: 10px"
              >mdi-card-account-details-outline</v-icon
            >
            Liste des immats en maintenance
          </v-card-title>
          <v-simple-table>
            <thead>
              <tr>
                <th style="width: 100px">Immat</th>
                <th>Modèle</th>
                <th>Client</th>
              </tr>
            </thead>
            <tbody>
              <td></td>
              <td></td>
              <td></td>
            </tbody>
          </v-simple-table>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.nonrens {
  color: lightgrey;
  font-style: italic;
}

.rens {
  color: royalblue;
  font-style: italic;
}
</style>

<script>
import { contactQuery } from "../../graphql/contact/contact";
import { contractsQuery } from "../../graphql/contract/contracts";

export default {
  name: "ContactCoordonnees",

  data() {
    return {
      contracts: [],
    };
  },
  computed: {
    contractAircrafts() {
      var aircraftContracts = [];
      if (this.contracts) {
        this.contracts.forEach((contract) => {
          contract.aircraftContracts.forEach((ac) => {
            aircraftContracts.push({
              contractType: contract.contractType,
              register: contract.register,
              aircraftContrat: ac,
            });
          });
        });
      }
      return aircraftContracts;
    },
  },
  methods: {
    colorStyle(aircraftContrat) {
      if (aircraftContrat.closedAt) {
        return "text-decoration: line-through red; color: #ff0000";
      } else {
        return "";
      }
    },
  },
  apollo: {
    contact: {
      query: contactQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    contracts: {
      query: contractsQuery,
      result() {
        this.isLoading = false;
      },
      variables() {
        return {
          contactId: this.$route.params.id,
        };
      },
    },
  },
};
</script>
