import gql from 'graphql-tag';

export const aircraftsSuiviQuery = gql`query AircraftsSuivi($status: String)
      {
        aircrafts(status: $status) {
          id
          status
          immatriculation          
          contact {
              id
              name
          }
          supervisor {
            id
            name
          }
          cell {            
            partModel {              
              fullName              
            }
          }
          aircraftDocuments {
            id
            expiredAt
            status
            typeName
          }
          documentProcessings {
            id
            process
            document {
              type
            }
          }
        }
      }`
