import gql from 'graphql-tag';
import { flyFragment } from './fragment_fly';

export const createFlyMutation = gql`
  mutation createFly($input: CreateFlyInput!) {
    createFly(input: $input) {
      fly{
       ...fly 
      }
    }
}
${flyFragment.fragments.fly}`
