<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-form>
          <v-row>
            <v-col class="py-0">
              <new-user
                :contact_id="this.OT.workshop.id"
                @refreshPage="refreshPage()"
              ></new-user>
              <v-list>
                <v-list-item v-for="user in users" :key="user.id">
                  <v-checkbox
                    v-model="mechanics"
                    :label="`${user.name} -  ${user.trigram}`"
                    :value="user.id"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="mt-8">
              <v-btn class="mr-4 primary" dark @click="save()"
                >Enregistrer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { usersQuery } from "../../graphql/user/users";
import { updateWorkOrderMutation } from "../../graphql/work_order/update_work_order";
import NewUser from "../User/NewUser.vue";

export default {
  name: "MechanicsComponent",
  components: { NewUser },
  props: ["OT"],
  data() {
    return {
      mechanics: [],
      users: [],
    };
  },
  methods: {
    refreshPage() {
      this.$apollo.queries.users.refetch();
    },
    save() {
      this.$apollo.mutate({
        mutation: updateWorkOrderMutation,
        variables: {
          input: {
            id: parseInt(this.OT.id, 10),
            attributes: {
              returnMechanics: this.mechanics,
            },
          },
        },
      });
    },
  },
  computed: {},
  apollo: {
    users: {
      query: usersQuery,
      variables() {
        return {
          contactId:
            this.OT.workshop && this.OT.workshop.id
              ? parseInt(this.OT.workshop.id, 10)
              : null,
        };
      },
    },
  },
  created() {
    this.mechanics = this.OT.returnMechanics;
  },
};
</script>