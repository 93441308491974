import gql from 'graphql-tag';

export const itemStopsQuery = gql`query itemStops($aircraftId: ID, $aircraftPartId: ID, $inspection: Boolean, $documentProcessingId: ID, $type: String) { 
  itemStops(aircraftId: $aircraftId, aircraftPartId: $aircraftPartId, inspection: $inspection, documentProcessingId: $documentProcessingId, type: $type){
    action {
      id
      name
    }
    additionalInfoEn
    additionalInfoFr
    value
    globalCheck
    globalCheckLink
    reference
    priority
    aircraftPart {
      id
      serialNumber
      attachGroup
      partModel {
        id
        tcHolder {
          id
          name
        }
        reference
        designation
        type
        typeRaw
        category {
          id
          name
        }
        ata {
          id
          chapter
        }
      }
    }
    documentProcessing {
      id
      document {
        id
        number
        title
        numberRev
        tcHolders {
          id
          name
        }
      }
    }
    tcHolders {
      id
      name
    }
    createdAt
    id
    manufacturerDocRef
    nextActionDate
    stop {
      id
      name
      value
      inspection
    }
    updatedAt
    remainingText
    usedText
  }
}`