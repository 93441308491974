import gql from 'graphql-tag';

export const createCenMutation = gql`
  mutation createCen($input: CreateCenInput!) {
    createCen(input: $input) {
      cen {
        user {
          id
          name
          firstName
          lastName
          titleName
        }
        id
        reference
        aircraft {
          id
          immatriculation
          contact {
            id
            name
          }
        }
        action
        closedAt
        createdAt
        updatedAt
        deadlineAt
        
      }
    }
  }`
