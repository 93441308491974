<template>

    <v-container fluid>
      <v-row v-if="contract">
        <v-col cols="1" align="center">
          <v-btn
            icon
            fab
            outlined
            x-small
            class="primary--text"
            link
            to="/contrats"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10">
          <v-sheet outlined>
            <v-card-text style="background-color: #eceff1">
              <v-card-title class="text-h5 font-weight-bold">
                <p>CONTRAT DE GESTION DE NAVIGABILITE</p>
              </v-card-title>
              <v-card-subtitle class="text-h6">{{
                contract.number
              }}
              </v-card-subtitle>
            </v-card-text>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-card-title>
                    <v-icon style="margin-right: 10px"
                      >mdi-card-account-details-outline</v-icon
                    >
                    Informations
                    <v-spacer></v-spacer>
                    <v-menu open-on-hover offset-y bottom>
                      <template v-slot:activator="{ on }" >
                        <v-btn small fab icon outlined v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item :to="{
                              name: 'edit_contrat',
                              params: { id: contract.id },
                            }">Modifier
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title @click="renewContract()"
                              >Renouveller le contrat</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item>
                            <dialog-close-contract :contract="contract"></dialog-close-contract>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
               
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="1">
                          <v-icon>mdi-account-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span>Client</span></v-col>
                        <v-col cols="7">
                          <span>
                            <v-btn
                              large
                              text
                              :to="{
                                name: 'contact',
                                params: { id: contract.contact.id },
                              }"
                              >{{ contract.contact.name }}</v-btn
                            >
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-file-certificate-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span>Type</span></v-col>
                        <v-col cols="7" class="pl-8">
                          <span v-if="contract.register == true">DEPOSE</span>
                          <span v-else>COMMERCIAL</span>
                          <span> ( {{ contract.contractType }} )</span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span>Début de contrat</span></v-col>
                        <v-col cols="7" class="pl-8"
                          ><span>{{
                            contract.start | moment("DD/MM/YYYY")
                          }}</span></v-col
                        >
                      <template v-if="!contract.closedAt">    
                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="4"
                          ><span>Date d'expiration</span></v-col
                        >
                        <v-col cols="7" class="pl-8"
                          ><span >{{
                            contract.expire | moment("DD/MM/YYYY")
                          }}</span></v-col
                        >
                      </template>  

                      
                        <v-col cols="1">
                          <v-icon>mdi-file-clock-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span>Statut</span></v-col>
                        <v-col cols="7" class="pl-8">
                          <span v-if="contract.closedAt">
                            <v-chip
                              color="pink lighten-2"
                              label
                              small
                              text-color="white"
                            > Clôturé le {{
                            contract.closedAt | moment("DD/MM/YYYY")
                          }}</v-chip>
                          </span>
                          <span v-else>
                            <v-chip
                              color="blue lighten-2"
                              label
                              small
                              text-color="white"
                            > contrat en cours</v-chip>
                          </span>  
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-file-pdf-box</v-icon>
                        </v-col>
                        <v-col cols="4"><span>Contrat</span></v-col>
                        <v-col cols="7">
                          <v-btn
                            color="white"
                            class="red--text"
                            outlined
                            v-if="contract.document"
                            target="_blank"
                            :href="contract.document"
                            >Télécharger
                            <v-icon>mdi-file-pdf-box</v-icon></v-btn
                          >
                          <span v-else> Pas de contrat </span>
                        </v-col>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <dialog-close-contract :contract="contract"></dialog-close-contract>

                  <!-- <v-sheet outlined>
                    <v-card-title>
                      <v-icon style="margin-right: 10px">mdi-history</v-icon>
                      Historique
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="renew in contract.contractRenews"
                          :key="renew.id"
                        >
                          <v-list-item-content>
                            <span>
                              <b></b>
                              - Renouvellement du contrat au
                              {{ renew.start | moment("DD/MM/YYYY") }}
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-sheet> -->

                </v-col>
                <v-col cols="6">
                  <v-sheet>
                    <v-card-title>
                      <v-icon style="margin-right: 10px"
                        >mdi-clipboard-text-outline</v-icon
                      >
                      Immatriculations concernés
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        icon
                        fab
                        :to="{
                          name: 'new_contrat_immat',
                          params: { id: contract.id },
                        }"
                        ><v-icon>mdi-airplane-plus</v-icon></v-btn
                      >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list dense>
                        <v-list-item
                          v-for="aircraftContract in contract.aircraftContracts"
                          :key="aircraftContract.id"
                        >
                          <v-list-item-icon>
                           <v-icon v-if="aircraftContract.closedAt"
                            color="red"> mdi-close-circle</v-icon>
                           <v-icon v-else color="green"> mdi-check-circle</v-icon>
                           
                          </v-list-item-icon>
                          <dialogHistorique
                            :aircraftContract="aircraftContract"
                          ></dialogHistorique>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-sheet>

              <v-sheet>
                <v-card-title
                  class="text-uppercase subtitle-1 font-weight-medium"
                  ><v-icon style="margin-right: 10px"
                    >mdi-note-edit-outline</v-icon
                  >
                  NOTES
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialogNote" max-width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="primary--text"
                        icon
                        v-on="on"
                        @click="dialogNote = !dialogNote"
                        ><v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <v-card outlined>
                      <v-card-title> Notes </v-card-title>    
                      <v-divider class="mb-8"></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col cols="10" offset="1">
                            <v-textarea
                              style="padding: 0 16px 0 16px"
                              placeholder="Ajoutez un commentaire"
                              rows="3"
                              auto-grow
                              filled
                              v-model="notes"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialogNote = false"
                        >
                          Annuler
                        </v-btn>
                        <v-btn color="primary" text @click="updateNotes()">
                          Enregistrer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-title>
                <v-card-text>
                  <p v-if="notes" v-html="notes.replaceAll('\n','<br />')"> {{ notes }}</p>
                 
                </v-card-text>
              </v-sheet>

                  <!-- <v-sheet outlined>
                    <v-card-title
                      class="text-uppercase subtitle-1 font-weight-medium"
                    >
                      <v-row>
                        <v-col cols="1">
                          <v-icon>mdi-playlist-edit</v-icon>
                        </v-col>
                        <v-col align="left">Notes</v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-textarea
                        style="padding: 0 16px 0 16px"
                        placeholder="Ajoutez un commentaire"
                        rows="1"
                        auto-grow
                        v-model="notes"
                        @change="updateNotes()"
                      ></v-textarea>
                    </v-card-text>
                  </v-sheet> -->

                </v-col>
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

</template>

<style scoped>
.v-chip {
  margin: 4px 8px 4px 0;
}
.v-application a:hover {
  background-color: #b0bec5;
}
span {
  font-size: 14px;
}
</style>

<script>
import { contractQuery } from "../../graphql/contract/contract";
import dialogHistorique from "./dialogHistorique.vue";
import dialogCloseContract from "./dialogCloseContract.vue"
import { renewContractMutation } from "../../graphql/contract/renew_contract";
import { updateContractMutation } from "../../graphql/contract/update_contract";

export default {
  name: "Contract",
  components: { dialogHistorique, dialogCloseContract },
  data() {
    return {
      contract: null,
      dialogNote: false,
      notes: null,
    };
  },
  methods: {
    renewContract() {
      this.$apollo
        .mutate({
          mutation: renewContractMutation,
          variables: {
            input: {
              id: parseInt(this.contract.id, 10),
            },
          },
        })
        .then((data) => {
          console.log(data);
        });
    },
    updateNotes() {
      this.$apollo.mutate({
        mutation: updateContractMutation,
        variables: {
          input: {
            id: parseInt(this.contract.id, 10),
            attributes: {
              notes: this.notes,
            },
          },
        },
      })
             .then(() => {
          this.dialogObs = false;
        });
    },
  },
  apollo: {
    contract: {
      query: contractQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (data) {
          this.notes = data.contract.notes
        }
      }
    },
  },
};
</script>
