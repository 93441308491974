import gql from 'graphql-tag';

export const createCenCheckMutation = gql`
  mutation createCenCheck($input: CreateCenCheckInput!) {
    createCenCheck(input: $input) {
			cenCheck {
				id
				title
				description
				typeCheck
				order
				createdAt
				updatedAt
			}
    }
  }`
