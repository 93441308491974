<template>
  <div>
    <v-col cols="12">
      <v-card-subtitle>
        <span
          >Veuillez remplir ce champs si vous désirez ajouter des conseils personnalisé à la fin du rapport</span
        >
      </v-card-subtitle>
      <tiptap-vuetify
        v-model="advice"
        :extensions="extensions"
        :card-props="{ outlined: true }"
        :toolbar-attributes="{ dense: true, color: '#eceff1' }"
        placeholder="Listez les conseils pour le client"
      />
    </v-col>
    <v-col cols="12" align="center">
      <v-btn
        class="mr-4 primary--text"
        outlined
        depressed
        :exact="true"
        :to="{ name: 'cen', params: { id: cen.id } }"
        >Annuler</v-btn
      >
      <v-btn class="mr-4 primary" dark @click="updateCen()"> enregistrer</v-btn>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        rounded="pill"
        color="primary"
      >
        <span class="text-center">{{ text }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </div>
</template>



<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  Strike,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  Heading,
} from "tiptap-vuetify";
import { updateCenMutation } from "@/graphql/cen/update_cen";

export default {
  name: "misc",
  props: ["cen"],
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      snackbar: false,
      text: "Vos modifications ont bien été enregistrées",
      timeout: 1000,
      advice: this.cen.advice,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        [
          Heading,
          {
            options: {
              levels: [1, 2],
            },
          },
        ],
      ],
    };
  },
  methods: {
    updateCen() {
      this.$apollo
        .mutate({
          mutation: updateCenMutation,
          variables: {
            input: {
              id: parseInt(this.cen.id, 10),
              attributes: {
                advice: this.advice,
              },
            },
          },
        })
        .then(() => {
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
</style>