<template>
  <v-sheet outlined rounded height="254px">
    <v-card-title class="titre--text justify-center">Contrats-Avions</v-card-title>
    <v-card-text class="text-center pa-5 pb-0 pt-5">
      <v-row class="pb-6 no-gutters">
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#a6b3ff">
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ registerContractCount }}</p>
          <p style="font-size: 1rem">Contrats déposés</p>
        </v-col>
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#ff5992">
            <v-icon>mdi-airplane</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ registerAircraftCount }}</p>
          <p style="font-size: 1rem">Aéronefs déposés</p>
        </v-col>
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#ffc96f">
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ commercialContractCount }}</p>
          <p style="font-size: 1rem">Contrats commercials</p>
        </v-col>
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#68deb5">
            <v-icon>mdi-airplane</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ commercialAircraftCount }}</p>
          <p style="font-size: 1rem">Aéronefs commercials</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import { registerAircraftCountQuery } from '../graphql/contract/register_aircraft_count';
import { commercialAircraftCountQuery } from '../graphql/contract/commercial_aircraft_count';
import { registerContractCountQuery } from '../graphql/contract/register_contract_count';
import { commercialContractCountQuery } from '../graphql/contract/commercial_contract_count';

export default {
  data: () => ({}),
  apollo: {
    registerAircraftCount: {
      query: registerAircraftCountQuery,
    },
    commercialAircraftCount: {
      query: commercialAircraftCountQuery,
    },
    registerContractCount: {
      query: registerContractCountQuery,
    },
    commercialContractCount: {
      query: commercialContractCountQuery,
    },
  },
};
</script>
