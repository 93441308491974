<template>
  <v-container>
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <span class="primary--text">Nouvel audit</span>
      </v-card-title>
      <v-divider style="padding-bottom: 30px"></v-divider>
      <v-form>
        <v-row>
          <v-col cols="6" class="pt-4">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Numéro"
                  placeholder="2022-01"
                  v-model="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
								<v-menu
									v-model="menu"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									min-width="290px"
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											v-model="datePicker"
											label="Date de l'audit"

											outlined
											dense
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="start"
										:first-day-of-week="1"
										locale="fr"
										color="green"
										no-title
										@input="menu = false"
									>
									</v-date-picker>
								</v-menu>							
              </v-col>
            </v-row>
            <v-row>
							<v-col cols="6">							
								<v-select
									dense
									outlined
									label="référentiel"
									v-model="referentiel"
								></v-select>
							</v-col>
							<v-col cols="6">
								<v-select
									dense
									outlined
									label="Amendement"
									v-model="amendement"
								></v-select>
							</v-col>	
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-select
									outlined
									chips
									multiple
									dense
									label="chapitre(s) concerné(s)"
									v-model="chapConcerne"
								>

								</v-select>
							</v-col>
							<v-col cols="12">
								<v-text-field
									outlined
									dense
									label="M.A concerné(s)"
									v-model="refMA"
								>

								</v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									outlined
									dense
									label="thème de l'audit"
									v-model="theme"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-select
									outlined
									dense
									label="auditeur"
									v-model="auditeur"								
								>
								</v-select>
							</v-col>
							<v-col cols="6">
								<v-text-field
									outlined
									dense
									label="Source"
									v-model="source"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-menu
									v-model="menu1"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									min-width="290px"
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											v-model="datePicker"
											label="Date de clôture"

											outlined
											dense
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="start2"
										:first-day-of-week="1"
										locale="fr"
										color="green"
										no-title
										@input="menu = false"
									>
									</v-date-picker>
								</v-menu>								
							</v-col>
							<v-col cols="6">


							</v-col>
						</v-row>
					</v-col>
					<v-col cols="6" class="pt-4">
						<v-row>
							<v-textarea
							outlined
							rows="12"
							auto-grow
							label="Procédure"
							v-model="procedure"
							>
							</v-textarea>
						</v-row>
						<v-row>
							<v-textarea
							outlined
							auto-grow
							label="Note"
							v-model="note"
							>
							</v-textarea>
						</v-row>
					</v-col>
						

        </v-row>

        <v-col cols="12" align="center" class="mt-8">
          <sbuttonCancel to="/audits"
            >Annuler</sbuttonCancel
          >
          <v-btn class="mr-4 primary primary-text" dark
            >Créer</v-btn>
        </v-col>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>

import sbuttonCancel from '../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../components/v-btn.vue';

export default {
  name: "NewAudit",
  components: {
		sbuttonCancel,
		//sbuttonSave,
	},
  data() {
    return {
			start: null,
      menu: false,
			start1: null,
      menu1: false,
};
  },
	computed: {
    datePicker() {
      return this.formatDate(this.start);
    },
	},
	methods: {
		formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },		
  apollo: {

  },
};
</script>