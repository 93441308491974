import gql from 'graphql-tag';
import { cenCheckValueFragment } from './fragment_cen_check_value';


export const updateCenCheckValueMutation = gql`
  mutation updateCenCheckValue($input: UpdateCenCheckValueInput!) {
    updateCenCheckValue(input: $input) {
      cenCheckValue {
        ...cenCheckValue      
      }
    }
} ${cenCheckValueFragment.fragments.cenCheckValue}`
