import gql from 'graphql-tag';
import { workOrderItemFragment } from './fragment_work_order_item';

export const createWorkOrderItemMutation = gql`
  mutation createWorkOrderItem($input: CreateWorkOrderItemInput!) {
    createWorkOrderItem(input: $input) {
      workOrderItem {
        ...workOrderItem
      }
    }
}
${workOrderItemFragment.fragments.workOrderItem}`