<template>
  <v-container fluid>
    <v-col cols="10" offset="1">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouvel | outil</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="6" offset="3">
                <!-- anti doublon -->
                <v-text-field
                  dense
                  outlined
                  label="Nom"
                  v-model="name"
                  :rules="[rules.required]"
                  counter="250"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  to="/admin?tab=outillage"
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark v-if="workshopToolModel"  @click="save()"
                  >Modifier</v-btn>
                <v-btn class="mr-4 primary primary-text" dark v-else  @click="save()"
                  >Créer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { createWorkshopToolModelMutation } from "../../../graphql/workshop_tool/create_workshop_tool_model";
import { updateWorkshopToolModelMutation } from "../../../graphql/workshop_tool/update_workshop_tool_model";
import { workshopToolModelQuery } from "../../../graphql/workshop_tool/workshop_tool_model";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "dataTableAction",
  components : {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      name: null,
      workshopToolModelId: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.workshopToolModelId = this.$route.params.id;
  },
  methods: {
    save() {
      if (this.workshopToolModel) {
        this.$apollo
        .mutate({
          mutation: updateWorkshopToolModelMutation,
          variables: {
            input: {
              id: parseInt(this.workshopToolModel.id, 10),
              attributes: {
                name: this.name,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "outillage" } });
        });
      } else {      
      this.$apollo
        .mutate({
          mutation: createWorkshopToolModelMutation,
          variables: {
            input: {
              attributes: {
                name: this.name,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "outillage" } });
        });
      }
    },
  },
  apollo: {
    workshopToolModel: {
      query: workshopToolModelQuery,
      variables() {
        return {
          id: this.workshopToolModelId,
        };
      },
      skip() {
        return !this.workshopToolModelId;
      },
      result({ data }) {
        if (data) {
          console.log(data);
          this.name = data.workshopToolModel.name;
        }
      },
    },
  },
};
</script>