import gql from 'graphql-tag';

export const deleteCustomQueryMutation = gql`
  mutation deleteCustomQuery($input: DeleteCustomQueryInput!) {
    deleteCustomQuery(input: $input)  {
      customQuery {
        id
        name
        report
        edition
        customQueryType
        updatedAt
        createdAt
      }
    }
  }
  `
