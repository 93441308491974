<template>
  <div>
    <!-- <v-col cols="4">
      <v-select dense class="py-0" label="Filtre">

      </v-select>
    </v-col> -->
    <!-- <v-virtual-scroll :items="itemStops" :item-height="50" height="500">
      <template v-slot:default="{ item }"> -->
        
        <!-- <v-list three-line>
          <v-list-item :key="item.id" @click="save(item)">
            <v-list-item-action>
              <v-checkbox
                dense
                :input-value="
                  workOrderItemStops.find((d) => d.itemStop.id == item.id)
                    ? true
                    : false
                "
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle
                >{{ item.aircraftPart.partModel.ata.chapter }} -
                {{ item.aircraftPart.partModel.tcHolder?.name }}
                {{ item.aircraftPart.partModel.designation }} - 
                {{ item.aircraftPart.partModel.reference }}
                <span v-if="item.aircraftPart.serialNumber">(S/N:
                {{ item.aircraftPart.serialNumber }})</span> 
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.stop.name }} - {{ item.action.name }} 
                <span v-if="item.additionalInfoFr">{{ item.additionalInfoFr }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon small color="red" class="mr-2" v-if="item.remainingText">mdi-clock</v-icon>
                <span class="red--text" text-subtitle-2 v-html="item.remainingText"> </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
         
        <v-row>
          <v-col cols="3">  
            <v-select
              label="Filtrer"
              v-model="TimeFilter"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">  
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
        class="pb-8"
        :items="itemStops"
        :headers="headers"
        disable-pagination
        hide-default-footer
        :sort-by="['aircraftPart.partModel.ata.chapter']"
        >
            <template v-slot:item.check="{ item }">
                <v-checkbox
                dense
                 @change="save(item)"
                 :input-value="
                  workOrderItemStops.find((d) => d.itemStop.id == item.id)
                    ? true
                    : false
                "
              ></v-checkbox>
            </template>
            <template v-slot:item.ata="{ item }">
                <span>{{ item.aircraftPart.partModel.ata.chapter }}</span> 
            </template>
            <template v-slot:item.text="{ item }">
              <div>
                {{ item.aircraftPart.partModel.tcHolder?.name }}
                <span v-if="item.aircraftPart.partModel.designation">{{ item.aircraftPart.partModel.designation }} - </span>
                <span v-if="item.aircraftPart.partModel.reference">{{ item.aircraftPart.partModel.reference }}</span>
                <span v-if="item.aircraftPart.serialNumber" class="font-italic"> (S/N:
                {{ item.aircraftPart.serialNumber }})</span>
              </div>
            </template>
            <template v-slot:item.stop="{ item }">
              <div>
                {{ item.stop.name }} - {{ item.action.name }} 
                <span v-if="item.additionalInfoFr">{{ item.additionalInfoFr }}</span>
              </div>
            </template>
            <template v-slot:item.remain="{ item }">
              <div>
                <!-- <v-icon small color="red" class="mr-2" v-if="item.remainingText">mdi-clock</v-icon> -->
                <span class="red--text" text-subtitle-2 v-html="item.remainingText">
               </span>
              </div>
            </template>
        </v-data-table>
      <!-- </template>
    </v-virtual-scroll> -->
  </div>
  
</template>

<style scoped>
v-checkbox.v-icon {
    font-size: 24px;
}
</style>

<script>
import { changeWorkOrderItemStopkMutation } from "../../graphql/work_order/change_work_order_item_stop";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";

export default {
  name: "Piece",
  components: {},
  props: ["OT"],
  data() {
    return {
      itemStops: [],
      workOrderItemStops: [],
      selectedStop: [],
      workOrder: null,
      headers: [
        { text: "", value: "check" },
        { text: "ATA", value: "ata" },
        { text: "items", value: "text" },
        { text: "Butée", value: "stop" },
        { text: "Temps", value: "remain" },
      ],
      TimeFilter: [],
      search: "",
    };
  },
  methods: {
    save(item) {
      this.$apollo.mutate({
        mutation: changeWorkOrderItemStopkMutation,
        variables: {
          input: {
            id: parseInt(this.OT.id, 10),
            itemStopId: parseInt(item.id, 10),
          },
        },
      }).then(() => {
        this.$apollo.queries.workOrderItemStops.refetch();
      });
    },
  },
  computed: {},
  apollo: {
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.OT.aircraft.id, 10),
          type: "aircraft_part",
          inspection: false,
        };
      },
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: this.OT.id,
        };
      },
    },
  },
};
</script>