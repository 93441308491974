<template>
  <div>
    <v-row>
      <v-col offset="3" cols="6">
        <v-sheet class="mt-16">
          <v-col align="center">
            <p><img src="@/assets/logo.png" height="100px" /></p>
            <p class="text-h4 primary--text text-center">PORTAIL IGAME</p>
          </v-col>

          <v-form class="pt-8">
            <v-row>
              <v-col cols="6" offset="3">
                <v-text-field
                  outlined
                  rounded
                  placeholder="email@sega-aero.fr"
                  type="email"
                  v-model="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" offset="3">
                <v-text-field
                  outlined
                  rounded
                  placeholder="Mot de passe"
                  type="password"
                  v-model="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" offset="3" align="center" class="mt-6">
                <v-btn
                  class="mr-4 primary"
                  dark
                  outlined
                  depressed
                  @click.prevent="login()"
                  >Login</v-btn
                >
              </v-col>
            </v-row>
          </v-form></v-sheet
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { loginMutation } from "@/graphql/login";
import { meQuery } from "@/graphql/me";

export default {
  name: "Login",
  components: {},
  data: () => ({
    email: null,
    password: null,
    me: null,
  }),
  methods: {
    login() {
      this.$apollo.mutate({
        mutation: loginMutation,
        variables: {
          input: {
            email: this.email,
            password: this.password,
          },
        },
        update: (store, { data: { login } }) => {
          console.log(login);
          localStorage.setItem("apollo-token", login.token);
          this.$apollo.queries.me.refetch();
        },
      });
    },
  },
  apollo: {
    me: {
      query: meQuery,
    },
  },
};
</script>