<template>
  <v-container fluid class="background">
  <v-col cols="6" offset="3">
    <v-sheet outlined>
      <v-card-title>
        <sbuttonAdd :to="{ name: 'tcholder_new' }"
          ><v-icon left dark> mdi-plus </v-icon>Nouveau TcHolder</sbuttonAdd
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :items="tcHolders"
        :headers="header"
        :footer-props="{
          'items-per-page-options': [13, 50, 100, -1],
          'items-per-page-text': 'lignes par page',
          'items-per-page-all-text': 'Tous',
        }"
        :items-per-page="13"
        :search="search"
        :loading="isLoading"
        loading-text="Chargement... merci de patienter"
        dense
        width:400
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="{ name: 'tcholder_edit', params: { id: item.id } }"
              >
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on" color="red" @click="deleteTcHolder(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <div v-if="$apollo.loading" class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue-grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <confirm ref="confirm"></confirm>
  </v-col>
  </v-container>
</template>

<script>
import { tcHoldersQuery } from "../../../graphql/tc_holders";
import { deleteTcHolderMutation } from "@/graphql/delete_tc_holder";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from "../../../components/sbuttonAdd.vue"

export default {
  name: "dataTableTcHolder",
    components: {
    confirm,
    sbuttonAdd
  },
  data() {
    return {
      tcHolders: [],
      header: [
        { text: "Nom", value: "name" },
        { text: " ", value: "id", width: "150px", align: 'right', filterable: false },
      ],
      search: "",
      isLoading: true,
    };
  },
  methods: {
    deleteTcHolder(tcHolder) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
              this.$apollo
              .mutate({
                mutation: deleteTcHolderMutation,
                variables: {
                  input: {
                    id: parseInt(tcHolder.id, 10),
                  },
                },
                update: (store, { data: { deleteTcHolder } }) => {
                  const data = store.readQuery({ query: tcHoldersQuery });
                  console.log(deleteTcHolder);
                  const newData = {
                    tcHolders: data.tcHolders.filter(
                      (b) => b.id !== deleteTcHolder.tcHolder.id
                    ),
                  };
                  store.writeQuery({ query: tcHoldersQuery, data: newData });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    }
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>