import gql from 'graphql-tag';
import { aircraftPhotoFragment } from './fragment_aircraft_photo';


export const deleteAircraftPhotoMutation = gql`
  mutation deleteAircraftPhoto($input: DeleteAircraftPhotoInput!) {
    deleteAircraftPhoto(input: $input) {
      aircraftPhoto {
        ...aircraftPhoto
      }
    }
}
${aircraftPhotoFragment.fragments.aircraftPhoto}`
