import gql from 'graphql-tag';

export const tcHoldersQuery = gql`
{
  tcHolders {
    id
    name
    website
    login
    password
    cell
    motor
    propeller
    part
    country
    contactName
    contactEmail
    manualIndex
    sbIndex
    manufacturersInfo
    observation
    manufacturers {
      id
      name
    }
  }
}`