<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-dialog
              v-model="dialog_upload"
              persistent
              hide-overlay
              max-width="600"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="primary--text"
                  dark
                  outlined
                  v-on="on"
                  @click="dialog_upload = !dialog_upload"
                >
                  Nouvelle Modification/STC
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline"
                  >Ajout de la modification/STC</v-card-title
                >
                <v-card-text class="pt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="number"
                          dense
                          outlined
                          label="n° dossier"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="datePicker"
                              label="Date de signature"
                              prepend-icon="mdi-calendar-month"
                              outlined
                              dense
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="customizationDate"
                            :first-day-of-week="1"
                            locale="fr"
                            color="green"
                            no-title
                            @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          v-model="description"
                          label="Description"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <dashboard :uppy="uppy" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-4 primary--text"
                    outlined
                    depressed
                    @click="cleanDialog()"
                  >
                    Fermer</v-btn
                  >
                  <v-btn class="mr-4 primary" dark @click="saveCustomization()"
                    >Uploader</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-row></v-row>
          </v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items="aircraftCustomizations"
            :sort-by="['customizationDate']"
            hide-default-footer
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            no-results-text="Aucun résultat"
            disable-pagination
          >
            <template v-slot:item.pdf="{ item }">
              <v-btn
                icon
                v-if="item.document"
                :href="item.document"
                target="_blank"
              >
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn  icon v-else>
                  <v-icon class="grey--text">mdi-block-helper</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="editCustomization(item)">
                <v-icon color="">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteCustomization(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
             <template v-slot:item.customizationDate="{ item }">
              <span v-if="item.customizationDate">{{
                item.customizationDate | moment("DD/MM/YYYY")
              }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <confirm ref="confirm"></confirm>

    </v-row>
  </div>
</template>

<style>
</style>


<script>
import { aircraftCustomizationsQuery } from "../../../graphql/aircraft_customization/aircraft_customizations";
import { createAircraftCustomizationMutation } from "../../../graphql/aircraft_customization/create_aircraft_customization";
import { updateAircraftCustomizationMutation } from "../../../graphql/aircraft_customization/update_aircraft_customization";
import { deleteAircraftCustomizationMutation } from "../../../graphql/aircraft_customization/delete_aircraft_customization";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import confirm from "@/components/confirm.vue";

export default {
  name: "dataTableCustomization",
  props: {
    aircraftId: {
      type: String,
      required: false,
    },
  },
  components: {
    Dashboard,
    confirm,
  },
  data() {
    return {
      // aircraft: null,
      aircraftCustomizations: [],
      isLoading: true,
      dialog_upload: false,
      modal: false,
      menu: false,
      customizationDate: null,
      uploadedFileID: null,
      description: null,
      number: null,
      customizationId: null,
      headers: [
        { text: "pdf", value: "pdf", width: "70px" },


        {
          text: "Date",
          value: "customizationDate",
          width: "100px",
        },
        { text: "Ref", value: "number", width: "200px" },
        {
          text: "Description",
          value: "description",
        },
        { text: "", value: "action", width: "140px" },
      ],
    };
  },
  methods: {
    cleanDialog() {
      this.uploadedFileID = null;
      this.number = null;
      this.customizationDate = null;
      this.description = null;
      this.customizationId = null;
      this.dialog_upload = false;
      this.uppy.reset();
    },
    editCustomization(item) {
      this.number = item.number;
      this.description = item.description;
      this.customizationDate = item.customizationDate;
      this.customizationId = item.id;
      this.uploadedFileID = item.documentSignedId;
      this.dialog_upload = true;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    deleteCustomization(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAircraftCustomizationMutation,
                variables: {
                  input: {
                    id: item.id,
                  },
                },
              })
              .then(() => {
                this.$apollo.queries.aircraftCustomizations.refetch();
              });
          }
        });
    },
    saveCustomization() {
      if (this.customizationId) {
        this.$apollo
          .mutate({
            mutation: updateAircraftCustomizationMutation,
            variables: {
              input: {
                id: this.customizationId,
                attributes: {
                  customizationDate: this.customizationDate,
                  document: this.uploadedFileID,
                  number: this.number,
                  description: this.description,
                  aircraftId: this.aircraftId,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.aircraftCustomizations.refetch();
            this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftCustomizationMutation,
            variables: {
              input: {
                // id: parseInt(this.maintenanceProgramme.id, 10),
                attributes: {
                  customizationDate: this.customizationDate,
                  document: this.uploadedFileID,
                  number: this.number,
                  description: this.description,
                  aircraftId: this.aircraftId,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.aircraftCustomizations.refetch();
            this.cleanDialog();
          });
      }
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.customizationDate);
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
  },
  apollo: {
    aircraftCustomizations: {
      query: aircraftCustomizationsQuery,
      variables() {
        return {
          aircraftId: this.aircraftId ? parseInt(this.aircraftId, 10) : null,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
