<template>
  <v-card outlined>
  <v-row>
    <v-col cols="5">
      <mechanics-component :OT="OT"></mechanics-component
    ></v-col>
    <v-col cols="7"> <tools-component :OT="OT"></tools-component></v-col>
  </v-row></v-card>
</template>
<script>
import MechanicsComponent from "./MechanicsComponent.vue";
import ToolsComponent from "./ToolsComponent.vue";

export default {
  name: "RetourAdministratif",
  props: ["OT"],
  components: {
    MechanicsComponent,
    ToolsComponent,
  },
};
</script>