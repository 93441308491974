import gql from 'graphql-tag';

export const createManufacturerMutation = gql`
  mutation createManufacturer($input: CreateManufacturerInput!) {
    createManufacturer(input: $input) {
      manufacturer {
        id
        name
        tcHolder {
          id
          name
        }
      }
    }
}`