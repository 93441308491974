<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10" offset="1">
        <v-sheet outlined>
          <v-card-title>
						<h2 class="primary--text">VOCABULAIRE AERONAUTIQUE</h2>
						<v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :items="documents"
            :headers="header"
            :footer-props="{
              'items-per-page-options': [20, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :items-per-page="20"
            :search="search"
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            dense
            width:400
            no-results-text="Aucun résultat"
          >

          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { documentsQuery } from "../../graphql/document/documents";

export default {
  name: "lexique",
  components: {

  },
  data() {
    return {
      header: [
        { text: "Français", value: "titleFr" },
        { text: "Anglais", value: "titleEn" },
      ],
      search: "",
      isLoading: true,
    };
  },
	// computed: {
  //   items: function () {
  //     if (this.typeDoc == "AdSb") {
  //       return ["Ad", "Sb"];
  //   },
  apollo: {
    documents: {
      query: documentsQuery,
      variables() {
        return {
          type: ["Ad", "Sb"],
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
