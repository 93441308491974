<template>
  <div>
    <v-container fluid v-if="contact" style="width: 100%" color="background">
      <v-row>
        <!-- info -->
        <v-col cols="3">
          <v-sheet outlined min-height="813" justify-center>
            <v-card-text align="center">
              <div v-if="contact.type == 'Workshop'">
                <v-avatar color="blue lighten-2" size="36">
                  <v-icon dark>mdi-tools</v-icon>
                </v-avatar>
              </div>
              <div v-if="contact.type == 'AeroClub'">
                <v-avatar color="green lighten-2" size="36">
                  <v-icon dark>mdi-airport</v-icon>
                </v-avatar>
              </div>
              <div v-if="contact.type == 'Engineer'">
                <v-avatar color="indigo lighten-2" size="36">
                  <v-icon dark>mdi-wrench</v-icon>
                </v-avatar>
              </div>
              <div v-if="contact.type == 'Owner'">
                <v-avatar color="purple lighten-2" size="36">
                  <v-icon dark>mdi-airplane</v-icon>
                </v-avatar>
              </div>
              <div v-if="contact.type == 'Company'">
                <v-avatar color="orange lighten-2" size="36">
                  <v-icon dark>mdi-office-building</v-icon>
                </v-avatar>
              </div>
              <div v-if="contact.type == 'Rental'">
                <v-avatar color="brown lighten-2" size="36">
                  <v-icon dark>mdi-location-exit</v-icon>
                </v-avatar>
              </div>
              <div style="padding: 10px 0 10px 0">
                <span class="text-h5 font-weight-bold text-uppercase" style="color: #2979ff">
                  {{ contact.name }}<br />
                </span>
                <span class="text-caption">
                  Dernière mise à jour :
                  {{ contact.updatedAt | moment("DD/MM/YYYY") }}
                </span>
              </div>
            </v-card-text>
            <v-col cols="12">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-col cols="5">Réf/client</v-col>
                    <v-col cols="7" style="font-weight: 600">{{
                      contact.reference
                    }}</v-col>
                    <v-divider></v-divider>
                    <v-col cols="5">Type</v-col>
                    <v-col
                      cols="7"
                      v-if="contact.type == 'AeroClub'"
                      style="font-weight: 600"
                      >Aéroclub</v-col
                    >
                    <v-col cols="7" v-if="contact.type == 'Workshop'"
                      >Atelier</v-col
                    >
                    <v-col cols="7" v-if="contact.type == 'Engineer'"
                      >Mécanicien Ind.</v-col
                    >
                    <v-col cols="7" v-if="contact.type == 'Rental'"
                      >Location</v-col
                    >
                    <v-col cols="7" v-if="contact.type == 'Owner'"
                      >Propriétaire</v-col
                    >
                    <v-col cols="7" v-if="contact.type == 'Company'"
                      >Société</v-col
                    >
                    <v-divider></v-divider>
                    <template v-if="contact.type == 'Engineer' || contact.type == 'Workshop' ">
                      <v-col cols="5" v-if="contact.type == 'Engineer'">Licence</v-col>
                      <v-col cols="5" v-if="contact.type == 'Workshop'">Agrément</v-col>
                      <v-col cols="7">{{ contact.easaNumber}}</v-col>
                      <v-divider></v-divider>
                    </template>
                    <v-col cols="5">Statut</v-col>
                    <v-col cols="7">
                      <span
                        v-if="contact.status == 'active'"
                        style="font-weight: 600"
                        >Active</span
                      >
                      <span
                        v-if="contact.status == 'archived'"
                        style="font-weight: 600"
                        >Archivé</span
                      >
                      <span
                        v-if="contact.managed == true"
                        style="font-weight: 600"
                      >
                        / En gestion</span
                      >
                    </v-col>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!-- observations -->
            <v-col cols="12">
              <v-sheet outlined>
                <v-card-title
                  class="text-uppercase subtitle-1 font-weight-medium"
                  ><v-icon style="margin-right: 10px"
                    >mdi-note-edit-outline</v-icon
                  >
                  Observations
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialogObs" max-width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="primary--text"
                        icon
                        v-on="on"
                        @click="dialogObs = !dialogObs"
                        ><v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title> Observations </v-card-title>
                      <v-divider class="mb-8"></v-divider>
                      <v-card-text style="min-height:500px">
                        <v-row>
                          <v-col cols="10" offset="1">
                            <v-textarea
                              style="padding: 0 16px 0 16px"
                              placeholder="Ajoutez un commentaire"
                              auto-grow
                              filled
                              v-model="notes"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialogObs = false"
                        >
                          Annuler
                        </v-btn>
                        <v-btn color="primary" text @click="updateNotes()">
                          Enregistrer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-title>
                <v-card-text style="min-height:300px">
                  {{ contact.notes }}
                </v-card-text>
              </v-sheet>
            </v-col>
          </v-sheet>
        </v-col>
        <!-- tabs -->
        <v-col cols="9">
          <v-tabs
            v-model="tab"
            background-color="background"
            color="titre"
            centered
            center-active
            grow
            show-arrows
            icons-and-text
            class="pb-4"
          >
            <v-tabs-slider color="#2979FF"></v-tabs-slider>
            <v-tab href="#coordonnees">
              Information
              <v-icon>mdi-account-details-outline</v-icon>
            </v-tab>
            <v-tab href="#tools" v-if="contact.isWorkshop">
              Outils
              <v-icon>mdi-tools</v-icon>
            </v-tab>            
            <v-tab href="#contact">
              Contact
              <v-icon>mdi-calculator</v-icon>
            </v-tab>
            <v-tab href="#invoice">
              Facturation
              <v-icon>mdi-calculator</v-icon>
            </v-tab>

          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="coordonnees">
              <contact-coordonnees />
            </v-tab-item>
            <v-tab-item value="tools">
              <dataTableTools :contactId="contact.id" />
            </v-tab-item>
             <v-tab-item value="contact">
              <dataTableUser :contactId="contact.id" />
            </v-tab-item>           
            <v-tab-item value="invoice">
              <data-table-invoice :contactId="contact.id" />
            </v-tab-item>


          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.v-card__text {
  height: 150px;
}

.nonrens {
  color: lightgrey;
  font-style: italic;
}

.rens {
  color: royalblue;
  font-style: italic;
}
</style>

<script>
import { contactQuery } from "../../graphql/contact/contact";
import dataTableInvoice from "../Facturation/dataTableInvoice.vue";
import dataTableUser from "../User/dataTableUser.vue";
import dataTableTools from "./dataTableTools.vue";
import ContactCoordonnees from "./ContactCoordonnees.vue";
import { updateContactMutation } from "../../graphql/contact/update_contact";

export default {
  name: "Contact",
  components: {
    dataTableInvoice,
    dataTableUser,
    ContactCoordonnees,
    dataTableTools
  },
  data() {
    return {
      dialogObs: false,
      civilite: "",
      name: "",
      societe: "",
      adresse: "",
      complement: "",
      cp: "",
      ville: "",
      pays: "",
      mail_fact: "",
    };
  },
  apollo: {
    contact: {
      query: contactQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          this.notes = data.contact.notes;
        }
      },
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  methods: {
    updateNotes() {
      this.$apollo
        .mutate({
          mutation: updateContactMutation,
          variables: {
            input: {
              id: parseInt(this.contact.id, 10),
              attributes: {
                notes: this.notes,
              },
            },
          },
        })
        .then(() => {
          this.dialogObs = false;
        });
    },
  },
};
</script>