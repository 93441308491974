<template>
  <v-container fluid>
    <v-col cols="12">
      <v-sheet outlined style="padding-bottom: 30px">
      <v-row>
        <v-col cols="6">
          <v-sheet style="padding-bottom: 30px">
              <v-form>
              <v-card-title class="headline text-uppercase">
                <span class="primary--text">Nouvelle Information générique</span>
              </v-card-title>
              <v-divider style="padding-bottom: 50px"></v-divider>
            
              <v-row>
                <v-col cols="2" offset="1" class="pb-2">
                  <v-checkbox v-model="cell" label="Cellule"></v-checkbox>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-checkbox v-model="motor" label="Moteur"></v-checkbox>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-checkbox v-model="propeller" label="Hélice"></v-checkbox>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-checkbox v-model="part" label="Pièce"></v-checkbox>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <!-- société -->
                  <v-text-field
                    label="Nom"
                    v-model="name"
                    outlined
                    dense
                    prepend-icon="mdi-office-building"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <!-- Pays -->
                  <v-text-field
                    label="Pays"
                    outlined
                    dense
                    prepend-icon="mdi-map-marker"
                    v-model="country"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <!-- Nom de contact -->
                  <v-text-field
                    label="Nom du contact"
                    outlined
                    dense
                    prepend-icon="mdi-email-outline"
                    v-model="contactName"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <!-- email -->
                  <v-text-field
                    label="Email de contact"
                    v-model="contactEmail"
                    :rules="[rules.email]"
                    outlined
                    dense
                    prepend-icon="mdi-card-account-details-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <v-textarea
                    label="Observations"
                    rows="9"
                    auto-grow
                    outlined
                    prepend-icon="mdi-eye"
                    v-model="observation"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-sheet style="padding-bottom: 30px">
            <v-form>
              <v-card-title class="headline text-uppercase">
                <span class="primary--text">Information Supplémentaire</span>
              </v-card-title>
              <v-divider style="padding-bottom: 50px"></v-divider>
            
              <v-row>
                <v-col cols="10" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="SiteWeb"
                    prepend-icon="mdi-web"
                    v-model="website"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="Identifiant"
                    prepend-icon="mdi-account"
                    v-model="login"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="Mot de passe"
                    prepend-icon="mdi-account-edit-outline"
                    v-model="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="Index des manuels"
                    v-model="manualIndex"
                    prepend-icon="mdi-format-list-checkbox"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="Index des SBs"
                    v-model="sbIndex"
                    prepend-icon="mdi-format-list-checkbox"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="py-0">
                  <v-text-field
                    v-model="name_manufacturer"
                    outlined
                    dense
                    label="Ancien nom"
                    prepend-icon="mdi-archive-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>
        </v-col>
        <v-col cols="12" align="center" class="mb-4">
          <sbuttonCancel
            to="/admin?tab=tcholder"
            >Annuler</sbuttonCancel
          >
          <v-btn class="mr-4 primary primary-text" dark @click="createTcholder">Créer</v-btn>
        </v-col>
      </v-row>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { createTcHolderMutation } from "@/graphql/create_tc_holder";
import { tcHoldersQuery } from "../../../graphql/tc_holders";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "NewTcHolder",
  components : {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      cell: false,
      motor: false,
      propeller: false,
      part: false,
      name: null,
      country: null,
      contactName: null,
      contactEmail: null,
      website: null,
      login: null,
      password: null,
      name_manufacturer: null,
      sbIndex: null,
      manualIndex: null,
      observation: null,
      rules: {
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "e-mail invalide.";
        },
      },
    };
  },
  methods: {
    createTcholder() {
      this.$apollo
        .mutate({
          mutation: createTcHolderMutation,
          variables: {
            input: {
              attributes: {
                  cell: this.cell,
                  motor: this.motor,
                  propeller: this.propeller,
                  part: this.part,
                  name: this.name,
                  country: this.country,
                  contactName: this.contactName,
                  contactEmail: this.contactEmail,
                  website: this.website,
                  login: this.login,
                  password: this.password,
                  sbIndex: this.sbIndex,
                  manualIndex: this.manualIndex,
                  observation: this.observation,
                  manufacturersInfo: this.name_manufacturer,
              },
            },
          },
          update: (store, { data: { createTcHolder } }) => {
            console.log(createTcHolder);
            const data = store.readQuery({ query: tcHoldersQuery });
            console.log(data);
            data.tcHolders.push(createTcHolder);
            store.writeQuery({ query: tcHoldersQuery, data });
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"tcholder" }});
        });
    },
  },  
};
</script>