import gql from 'graphql-tag';


export const deleteAircraftCustomizationMutation = gql`
  mutation deleteAircraftCustomization($input: DeleteAircraftCustomizationInput!) {
    deleteAircraftCustomization(input: $input) {
      aircraftCustomization {
        id
        number
        customizationDate
        description
        document
        documentSignedId
        aircraft {
          id
          immatriculation
        }
      }
    }
}`
