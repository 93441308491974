import gql from 'graphql-tag';


export const updateAircraftCustomizationMutation = gql`
  mutation updateAircraftCustomization($input: UpdateAircraftCustomizationInput!) {
    updateAircraftCustomization(input: $input) {
      aircraftCustomization {
        id
        number
        customizationDate
        description
        document
        documentSignedId
        aircraft {
          id
          immatriculation
        }
      }
    }
}`
