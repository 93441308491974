<template>

    <v-sheet outlined rounded >
      <v-card-title class="titre--text">
        <v-row>
          <v-col align="center">
            <span>Aéronefs entrants/sortants</span>
          </v-col>
        </v-row>
      </v-card-title>
      <div v-if="$apollo.loading">
        <v-row>
          <v-skeleton-loader
            ref="skeleton"
            type="table"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-row>
      </div>
      <v-simple-table fixed-header height="550px" dense v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-uppercase">Statut</th>
              <th class="text-left text-uppercase">Entrée/sortie</th>
              
              <th class="text-left text-uppercase">Immat.</th>
              <th class="text-left text-uppercase">Modèle</th>
              <th class="text-left text-uppercase" style="width:200px">Client</th>
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="aircraft in lastAircrafts" :key="aircraft.id">
              
              <td>
                <v-icon color="red" v-if="aircraft.aircraftContracts[0].closedAt"
                  >mdi-airplane-takeoff</v-icon
                >
                <v-icon color="green" v-else
                  >mdi-airplane-landing</v-icon
                >

              </td>
              <td v-if="aircraft.aircraftContracts[0].closedAt" >{{ aircraft.aircraftContracts[0].closedAt | moment("DD/MM/YYYY") }}</td>
              <td v-else>{{ aircraft.aircraftContracts[0].start | moment("DD/MM/YYYY") }}</td>
              <td>
                <v-btn
                  text
                  :to="{
                    name: 'aeronef',
                    params: { immatriculation: aircraft.immatriculation },
                  }"
                  >{{ aircraft.immatriculation }}</v-btn
                >
              </td>
              <td>
                {{ aircraft.cell.partModel.tcHolder.name }}
                {{ aircraft.cell.partModel.reference }}
              </td>
              <td v-if="aircraft.contact"> {{ aircraft.contact.name }}</td>
              <td v-else ></td>
              
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>

</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { lastAircraftsQuery } from "../graphql/aircraft/last_aircrafts";

export default {
  name: "DerniereImmat",
  data() {
    return {
      lastAircrafts: [],
    };
  },
  apollo: {
    lastAircrafts: {
      query: lastAircraftsQuery,
    },
  },
};
</script>
