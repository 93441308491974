<template>
  <v-card class="mt-6" height="400">
    <v-card-title class="text-h4 align-start">
      <v-sheet
        outlined
        overflow="hidden"
        color="primary"
        border-color="primary"
        elevation="6"
        rounded
        height="80"
        width="80"
        class="mt-n8"
      >
        <div class="pl-7 pt-4">
          <v-icon dark>mdi-airplane</v-icon>
        </div>
      </v-sheet>
      <div class="pt-0 mt-0">
        <span class="text-h5 pl-4 font-weight-light">Cause de départ</span>
      </div>
    </v-card-title>
    <v-card-text>
      <apexchart
        width="500"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<style>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>

<script>
import { archiveReasonsQuery } from "../../graphql/aircraft/archive_reasons";

export default {
  data() {
    return {
      options: {
        labels: [
          "Avion Cassé",
          "Avion vendu",
          "Abonnement non renouvellé par client",
          "Abonnement non renouvellé par sega",
          "Contrat résilié par client",
          "Contrat résilié par sega",
          "Autres",
        ],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: [2019, 2020, 2021, 2022, 2023],
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
      },
      archiveReasons: [],
      series: [],
    };
  },
  apollo: {
    archiveReasons: {
      query: archiveReasonsQuery,
      result(data) {
        if (data) {
          const broken = data.data.archiveReasons.filter(
            (r) => r.reason === "broken"
          ).length;
          const sell = data.data.archiveReasons.filter(
            (r) => r.reason === "sell"
          ).length;
          const no_renew_client = data.data.archiveReasons.filter(
            (r) => r.reason === "no_renew_client"
          ).length;
          const no_renew_sega = data.data.archiveReasons.filter(
            (r) => r.reason === "no_renew_sega"
          ).length;
          const cancel_client = data.data.archiveReasons.filter(
            (r) => r.reason === "cancel_client"
          ).length;
          const cancel_sega = data.data.archiveReasons.filter(
            (r) => r.reason === "cancel_sega"
          ).length;
          const other = data.data.archiveReasons.filter(
            (r) => r.reason === "other"
          ).length;
          this.series = [
            broken,
            sell,
            no_renew_client,
            no_renew_sega,
            cancel_client,
            cancel_sega,
            other,
          ];
        }
      },
    },
  },
};
</script>