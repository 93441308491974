import gql from 'graphql-tag';

export const censQuery = gql`
query Cens($status: String, $year: String, $action: String) {
  cens(status: $status, year: $year, action: $action) {
    user {
      id
      name
      firstName
      lastName
      titleName
    }
    id
    reference
    immatriculation
    aircraft {
      id
      immatriculation
      contact {
        id
        name
      }
      contract {
          id
          number
          register
      }
    }
    action
    closedAt
    createdAt
    updatedAt
    deadlineAt
    lastCenDate
    certificateAttachment {
      id      
      name
      fileUrl
      createdAt
    }
    cenDate
    place
    contact
    client {
      id
      name
    }
    deliveryDate
  }
}`