import gql from 'graphql-tag';
import { cenFragment } from './fragment_cen';


export const deleteCenMutation = gql`
  mutation deleteCen($input: DeleteCenInput!) {
    deleteCen(input: $input) {
      cen {
        ...cen
      }
    }
}
${cenFragment.fragments.cen}`