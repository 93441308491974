<template>
  <div>
    <v-container fluid>
      <v-row v-if="workOrder">
        <v-col cols="12" class="my-0">
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
          <v-sheet outlined>
            <v-card-title class="text-h5 tertio--text py-2">
              <v-btn
                icon
                fab
                outlined
                color="primary"
                x-small
                link
                active-class="false"
                class="ma-4"
                :to="{
                  name: 'ot',
                  params: {
                    id: workOrder.id,
                  },
                }"
              >
                <v-icon class="primary--text">mdi-arrow-left-thick</v-icon>
              </v-btn>
              <strong>OT | </strong>
              <span style="font-size: 24px" class="ml-2"
                >Retour de l'ordre de travail {{ workOrder.reference }}
              </span>
            </v-card-title>
            <v-row>
              <v-col cols="2" class="d-none d-md-flex">
                <v-tabs v-model="tab" vertical color="#2ebdc8">
                  <v-tabs-slider color="#2ebdc8"></v-tabs-slider>
                  <v-tab href="#info" style="justify-content: left">
                    <v-icon left>mdi-information-outline</v-icon>
                    INFORMATION
                  </v-tab>
                  <v-tab href="#visite" style="justify-content: left"  v-if="workOrder.aprsDate">
                    <v-badge
                      color="accent"
                      v-if="nullVisiteValues"
                      :content="nullVisiteValues"
                      overlap
                      left
                      ><v-icon left>mdi-progress-wrench</v-icon>
                    </v-badge>
                    VISITE
                  </v-tab>
                  <v-tab href="#butees_doc" style="justify-content: left"  v-if="workOrder.aprsDate">
                    <v-badge
                      color="accent"
                      v-if="nullButeesDocValues"
                      :content="nullButeesDocValues"
                      overlap
                      left
                      ><v-icon left>mdi-book-open-variant</v-icon>
                    </v-badge>
                    <!-- <v-icon left v-else>mdi-clipboard-list-outline</v-icon>  -->
                    AD/SB
                  </v-tab>
                  <v-tab href="#butees" style="justify-content: left"  v-if="workOrder.aprsDate">
                    <v-badge
                      color="accent"
                      v-if="nullButeesValues"
                      :content="nullButeesValues"
                      overlap
                      left
                      ><v-icon left>mdi-wrench-clock</v-icon>
                    </v-badge>
                    BUTEE
                  </v-tab>
                  <v-tab href="#travaux" style="justify-content: left"  v-if="workOrder.aprsDate">
                    <v-badge
                      color="accent"
                      v-if="nullTravauxValues"
                      :content="nullTravauxValues"
                      overlap
                      left
                      ><v-icon left>mdi-clipboard-list-outline</v-icon>
                    </v-badge>
                    TRAVAUX SUPP
                  </v-tab>
                  <v-tab href="#mechanic" style="justify-content: left" v-if="workOrder.aprsDate"><v-icon left>mdi-tools</v-icon>
                    MECANO ET OUTILS
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col :cols="colsize">
                <v-tabs-items v-model="tab">                  
                  <v-tab-item value="info">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-administratif
                          :OT="workOrder"
                        ></retour-administratif>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="visite">
                    <v-card-title class="d-flex d-md-none ml-4">
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-butee :OT="workOrder" typeStop="visite"></retour-butee>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="butees_doc">
                    <v-card-title class="d-flex d-md-none ml-4">
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-butee :OT="workOrder" typeStop="document_processing"></retour-butee>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="butees">
                    <v-card-title class="d-flex d-md-none ml-4">
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-butee :OT="workOrder" typeStop="aircraft_part"></retour-butee>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="travaux">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-travaux :OT="workOrder"></retour-travaux>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="mechanic">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-mechanic
                          :OT="workOrder"
                        ></retour-mechanic>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
            <div v-if="$apollo.loading" class="text-center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="blue-grey"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { workOrderQuery } from "../../graphql/work_order/work_order";
import { workOrderItemsQuery } from "../../graphql/work_order/work_order_items";
import RetourAdministratif from "./RetourAdministratif.vue";
import RetourTravaux from "./RetourTravaux.vue";
import RetourButee from "./RetourButee.vue";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";
import RetourMechanic from './RetourMechanic.vue';


export default {
  mixins: [],
  name: "Retour",
  components: { RetourAdministratif, RetourTravaux, RetourButee, RetourMechanic },
  data() {
    return {
      workOrder: null,
      fab: false,
      workOrderItems: [],
      workOrderItemStops: [],
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 10;
      }
      return "10";
    },
    nullTravauxValues: function () {
      if (this.workOrderItems.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItems.forEach((item) => {
        if (item.status == "new") {
          i += 1;
        }
      });
      return i;
    },
    nullVisiteValues: function () {
      if (this.workOrderItemStops.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItemStops.forEach((item) => {
        if (item.status == "new" && item.itemStop.stop.inspection == true) {
          i += 1;
        }
      });
      return i;
    },
    nullButeesDocValues: function () {
      if (this.workOrderItemStops.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItemStops.forEach((item) => {
        if (item.status == "new" && item.itemStop.documentProcessing !== null) {
          i += 1;
        }
      });
      return i;
    },
    nullButeesValues: function () {
      if (this.workOrderItemStops.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItemStops.forEach((item) => {
        if (item.status == "new" && item.itemStop.aircraftPart !== null && item.itemStop.stop.inspection == false) {
          i += 1;
        }
      });
      return i;
    },
  },
  apollo: {
    workOrder: {
      query: workOrderQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    workOrderItems: {
      query: workOrderItemsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.$route.params.id, 10),
        };
      },
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.$route.params.id, 10),
        };
      },
    },
  },
};
</script>  