import gql from 'graphql-tag';
import { workOrderFragment } from './fragment_work_order';

export const updateWorkOrderMutation = gql`
  mutation updateWorkOrder($input: UpdateWorkOrderInput!) {
    updateWorkOrder(input: $input) {
      workOrder {
        ...workOrder
      }
    }
}
${workOrderFragment.fragments.workOrder}`