import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';


export const updateAircraftMutation = gql`
  mutation updateAircraft($input: UpdateAircraftInput!) {
    updateAircraft(input: $input) {
      aircraft {
        ...aircraft
      }
    }
}
${aircraftFragment.fragments.aircraft}`
