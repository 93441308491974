<template>
  <v-row class="mb-2 mt-2">
    <v-col cols="1">
      <v-row>
        <v-dialog v-model="dialog" max-width="600">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              fab
              x-small
              class="ml-12 mt-3"
              v-on="on"
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Travaux/demandes supplémentaires</v-card-title>
            <v-card-subtitle
              >Ne renseignez qu'une ligne par travaux spécifique ou
              question</v-card-subtitle
            >
            <v-card-text class="pt-4">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="question"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Description des travaux ou posez une question"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="itemId" cols="12" align="center">
                    <v-btn small color="primary" @click="updateData(itemId)">
                      Modifier
                    </v-btn>
                  </v-col>
                  <v-col v-else cols="12" align="center">
                    <v-btn small color="primary" @click="addData()">
                      Ajouter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
    <v-col cols="8">
      <v-sheet outlined rounded>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Liste des questions</th>
              <th style="width: 110px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="w in workOrderItems" :key="w.id">
              <td v-html="w.question" class="pt-4"></td>
              <td>
                <v-btn icon @click="editData(w.id)">
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="removeData(w)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import { workOrderItemsQuery } from "../../graphql/work_order/work_order_items";
import { createWorkOrderItemMutation } from "../../graphql/work_order/create_work_order_item";
import { updateWorkOrderItemMutation } from "../../graphql/work_order/update_work_order_item";
import { deleteWorkOrderItemMutation } from "../../graphql/work_order/delete_work_order_item";

export default {
  name: "Travaux",
  props: ["OT"],
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      dialog: false,
      workOrderItems: [],
      question: null,
      aircraftParts: [],
      aircraftPart: null,
      itemId: null,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    };
  },
  methods: {
    addData() {
      if (this.question) {
        this.$apollo
          .mutate({
            mutation: createWorkOrderItemMutation,
            variables: {
              input: {
                attributes: {
                  workOrderId: this.OT.id,
                  question: this.question,
                },
              },
            },
          })
          .then(() => {
            this.question = null;
            this.itemId = null;
            this.$apollo.queries.workOrderItems.refetch();
            this.dialog = false;
          });
      }
    },
    editData(id) {
      const item = this.workOrderItems.find((mpi) => mpi.id == id);
      this.itemId = item.id;
      this.question = item.question;
      this.dialog = true;
    },
    updateData() {
      if (this.question) {
        this.$apollo
          .mutate({
            mutation: updateWorkOrderItemMutation,
            variables: {
              input: {
                id: parseInt(this.itemId, 10),
                attributes: {
                  workOrderId: this.OT.id,
                  question: this.question,
                },
              },
            },
          })
          .then(() => {
            this.question = null;
            this.aircraftPart = null;
            this.itemId = null;

            this.$apollo.queries.workOrderItems.refetch();
            this.dialog = false;
          });
      }
    },
    removeData(item) {
      this.$apollo
        .mutate({
          mutation: deleteWorkOrderItemMutation,
          variables: {
            input: {
              id: parseInt(item.id, 10),
            },
          },
        })
        .then(() => {
          this.$apollo.queries.workOrderItems.refetch();
        });
    },
  },
  apollo: {
    workOrderItems: {
      query: workOrderItemsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.OT.id, 10),
        };
      },
      skip() {
        return !this.OT;
      },
    },
  },
};
</script>

