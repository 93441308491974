// Recomendation of babeljs (https://babeljs.io/docs/en/babel-polyfill)
import 'core-js/stable'; // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime'; // To ensure that regeneratorRuntime is defined globally
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import { createProvider } from './vue-apollo'
import Vuelidate from 'vuelidate'
import { permissions } from './permissions'
import VueMeta from 'vue-meta'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

const moment = require('moment')
require('moment/locale/fr')

Vue.use(require('vue-moment'), {
  moment
});


Vue.use(Vuelidate)

Vue.prototype.$permissions = permissions;


Vue.filter('formatMinutes', function (value) {
  if (!value) return ''
  var str = "";
  str += Math.floor(value / 60);
  str += "h";
  const m = value % 60
  if (m < 10) {
    str += '0' + m;
  } else {
    str += m;
  }
  return str;
})

Vue.use(VueMeta)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  vuetify,
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
