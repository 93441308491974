import gql from 'graphql-tag';

export const cenChecksQuery = gql`
query CenChecks($typeCheck: String) {
  cenChecks(typeCheck: $typeCheck) {
    id
    title
    description
    typeCheck
    order
    createdAt
    updatedAt
  }
}`