<template>

    <v-sheet outlined rounded flex >
      <v-card-title class="titre--text">
        <v-row>
          <v-col align="center">
            <span>Derniers documents</span>
          </v-col>
        </v-row>
      </v-card-title>
      <div v-if="$apollo.loading">
        <v-row>
          <v-skeleton-loader
            ref="skeleton"
            type="table"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-row>
      </div>
      <v-simple-table fixed-header
    height="550px" dense v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-uppercase">Date</th>
              <th class="text-left text-uppercase">Constructeur</th>
              <th class="text-left text-uppercase">Numéro</th>
              <th class="text-left text-uppercase">Titre</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="document in lastDocuments" :key="document.id">
              <td>{{ document.createdAt | moment("DD/MM/YYYY") }}</td>
              <td class="text-truncate" style="max-width: 170px">
                <span
                  v-for="tcHolder in document.tcHolders"
                  :key="tcHolder.id"
                 
                  >{{ tcHolder.name }}</span
                >
              </td>
              <td>
                <v-btn
                  text
                  :to="{ name: 'document', params: { id: document.id } }"
                  ><span v-if="document.type == 'Manual'">{{
                    document.number
                  }}</span>
                  <span v-else
                    >{{ document.number }} {{ document.revision }}</span
                  >
                </v-btn>
              </td>
              <td class="text-truncate" style="max-width: 200px">
                {{ document.title }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>

</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { lastDocumentsQuery } from "../graphql/document/last_documents";

export default {
  name: "DernierDocument",
  data() {
    return {
      documents: [],
    };
  },
  apollo: {
    lastDocuments: {
      query: lastDocumentsQuery,
    },
  },
};
</script>
