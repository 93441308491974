import gql from 'graphql-tag';

export const aircraftDeviationsQuery = gql`query aircraftDeviations($aircraftId: ID!) {
  aircraftDeviations(aircraftId: $aircraftId) {
    id
    title
    deviationDate    
    aircraft {
      id
      immatriculation
    }
  }
}`