var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"mb-8",attrs:{"outlined":"","rounded":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.workOrderItemStop.itemStop.documentProcessing !== null)?_c('div',{staticClass:"text-h6 ml-6 mt-4"},[_vm._v(" "+_vm._s(_vm.workOrderItemStop.itemStop.documentProcessing.document.number)+" - "+_vm._s(_vm.workOrderItemStop.itemStop.documentProcessing.document.title)+" ")]):_vm._e(),(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',{staticClass:"text-h6 ml-6 mt-4"},[_vm._v(" "+_vm._s(_vm.workOrderItemStop.itemStop.aircraftPart.partModel.ata.chapter)+" "),(_vm.workOrderItemStop.itemStop.aircraftPart.partModel.fullName)?_c('span',[_vm._v("- "+_vm._s(_vm.workOrderItemStop.itemStop.aircraftPart.partModel.fullName))]):_vm._e(),(_vm.workOrderItemStop.itemStop.aircraftPart.serialNumber)?_c('span',[_vm._v(" - "+_vm._s(_vm.workOrderItemStop.itemStop.aircraftPart.serialNumber))]):_vm._e()]):_vm._e(),(_vm.workOrderItemStop.itemStop.stop.inspection === true)?_c('div',{staticClass:"text-h6 ml-6 mt-4"},[_vm._v(" "+_vm._s(_vm.workOrderItemStop.itemStop.stop.name)+" ")]):_vm._e(),(_vm.workOrderItemStop.itemStop.documentProcessing !== null)?_c('div',{staticClass:"text-body-1 editor ml-6"},[_vm._v(_vm._s(_vm.workOrderItemStop.itemStop.stop.name)+" : "+_vm._s(_vm.workOrderItemStop.itemStop.action.name)+" ")]):_vm._e(),(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',{staticClass:"text-body-1 editor ml-6"},[_vm._v(" "+_vm._s(_vm.workOrderItemStop.itemStop.stop.name)+" : "+_vm._s(_vm.workOrderItemStop.itemStop.action.name)+" ")]):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"ml-6 mt-4"},[_c('v-radio-group',{attrs:{"row":true},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}},[_c('v-radio',{attrs:{"label":"Effectué","value":"done"}}),_c('v-radio',{attrs:{"label":"Reporté","value":"report"}})],1)],1)]),_c('v-col',{attrs:{"cols":"5"}},[(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',[_c('depose-modal',{attrs:{"aircraftPart":_vm.workOrderItemStop.itemStop.aircraftPart}}),_c('pose-modal',{attrs:{"aircraft":_vm.workOrderItemStop.workOrder.aircraft}}),_c('replace-modal',{attrs:{"aircraftPart":_vm.workOrderItemStop.itemStop.aircraftPart,"OT":_vm.workOrderItemStop.workOrder}})],1):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4 primary",attrs:{"dark":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Enregistrer")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }