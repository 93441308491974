import gql from 'graphql-tag';

export const workOrderListFragment = {
    fragments: {
      workOrder: gql`
      fragment workOrder on WorkOrder {
        id
        aircraft {
          immatriculation          
        }
        orderType
        reference
        createdAt        
        subOrderType              
        aprsDate
        status
        generateInvoice
        invoiced
        typeIgame      
        closIgame 
        user {
          id
          name
        }        
        workshop {
          id
          name
          easaNumber
        }
        contact {
          id
          name
        }
        signedWorkOrderAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
      }` 
    }
}