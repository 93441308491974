import gql from 'graphql-tag';

export const workOrderItemStopFragment = {
    fragments: {
      workOrderItemStop: gql`
        fragment workOrderItemStop on WorkOrderItemStop {
          id
          status
          observation
          workOrder {
            id
            aprsDate
            aircraft {
              id
              immatriculation
            }
          }  
          itemStop {
            action {
              id
              name
            }
            additionalInfoEn
            additionalInfoFr
            value
            globalCheck
            globalCheckLink
            startDate
            reference
            priority
            docRef
            aircraftPart {
              id
              serialNumber
              partModel {
                id
                reference
                designation
                type
                typeRaw
                fullName
                category {
                  id
                  name
                }
                ata {
                  id
                  chapter
                }
              }
            }
            documentProcessing {
              id
              document {
                id
                number
                title
              }
            }
            tcHolders {
              id
              name
            }
            createdAt
            id
            manufacturerDocRef
            nextActionDate
            stop {
              id
              name
              value
              inspection
            }
            updatedAt
            remainingText
            usedText
          }        
      }`
    }
  }