import gql from 'graphql-tag';

export const createUserMutation = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        contact {
          id
          name
        }
        id
        name
        role
        firstName
        lastName
        titleName
        email
        title
        avatar
        trigram
        mechanic
        mechanicNumber
      }
    }
}`
