import gql from 'graphql-tag';

export const createTcHolderMutation = gql`
  mutation createTcHolder($input: CreateTcHolderInput!) {
    createTcHolder(input: $input) {
      tcHolder {
        id
        name
        website
        login
        password
        cell
        motor
        propeller
        part
        country
        contactName
        contactEmail
        manualIndex
        sbIndex
        manufacturersInfo
        observation
      }
    }
}`