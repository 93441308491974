<template>
  <v-container >
    <v-col cols="8" offset="2">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-form>
        <v-card-title class="headline text-uppercase">
          <span class="primary--text">Modifier | constructeur</span>
        </v-card-title>
        <v-divider style="padding-bottom: 50px"></v-divider>

        <v-row>
          <v-col cols="6" offset="3">
            <!-- anti doublon -->
            <v-text-field 
            dense
            outlined
            placeholder="Nom du constructeur" 
            required 
            v-model="name"
            :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" offset="3">
            <v-autocomplete
              v-model="tcHolder"
              :items="
                tcHolders.map(function (a) {
                  return { text: a.name, value: a.id };
                }).sort((a, b) => a.text.localeCompare(b.text))
              "
              placeholder="Choisir le TcHolder Parent"
                          :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center" class="mt-8">
            <sbuttonCancel
              to="/admin?tab=constructeur"
              >Annuler</sbuttonCancel
            >
            <v-btn class="mr-4 primary primary-text" dark
              @click="updateManufacturer()"
              >Enregistrer</v-btn>
          </v-col>
        </v-row>
      </v-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { manufacturerQuery } from "@/graphql/manufacturer/manufacturer";
import { manufacturersQuery } from "@/graphql/manufacturer/manufacturers";
import { updateManufacturerMutation } from "@/graphql/manufacturer/update_manufacturer";
import { tcHoldersQuery } from "@/graphql/tc_holders";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateManufacturer",
  components: {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
        name : null,
        manufacturers : [],
        tcHolder: null,
        tcHolders: [],
      rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 2 || '2 nombres max',
        },
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    manufacturers: {
      query: manufacturersQuery,
    },
    manufacturer: {
      query: manufacturerQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },      
       result({ data }) {
        this.name = data.manufacturer.name;
        this.tcHolder = data.manufacturer.tcHolder.id;
      },
    },
  },
  methods: {
    updateManufacturer() {
      this.$apollo
        .mutate({
          mutation: updateManufacturerMutation,
          variables: {
            input: {
              id: parseInt(this.manufacturer.id, 10),
              attributes: {
                name: this.name,
                tcHolderId: this.tcHolder,
              },
            },
          },
          update: (store, { data: { updateManufacturer } }) => {
            const data = store.readQuery({ query: manufacturersQuery });
            const currentManufacturer = data.manufacturers.find(b => b.id === updateManufacturer.manufacturer.id);
            currentManufacturer.manufacturerUpdatedAt = updateManufacturer.manufacturer.manufacturerUpdatedAt;
            store.writeQuery({ query: manufacturersQuery, data });
          },          
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"constructeur" } });
        });
    },
  },  
};
</script>
