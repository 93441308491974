import gql from 'graphql-tag';


export const createAircraftMb303Mutation = gql`
  mutation createAircraftMb303($input: CreateAircraftMb303Input!) {
    createAircraftMb303(input: $input) {
      aircraftMb303 {
        id
        reference
        inspector
        place
        mb303Date
        reportAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aircraft {
          id
          immatriculation
        }        
      }
    }
}`
