import gql from 'graphql-tag';
import { documentFragment } from './fragment_document';


export const updateDocumentMutation = gql`
  mutation updateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      document {
        ...document
      }
    }
}
${documentFragment.fragments.document}`
