import gql from 'graphql-tag';

export const updateCenExtensionFormMutation = gql`
  mutation updateCenExtensionForm($input: UpdateCenExtensionFormInput!) {
    updateCenExtensionForm(input: $input) {
      cenExtensionForm {
        id
        checkType
        checkValue
        checkText
        createdAt
        updatedAt
      }
    }
  }`
