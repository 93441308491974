<template>

  <div v-if="contracts">

      <v-col cols="12" >
        <v-card>
          <v-breadcrumbs class="mt-8, mb-6, px-4, py-2  title--text text-uppercase" divider=">" :items="breadItems">
          </v-breadcrumbs>
        </v-card>
      </v-col>

    <v-main class="pt-4 pl-4">
      <v-row>
        <v-col cols="12">
          <v-card>      
            <v-card-title>
              <sbuttonAdd
                :to="{ name: 'new_contrat' }"
                ><v-icon left dark> mdi-plus </v-icon>Nouveau contrat</sbuttonAdd
              >
              <v-spacer></v-spacer>
              <v-row>
                <v-col cols="3" offset="3">
                  <v-select
                    label="En cours"
                    :items="listingOptions"
                    v-model="listingFilter"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="par type de contrat"
                    :items="registerOptions"
                    v-model="registerFilter"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Rechercher"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="contracts"
              :items-per-page="15"
              :footer-props="{
                'items-per-page-options': [15, 50, 100, -1],
                'items-per-page-text': 'lignes par page',
                'items-per-page-all-text': 'Tous',
              }"
              :search="search"
              :sort-by="['contact.id']"
              :sort-desc="[1]"
              dense
              :loading="isLoading"
              loading-text="Chargement... merci de patienter"
              item-key="id"
              no-results-text="Aucun résultat"
            >
              <template v-slot:item.register="{ item }">
                <span v-if="item.register">Déposé</span>
                <span v-else>Commercial</span>
              </template>
              <template v-slot:item.start="{ item }">{{
                item.start | moment("DD/MM/YYYY")
              }}</template>
              <template v-slot:item.expire="{ item }">
                <v-chip small v-if="!item.closedAt" dark :color="getColor(item.expire)">{{
                  item.expire | moment("DD/MM/YYYY")
                }}</v-chip>
              </template>
              <template v-slot:item.closedAt="{ item }">
                <v-chip small v-if="item.closedAt" dark color="red">{{ item.closedAt | moment("DD/MM/YYYY")
                }}</v-chip>
              </template>
              <template v-slot:item.document="{ item }">
                <v-btn icon :to="{ name: 'contrat', params: { id: item.id } }"><v-icon color="primary">mdi-eye-outline</v-icon></v-btn>
                <v-btn
                  icon
                  v-if="item.document"
                  :href="item.document"
                  target="_blank"
                >
                  <v-icon color="red">mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-btn icon v-else>
                  <v-icon class="primary--text">mdi-block-helper</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.notes="{ item }">
                <v-tooltip top v-if="item.notes">
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark color=" green">
                      <v-icon dark v-on="on">mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span v-html="item.notes.replaceAll('\n','<br />')"> {{ item.notes }}</span>
                </v-tooltip>
                <v-btn icon v-else>
                  <v-icon color="grey lighten-1" v-on="on">mdi-information-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { contractsQuery } from "../../graphql/contract/contracts";
import { closeContractMutation } from "@/graphql/contract/close_contract";
import sbuttonAdd from "../../components/sbuttonAdd.vue"


export default {
  components : {
    sbuttonAdd,
  },
  data() {
    return {
      headers: [
        { text: "", value: "document", width: "110px" },
        { text: "Référence", value: "number", width: "250px" },
        { text: "Client", value: "contact.name" },
        { text: "type", value: "register", width: "100px" },
        { text: "Spé", value: "contractType", width: "180px" },
        {
          text: "début contrat",
          value: "start",
          width: "120px",
        },
        { text: "Note", value: "notes", width: "40px" },
        { text: "renouvellement", value: "expire", width: "150px" },
        { text: "clôture", value: "closedAt", width: "120px" },

      ],
      contracts: null,
      search: "",
      isLoading: true,
      registerFilter: null,
      registerOptions: [
        { text: "Tout", value: null },
        { text: "Déposé", value: true },
        { text: "Commercial", value: false },
      ],
      listingFilter: "active",
      listingOptions: [
        { text: "Tout", value: "" },
        { text: "En cours", value: "active" },
        { text: "Archivé", value: "archived" },
      ],
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Contrats de gestion',
          disabled: false,
        },
        ],
    };
  },
  methods: {
    getColor(expire) {    
      if (new Date(expire) < Date.now() ) { return "red lighten-2";}
      else return "green lighten-2";
    },
    closeContract() {
      this.$apollo
        .mutate({
          mutation: closeContractMutation,
          variables: {
            input: {
              id: parseInt(this.contract.id, 10),
              closeDate: Date.now,
            },
          },
        })
        .then(() => {
          this.$router.push({
            name: "contrat",
            params: { id: this.contract.id },
          });
        });
    }
  },
  apollo: {
    contracts: {
      query: contractsQuery,
      result() {
        this.isLoading = false;
      },
      variables() {
        return {
          register: this.registerFilter,
          status: this.listingFilter,
        };
      },
    },
  },
};
</script>