<template>
  <div>
    <div v-for="w in workOrderItems" :key="w.id">
      <retour-travaux-component :workOrderItem="w"></retour-travaux-component>
    </div>  
  </div>
</template>
<script>
import { workOrderItemsQuery } from "../../graphql/work_order/work_order_items";
import RetourTravauxComponent from "./RetourTravauxComponent.vue";

export default {
  name: "RetourTravaux",
  props: ["OT"],
  components: {RetourTravauxComponent},
  data() {
    return {
      workOrderItems: [],      
    };
  },
  apollo: {
    workOrderItems: {
      query: workOrderItemsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.OT.id, 10),
        };
      },     
    },
  },
};
</script>