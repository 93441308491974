<template>
  <div>
    <v-select :items="filterItems" v-model="filter" dense></v-select>
    <div v-for="w in workOrderItemStopByType" :key="w.id">
      <retour-butee-component
        :workOrderItemStop="w"
        @change_work_order_items="refresh()"
      ></retour-butee-component>
    </div>
  </div>
</template>
<script>
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";
import RetourButeeComponent from "./RetourButeeComponent.vue";

export default {
  name: "RetourTravaux",
  props: ["OT", "typeStop"],
  components: { RetourButeeComponent },
  data() {
    return {
      workOrderItemStops: [],
      filter: "not_process",
      filterItems: [
        { text: "Tous", value: "all" },
        { text: "Non traité", value: "not_process" },
      ],
    };
  },
  methods: {
    refresh() {
      this.$apollo.queries.workOrderItemStops.refetch();
    },
  },
  computed: {
    workOrderItemStopByType() {
      if (this.typeStop == "document_processing") {
        return this.workOrderItemStops.filter(
          (w) => w.itemStop.documentProcessing !== null
        );
      } else if (this.typeStop == "aircraft_part") {
        return this.workOrderItemStops.filter(
          (w) =>
            w.itemStop.aircraftPart !== null &&
            w.itemStop.stop.inspection == false
        );
      } else if (this.typeStop == "visite") {
        return this.workOrderItemStops.filter(
          (w) => w.itemStop.stop.inspection == true
        );
      } else {
        return this.workOrderItemStops;
      }
    },
  },

  apollo: {
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.OT.id, 10),
          filter: this.filter,
        };
      },
    },
  },
};
</script>