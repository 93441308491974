import gql from 'graphql-tag';

export const categoryQuery = gql`query category($id: ID!) {
      category(id: $id) {
        id
        name
        updatedAt
        createdAt
      }
  }`
