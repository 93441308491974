import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';

export const aircraftsWithoutContractQuery = gql`
      {
        aircraftsWithoutContract {
          ...aircraft
        }
      }
    ${aircraftFragment.fragments.aircraft}`
