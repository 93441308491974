<template>
  <div>
    <v-list-item-content>
      <v-dialog v-model="dialogClose" width="400">
        <v-card >
          <v-card-title>
            Cloturer l'aéronef {{ aircraftContract.aircraft.immatriculation }}
          </v-card-title>

          <v-card-text class="mt-4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="datePicker"
                  label="Date de cloture"
                  prepend-icon="mdi-calendar-month"
                  outlined
                  dense
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="closeDate"
                :first-day-of-week="1"
                locale="fr"
                color="green"
                no-title
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-card-text>

          

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelClose()"> Annuler </v-btn>
            <v-btn text @click="saveClose()">
              Cloturer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <span style="margin-left: 10px">
        <b>{{ aircraftContract.aircraft.immatriculation }}</b>
        -
        {{ aircraftContract.aircraft.cell.partModel.reference }}
        
        ( depuis le
        {{ aircraftContract.start | moment("DD/MM/YYYY") }}

        <span v-if="aircraftContract.closedAt">
          - cloturé le
          {{ aircraftContract.closedAt | moment("DD/MM/YYYY") }} </span
        >)

        <v-btn icon v-if="aircraftContract.closedAt == null " @click="openCloseAircraftContractDialog(aircraftContract)"
          ><v-icon color="grey lighten-1"
            >mdi-file-cancel-outline</v-icon
          ></v-btn
        >
      </span>
    </v-list-item-content>
  </div>
</template>
<script>
import { updateAircraftContractMutation } from "@/graphql/contract/update_aircraft_contract";

export default {
  name: "dialogHistorique",
  props: {
    aircraftContract: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      dialogClose: false,
      menu: false,
      closeDate: this.aircraftContract.closedAt,
      start: null,
    };
  },
  computed: {
    datePicker() {
      return this.formatDate(this.closeDate);
    },
  },
  methods: {
    openCloseAircraftContractDialog() {
      this.dialogClose = true;
    },
    saveClose() {
      this.$apollo
        .mutate({
          mutation: updateAircraftContractMutation,
          variables: {
            input: {
              id: parseInt(this.aircraftContract.id, 10),
              attributes: {
                closedAt: this.closeDate,
              },
            },
          },
        })
        .then(() => {
          this.dialogClose = false;
        });
    },
    cancelClose() {
      this.closeDate = null;
      this.dialogClose = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  apollo: {
  },
};
</script>
