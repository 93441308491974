import gql from 'graphql-tag';
import { maintenanceProgrammeFragment } from './fragment_maintenance_programme';

export const deleteMaintenanceProgrammeMutation = gql`
  mutation deleteMaintenanceProgramme($input: DeleteMaintenanceProgrammeInput!) {
    deleteMaintenanceProgramme(input: $input) {
      maintenanceProgramme {
        ...maintenanceProgramme
      }
    }
} ${maintenanceProgrammeFragment.fragments.maintenanceProgramme}`
