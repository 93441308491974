import gql from 'graphql-tag';

export const userQuery = gql`query
  User($id: ID!) {
    user(id: $id) {
      contact {
        id
        name
      }
      id
      name
      role
      firstName
      lastName
      titleName
      email
      title
      avatar
      penNumber
      supervisor
      pen
      trigram
      cen
      mechanic
      mechanicNumber
    }
}`