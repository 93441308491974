import gql from 'graphql-tag';

export const aircraftContractFragment = {
    fragments: {
      aircraftContract: gql`
      fragment aircraftContract on AircraftContract {
        id
        start
        closedAt
        aircraft {
          id
          immatriculation
          cell {
            id
            partModel {
              id
              reference
            }
          }
        }
      }` 
    }
}