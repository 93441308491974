import gql from 'graphql-tag';
import { aircraftDocumentFragment } from './fragment_aircraft_document';


export const updateAircraftDocumentMutation = gql`
  mutation updateAircraftDocument($input: UpdateAircraftDocumentInput!) {
    updateAircraftDocument(input: $input) {
      aircraftDocument {
        ...aircraftDocument
      }
    }
}
${aircraftDocumentFragment.fragments.aircraftDocument}`
