<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn block class="my-3" color="primary" @click="showDialog()"
        >Remplacement de la pièce</v-btn
      >
    </template>
    <v-card>
      <v-card-title
        >Remplacement de {{ aircraftPart.partModel.fullName
        }}<span v-if="aircraftPart.serialNumber">
          {{ aircraftPart.serialNumber }}</span
        ></v-card-title
      >
      <v-card-text class="">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Nouveau Numéro de série"
                v-model="serialNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              Form One
              <dashboard :uppy="uppy" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="cancel()"> Annuler </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="save()">Remplacer</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { replaceAircraftPartMutation } from "../../graphql/aircraft_part/replace_aircraft_part";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";

export default {
  name: "ReplaceModal",
  props: {
    aircraftPart: {
      required: true,
    },
    OT: {
      required: true,
    },
  },
  components: { Dashboard },
  data() {
    return {
      dialog: false,
      serialNumber: null,
      uploadedFileID: null,
    };
  },
  computed: {
    uppy: (self) =>
      new Uppy({
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.serialNumber = null;
      this.uploadedFileID = null;
    },
    showDialog() {
      this.dialog = true;
    },
    save() {
      this.$apollo
        .mutate({
          mutation: replaceAircraftPartMutation,
          variables: {
            input: {
              aircraftPartId: parseInt(this.aircraftPart.id, 10),
              serialNumber: this.serialNumber,
              documentId: this.uploadedFileID,
              installDate: this.OT.aprsDate,
            },
          },
        })
        .then(() => {
          this.cleanDialog();
        });
    },
    cancel() {
      this.cleanDialog();
    },
  },
};
</script>

