import gql from 'graphql-tag';


export const updateAircraftDeviationMutation = gql`
  mutation updateAircraftDeviation($input: UpdateAircraftDeviationInput!) {
    updateAircraftDeviation(input: $input) {
      aircraftDeviation {
        id
        title
        deviationDate        
        aircraft {
          id
          immatriculation
        }
      }
    }
}`
