import gql from 'graphql-tag';
import { workOrderFragment } from './fragment_work_order';

export const createWorkOrderMutation = gql`
  mutation createWorkOrder($input: CreateWorkOrderInput!) {
    createWorkOrder(input: $input) {
      workOrder {
        ...workOrder
      }
    }
}
${workOrderFragment.fragments.workOrder}`