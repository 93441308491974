import gql from 'graphql-tag';

export const workshopToolModelQuery = gql`query workshopToolModel($id: ID!) {
      workshopToolModel(id: $id) {
        id
        name
        periodicity       
        updatedAt
        createdAt
      }
  }`
