<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10" offset="1">
        <v-sheet outlined>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :items="tcHolders"
            :headers="header"
            :footer-props="{
              'items-per-page-options': [20, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :items-per-page="20"
            :search="search"
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            dense
            width:400
            no-results-text="Aucun résultat"
          >
           <template  v-slot:item.website="{ item }">
             <v-btn
              icon
              :href="item.website"
              target="_blank"
              v-if="item.website"
              >
                <v-icon  color="blue">mdi-earth</v-icon>
                
              </v-btn>
              <v-icon  v-else color="grey">mdi-earth</v-icon>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { tcHoldersQuery } from "../../graphql/tc_holders";

export default {
  name: "dataTableTcHolder",
  components: {

  },
  data() {
    return {
      tcHolders: [],
      header: [
        {
          text: "website",
          value: "website",
          width: "75px",
          align: "center",
          filterable: false,
        },
        { text: "Nom", value: "name" },
        { text: "Identifiant", value: "login" },
        { text: "Mot de passe", value: "password" },
        { text: "commentaire", value: "observation" },
      ],
      search: "",
      isLoading: true,
    };
  },
  methods: {
    
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
      result() {
        this.isLoading = false;
      },
    },

  },
};
</script>
