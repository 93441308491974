import gql from 'graphql-tag';

export const cenCheckQuery = gql`
query CenCheck($id: ID!) {
  cenCheck(id: $id) {
    id
    title
    description
    typeCheck
    order
    createdAt
    updatedAt
  }
}`