import gql from 'graphql-tag';

export const aircraftMb303sQuery = gql`query aircraftMb303s($aircraftId: ID!) {
  aircraftMb303s(aircraftId: $aircraftId) {
    id
    reference
    inspector
    place
    mb303Date
    reportAttachment {
      id
      name
      fileUrl
      createdAt
    }
    aircraft {
      id
      immatriculation
    }    
  }
}`