import gql from 'graphql-tag';
import { aircraftPhotoFragment } from './fragment_aircraft_photo';


export const updateAircraftPhotoMutation = gql`
  mutation updateAircraftPhoto($input: UpdateAircraftPhotoInput!) {
    updateAircraftPhoto(input: $input) {
      aircraftPhoto {
        ...aircraftPhoto
      }
    }
}
${aircraftPhotoFragment.fragments.aircraftPhoto}`
