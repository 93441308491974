import gql from 'graphql-tag';

export const workOrderItemFragment = {
    fragments: {
      workOrderItem: gql`
        fragment workOrderItem on WorkOrderItem {
          id
          question
          observation
          status
      }`
    }
  }