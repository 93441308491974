import gql from 'graphql-tag';
import { maintenanceProgrammeFragment } from './fragment_maintenance_programme';

export const createMaintenanceProgrammeMutation = gql`
  mutation createMaintenanceProgramme($input: CreateMaintenanceProgrammeInput!) {
    createMaintenanceProgramme(input: $input) {
      maintenanceProgramme {
        ...maintenanceProgramme
      }
    }
} ${maintenanceProgrammeFragment.fragments.maintenanceProgramme}`
