import gql from 'graphql-tag';

export const usersQuery = gql`
query users($contactId: ID, $role: String, $supervisor: Boolean, $pen: Boolean, $cen: Boolean) {
  users(contactId: $contactId, role: $role, supervisor: $supervisor, pen: $pen, cen: $cen) {
    contact {
      id
      name
    }
    id
    name
    role
    firstName
    lastName
    titleName
    name
    email
    title
    phone
    mobile
    avatar
    cen
    trigram
    mechanic
    mechanicNumber
  }
}`