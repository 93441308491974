import gql from 'graphql-tag';


export const createAircraftCustomizationMutation = gql`
  mutation createAircraftCustomization($input: CreateAircraftCustomizationInput!) {
    createAircraftCustomization(input: $input) {
      aircraftCustomization {
        id
        number
        customizationDate
        description
        document
        documentSignedId
        aircraft {
          id
          immatriculation
        }
      }
    }
}`
