<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs
            large
            class="mt-8, mb-6, px-4, py-2 primary--text text-uppercase"
            divider=">"
            :items="breadItems"
          >
          </v-breadcrumbs>
        </v-card>
      </v-col>
      <v-col cols="10" offset="1">
        <v-sheet outlined>
          <v-card-title>
            <sbuttonAdd :to="{ name: 'new_audit'}"
              ><v-icon left dark> mdi-plus </v-icon>Nouvel audit
            </sbuttonAdd>
            <v-spacer></v-spacer>

            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Rechercher"
                single-line
                hide-details
              ></v-text-field> </v-col
          ></v-card-title>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left text-uppercase" style="width: 100px">
                  Ref. audit
                </th>
                <th class="text-left text-uppercase" style="width: 100px">
                  Date audit
                </th>
                <th class="text-left text-uppercase" style="width: 400px">
                  chapitre
                </th>
                <th class="text-left text-uppercase">Thème audité</th>
                <th class="text-left text-uppercase" style="width: 150px">
                  Date de clôture
                </th>
                <th class="text-left text-uppercase" style="width: 100px">
                  Nb d'écarts
                </th>
                <th class="text-left text-uppercase" style="width: 75px">
                  Pdf
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2021-18</td>
                <td>25/11/2022</td>
                <td>A.1 - Déclaration du dirigeant responsable</td>
                <td>blabla blablabal blabla</td>
                <td>21-01-2022</td>
                <td>0/0</td>
                <td>pdf</td>
              </tr>
            </tbody>
          </v-simple-table></v-sheet
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import sbuttonAdd from "../../components/sbuttonAdd.vue"

export default {
  name: "Audits",
  components: {
    sbuttonAdd,
  },
  metaInfo() {
    return {
      title: "Audit"
    }
  },
  data() {
    return {
      fab: false,
      breadItems: [
        {
          text: "Accueil",
          disabled: false,
          href: "root",
        },
        {
          text: "documentations",
          disabled: false,
        },
        {
          text: "Audits",
          disabled: false,
        },
        {
          text: "Liste des audits",
          disabled: false,
        },
      ],
    };
  },
};
</script>
