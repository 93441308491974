import gql from 'graphql-tag';
import { aircraftPartFragment } from './fragment_aircraft_part';


export const createAircraftPartMutation = gql`
  mutation createAircraftPart($input: CreateAircraftPartInput!) {
    createAircraftPart(input: $input) {
      aircraftPart {
        ...aircraftPart
      }
    }
}
${aircraftPartFragment.fragments.aircraftPart}`
