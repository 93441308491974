import gql from 'graphql-tag';

export const documentsSbLinkQuery = gql`query documents{
        documents {
          id
          numberRev
          tcHolders {
            id
            name
          }
       }
      }`
