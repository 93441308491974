import gql from 'graphql-tag';

export const archiveReasonsQuery = gql`query archiveReasons
      {
        archiveReasons {
          id
          reason
          details
          createdAt
        }
      }`
