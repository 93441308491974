import gql from 'graphql-tag';

export const updateAircraftPartDocumentMutation = gql`
  mutation updateAircraftPartDocument($input: UpdateAircraftPartDocumentInput!) {
    updateAircraftPartDocument(input: $input) {
      aircraftPartDocument {
        id
        name
        createdAt
        updatedAt            
        documentAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
      }
    }
}`
