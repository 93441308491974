<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs :items="breadItems" divider=">" large class="mt-8, mb-6, px-4, py-2 text-uppercase primary--text">
          </v-breadcrumbs>
        </v-card>
      </v-col>

      <v-col>
        <data-table-document typeDoc="AdSb" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTableDocument from "./dataTableDocument.vue";

export default {
  name: "ListeDocument",
  components: {
    dataTableDocument
  },
  metaInfo() {
    return {
      title: "Bibliothèque"
    }
  },
  data() {
    return {
      typeDoc: null,
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Documentation',
          disabled: false,
        },
        {
          text: 'Bibliothèque',
          disabled: false,
        },
        {
          text: 'Documents AD/SB',
          disabled: false,
        },
        ],
    }
  },
};
</script>
