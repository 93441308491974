import gql from 'graphql-tag';

export const documentsQuerySuivi = gql`query documents($type: [String], $tcHolder: [ID], $sort: String, $status: String, $partType: String){
        documents(type: $type, tcHolder: $tcHolder, sort: $sort, status: $status, partType: $partType) {
          id
          type
          mandatory
          number
          numberRev          
          revision
          status
          version
          documentFile
          manualType
          lastCheckAt
          tcHolders {
            id
            name
          }
          title
          updatedAt
          createdAt
          aircraftParts {
            id
            aircraft {
              id
              immatriculation
              status
            }
          }
       }
      }`
