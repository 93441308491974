<template>
  <v-container fluid class="background">
  <v-col cols="6" offset="3">
    <v-sheet outlined>
      <v-card-title>
        <sbuttonAdd
          :to="{ name: 'categorie_new' }"
          ><v-icon left dark> mdi-plus </v-icon>Nouvelle Catégorie</sbuttonAdd
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :items="categories"
        :headers="categoriesHeader"
        :footer-props="{
          'items-per-page-options': [13, 50, 100],
          'items-per-page-text': 'lignes par page',
          'items-per-page-all-text': 'Tous',
        }"
        :items-per-page="13"
        :search="search"
        :loading="isLoading"
        loading-text="Chargement... merci de patienter"
        multi-sort
        dense
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="{ name: 'categorie_edit', params: { id: item.id } }"
              >
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="deleteCategory(item)">
                <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
      </v-data-table>
    <div v-if="$apollo.loading" class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue-grey"
        indeterminate
      ></v-progress-circular>
    </div>
      <confirm ref="confirm"></confirm>
    </v-sheet>
  </v-col>
  </v-container>
</template>

<script>
import { categoriesQuery } from "../../../graphql/categories";
import { deleteCategoryMutation } from "@/graphql/delete_category";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from '../../../components/sbuttonAdd.vue';

export default {
  name: "dataTableCat",
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      categories: [],
      categoriesHeader: [
        { text: "NOM", value: "name" },
        { text: " ", value: "id", width: "150px", filterable: false },
      ],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    categories: {
      query: categoriesQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    deleteCategory(category) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteCategoryMutation,
                variables: {
                  input: {
                    id: parseInt(category.id, 10),
                  },
                },
                update: (store, { data: { deleteCategory } }) => {
                  const data = store.readQuery({ query: categoriesQuery });
                  const newData = {
                    categories: data.categories.filter((b) => b.id !== deleteCategory.category.id),
                  };
                  store.writeQuery({ query: categoriesQuery, data: newData });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
};
</script>