import gql from 'graphql-tag';

export const stopQuery = gql`query Stop($id: ID!) {
  stop(id: $id) {
    id
    name
    nameFr
    nameEn
    value
    inspection
    recurring
    createdAt
    updatedAt
  }
}`