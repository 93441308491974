import gql from 'graphql-tag';
import { aircraftPartFragment } from './fragment_aircraft_part';


export const updateAircraftPartMutation = gql`
  mutation updateAircraftPart($input: UpdateAircraftPartInput!) {
    updateAircraftPart(input: $input) {
      aircraftPart {
        ...aircraftPart
      }
    }
}
${aircraftPartFragment.fragments.aircraftPart}`
