<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Modifier | Pièce</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="5" offset="1">
                <v-select
                  dense
                  outlined
                  v-model="type"
                  label="Quel type celà concerne-t-il ?"
                  :items="typeItems"
                ></v-select>
              </v-col>
              <v-col cols="5" v-if="type == 'Equipment'">
                <v-select
                  dense
                  outlined
                  v-model="tcHolder"
                  label="TcHolder"
                  :items="
                    tcHolders.map(function (t) {
                      return {
                        text: t.name,
                        value: t.id,
                      };
                    }).sort((a, b) => a.text.localeCompare(b.text))
                  "
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" offset="1">
                <v-select
                  dense
                  outlined
                  v-model="ata"
                  label="Ata"
                  :items="
                    atas.map(function (t) {
                      return {
                        text: t.chapter + ' - ' + t.nameFr,
                        value: t.id,
                      };
                    })
                  "
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-select
                  dense
                  outlined
                  v-model="category"
                  label="Catégorie générique"
                  :items="
                    categories.map(function (t) {
                      return {
                        text: t.name,
                        value: t.id,
                      };
                    }).sort((a, b) => a.text.localeCompare(b.text))
                  "
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" offset="1">
                <v-text-field
                  dense
                  outlined
                  label="Désignation Fr"
                  required
                  v-model="designationFr"
                  hint="ex: Tuyauterie souple carburant"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  dense
                  outlined
                  label="Désignation En"
                  required
                  v-model="designationEn"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" offset="3">
                <v-text-field
                  dense
                  outlined
                  label="Part Number"
                  required
                  v-model="reference"
                  hint="ex: RAP-215-CC"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  to="/admin?tab=piece"
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark
                  @click="updatePartModel"
                  >Créer</v-btn
                >
              </v-col>
            </v-row>
          </v-form> 
        </v-sheet> 

    </v-col>
  </v-container>
</template>

<script>
import { updatePartModelMutation } from "@/graphql/part_model/update_part_model";
import { partModelQuery } from "@/graphql/part_model/part_model";
import { tcHoldersQuery } from "@/graphql/tc_holders";
import { categoriesQuery } from "@/graphql/categories";
import { atasQuery } from "@/graphql/ata/atas";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateModelPart",
  components : {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      tcHolders: [],
      tcHolder: null,
      categories: [],
      category: null,
      reference: null,
      designationFr: null,
      designationEn: null,
      ata: null,
      atas: [],
      type: null,
      typeItems: [
        { text: "Pièce", value: "Part" },
        { text: "Equipement", value: "Equipment" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    categories: {
      query: categoriesQuery,
    },
    atas: {
      query: atasQuery,
    },
    partModel: {
      query: partModelQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (data) {
          console.log(data)
          this.type = data.partModel.typeRaw;
          this.reference = data.partModel.reference;
          this.designationFr = data.partModel.designation;
          this.designationEn = data.partModel.designationEn;
          if (data.partModel.tcHolder) {
            this.tcHolder = data.partModel.tcHolder.id;
          }
          if (data.partModel.category) {
            this.category = data.partModel.category.id;
          }
          if (data.partModel.ata) {
            this.ata = data.partModel.ata.id;
          }
        }
      },
    },
  },
  methods: {
    updatePartModel() {
      this.$apollo
        .mutate({
          mutation: updatePartModelMutation,
          variables: {
            input: {
              id: parseInt(this.partModel.id, 10),
              attributes: {
                type: this.type,
                reference: this.reference,
                designationFr: this.designationFr,
                designationEn: this.designationEn,
                categoryId: this.category,
                atumId: this.ata,
                tcHolderId: this.tcHolder,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "piece" } });
        });
    },
  },
};
</script>