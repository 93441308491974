import gql from 'graphql-tag';

export const updateStopMutation = gql`
  mutation updateStop($input: UpdateStopInput!) {
    updateStop(input: $input) {
      stop{
        id
        name
        nameFr
        nameEn
        value
        inspection
        recurring
        createdAt
        updatedAt
      }
    }
}`
