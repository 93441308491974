<template>
	<v-btn 
		@click="onClick"
    class="mr-4 btn--text"
    outlined
    depressed
    :to="to"
	>
		<slot />
	</v-btn>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      required: true
    },
    to: {}
  }
}
</script>