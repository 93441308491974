<template>
  <div>
    <v-container fluid>
      <v-row v-if="cen && !cen.closedAt">
        <v-col cols="12" class="my-0">
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
          <v-sheet outlined >
            <v-card-title class="text-h5 tertio--text py-2">
              <v-btn
                icon
                fab
                outlined
                color="primary"
                x-small
                link
                active-class="false"
                :to="{ name: 'cen' }"
                class="ma-4"
              >
                <v-icon class="primary--text"
                  >mdi-arrow-left-thick</v-icon
                >
              </v-btn>
              <strong>CEN | </strong>
              <span style="font-size: 24px" v-if="cen.cell" class="ml-2"
                >Réalisation du {{ cen.immatriculation }} </span
              >


            </v-card-title>
            <v-row>
              <v-col cols="2" class="d-none d-md-flex">
                <v-tabs
                  v-model="tab"
                  vertical
                  color="#2ebdc8"
                >
                <v-tabs-slider color="#2ebdc8"></v-tabs-slider>
                  <v-tab href="#doc" style="justify-content:left">
                    <v-badge
                      color="accent"
                      :content="nullDocValues"
                      v-if="nullDocValues"
                      offset-x="40"
                      offset-y="10"
                    ><v-icon left>mdi-clipboard-text-outline</v-icon></v-badge>
                    <v-icon left v-else>mdi-clipboard-text-outline</v-icon>                        
                    REVUE DOCUMENTAIRE
                  </v-tab>
                  <v-tab href="#mechanic" style="justify-content:left" v-if="cen.action == 'renew'">
                    
                    <v-badge
                      color="accent"
                      :content="nullMechanicValues"
                      v-if="nullMechanicValues"
                      offset-x="40"
                      offset-y="10"
                    ><v-icon left>mdi-clipboard-list-outline</v-icon>
                     
                    </v-badge>
                    <v-icon left v-else>mdi-clipboard-list-outline</v-icon>REVUE PHYSIQUE 
                    
                  </v-tab>
                  <v-tab href="#ecarts" style="justify-content:left"> 
                    
                    <v-icon left>mdi-file-document-edit-outline</v-icon>LISTE DES ECARTS
                  </v-tab>

                  <v-tab href="#extension" style="justify-content:left" v-if="cen.action !== 'renew'"> 
                    
                    <v-icon left>mdi-file-document-edit-outline</v-icon>FORM EXTENSION
                  </v-tab>

                  <v-tab href="#misc" style="justify-content:left">

                    <v-icon left>mdi-file-document-edit-outline</v-icon>OBSERVATIONS
                  </v-tab>
                   <v-tab href="#certificat" style="justify-content:left">
                    
                    <v-icon left>mdi-file-document-edit-outline</v-icon>CERTIFICAT
                  </v-tab>
                                    
                </v-tabs>
              </v-col>
              <v-col :cols="colsize">
                <v-tabs-items v-model="tab">
                  <v-tab-item value="doc">
                    <v-card-title class="d-flex d-md-none ml-4">
                      <v-badge
                      color="accent"
                      :content="nullDocValues"
                      v-if="nullDocValues"
                      offset-x="40"
                      offset-y="10"
                    ><v-icon left>mdi-clipboard-text-outline</v-icon></v-badge>
                    <v-icon left v-else>mdi-clipboard-text-outline</v-icon>                        
                    REVUE DOCUMENTAIRE
                    </v-card-title>
             
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <realisationItem
                          typeCheck="doc"
                          :cen="cen"
                          v-if="cen"
                        ></realisationItem>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="mechanic">
                    <v-card-title class="d-flex d-md-none ml-4">
                      <v-badge
                      color="accent"
                      :content="nullMechanicValues"
                      v-if="nullMechanicValues"
                      offset-x="40"
                      offset-y="10"
                    ><v-icon left>mdi-clipboard-list-outline</v-icon>
                     
                    </v-badge>
                    <v-icon left v-else>mdi-clipboard-list-outline</v-icon>REVUE PHYSIQUE
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <realisationItem
                          typeCheck="mechanic"
                          :cen="cen"
                          v-if="cen"
                        ></realisationItem>
                      </v-card-text>
                    </v-card>

                  </v-tab-item>
                  <v-tab-item value="ecarts">
                    <v-card-title class="d-flex d-md-none ml-4">
                      LISTE DES ECARTS
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <ecarts :cen="cen" v-if="cen"></ecarts>
                      </v-card-text>
                    </v-card>

                  </v-tab-item>
                  <v-tab-item value="misc">
                    <v-card-title class="d-flex d-md-none ml-4">
                      OBSERVATIONS
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <misc :cen="cen" v-if="cen"></misc>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="extension">
                    <v-card-title class="d-flex d-md-none ml-4">
                      FORMULAIRE D'EXTENSION
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                         <ExtensionForm :cen="cen" v-if="cen"></ExtensionForm>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="certificat">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <CENFinalisation :cen="cen" v-if="cen"></CENFinalisation>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                 
                </v-tabs-items>
              </v-col>
            </v-row>
              <div v-if="$apollo.loading" class="text-center">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="blue-grey"
                  indeterminate
                ></v-progress-circular>
              </div>

          </v-sheet>
        </v-col>
      </v-row>
      <v-row v-else>
        LE CEN A ETE CLOTURE
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.v-tab {
    max-width: 100% !important;
}
</style>

<script>
import { cenQuery } from "@/graphql/cen/cen";
import { cenCheckValuesQuery } from "@/graphql/cen/cen_check_values";
import realisationItem from "./realisationItem.vue";
import ecarts from "./ecarts.vue";
import misc from "./misc.vue";
import CENFinalisation from "./CENFinalisation.vue";
import ExtensionForm from "./ExtensionForm.vue";

export default {
  name: "CENRealisation",
  data() {
    return {
      fab: false,
      isLoading: true,
      cen: null,
      cenCheckValuesDoc: [],
      cenCheckValuesMechanic: [],
    };
  },
  components: { realisationItem, ecarts, misc, CENFinalisation, ExtensionForm },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    nullDocValues: function () {
      if (this.cenCheckValuesDoc.length == 0) {
        return null;
      }

      var i = 0;
      this.cenCheckValuesDoc.forEach((check) => {
        if (check.value === null) {
          i += 1;
        }
      });
      return i;
    },
    nullMechanicValues: function () {
      if (this.cenCheckValuesMechanic.length == 0) {
        return null;
      }

      var i = 0;
      this.cenCheckValuesMechanic.forEach((check) => {
        if (check.value === null) {
          i += 1;
        }
      });
      return i;
    },
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 10;
      }
      return "10";
    },
  },
  apollo: {
    cen: {
      query: cenQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    cenCheckValuesDoc: {
      query: cenCheckValuesQuery,
      variables() {
        return {
          cenId: this.cen.id,
          typeCheck: "doc",
        };
      },
      skip() {
        return !this.cen;
      },
      result() {
        this.isLoading = false;
      },
      update: (data) => data.cenCheckValues,
    },
    cenCheckValuesMechanic: {
      query: cenCheckValuesQuery,
      variables() {
        return {
          cenId: this.cen.id,
          typeCheck: "mechanic",
        };
      },
      skip() {
        return !this.cen;
      },
      result() {
        this.isLoading = false;
      },
      update: (data) => data.cenCheckValues,
    },
  },
};
</script>
