import gql from 'graphql-tag';

export const updateManufacturerMutation = gql`
  mutation updateManufacturer($input: UpdateManufacturerInput!) {
    updateManufacturer(input: $input) {
      manufacturer {
        id
        name
        tcHolder {
          id
          name
        }
      }
    }
}`