import gql from 'graphql-tag';

export const cenCheckValueItemFragment = {
    fragments: {
      cenCheckValueItem: gql`
      fragment cenCheckValueItem on CenCheckValueItem {
        createdAt        
        deviation
        deviationClosedAt
        deviationCorrection
        deviationLevel
        deviationPhotos {
          id
          name
          fileUrl
          fileSignedId
        }
        deviationCorrectionPhotos {
          id
          name
          fileUrl
          fileSignedId
        }
        id
        number
        updatedAt
        cenCheckValue {
          id
          title
        }
      }`
    }
}