<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-dialog
              v-model="dialog_upload"
              persistent
              hide-overlay
              max-width="600"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="primary--text"
                  dark
                  outlined
                  v-on="on"
                  @click="dialog_upload = !dialog_upload"
                >
                  Nouvelle Déviation
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline"
                  >Ajout de la Déviation</v-card-title
                >
                <v-card-text class="pt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="title"
                          dense
                          outlined
                          label="Titre"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="datePicker"
                              label="Date de signature"
                              prepend-icon="mdi-calendar-month"
                              outlined
                              dense
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="deviationDate"
                            :first-day-of-week="1"
                            locale="fr"
                            color="green"
                            no-title
                            @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-4 primary--text"
                    outlined
                    depressed
                    @click="cleanDialog()"
                  >
                    Fermer</v-btn
                  >
                  <v-btn class="mr-4 primary" dark @click="saveDeviation()"
                    >Uploader</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-row></v-row>
          </v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items="aircraftDeviations"
            :sort-by="['deviationDate']"
            hide-default-footer
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            no-results-text="Aucun résultat"
            disable-pagination
          >
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="editDeviation(item)">
                <v-icon color="">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteDeviation(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.deviationDate="{ item }">
              <span v-if="item.deviationDate">{{
                item.deviationDate | moment("DD/MM/YYYY")
              }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <confirm ref="confirm"></confirm>
    </v-row>
  </div>
</template>

<style>
</style>


<script>
import { aircraftDeviationsQuery } from "../../../graphql/aircraft_deviation/aircraft_deviations";
import { createAircraftDeviationMutation } from "../../../graphql/aircraft_deviation/create_aircraft_deviation";
import { updateAircraftDeviationMutation } from "../../../graphql/aircraft_deviation/update_aircraft_deviation";
import { deleteAircraftDeviationMutation } from "../../../graphql/aircraft_deviation/delete_aircraft_deviation";
import confirm from "@/components/confirm.vue";

export default {
  name: "dataTabledeviation",
  props: {
    aircraft: {
      type: Object,
      required: true,
    },
  },
  components: {
    confirm,
  },
  data() {
    return {
      // aircraft: null,
      aircraftDeviations: [],
      isLoading: true,
      dialog_upload: false,
      modal: false,
      menu: false,
      deviationDate: null,
      title: null,
      deviationId: null,
      headers: [
        { text: "Titre", value: "title", width: "200px" },
        {
          text: "Date",
          value: "deviationDate",
          width: "100px",
        },
        { text: "", value: "action", width: "140px" },
      ],
    };
  },
  methods: {
    cleanDialog() {
      this.title = null;
      this.deviationDate = null;
      this.deviationId = null;
      this.dialog_upload = false;
    },
    editDeviation(item) {
      this.title = item.title;
      this.deviationDate = item.deviationDate;
      this.deviationId = item.id;
      this.dialog_upload = true;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    deleteDeviation(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAircraftDeviationMutation,
                variables: {
                  input: {
                    id: item.id,
                  },
                },
              })
              .then(() => {
                this.$apollo.queries.aircraftDeviations.refetch();
              });
          }
        });
    },
    saveDeviation() {
      if (this.deviationId) {
        this.$apollo
          .mutate({
            mutation: updateAircraftDeviationMutation,
            variables: {
              input: {
                id: parseInt(this.deviationId, 10),
                attributes: {
                  deviationDate: this.deviationDate,
                  title: this.title,
                  aircraftId: this.aircraft.id,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.aircraftDeviations.refetch();
            this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftDeviationMutation,
            variables: {
              input: {
                attributes: {
                  deviationDate: this.deviationDate,
                  title: this.title,
                  aircraftId: this.aircraft.id,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.aircraftDeviations.refetch();
            this.cleanDialog();
          });
      }
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.deviationDate);
    },
  },
  apollo: {
    aircraftDeviations: {
      query: aircraftDeviationsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
