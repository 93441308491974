import gql from 'graphql-tag';

export const generateProjectedMutation = gql`
  mutation generateProjected($input: GenerateProjectedInput!) {
    generateProjected(input: $input) {
      projected {
        id
        aircraft {
          id
          immatriculation
        }
        projectedAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
      }
    }
  }`
