import gql from 'graphql-tag';

export const deleteWorkshopToolModelMutation = gql`
  mutation deleteWorkshopToolModel($input: DeleteWorkshopToolModelInput!) {
    deleteWorkshopToolModel(input: $input) {
      workshopToolModel {
        id
        name
        periodicity       
        updatedAt
        createdAt
      }
    }
}`
