<template>
  <v-container v-if="$apollo.loading">
    <v-row>
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
    </v-row>
  </v-container>
  <v-container v-else fluid>
    <v-row>
      <v-col flex>
        <v-tabs
          v-model="tab"
          background-color="background"
          color="titre"
          centered
          center-active
          grow
          show-arrows
          icons-and-text
        >
          <v-tabs-slider color="titre"></v-tabs-slider>
          <v-tab href="#ata">
            ATA 100
            <v-icon>mdi-format-list-numbered</v-icon>
          </v-tab>
          <v-tab href="#constructeur">
            CONSTRUCTEUR
            <v-icon>mdi-factory</v-icon>
          </v-tab>
          <v-tab href="#tcholder">
            TC HOLDER
            <v-icon>mdi-factory</v-icon>
          </v-tab>
          <v-tab href="#modele">
            CMH
            <v-icon>mdi-folder-cog-outline</v-icon>
          </v-tab>
          <v-tab href="#serie">
            SERIE
            <v-icon>mdi-folder-cog-outline</v-icon>
          </v-tab>
          <v-tab href="#categorie">
            CATEGORIE
            <v-icon>mdi-folder-cog-outline</v-icon>
          </v-tab>
          <v-tab href="#piece">
            PIECE
            <v-icon>mdi-folder-cog-outline</v-icon>
          </v-tab>
          <v-tab href="#butee">
            BUTEE
            <v-icon>mdi-timer-sand</v-icon>
          </v-tab>
          <v-tab href="#action">
            ACTION
            <v-icon>mdi-timer-sand</v-icon>
          </v-tab>
          <v-tab href="#cenVerifdoc">
            ITEM CEN RD
            <v-icon>mdi-timer-sand</v-icon>
          </v-tab>
          <v-tab href="#cenVerifmechanic">
            ITEM CEN RP
            <v-icon>mdi-timer-sand</v-icon>
          </v-tab>
          <v-tab href="#outillage">
            OUTILLAGE
            <v-icon>mdi-tools</v-icon>
          </v-tab>
          <v-tab href="#template">
            TEMPLATE
            <v-icon>mdi-airplane</v-icon>
          </v-tab>
          <v-tab href="#vente">
            REF VENTE
            <v-icon>mdi-currency-eur</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="ata">
            <dataTableAta />
          </v-tab-item>
          <v-tab-item value="constructeur">
            <data-table-manufacturers />
          </v-tab-item>
          <v-tab-item value="tcholder">
            <dataTableTcHolders />
          </v-tab-item>
          <v-tab-item value="modele">
            <dataTableModele />
          </v-tab-item>
          <v-tab-item value="serie">
            <dataTableModeleSerie />
          </v-tab-item>
          <v-tab-item value="categorie">
            <dataTableCat />
          </v-tab-item>
          <v-tab-item value="piece">
            <dataTableModelePart />
          </v-tab-item>
          <v-tab-item value="butee">
            <dataTableModeleButee />
          </v-tab-item>
          <v-tab-item value="action">
            <dataTableAction />
          </v-tab-item>
          <v-tab-item value="cenVerifdoc">
            <dataTableCenVerification typeCheck="doc" />
          </v-tab-item>
          <v-tab-item value="cenVerifmechanic">
            <dataTableCenVerification typeCheck="mechanic" />
          </v-tab-item>
          <v-tab-item value="outillage"> 
            <data-table-workshop-tool-model></data-table-workshop-tool-model>
          </v-tab-item>
          <v-tab-item value="template"> </v-tab-item>
          <v-tab-item value="vente"> </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<script>
import dataTableAta from "./Ata/dataTableAta.vue";
import dataTableModele from "./Modele/dataTableModele.vue";
import dataTableModeleButee from "./Butee/dataTableModeleButee.vue";
import dataTableModelePart from "./Modele/dataTableModelePart.vue";
import dataTableModeleSerie from "./Modele/dataTableModeleSerie.vue";
import dataTableTcHolders from "./TcHolder/dataTableTcHolders.vue";
import dataTableAction from "./Action/dataTableAction.vue";
import dataTableManufacturers from "./Constructeur/dataTableManufacturers.vue";
import dataTableCat from "./Categorie/dataTableCat.vue";
import dataTableCenVerification from './CenVerification/dataCenVerification.vue';
import dataTableWorkshopToolModel from './WorkshopTool/dataTableWorkshopToolModel.vue'

export default {
  name: "Index",
  metaInfo() {
    return {
      title: "Admin"
    }
  },
  data: function () {
    return {
    };
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  components: {
    dataTableAta,
    dataTableModeleButee,
    dataTableModele,
    dataTableModelePart,
    dataTableModeleSerie,
    dataTableAction,
    dataTableTcHolders,
    dataTableManufacturers,
    dataTableCat,
    dataTableCenVerification,
    dataTableWorkshopToolModel,
  },
};
</script>