import gql from 'graphql-tag';

export const ataQuery = gql`query ata($id: ID!) {
      ata(id: $id) {
        id
        name
        nameFr
        nameEn
        chapter
        number
        parent {
          id
        }
        updatedAt
        createdAt
      }
  }`
