var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('v-sheet',{staticStyle:{"padding-bottom":"30px"},attrs:{"outlined":""}},[_c('v-form',[_c('v-card-title',{staticClass:"headline text-uppercase"},[_c('span',{staticClass:"primary--text"},[_vm._v("Nouveau Livret")])]),_c('v-divider',{staticStyle:{"padding-bottom":"50px"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.aircrafts.map(function (t) {
                  return { text: t.immatriculation, value: t.id };
                }).sort((a, b) => a.text.localeCompare(b.text)),"label":"Aéronef","outlined":"","dense":""},model:{value:(_vm.aircraft),callback:function ($$v) {_vm.aircraft=$$v},expression:"aircraft"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.bookletUpdatedAt,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.bookletUpdatedAt=$event},"update:return-value":function($event){_vm.bookletUpdatedAt=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Date de mise à jour","prepend-icon":"mdi-calendar-month","readonly":"","outlined":"","dense":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.dateFormatted)}},model:{value:(_vm.bookletUpdatedAt),callback:function ($$v) {_vm.bookletUpdatedAt=$$v},expression:"bookletUpdatedAt"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"locale":"fr","no-title":"","scrollable":""},model:{value:(_vm.bookletUpdatedAt),callback:function ($$v) {_vm.bookletUpdatedAt=$$v},expression:"bookletUpdatedAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.bookletUpdatedAt)}}},[_vm._v("Ok")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[(_vm.aircraft)?_c('v-select',{attrs:{"items":_vm.getprimaryPartForSelect(_vm.aircrafts[_vm.aircraft]).map(function (t) {
                  return {
                    text: t.partModel.reference + t.serialNumber,
                    value: t.id,
                  };
                }),"label":"Modele","outlined":"","dense":""},model:{value:(_vm.aircraftPart),callback:function ($$v) {_vm.aircraftPart=$$v},expression:"aircraftPart"}}):_vm._e()],1)],1),_c('v-row',[(_vm.status === 'sega')?_c('v-col',{attrs:{"cols":"6","offset":"2"}},[_c('v-select',{attrs:{"items":_vm.locationItems,"label":"Localisation","outlined":"","dense":""},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Tome","type":"number","min":"1","max":"5"},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":"Status","outlined":"","dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('sbuttonCancel',{attrs:{"to":{ name: 'livrets' }}},[_vm._v("Annuler")]),_c('v-btn',{staticClass:"mr-4 primary white--text",attrs:{"dark":""},on:{"click":function($event){return _vm.createBooklet()}}},[_vm._v("Ajouter")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }