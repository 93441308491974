import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';


export const archiveAircraftMutation = gql`
  mutation archiveAircraft($input: ArchiveAircraftInput!) {
    archiveAircraft(input: $input) {
      aircraft {
        ...aircraft
      }
    }
}
${aircraftFragment.fragments.aircraft}`
