import gql from 'graphql-tag';
import { cenFragment } from './fragment_cen';

export const cenQuery = gql`
query Cen($id: ID!) {
  cen(id: $id) {
    ...cen
  }
}
${cenFragment.fragments.cen}
`