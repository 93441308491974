<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs
            large
            class="mt-8, mb-6, px-4, py-2 primary--text text-uppercase"
            divider=">"
            :items="breadItems"
          >
          </v-breadcrumbs>
        </v-card>
      </v-col>
      <v-col cols="10" offset="1">
        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left text-uppercase" style="width: 100px">
                  CAO
                </th>
                <th class="text-left text-uppercase" style="width: 75px">
                  Chap.
                </th>
                <th class="text-left text-uppercase" style="width: 400px">
                  Ref. partm/ml
                </th>
                <th class="text-left text-uppercase">Thème audité</th>
                <th class="text-left text-uppercase" style="width: 100px">
                  Date d'audit
                </th>
                <th class="text-left text-uppercase" style="width: 100px">
                  Ref. audit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CAE - Ed.1</td>
                <td>A.1</td>
                <td>
                  CAO.A.025(a)(1), CAO.A.035(a), CAO.A.110, M.L.A.201(a-h)
                </td>
                <td>Déclaration du dirigeant responsable</td>
                <td>21-01-2022</td>
                <td>2022-01</td>
              </tr>
            </tbody>
          </v-simple-table></v-card
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PlanningAudit",
  components: {},
  metaInfo() {
    return {
      title: "Planning Audit"
    }
  },
  data() {
    return {
      fab: false,
      breadItems: [
        {
          text: "Accueil",
          disabled: false,
          href: "root",
        },
        {
          text: "documentations",
          disabled: false,
        },
        {
          text: "Audits",
          disabled: false,
        },
        {
          text: "Planning des audits",
          disabled: false,
        },
      ],
    };
  },
};
</script>
