import gql from 'graphql-tag';
import { maintenanceProgrammeItemFragment } from './fragment_maintenance_programme_item';

export const createMaintenanceProgrammeItemMutation = gql`
  mutation createMaintenanceProgrammeItem($input: CreateMaintenanceProgrammeItemInput!) {
    createMaintenanceProgrammeItem(input: $input) {
      maintenanceProgrammeItem {
        ...maintenanceProgrammeItem
      }
    }
} ${maintenanceProgrammeItemFragment.fragments.maintenanceProgrammeItem}`
