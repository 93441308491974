import gql from 'graphql-tag';
import { workOrderFragment } from './fragment_work_order';

export const generateOtMutation = gql`
  mutation generateOt($input: GenerateOtInput!) {
    generateOt(input: $input) {
      workOrder {
        ...workOrder
      }
    }
  }
  ${workOrderFragment.fragments.workOrder}
  `
