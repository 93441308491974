import gql from 'graphql-tag';

export const updateWorkshopToolModelMutation = gql`
  mutation updateWorkshopToolModel($input: UpdateWorkshopToolModelInput!) {
    updateWorkshopToolModel(input: $input) {
      workshopToolModel {
        id
        name
        periodicity       
        updatedAt
        createdAt
      }
    }
}`
