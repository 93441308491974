<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
      <v-row>
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Modifier | série</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="6" offset="3">
                <v-select
                  dense
                  outlined
                  v-model="type"
                  label="Quel type celà concerne-t-il ?"
                  :items="typeItems"
                ></v-select>
              </v-col>
              <v-col cols="6" offset="3">
                <v-select 
                  v-model="tcHolder"
                  :items="
                    tcHolders.map(function (t) {
                      return { text: t.name, value: t.id };
                    }).sort((a, b) => a.text.localeCompare(b.text))
                  "
                  placeholder="Choisir le TcHolder"
                ></v-select>
              </v-col>
              <v-col cols="6" offset="3" class="mt-4">
                <v-text-field
                  dense
                  outlined
                  label="Serie"
                  required
                  v-model="serie"
                  hint="ex: DR400-200"
                >
                </v-text-field>
              </v-col>
            <v-col cols="12" align="center" class="mt-8">
              <sbuttonCancel
                to="/admin?tab=serie"
                >Annuler</sbuttonCancel
              >
              <v-btn class="mr-4 primary primary-text" dark @click="updatePartModel">Enregistrer</v-btn>
            </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { updatePartModelMutation } from "@/graphql/part_model/update_part_model";
import { partModelQuery } from "@/graphql/part_model/part_model";
import { tcHoldersQuery } from "@/graphql/tc_holders";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateModelSerie",
  components : {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      tcHolder: null,
      tcHolders: [],
      type: "",
      serie: null,
      typeItems: [
        { text: "Cellule", value: "Cell" },
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
      ],
      rules: {
        required: value => !!value || 'Required.',
      },      
    };
  },
  apollo: {
    tcHolders: {
      query: tcHoldersQuery,
    },
    partModel: {
      query: partModelQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (data) {
          this.type = data.partModel.typeRaw;
          if (data.partModel.tcHolder) {
            this.tcHolder = data.partModel.tcHolder.id;
          }
          this.serie = data.partModel.reference;
        } 
      },
  },
},
methods: {
  updatePartModel() {
    this.$apollo
      .mutate({
        mutation: updatePartModelMutation,
        variables: {
          input: {
            id: parseInt(this.partModel.id, 10),
            attributes: {
              type: this.type,
              tcHolderId: parseInt(this.tcHolder, 10),
              reference: this.serie,
            },
          },
        },
      })
      .then(() => {
        this.$router.push({ name: "Admin", query: {tab:"serie" }});
      });
    },
  },    
 };
</script>