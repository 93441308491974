import gql from 'graphql-tag';

export const manufacturersQuery = gql` query manufacturers($tcHolderId: ID)
{
  manufacturers(tcHolderId: $tcHolderId) {
    id
    name
    tcHolder {
      id
      name
    }
  }
}`