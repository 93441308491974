import gql from 'graphql-tag';

export const contactsQuery = gql`
query Contacts($type: [String], $notType: [String], $status: String){
  contacts(type: $type, notType: $notType, status: $status) {
    createdAt
    email
    id
    name
    reference
    status
    type
    managed
    notes
    updatedAt
    agrementNumber
    easaNumber
  }
}`