import gql from 'graphql-tag';


export const deleteAircraftIncidentMutation = gql`
  mutation deleteAircraftIncident($input: DeleteAircraftIncidentInput!) {
    deleteAircraftIncident(input: $input) {
      aircraftIncident {
        id
        number
        title
        description
        incidentDate
        documentAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aircraft {
          id
          immatriculation
        }       
      }
    }
}`
