<template>
  <v-container fluid>
    <v-col cols="10" offset="1">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouvel item Cen</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="4" offset="2">
                <v-text-field
                  dense
                  outlined
                  label="Ordre"
                  v-model="ordre"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <!-- pré-remplir automatiquement -->
                <v-select
                  dense
                  outlined
                  v-model="type"
                  label="Quel type celà concerne-t-il ?"
                  :items="typeItems"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-text-field
                  dense
                  outlined
                  label="Titre"
                  v-model="title"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" offset="1">
                <tiptap-vuetify
                  v-model="description"
                  :extensions="extensions"
                  :card-props="{ outlined: true }"
                  :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                  placeholder="Indiquer le détail des items à vérifier"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-switch
                  dense
                  inset
                  label="Cet item concerne-t-il un modèle d'aéronef particulier ?"
                  v-model="modele"
                ></v-switch>
              </v-col>
            </v-row>
            <v-col cols="8" offset="2" v-if="modele === true" class="mt-4">
              <!-- <v-autocomplete
                :items="
                  partModels.map(function (t) {
                    return { text: t.reference, value: t.id };
                  })
                "
                attach
                small-chips
                label="Modèle concerné"
                multiple
                dense
                v-model="Modconcerne"
                outlined
                v-show="!all_model"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                    >{{ data.item.text }}</v-chip
                  >
                </template>
              </v-autocomplete> -->
            </v-col>

            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  class="mr-4 primary--text"
                  outlined
                  depressed
                  to="/admin?tab=cenVerifdoc"
                  v-if="type == 'doc'"
                  >Annuler</sbuttonCancel
                >
                <sbuttonCancel
                  class="mr-4 primary--text"
                  outlined
                  depressed
                  to="/admin?tab=cenVerifmechanic"
                  v-else
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark
                  @click="createCenChecks()"
                  >Créer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { createCenCheckMutation } from "@/graphql/cen/create_cen_check";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "NewCenChecks",
  components: {
    TiptapVuetify,
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      modconcerne: [],
      modele: false,
      all_model: false,
      ordre: "",
      title: "",
      description: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      type: "",
      typeItems: [
        { text: "Revue documentaire", value: "doc" },
        { text: "Revue Physique", value: "mechanic" },
      ],
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    };
  },
  mounted: function () {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  methods: {
    createCenChecks() {
      this.$apollo
        .mutate({
          mutation: createCenCheckMutation,
          variables: {
            input: {
              attributes: {
                order: parseInt(this.ordre, 10),
                typeCheck: this.type,
                title: this.title,
                description: this.description,
              },
            },
          },
        })
        .then(() => {
          if (this.type == "doc") {
            this.$router.push({ name: "Admin", query: { tab: "cenVerifdoc" } });
          } else {
            this.$router.push({
              name: "Admin",
              query: { tab: "cenVerifmechanic" },
            });
          }
        });
    },
  },
};
</script>