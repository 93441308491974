<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" small icon v-bind="attrs" v-on="on" class="mr-4">
        <v-icon v-if="cenCheckValueItemId">mdi-pen</v-icon>
        <v-icon v-else>mdi-pen-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline text-uppercase">
        Ajout d'un écart</v-card-title
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="10" class="pb-0 pt-8">
              <v-select
                v-model="deviationLevel"
                label="Niveau"
                outlined
                dense
                :items="levelTypeList"
                :error-messages="deviationLevelErrors"
              >
              </v-select>

              <tiptap-vuetify
                v-model="deviation"
                :extensions="extensions"
                :card-props="{ outlined: true }"
                :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                placeholder="Indiquez les détails de la non-conformité"
                :error-messages="deviationErrors"
                @blur="$v.deviation.$touch()"
                @input="$v.deviation.$touch()"
              />
              <p
                v-if="deviationErrors.length > 0"
                style="font-weight: bold; color: red"
                align="center"
              >
                {{ deviationErrors[0] }}
              </p>
            </v-col>

            <v-col cols="12">
              <v-card outlined>
                <dashboard-modal
                  :uppy="uppy"
                  :open="open"
                  :props="{ trigger: '#dashModal' }"
                />
                <v-btn
                  id="dashModal"
                  class="select-file-button ma-4 white--text"
                  color="primary"
                  x-small
                  >Ajout de photo<v-icon dark right
                    >mdi-camera-plus-outline</v-icon
                  ></v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          depressed
          class="primary--text mb-4"
          @click="clearForm()"
        >
          Fermer
        </v-btn>
        <v-btn class="mr-4 btn mb-4" @click="updateDeviation(true)">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cenCheckValueItemQuery } from "../../graphql/cen/cen_check_value_item";
import { createCenCheckValueItemMutation } from "../../graphql/cen/create_cen_check_value_item";
import { updateCenCheckValueItemMutation } from "../../graphql/cen/update_cen_check_value_item";
import { DashboardModal } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Uppy from "@uppy/core";
import French from "@uppy/locales/lib/fr_FR";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "DialogNotConformItem",
  props: ["cenCheckValueItemId", "cenCheckValue"],
  components: {
    TiptapVuetify,
    DashboardModal,
  },
  mixins: [validationMixin],
  data() {
    return {
      cenCheckValueItem: null,
      deviationLevel: 2,
      deviation: null,
      deviationPhotos: [],
      dialog_upload: false,
      dialog: false,
      open: false,
      visible: false,
      lboxIndex: 0,
      levelTypeList: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "Observations", value: 3 },
      ],
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    };
  },
  validations: {
    deviationLevel: { required },
    deviation: { required },
  },
  computed: {
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: true,
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          result.successful.forEach((photo) => {
            self.deviationPhotos.push(photo.response.signed_id);
          });
          console.log(self.deviationPhotos);
        }),
    deviationLevelErrors() {
      const errors = [];
      if (!this.$v.deviationLevel.$dirty) return errors;
      !this.$v.deviationLevel.required && errors.push("niveau manquant.");

      return errors;
    },
    deviationErrors() {
      const errors = [];
      if (!this.$v.deviation.$dirty) return errors;
      !this.$v.deviation.required && errors.push("description manquant.");
      return errors;
    },
  },
  methods: {
    updateDeviation: function (close = false) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.cenCheckValueItem) {
          this.$apollo
            .mutate({
              mutation: updateCenCheckValueItemMutation,
              variables: {
                input: {
                  id: parseInt(this.cenCheckValueItem.id, 10),
                  attributes: {
                    deviation: this.deviation,
                    deviationLevel: parseInt(this.deviationLevel, 10),
                    deviationPhotos: this.deviationPhotos,
                  },
                },
              },
            })
            .then(() => {
              this.$emit("refreshDeviation");
            });
        } else {
          this.$apollo
            .mutate({
              mutation: createCenCheckValueItemMutation,
              variables: {
                input: {
                  attributes: {
                    cenCheckValueId: this.cenCheckValue.id,
                    deviation: this.deviation,
                    deviationLevel: parseInt(this.deviationLevel, 10),
                    deviationPhotos: this.deviationPhotos,
                  },
                },
              },
            })
            .then(() => {
              this.$emit("refreshDeviation");
            });
        }
        if (close) {
          this.clearForm();
        }
      }
    },
    handleHide() {
      this.visible = false;
    },
    clearForm() {
      this.deviationLevel = 2;
      this.deviationPhotos = [];
      this.deviation = null;
      this.dialog = false;
      this.$v.$reset();
    },
  },
  apollo: {
    cenCheckValueItem: {
      query: cenCheckValueItemQuery,
      variables() {
        return {
          id: parseInt(this.cenCheckValueItemId, 10),
        };
      },
      skip() {
        return !this.cenCheckValueItemId;
      },
      result({ data }) {
        if (data) {
          this.deviationLevel = data.cenCheckValueItem.deviationLevel;
          this.deviation = data.cenCheckValueItem.deviation;
          this.deviationPhotos = data.cenCheckValueItem.deviationPhotos.map(
            (p) => p.fileSignedId
          );
        }
      },
    },
  },
};
</script>

