<template >
  <v-container fluid>
    <v-alert border="left" dismissible prominent outlined color="primary">
      <p>
        <strong>
          Tous les documents à obtenir doivent être impérativement joints au
          présent rapport. Leur absence sera dûment justifiée dans le
          rapport.</strong
        >
      </p>
      <p>
        La période de référence est celle écoulée depuis le dernier examen
        réalisé par un organisme agréé, par une Autorité (même si un ou deux
        examens ont entretemps été réalisés par un mécanicien indépendant au
        titre du § ML.A.901(b)(4).
      </p>
      <p>
        Sauf directive particulière de l'Annexe X du G-40-01, le contrôle par
        échantillonnage portera sur un échantillon de 10 % de la population
        totale identifiée du point concerné :
      </p>
      <ul>
        <li>
          Si une anomalie est découverte, cette dernière est corrigée et
          l’échantillon sera étendu de 10 à 15 % de la population totale
          identifiée du point concerné.
        </li>
        <li>
          En cas d’anomalie supplémentaire constatée, le point sera déclaré non
          conforme et sera retourné au postulant pour revue.
        </li>
      </ul>
    </v-alert>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="3" class="mb-2">
        <v-btn class="btn" depressed @click="setAllToConform()"
          ><v-icon left dark> mdi-check </v-icon>Valider "tous conforme"</v-btn
        >
      </v-col>
    </v-row>
    <v-card outlined v-for="val in values" :key="val.id" class="mb-8">
      <v-row>
        <v-col cols="12">
          <div class="text-h5 ml-6 mt-4" style="word-break: break-word">
            {{ val.title }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <div
            class="text-body-2 editor ml-6 mb-4"
            v-html="val.description"
          ></div>
        </v-col>
        <v-col cols="3">
          <v-radio-group
            class="ml-6"
            v-model="val.value"
            dense
            @change="updateValue(val)"
          >
            <v-radio color="success" value="conform" label="conforme">
              <template v-slot:label>
                <div v-if="val.value == 'conform'">
                  <strong class="success--text">Conforme</strong>
                </div>
                <div v-else>Conforme</div>
              </template> </v-radio
            ><v-radio color="error" value="not_conform" label="Non-conforme">
              <template v-slot:label>
                <div v-if="val.value == 'not_conform'">
                  <strong class="error--text">Non-Conforme</strong>
                </div>
                <div v-else>Non-Conforme</div>
              </template>
            </v-radio>
            <v-radio color="info" value="not_applicable" label="Non applicable">
              <template v-slot:label>
                <div v-if="val.value == 'not_applicable'">
                  <strong class="info--text">Non applicable</strong>
                </div>
                <div v-else>Non applicable</div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <div v-if="val.value === 'not_conform'">
        <not-conform-item
          :cenCheckValue="val"
          @refreshDeviation="refreshDeviation()"
        ></not-conform-item></div
    ></v-card>
  </v-container>
</template>

<script>
import { cenCheckValuesQuery } from "@/graphql/cen/cen_check_values";
import { updateCenCheckValueMutation } from "@/graphql/cen/update_cen_check_value";
import NotConformItem from "./NotConformItem.vue";

export default {
  name: "realisationItem",
  props: ["typeCheck", "cen"],
  components: {
    NotConformItem,
  },
  data() {
    return {
      cenCheckValues: [],
    };
  },
  computed: {
    values: function () {
      const val = [...this.cenCheckValues];
      return val;
    },
  },
  apollo: {
    cenCheckValues: {
      query: cenCheckValuesQuery,
      variables() {
        return {
          cenId: this.cen.id,
          typeCheck: this.typeCheck,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
    falseCenCheckValues: {
      query: cenCheckValuesQuery,
      variables() {
        return {
          cenId: this.cen.id,
          value: "not_conform",
        };
      },
      update: (data) => data.cenCheckValues,
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    refreshDeviation() {
      this.$apollo.queries.falseCenCheckValues.refetch();
      this.$apollo.queries.cenCheckValues.refetch();
    },
    setAllToConform() {
      this.values.forEach((val) => {
        if (!val.value) {
          val.value = "conform";
          this.updateValue(val);
        }
      });
      this.values;
    },
    updateValue: function (val) {
      this.$apollo
        .mutate({
          mutation: updateCenCheckValueMutation,
          variables: {
            input: {
              id: parseInt(val.id, 10),
              attributes: {
                value: val.value,
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.falseCenCheckValues.refetch();
        });
    },
  },
};
</script>

<style>
</style>