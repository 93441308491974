<template>
  <v-app v-if="logged">
    <v-app-bar
      app
      style="
        background-image: linear-gradient(to right, #fff, #1e9ca5);
        height: 80;
      "
    >
      <!-- <v-col cols="3">
        <v-toolbar-title>
          <img src="@/assets/logo.png" height="72px" />
        </v-toolbar-title>
      </v-col> -->
      <v-spacer></v-spacer>
      <v-col cols="6" style="padding-bottom: 0px">
        <GlobalSearch />
      </v-col>
      <notifications />
      <v-btn
        icon
        large
        :to="{ name: 'user', params: { id: me.id } }"
        active-class
        exact-active-class
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn icon large @click="logout">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      permanent
      :mini-variant.sync="mini"
      color="#ffffff"
    >
      <v-list nav dense>
        <v-list-item link @click.stop="mini = !mini">
          <v-list-item-icon>
            <v-icon>mdi-view-headline</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase" color="#a3a3a3"
            >Accueil</v-list-item-title
          >
        </v-list-item>

        <v-list-item link to="/gestion_nav">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase"
            >Tableau de bord</v-list-item-title
          >
        </v-list-item>

        <v-divider class="pb-4"></v-divider>

        <!-- <v-row class="align-center" v-show="!mini">
          <v-col cols="12" class="ml-4 pb-4">
            <span
              class="subheader show font-weight-medium"
              style="color: #2ebdc8"
            >
              GESTION DE NAVIGABILITE
            </span>
          </v-col>
        </v-row> -->

        <v-list-item link to="/liste_immat">
          <v-list-item-icon>
            <v-icon>mdi-airplane</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase" color="#a3a3a3"
            >Liste des immats</v-list-item-title
          >
        </v-list-item>

        <!-- <v-list-item link to="/pieces">
            <v-list-item-content> 
              <v-list-item-title
                >Gestion de Piéces</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/butees">
            <v-list-item-content>
              <v-list-item-title
                >Gestion de Butées</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/traitement_doc">
            <v-list-item-content>
              <v-list-item-title
                >Traitement des AD/SB</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>  -->

        <v-list-item link to="/pe">
          <v-list-item-icon>
            <v-icon color="#6e6e6e">mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase"
            >Programme d'entretien</v-list-item-title
          >
        </v-list-item>

        <v-list-item link to="/cens">
          <v-list-item-icon>
            <v-icon color="#6e6e6e">mdi-shield-airplane-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase">C.E.N</v-list-item-title>
        </v-list-item>

        <v-divider class="pb-4"></v-divider>

        <v-list-group color="#6e6e6e" prepend-icon="mdi-bookshelf" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase"
                >Bibliothèque</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item link to="/documents">
            <v-list-item-content>
              <v-list-item-title>AD/SB</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/gestion_manuel">
            <v-list-item-content>
              <v-list-item-title>Manuels</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/livrets">
            <v-list-item-content>
              <v-list-item-title>Livrets</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/tcholder_access">
            <v-list-item-content>
              <v-list-item-title>TcHolders</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/Lexique">
            <v-list-item-content>
              <v-list-item-title>Lexique</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          link
          to="/SuiviVeille"
          v-if="$permissions.can(me, 'veille')"
        >
          <v-list-item-icon>
            <v-icon>mdi-text-search</v-icon>
          </v-list-item-icon>
          <v-list-item-title>VEILLE</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/SuiviDoc">
          <v-list-item-icon>
            <v-icon>mdi-notebook-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>SUIVI</v-list-item-title>
        </v-list-item>

        <v-list-group
          color="#6e6e6e"
          prepend-icon="mdi-order-bool-ascending-variant"
          no-action
          v-if="$permissions.can(me, 'audit')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase"
                >Audits</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item link to="/PlanningAudit">
            <v-list-item-content>
              <v-list-item-title>Planning des audits</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/Audits">
            <v-list-item-content>
              <v-list-item-title>Liste des audits</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/SuiviEcarts">
            <v-list-item-content>
              <v-list-item-title>Suivi de écarts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/SuiviIgame">
            <v-list-item-content>
              <v-list-item-title>Suivi Igame</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider class="pb-4"></v-divider>

        <v-list-item link to="/contacts">
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase">Clients</v-list-item-title>
        </v-list-item>

       <v-list-item link to="/ateliers">
          <v-list-item-icon>
            <v-icon>mdi-tools</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase">Atelier</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item link to="/users">
          <v-list-item-icon>
            <v-icon  >mdi-account-group</v-icon>s
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase"
            >Utilisateurs</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item link to="/contrats">
          <v-list-item-icon>
            <v-icon>mdi-file-sign</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase"
            >Contrats de gestion</v-list-item-title
          >
        </v-list-item>

        <v-divider class="pb-4"></v-divider>

        <!-- <v-row class="align-center" v-show="!mini">
          <v-col cols="12" class="ml-4 pb-4">
            <span
              class="subheader show font-weight-medium"
              style="color: #2ebdc8"
            >
              DIVERS
            </span>
          </v-col>
        </v-row> -->

        <v-list-item link to="/admin" v-if="$permissions.can(me, 'admin')">
          <v-list-item-icon>
            <v-icon color="#6e6e6e">mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title to="/admin" class="text-uppercase"
            >Admin</v-list-item-title
          >
        </v-list-item>

        <v-list-item link to="/gestion_stat">
          <v-list-item-icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase"
            >Statistique</v-list-item-title
          >
        </v-list-item>

        <v-list-item link to="/requete">
          <v-list-item-icon>
            <v-icon>mdi-table-question</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase">Requête</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/guide" v-if="$permissions.can(me, 'admin')">
          <v-list-item-icon>
            <v-icon>mdi-book-open-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase">Guide</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main color="background">
      <router-view></router-view>
    </v-main>
    <!-- <v-footer color="#ffffff" app>
      <span class="black--text">Sega Aero &copy;2020</span>
    </v-footer> -->
  </v-app>
  <v-app v-else>
    <login></login>
  </v-app>
</template>

<style scoped>
.v-navigation-drawer .v-list-item .v-list-item__title {
  color: #6e6e6ecc;
}
.v-list--dense .v-list-item .v-list-item__icon,
.v-list-item--dense .v-list-item__icon {
  height: 24px;
}
.v-application
  .v-navigation-drawer
  .v-navigation-drawer__content
  .v-list
  .v-list-item--active {
  background-color: #1e9ca5;
}
.v-application
  .v-navigation-drawer
  .v-navigation-drawer__content
  .v-list
  .v-list-group__header--active {
  background-color: #1e9ca5;
}
.v-navigation-drawer .v-list-item--active .v-list-item__title {
  color: #ffffff;
}
.v-list .v-list-item--active .v-icon {
  color: #ffffff;
}
.v-list-item__icon .v-list-group__header__append-icon .v-icon {
  font-size: 1em;
}
</style>


<script>
import GlobalSearch from "./components/GlobalSearch.vue";
import notifications from "./components/notifications.vue";
import { onLogout } from "./vue-apollo";

import { meQuery } from "@/graphql/me";
import login from "./views/Login.vue";

export default {
  props: {
    source: String,
  },
  components: {
    GlobalSearch,
    notifications,

    login,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Igame",
    // all titles will be injected into this template
    // titleTemplate: 'Igame %s'
  },
  data: () => ({
    drawer: true,
    me: null,
    mini: true,
  }),
  computed: {
    logged: function () {
      if (this.me) {
        return true;
      }
      return false;
    },
  },
  methods: {
    logout() {
      console.log("logout");
      const apolloClient = this.$apollo.provider.defaultClient;
      // Update token and reset cache
      onLogout(apolloClient);
      // localStorage.setItem("apollo-token", null);
      this.me = null;
    },
  },
  apollo: {
    me: {
      query: meQuery,
    },
  },
};
</script>