<template>
  <v-container v-if="aircrafts">
    <v-col cols="12">
      <v-sheet outlined>
        <v-card-title class="headline text-uppercase">
          <span class="primary--text">Réalisation d'un nouveau Programme</span>
        </v-card-title>
        <v-divider></v-divider>
      </v-sheet>

      <v-card justify-center outlined class="px-5 my-5" v-if="!aircraft">
        <v-card-text>
          <v-col cols="6" offset="3" class="mt-6">
            <v-alert
              color="blue"
              border="left"
              elevation="2"
              colored-border
              type="info"
              >Il est rappelé que ce formulaire ne concerne que les Programme
              d'entretien pour part-ML. Les programmes part-M seront à réalisés
              à partir du canevas word de l'OSAC.
            </v-alert>
          </v-col>
          <v-col cols="4" offset="4" class="pt-12">
            <v-autocomplete
              filled
              rounded
              label="Choisir une immatriculation"
              v-model="aircraftChoice"
              :items="
                aircrafts.map(function (t) {
                  return {
                    text: t.immatriculation,
                    value: t.immatriculation,
                  };
                }).sort((a, b) => a.text.localeCompare(b.text))
              "
              :error-messages="immatriculationGestionErrors"
              required
            ></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn bottom to="/pe">Annuler </v-btn>
          <v-spacer></v-spacer>
          <v-btn bottom class="primary dark" @click="validImmatriculation()">
            Commencer
          </v-btn>
        </v-card-actions>
      </v-card>

      <template v-if="aircraft">
        <v-card-title>
          <v-col cols="4" offset="4">
            <v-sheet outlined rounded>
              <v-card-title
                style="color: grey; font-size: 24px"
                class="justify-center"
                >{{ aircraft.immatriculation }}
              </v-card-title>
            </v-sheet>
          </v-col>
        </v-card-title>

        <v-stepper v-model="step" alt-labels>
          <!-- stepper pour modifier -->
          <!-- <v-stepper-header v-if="maintenanceProgramme && maintenanceProgramme.id">
            <v-stepper-step editable edit-icon="mdi-pencil" step="1"
              >Identification<small>du propriétaire</small></v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step editable edit-icon="mdi-pencil" step="2"
              >Conformité<small>du programme</small></v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step editable edit-icon="mdi-pencil" step="3"
              >Manuel</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step  editable edit-icon="mdi-pencil" step="4"
              >Exigences<small>de maintenance</small></v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step editable edit-icon="mdi-pencil" step="5"
              >Tâches<small>alternatives</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable edit-icon="mdi-pencil" step="6"
              >Maintenance<small>Propriétaire/pilote</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable edit-icon="mdi-pencil" step="7"
              >Approbation
            </v-stepper-step>

            <v-divider></v-divider>

            <template>
              <v-stepper-step editable edit-icon="mdi-pencil" step="8"
                >Annexes
              </v-stepper-step>
            </template>
          </v-stepper-header> -->

          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1"
              >Identification<small>du propriétaire</small></v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2"
              >Conformité<small>du programme</small></v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 3" step="3"
              >Manuel</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 4" step="4"
              >Exigences<small>de maintenance</small></v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 5" step="5"
              >Tâches<small>alternatives</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 6" step="6"
              >Maintenance<small>Propriétaire/pilote</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 7" step="7"
              >Approbation
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 8" step="8"
              >Guide 3
            </v-stepper-step>

            <v-divider></v-divider>

            <template>
              <v-stepper-step :complete="step > 9" step="9"
                >Annexes
              </v-stepper-step>
            </template>

            <v-divider></v-divider>

            <template>
              <v-stepper-step :complete="step > 10" step="10"
                >Checklist
              </v-stepper-step>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-card-title>Identification de l'aéronef</v-card-title>
                <v-card-text>
                  <owners v-bind:ownersData.sync="ownersData"></owners>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile to="/pe">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Annuler
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="next()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div v-if="step == 2">
                <v-card>
                  <v-card-title>Base du programme</v-card-title>
                  <v-card-text>
                    <template>
                      <div style="font-weight: 600">
                        Ce programme d'entretien est conforme à :
                      </div>
                    </template>
                    <v-radio-group dense v-model="conform" column>
                      <v-radio
                        class="pl-3 py-3"
                        color="success"
                        value="ica"
                        label="ica"
                      >
                        <template v-slot:label>
                          <div v-if="conform == 'ica'">
                            <strong class="success--text"
                              >Instructions pour le maintien de la navigabilité
                              (ICA) fournies par le détenteur de la définition
                              approuvée ()design approval holder - (DAH)</strong
                            >
                          </div>
                          <div v-else>
                            Instructions pour le maintien de la navigabilité
                            (ICA) fournies par le détenteur de la définition
                            approuvée ()design approval holder - (DAH)
                          </div>
                        </template>
                      </v-radio>
                      <v-radio
                        color="success"
                        class="pl-3 py-3"
                        value="mip"
                        label="mip"
                      >
                        <template v-slot:label>
                          <div v-if="conform == 'mip'">
                            <strong class="success--text"
                              >Programme minimum d'inspection (MIP) comme
                              détaillé dans la dernière version en vigueur de
                              l'AMC ML.A.302 (d)</strong
                            >
                          </div>
                          <div v-else>
                            Programme minimum d'inspection (MIP) comme détaillé
                            dans la dernière version en vigueur de l'AMC
                            ML.A.302 (d)
                          </div>
                        </template>
                      </v-radio>
                      <v-radio
                        color="success"
                        class="pl-3 py-3"
                        value="mip_other"
                        label="mip_other"
                      >
                        <template v-slot:label>
                          <div v-if="conform == 'mip_other'">
                            <strong class="success--text"
                              >Autre programme minimum d'inspection (MIP)
                              conforme au ML.A.302(d) (Lister dans Annexe
                              A)</strong
                            >
                          </div>
                          <div v-else>
                            Autre programme minimum d'inspection (MIP) conforme
                            au ML.A.302(d) (Lister dans Annexe A)
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <!-- <div class="error" v-if="!$v.conform.required">Requis</div> -->
                    <template v-if="conform == 'mip_other'">
                      <div class="pt-2 pb-3 ml-12">
                        Est-ce que le MIP différe de celui décrit dans l'AMC
                        ML.A.302(d) ?
                      </div>
                      <v-radio-group dense class="ml-12" v-model="annexA" row>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Oui"
                          :value="true"
                        >
                          <template v-slot:label>
                            <div v-if="annexA == true">
                              <strong class="success--text">Oui</strong>
                            </div>
                            <div v-else>Oui</div>
                          </template>
                        </v-radio>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Non"
                          :value="false"
                        >
                          <template v-slot:label>
                            <div v-if="annexA == false">
                              <strong class="success--text">Non</strong>
                            </div>
                            <div v-else>Non</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </template>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      bottom
                      class="mr-4"
                      text
                      tile
                      @click.prevent="prev()"
                    >
                      <v-icon left>mdi-chevron-left</v-icon>
                      Précedent
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      bottom
                      v-if="$v.conform.required"
                      class="mr-4 primary dark"
                      @click.prevent="saveStep2()"
                      :loading="finishLoading"
                      >Suivant<v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn v-else bottom class="mr-4 primary--text" disabled
                      >Suivant<v-icon right>mdi-chevron-right</v-icon></v-btn
                    >

                    <!-- <v-btn
                  class="mr-4 my-8 primary--text"
                  outlined
                  depressed
                  @click.prevent="prev()"
                >
                  Précédent
                </v-btn>
                <v-btn
                  v-if="$v.conform.required"
                  class="mr-4 primary dark"
                  depressed
                  dark
                  @click.prevent="saveStep2()"
                >
                  Continue
                </v-btn>
                <v-btn
                  v-else
                  class="mr-4 my-8 primary--text"
                  disabled
                  outlined
                  depressed
                  >Continue</v-btn
                >  -->
                  </v-card-actions>
                </v-card>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card v-if="aircraft">
                <v-card-title
                  >Détenteur de la définition approuvée DAH - Instructions pour
                  le maintien de la navigabilité (ICA)</v-card-title
                >
                <v-card-text>
                  <v-col cols="12">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th width="50px">type</th>
                          <th width="250px">TcHolder</th>
                          <th width="250px">Modèle</th>
                          <th width="500px">Manuel</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cellule</td>
                          <td>
                            {{ aircraft.cell.partModel.tcHolder.name }}
                          </td>
                          <td>{{ aircraft.cell.partModel.reference }}</td>
                          <td>
                            <v-autocomplete
                              class="pt-2"
                              filled
                              rounded
                              label="Choisir les manuels"
                              :multiple="true"
                              v-model="cellManuals"
                              :items="getManuals(aircraft.cell.partModel.id)"
                            ></v-autocomplete>
                          </td>
                        </tr>
                        <tr v-for="motor in aircraft.motors" :key="motor.id">
                          <td>Moteur</td>
                          <td>{{ motor.partModel.tcHolder.name }}</td>
                          <td>{{ motor.partModel.reference }}</td>
                          <td>
                            <v-autocomplete
                              class="pt-2"
                              filled
                              rounded
                              label="Choisir les manuels"
                              :multiple="true"
                              v-model="motorManuals[motor.id]"
                              :items="getManuals(motor.partModel.id)"
                            ></v-autocomplete>
                          </td>
                        </tr>
                        <tr v-for="prop in aircraft.propellers" :key="prop.id">
                          <td>Hélice</td>
                          <td>{{ prop.partModel.tcHolder.name }}</td>
                          <td>{{ prop.partModel.reference }}</td>
                          <td>
                            <v-autocomplete
                              class="pt-2"
                              filled
                              rounded
                              label="Choisir les manuels"
                              :multiple="true"
                              v-model="propManuals[prop.id]"
                              :items="getManuals(prop.partModel.id)"
                            ></v-autocomplete>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep3()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card>
                <v-card-title
                  >Exigences de maintenance additionnelle aux ICA du DAH ou du
                  MIP</v-card-title
                >
                <v-card-text>
                  <div class="mb-8">
                    Cocher les éxigences de maintenance additionnelle qui sont
                    applicable
                  </div>

                  <annexe-b-form
                    type="maintenance_equipment"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance due à des équipements spécifiques ou des modifications"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_repair"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance du à des réparations"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_stop"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance due à des éléments à durée de vie limite (le cas devrait se présenter si le MIP est utilisé. Sinon, ces données font déjà partie des instructions du DAH utilisées comme base pour le PE)"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_mandatory"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance du à des exigences de maintien de navigabilité obligatoires (ALIs, CMRs, exigences spécifiques de la TCDS, etc... )"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_tbo"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance recommandée, comme les intervalles entre les révisions (TBO), issue de bulletins de service, de lettre de service, et autres informations de service non obligatoires"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_ad"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance du à des CNs répétitives"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_specific"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance du à des spécificités opérationnelle, de l'espace aérien ou d'exigences diverses (altimètre, compas, transpondeur, etc...)"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_exploitation"
                    :programmeId="maintenanceProgrammeId"
                    title="Maintenance du au type d'exploitation ou aux autorisations d'exploitation"
                  ></annexe-b-form>

                  <annexe-b-form
                    type="maintenance_other"
                    :programmeId="maintenanceProgrammeId"
                    title="Autre"
                  ></annexe-b-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep4()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card>
                <v-card-title
                  >Tâches de maintenance alternative aux exigences du
                  DAH</v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      <div class="mt-4" style="font-weight: 600">
                        Y-a-t-il des tâches de maintenance alternatives aux
                        exigences du DAH ?
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group v-model="maintenanceAlternative" row>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Oui (Annexe C)"
                          :value="true"
                        >
                          <template v-slot:label>
                            <div v-if="maintenanceAlternative == true">
                              <strong class="success--text"
                                >Oui (Annexe C)</strong
                              >
                            </div>
                            <div v-else>Oui (Annexe C)</div>
                          </template>
                        </v-radio>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Non"
                          :value="false"
                        >
                          <template v-slot:label>
                            <div v-if="maintenanceAlternative == false">
                              <strong class="success--text">Non</strong>
                            </div>
                            <div v-else>Non</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <dah
                    v-if="maintenanceAlternative == true"
                    :programmeId="maintenanceProgramme.id"
                  ></dah>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep5()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="6">
              <v-card>
                <v-card-title>
                  Maintenance Pilote/propriétaire
                  <br />
                  <span class="text-subtitle-2"
                    >(uniquement Aéronef part-NCO)</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      <div class="mt-4" style="font-weight: 600">
                        Le pilote propriétaire peut-il effectuer les tâches de
                        maintenance prévues à l'appendice III de la part-ML
                        (ref. ML.A.803) ?
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group v-model="pilotOwner" row>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Oui"
                          :value="true"
                        >
                          <template v-slot:label>
                            <div v-if="pilotOwner == true">
                              <strong class="success--text">Oui</strong>
                            </div>
                            <div v-else>Oui</div>
                          </template>
                        </v-radio>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Non"
                          :value="false"
                        >
                          <template v-slot:label>
                            <div v-if="pilotOwner == false">
                              <strong class="success--text">Non</strong>
                            </div>
                            <div v-else>Non</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <template v-if="pilotOwner">
                    <v-row class="pt-8">
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="12">
                            <div>
                              Lister les noms des pilotes-propriétaires
                              autorisés à libérer ce type de maintenance :
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="pilotName"
                              label="Nom du pilote-propriétaire"
                              placeholder="Pierre MARTIN"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="pilotLicence"
                              label="N° de licence de pilote"
                              placeholder="02345678"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" align="center">
                            <v-btn small color="primary" @click="addPilot()">
                              ajouter
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="12">
                            <v-sheet outlined rounded min-height="200px">
                              <v-simple-table dense class="my-2 mx-4">
                                <thead>
                                  <tr>
                                    <th>Nom</th>
                                    <th>Licence</th>
                                    <th style="width: 50px">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="pilot in pilotsInfos"
                                    :key="pilot.licence"
                                  >
                                    <td>{{ pilot.name }}</td>
                                    <td>{{ pilot.licence }}</td>
                                    <td>
                                      <v-btn
                                        icon
                                        small
                                        color="red"
                                        @click="removePilot(pilot.licence)"
                                      >
                                        <v-icon dark
                                          >mdi-delete-forever-outline</v-icon
                                        >
                                      </v-btn>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep6()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="7">
              <v-card>
                <v-card-title
                  >Approbation/déclaration du programme
                  d'entretien</v-card-title
                >
                <v-card-text>
                  <template>
                    <div style="font-weight: 600">
                      Qui déclare ce programme d'entretien ?
                    </div>

                    <v-radio-group dense v-model="registrant" row>
                      <v-radio
                        color="success"
                        label="le(s) propriétaire(s)"
                        value="owners"
                        class="px-4"
                      >
                        <template v-slot:label>
                          <div v-if="registrant == 'owners'">
                            <strong class="success--text"
                              >le(s) propriétaire(s</strong
                            >
                          </div>
                          <div v-else>le(s) propriétaire(s</div>
                        </template>
                      </v-radio>
                      <v-radio
                        color="success"
                        label="SEGA"
                        class="px-4"
                        value="camo_cao"
                      >
                        <template v-slot:label>
                          <div v-if="registrant == 'camo_cao'">
                            <strong class="success--text"
                              >SEGA (uniquement contrat déposé)</strong
                            >
                          </div>
                          <div v-else>SEGA (uniquement contrat déposé)</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </template>
                  <template v-if="registrant == 'camo_cao'">
                    <div style="font-weight: 600">
                      Qui signe ce programme d'entretien ?
                    </div>

                    <v-radio-group dense v-model="signature">
                      <v-radio
                        color="success"
                        label="Terence PIERRON"
                        value="4"
                        class="px-4"
                      >
                        <template v-slot:label>
                          <div v-if="signature == '4'">
                            <strong class="success--text"
                              >Terence PIERRON</strong
                            >
                          </div>
                          <div v-else>Terence PIERRON</div>
                        </template>
                      </v-radio>
                      <v-radio
                        color="success"
                        label="Jérôme TILMAN"
                        class="px-4"
                        value="2"
                      >
                        <template v-slot:label>
                          <div v-if="signature == '2'">
                            <strong class="success--text">Jérôme TILMAN</strong>
                          </div>
                          <div v-else>Jérôme TILMAN</div>
                        </template>
                      </v-radio>
                      <v-radio
                        color="success"
                        label="Jb MAURICE"
                        class="px-4"
                        value="3"
                      >
                        <template v-slot:label>
                          <div v-if="signature == '3'">
                            <strong class="success--text">Jb MAURICE</strong>
                          </div>
                          <div v-else>Jb MAURICE</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </template>
                  <!-- <v-radio-group dense v-model="registrant" row v-else>
                  <v-radio color="success" label="l'OSAC" value="ossac" class="px-4">
                  </v-radio>
                  <v-radio color="success" label="le CAMO ou CAO contracté" class="px-4" value="camo_cao">
                  </v-radio>
                </v-radio-group> -->
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep7()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="8">
              <v-card>
                <v-card-title>
                  Guide 3 : justification d'une déviation
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="3">
                      <div class="mt-4" style="font-weight: 600">
                        Avez-vous une déviation ?
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-radio-group dense v-model="guide3" row>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Oui"
                          :value="true"
                        >
                          <template v-slot:label>
                            <div v-if="guide3 == true">
                              <strong class="success--text">Oui</strong>
                            </div>
                            <div v-else>Oui</div>
                          </template>
                        </v-radio>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Non"
                          :value="false"
                        >
                          <template v-slot:label>
                            <div v-if="guide3 == false">
                              <strong class="success--text">Non</strong>
                            </div>
                            <div v-else>Non</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="6">
                      <v-textarea
                        label="Description de la déviation introduite au PE"
                        rows="2"
                        auto-grow
                        outlined
                        v-model="guide3Description"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <div v-if="guide3">
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G1"
                        title="Type d’exploitation"
                        :value="guide3_g1"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G2"
                        title="Regle de vol"
                        :value="guide3_g2"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G3"
                        title="Catégorie de l'aéronef"
                        :value="guide3_g3"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G4"
                        title="Qui gère le maintien de la navigabilité ?"
                        :value="guide3_g4"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G5"
                        title="Qui effectue la maintenance ?"
                        :value="guide3_g5"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G6"
                        title="Vieillissement de l’aéronef (heure de vol totales et
                        années de service)"
                        :value="guide3_g6"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G7"
                        title="Utilisation de l’aéronef"
                        :value="guide3_g7"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G8"
                        title="Défauts trouvés lors des contrôles de l’autorité (ACAM / ML.B.303)"
                        :value="guide3_g8"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G9"
                        title="Système redondant (tels que les moteurs ou hélices)"
                        :value="guide3_g9"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G10"
                        title="Mesures de maintenance compensatoires"
                        :value="guide3_g10"
                      ></guide3>
                    </div>
                    <div>
                      <guide3
                        :programmeId="maintenanceProgramme.id"
                        type="G11"
                        title="Facteur de risque d’un composant défaillant"
                        :value="guide3_g11"
                      ></guide3>
                    </div>
                    <div>
                      <template>
                        <div style="font-weight: 600" class="pb-4">
                          Conclusion de l’analyse, mesure(s) compensatoire(s)
                          prise(s) :
                        </div>
                      </template>
                      <tiptap-vuetify
                        v-model="guide3Justif"
                        :extensions="extensions"
                        :card-props="{ outlined: true }"
                        :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                      />
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep8()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="9">
              <v-card>
                <v-card-title>
                  <div>Annexe D - Informations additionnelles</div>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="5">
                      <div class="mt-4" style="font-weight: 600">
                        Avez-vous des informations additionnelles à insérer ?
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group dense v-model="annexD" row>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Oui"
                          :value="true"
                        >
                          <template v-slot:label>
                            <div v-if="annexD == true">
                              <strong class="success--text">Oui</strong>
                            </div>
                            <div v-else>Oui</div>
                          </template>
                        </v-radio>
                        <v-radio
                          class="pl-3"
                          color="success"
                          label="Non"
                          :value="false"
                        >
                          <template v-slot:label>
                            <div v-if="annexD == false">
                              <strong class="success--text">Non</strong>
                            </div>
                            <div v-else>Non</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <tiptap-vuetify
                        v-model="annexDData"
                        :extensions="extensions"
                        :card-props="{ outlined: true }"
                        :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                        v-if="annexD"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    @click.prevent="saveStep9()"
                    :loading="finishLoading"
                    >Suivant<v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="10" v-if="maintenanceProgramme">
              <v-card>
                <v-card-title> Liste de vérification pour PE </v-card-title>
                <v-card-subtitle
                  >Je certificie avoir vérifié les données ci-dessous afin de
                  permettre l'approbation indirecte du programme d'entretien
                </v-card-subtitle>
                <v-card-text>
                  <div>
                    <v-list>
                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_1"
                        title="Le PE identifie clairement le propriétaire de l'aéronef et l'aéronef auquel il se rapporte, y compris tout moteur et hélice installés."
                      ></checklistItem>

                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_2"
                        title="Le PE doit inclure, alternativement: 
                        <oL><li>Les tâches ou inspections contenues dans le programme d'inspection minimale (MIP) applicable visé au point MLA.302 (d) ;</li>
                        <li>Les instructions pour le maintien de la navigabilité (ICA) émises par le titulaire de l'agrément de conception (DAH).</li></oL>"
                      ></checklistItem>
                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_3"
                        title="Le PE peut inclure des actions d'entretien supplémentaires à celles visées au point ML.A.302 (c)(2) ou des actions d'entretien alternatives à celles visées au point ML.A.302(c)(2)(b) sur proposition du propriétaire ou de la SEGA, une fois approuvé ou déclaré conformément au point ML.A.301(b). <br />Les  actions de maintenance alternatives à celles visées au point ML.A.302  (c)(2)(b) ne doivent pas être moins restrictives que celles prévues dans le Minimum Inspection Programme (MIP) applicable."
                      ></checklistItem>
                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_4"
                        title="Le PE doit identifier toutes tâches d'entretiens supplémentaires à effectuer en raison du type d'aéronef spécifique, de la configuration et du type d'aéronef et de la spécificité de l'exploitation, tandis que les éléments suivants doivent être pris en considération au minimum :
                              <ol>
                              <li>Les équipements spécifiques installés et les modifications de l'aéronef ;</li>
                              <li>Les réparations effectuées dans l'aéronef ;</li>
                              <li>Les composants à durée de vie limitée et les composants critiques pour la 
                              sécurité du vol ;</li>
                              <li>les recommandations d'entretien, telles que les intervalles entre révisions 
                              (&quot;TBO&quot;), publiées dans les bulletins de service, les lettres de service et 
                              autres informations de service non obligatoires ;</li>
                              <li>les directives ou exigences opérationnelles applicables relatives à 
                              l'inspection périodique de certains équipements ;</li>
                              <li>les agréments opérationnels spéciaux ;</li>
                              <li>l'utilisation de l'aéronef et de l'environnement opérationnel.</li></ol>"
                      ></checklistItem>

                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_5"
                        title="Le PE doit identifier si les pilotes-propriétaires sont autorisés à effectuer l'entretien."
                      ></checklistItem>

                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_6"
                        title="Lorsqu'il est déclaré par le propriétaire, le PE doit contenir une déclaration signée par laquelle le propriétaire déclare qu'il s'agit du PE pour l'immatriculation particulière de l'aéronef et qu'il est entièrement responsable de son contenu et, en particulier, de tout écart par rapport aux recommandations du titulaire  de conception DAH."
                      ></checklistItem>

                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_7"
                        title="Lorsqu'il est approuvé par la SEGA, il doit être signé par la SEGA, qui doit conserver des enregistrements avec la justification de tout écart introduit par rapport aux recommandations du titulaire de conception DAH."
                      ></checklistItem>

                      <checklistItem
                        :programmeId="maintenanceProgramme.id"
                        type="checklist_8"
                        title="Le PE Est révisé au moins une fois par an afin d'éval0uwer son efficacité, et cette  révision est effectuée, alternativement :
                                <ol>
                                <li>conjointement avec l'examen de navigabilité de l'aéronef par la personne 
                                qui effectue cet examen de navigabilité ;</li>
                                <li>par la SEGA qui gère le maintien de la navigabilité de l'aéronef dans les 
                                cas où l'examen du PE n'est pas effectué conjointement avec un examen de 
                                navigabilité.</li></ol>"
                      ></checklistItem>
                    </v-list>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn bottom class="mr-4" text tile @click.prevent="prev()"
                    ><v-icon left>mdi-chevron-left</v-icon>
                    Précedent
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    bottom
                    class="mr-4 primary dark"
                    :loading="finishLoading"
                    @click="saveMaintenanceProgram(true)"
                    >Finaliser
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-col>
  </v-container>
</template>

<style scoped>
.v-application .rounded {
  border-radius: 28px !important;
}

.v-item--active > .v-input--selection-controls__input > label {
  color: #33bdc7 !important;
  font-weight: bold;
}

.v-stepper__content .v-card__title {
  text-decoration: underline;
  justify-content: center;
  margin-bottom: 20px;
  color: grey;
}

.v-card {
  min-height: 450px;
}

.v-card__text {
  min-height: 350px;
  font-size: 16px;
}

.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem !important;
  font-weight: 500;
}
</style>



<script>
import gql from "graphql-tag";
import { aircraftQuery } from "../../graphql/aircraft/aircraft";
import { createMaintenanceProgrammeMutation } from "../../graphql/maintenance_programme/create_maintenance_programme";
import { updateMaintenanceProgrammeMutation } from "../../graphql/maintenance_programme/update_maintenance_programme";
import { maintenanceProgrammeQuery } from "../../graphql/maintenance_programme/maintenance_programme";
import { contactsQuery } from "../../graphql/contact/contacts";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AnnexeBForm from "./AnnexeBForm.vue";
import Dah from "./Dah.vue";
import Owners from "./Owners.vue";
import Guide3 from "./Guide3.vue";
import ChecklistItem from "./ChecklistItem.vue";
import { partModelManualsQuery } from "../../graphql/document/part_model_manuals";

import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";

export default {
  mixins: [validationMixin],
  name: "NewPE",
  components: {
    AnnexeBForm,
    Dah,
    TiptapVuetify,
    Owners,
    Guide3,
    ChecklistItem,
  },
  data() {
    return {
      maintenanceProgrammeId: null,
      aircraftChoice: null,
      aircraftMaintenance: null,
      step: 1,
      conform: null,
      maintenanceAlternative: false,
      maintenanceAlternativeData: [],
      pilotOwner: false,
      registrant: null,
      aircraft: null,
      pilotName: null,
      pilotLicence: null,
      pilotsInfos: [],
      modelHolder: null,
      modelManual: null,
      modelName: null,

      annexA: false,
      annexD: false,
      dahData: [],
      cellManuals: [],
      motorManuals: [],
      propManuals: [],
      partModelManuals: [],
      annexDData: null,
      ownersData: [],
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
      signature: null,
      finishLoading: false,
      guide3: false,
      guide3Justif: null,
      guide3Description: null,
      guide3_g1: [
        [
          "eleve",
          "Risques élevés (opération commerciale, ATO ou DTO commercial)",
        ],
        [
          "moyen",
          "Risques moyens (ATO ou DTO non commercial, activité en aéroclub, opération non commerciale)",
        ],
        ["faible", "Risques faibles (utilisation à titre privée"],
      ],
      guide3_g2: [
        ["eleve", "Risques élevés (Vol aux instruments (IFR))"],
        ["moyen", "Risques moyens (Vol à vue de nuit (VFR de nuit))"],
        ["faible", "Risques faibles (Vol à vue de jour (VFR jour))"],
      ],
      guide3_g3: [
        ["eleve", "Risques élevés (autre qu’ELA1)"],
        [
          "moyen",
          "Risques moyens (ELA1 autre que light sport aeroplanes (LSA) very light aircraft (VLA), planeurs et motoplaneurs)",
        ],
        ["faible", "Risques faibles (LSA, VLA, planeurs et motoplaneurs)"],
      ],
      guide3_g4: [
        ["eleve", "Risques élevés (Propriétaire)"],
        ["faible", "Risques faibles (Organisme agrée Part-CAMO ou CAO)"],
      ],
      guide3_g5: [
        ["eleve", "Risques élevés (Pilote-Propriétaire)"],
        ["moyen", "Risques moyens (mécanicien indépendant)"],
        ["faible", "Risques faibles (Organisme agrée Part-CAO ou 145)"],
      ],
      guide3_g6: [
        [
          "eleve",
          "Risques élevés (nombreuse heures de vol ou d’années de service)",
        ],
        [
          "moyen",
          "Risques moyens (heures de vol ou années de service standards)",
        ],
        [
          "faible",
          "Risques faibles (peu d’heures de vol ou d’années de service )",
        ],
      ],
      guide3_g7: [
        ["eleve", "Risques élevés (moins de 50h de vol par an)"],
        ["moyen", "Risques moyens (autour de 200h de vol par an)"],
        ["faible", "Risques faibles (plus de 400h de vol par an)"],
      ],
      guide3_g8: [
        [
          "eleve",
          "Risques élevés (nombreux écarts relevés lors des contrôles)",
        ],
        [
          "moyen",
          "Risques moyens (faible nombre écarts relevés lors des contrôles)",
        ],
        ["faible", "Risques faibles (rares écarts relevés lors des contrôles)"],
      ],
      guide3_g9: [
        ["eleve", "Risques élevés (aéronef monomoteur)"],
        ["faible", "Risques faibles (aéronef multimoteurs)"],
      ],
      guide3_g10: [
        [
          "eleve",
          "Risques élevés (pas de mesure de maintenance compensatoire)",
        ],
        [
          "faible",
          "Risques faibles (mesures compensatoires (telles que analyses d’huile, contrôle des performances moteur, inspections corrosion, etc.))",
        ],
      ],
      guide3_g11: [
        ["eleve", "Risques élevés (moteur défaillant sur un hélicoptère)"],
        ["moyen", "Risques moyens (moteur défaillant sur un avion)"],
        ["faible", "Risques faibles (planeur et motoplaneur)"],
      ],
    };
  },
  validations() {
    if (this.step == 2) {
      return {
        conform: {
          required,
        },
      };
    }
    return {};
  },
  methods: {
    validImmatriculation() {
      this.aircraftMaintenance = this.aircraftChoice;
    },
    findManual(manuals, manualIds) {
      var info = [];
      manualIds.forEach(function (mid) {
        const m = manuals.find((man) => man.id == mid);
        info.push({ id: m.id, title: m.title, number: m.number });
      });
      return info;
    },
    addPilot() {
      const info = { name: this.pilotName, licence: this.pilotLicence };
      this.pilotsInfos.push(info);
      this.pilotName = null;
      this.pilotLicence = null;
    },
    removePilot(licence) {
      this.pilotsInfos = this.pilotsInfos.filter((p) => p.licence != licence);
    },
    saveMaintenanceProgram(finish = false) {
      var manuals = {};

      this.finishLoading = true;

      // console.log(this.cellManuals);
      if (this.cellManuals) {
        manuals["cell"] = this.cellManuals.map((m) => parseInt(m, 10));
      } else {
        manuals["cell"] = [];
      }

      // console.log(manuals);

      manuals["motors"] = [];
      this.motorManuals.forEach(function (motor, index) {
        var mans = motor.map((m) => parseInt(m, 10));
        manuals["motors"].push({ id: index, manual: mans });
      });

      manuals["propellers"] = [];
      this.propManuals.forEach(function (prop, index) {
        var mans = prop.map((m) => parseInt(m, 10));
        manuals["propellers"].push({ id: index, manual: mans });
      });

      if (this.maintenanceProgramme && this.maintenanceProgramme.id) {
        this.$apollo
          .mutate({
            mutation: updateMaintenanceProgrammeMutation,
            variables: {
              input: {
                id: parseInt(this.maintenanceProgramme.id, 10),
                attributes: {
                  part: this.typePart,
                  aircraftId: this.aircraft.id,
                  conform: this.conform,
                  registrant: this.registrant,
                  manuals: manuals,
                  pilotOwner: this.pilotOwner,
                  dah: this.dahData,
                  pilots: this.pilotsInfos,
                  annexDData: this.annexDData,
                  owners: this.ownersData,
                  maintenanceAlternative: this.maintenanceAlternative,
                  guide3: this.guide3,
                  guide3Conclusion: this.guide3Justif,
                  guide3Description: this.guide3Description,
                  validatedById: this.signature,
                },
              },
            },
          })
          .then((data) => {
            this.finishLoading = false;
            if (finish) {
              this.$router.push({
                name: "pe",
                params: {
                  id: data.data.updateMaintenanceProgramme.maintenanceProgramme
                    .id,
                },
              });
            }
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createMaintenanceProgrammeMutation,
            variables: {
              input: {
                attributes: {
                  part: this.typePart,
                  aircraftId: this.aircraft.id,
                  conform: this.conform,
                  registrant: this.registrant,
                  manuals: manuals,
                  pilotOwner: this.pilotOwner,
                  dah: this.dahData,
                  pilots: this.pilotsInfos,
                  annexDData: this.annexDData,
                  owners: this.ownersData,
                  maintenanceAlternative: this.maintenanceAlternative,
                  guide3: this.guide3,
                  guide3Conclusion: this.guide3Justif,
                  guide3Description: this.guide3Description,
                  validatedById: this.signature,
                },
              },
            },
          })
          .then((data) => {
            this.finishLoading = false;
            this.maintenanceProgrammeId =
              data.data.createMaintenanceProgramme.maintenanceProgramme.id;
            if (finish) {
              this.$router.push({
                name: "pe",
                params: {
                  id: data.data.createMaintenanceProgramme.maintenanceProgramme
                    .id,
                },
              });
            }
          });
      }
    },
    prev() {
      this.step--;
    },
    next() {
      this.step++;
    },
    saveStep2() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveMaintenanceProgram();
        this.step++;
      }
    },
    saveStep3() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    saveStep4() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    saveStep5() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    saveStep6() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    saveStep7() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    saveStep8() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    saveStep9() {
      this.saveMaintenanceProgram();
      this.step++;
    },
    getManuals(partModelId) {
      var man = [];

      this.partModelManuals.forEach((pm) => {
        pm.partModelManuals.forEach((m) => {
          if (m.partModel.id == partModelId) {
            man.push(pm);
          }
        });
      });

      return man.map(function (t) {
        return {
          text: t.numberRev + " - " + t.title,
          value: t.id,
        };
      });
    },
  },
  computed: {
    // checkboxErrors () {
    //   const errors = []
    //   if (!this.$v.checkbox.$dirty) return errors
    //   !this.$v.checkbox.checked && errors.push('You must agree to continue!')
    //   return errors
    // },
    // selectErrors () {
    //   const errors = []
    //   if (!this.$v.select.$dirty) return errors
    //   !this.$v.select.required && errors.push('Item is required')
    //   return errors
    // },
    partModelIds() {
      if (!this.aircraft) {
        return [];
      }
      var ids = [];
      ids.push(this.aircraft.cell.partModel.id);
      this.aircraft.motors.forEach((m) => {
        ids.push(m.partModel.id);
      });
      this.aircraft.propellers.forEach((p) => {
        ids.push(p.partModel.id);
      });

      //  console.log(ids)
      return ids;
    },
    immatriculationGestionErrors() {
      const errors = [];
      if (this.gestion) {
        if (!this.$v.immatriculation_gestion.$dirty) return errors;
        !this.$v.immatriculation_gestion.required &&
          errors.push("Vous devez choisir une immatriculation.");
      }
      return errors;
    },
  },
  mounted() {
    this.maintenanceProgrammeId = this.$route.params.id;
  },
  apollo: {
    aircrafts: {
      query: gql`
        {
          aircrafts {
            id
            immatriculation
          }
        }
      `,
    },
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.aircraftMaintenance,
        };
      },
      skip() {
        return !this.aircraftMaintenance;
      },
      result({ data }) {
        if (data) {
          if (this.ownersData.length == 0 && data.aircraft.contact) {
            this.ownersData.push({ owner: data.aircraft.contact.name });
          }
        }
      },
    },
    contacts: {
      query: contactsQuery,
    },
    partModelManuals: {
      query: partModelManualsQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          partModelIds: this.partModelIds,
        };
      },
      skip() {
        return !this.aircraft;
      },
    },
    maintenanceProgramme: {
      query: maintenanceProgrammeQuery,
      variables() {
        return {
          id: this.maintenanceProgrammeId,
        };
      },
      skip() {
        return !this.maintenanceProgrammeId;
      },
      result({ data }) {
        if (data) {
          this.aircraftMaintenance =
            data.maintenanceProgramme.aircraft.immatriculation;
          this.typePart = data.maintenanceProgramme.part;
          this.ownersData = data.maintenanceProgramme.owners;
          this.conform = data.maintenanceProgramme.conform;
          if (
            data.maintenanceProgramme.manuals &&
            data.maintenanceProgramme.manuals.cell
          ) {
            this.cellManuals = data.maintenanceProgramme.manuals.cell.map(
              (ma) => ma.toString()
            );
          }
          // TODO: soucis recuperation info
          if (
            data.maintenanceProgramme.manuals &&
            data.maintenanceProgramme.manuals.motors
          ) {
            data.maintenanceProgramme.aircraft.motors.forEach((m) => {
              var mInt = parseInt(m.id, 10);
              var curMotor = data.maintenanceProgramme.manuals.motors.find(
                (mo) => mo.id == mInt
              );
              if (curMotor) {
                this.motorManuals[mInt] = curMotor.manual.map((ma) =>
                  ma.toString()
                );
              }
            });
          }
          if (
            data.maintenanceProgramme.manuals &&
            data.maintenanceProgramme.manuals.propellers
          ) {
            data.maintenanceProgramme.aircraft.propellers.forEach((m) => {
              var mInt = parseInt(m.id, 10);
              var curProp = data.maintenanceProgramme.manuals.propellers.find(
                (mo) => mo.id == mInt
              );
              if (curProp) {
                this.propManuals[mInt] = curProp.manual.map((ma) =>
                  ma.toString()
                );
              }
            });
          }
          this.annexD = data.maintenanceProgramme.annexD;
          this.annexDData = data.maintenanceProgramme.annexDData;
          this.pilotOwner = data.maintenanceProgramme.pilotOwner;
          this.registrant = data.maintenanceProgramme.registrant;
          this.pilotsInfos = data.maintenanceProgramme.pilots;
          this.maintenanceAlternative =
            data.maintenanceProgramme.maintenanceAlternative;
          this.guide3 = data.maintenanceProgramme.guide3;
          this.guide3Justif = data.maintenanceProgramme.guide3Conclusion;
          this.guide3Description = data.maintenanceProgramme.guide3Description;
          if (data.maintenanceProgramme.validatedBy) {
            this.signature = data.maintenanceProgramme.validatedBy.id;
          }
        }
      },
    },
  },
};
</script>