import gql from 'graphql-tag';

export const lastAircraftsQuery = gql`
{
  lastAircrafts {
    id
    immatriculation
    cell {
      id
      partModel {
        id
        reference
        tcHolder {
          id
          name
        }
      }
    }
    contact {
      id
      name
    }
    aircraftContracts {
      id
      start
      closedAt
    }
  }
}`
