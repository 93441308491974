import gql from 'graphql-tag';
import { actionFragment } from './fragment_action';


export const deleteActionMutation = gql`
  mutation deleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input) {
      action {
        ...action
      }
    }
}
${actionFragment.fragments.action}`