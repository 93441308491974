<template>
  <div>
    <v-container fluid v-if="tcHolder">
      <v-row>
        <!-- colonne de gauche -->
        <v-col cols="3">
          <v-sheet outlined >
            <v-card-title class="justify-center">
              <span class="text-h5 font-weight-bold" style="color: #2979ff">
                {{ tcHolder.name }}<br />
              </span>
            </v-card-title>
            <v-col cols="12">
              <v-list style="font-size:14px"  >
                <v-list-item   >
                  <v-list-item-content class="py-0" >
                    <v-col cols="6">Site web</v-col>
                    <v-col cols="6" v-if="tcHolder.website" class="py-0"
                      >
                      <v-btn icon :href="tcHolder.website"  target="_blank" v-if="tcHolder.website" >
                      <v-icon >mdi-earth</v-icon>
                    </v-btn>
                    </v-col>
                    <v-col cols="6" v-else
                      ><span class="nonrens">Non renseigné</span></v-col
                    >
                    <v-divider></v-divider>
                    <v-col cols="6">Identifiant</v-col>
                    <v-col cols="6" v-if="tcHolder.login">
                    <span class="rens">{{ tcHolder.login }}</span></v-col
                    >
                    <v-col cols="6" v-else
                      ><span class="nonrens">Non renseigné</span></v-col
                    >
                    <v-divider></v-divider>
                    <v-col cols="6">Mot de passe</v-col>
                    <v-col cols="6" v-if="tcHolder.password">
                      <span class="rens">{{ tcHolder.password }}</span></v-col
                    >
                    <v-col cols="6" v-else
                      ><span class="nonrens">Non renseigné</span></v-col
                    >
                    <v-divider></v-divider>
                    <v-col cols="6">Index des manuel</v-col>
                    <v-col cols="6" v-if="tcHolder.manualIndex">
                      <span class="rens">{{ tcHolder.manualIndex }}</span></v-col
                    >
                    <v-col cols="6" v-else
                      ><span class="nonrens">Non renseigné</span></v-col
                    >
                    <v-divider></v-divider>
                    <v-col cols="6">Index des SBs</v-col>
                    <v-col cols="6" v-if="tcHolder.sbIndex">
                      <span class="rens">{{ tcHolder.sbIndex }}</span></v-col
                    >
                    <v-col cols="6" v-else
                      ><span class="nonrens">Non renseigné</span></v-col
                    >
                    <v-divider></v-divider>                    
                    <v-col cols="6">Anciennement</v-col>
                    <v-col cols="6" v-if="tcHolder.manufacturersInfo">
                      <span class="rens">{{ tcHolder.manufacturersInfo }}</span></v-col
                    >
                    <v-col cols="6" v-else
                      ><span class="nonrens">Non renseigné</span></v-col
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!-- observations -->
            <v-col cols="12">
              <v-row>
                <v-col>
                  <v-sheet outlined style="font-size:14px">
                    <v-card-title>
                      <span
                        class="text-uppercase subtitle-1 font-weight-medium"
                        ><v-icon style="margin-right: 10px"
                          >mdi-note-edit-outline</v-icon
                        >
                        Observations</span>
                      <v-spacer></v-spacer>
                      <v-dialog v-model="dialogObs" max-width="700">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="primary--text"
                            icon
                            v-on="on"
                            @click="dialogObs = !dialogObs"
                            ><v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title> Observations </v-card-title>
                          <v-divider class="mb-8"></v-divider>
                          <v-card-text>
                            <v-row>
                              <v-col cols="10" offset="1">
                                <tiptap-vuetify
                                  class="my-0"
                                  v-model="observation"
                                  :extensions="extensions"
                                  :card-props="{ outlined: true }"
                                  :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions >
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogObs = false"
                            >
                              Annuler
                            </v-btn>
                            <v-btn color="primary" text @click="updateNotes()">
                              Enregistrer
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-card-title>
                    <div class="ml-4">
                      <p v-if="observation" v-html="observation.replaceAll('\n','<br />')"> {{ observation }}</p>
                    </div>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
        <!-- colonne de droite     -->
        <v-col cols="9">
          <v-col cols="12">
            <data-table-tc-holder />
            <!-- <v-simple-table :dense="true" :fixed-header="true">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Pdf</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Numero</th>
                    <th class="text-left">Titre</th>
                    <th class="text-left">Date Edition</th>
                    <th class="text-left">Edition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="document in tcHolder.documents" :key="document.id">
                    <td>
                      <v-btn icon v-if="document.documentFile">
                        <v-icon color="red">mdi-file-pdf-box-outline</v-icon>
                      </v-btn>
                      <v-btn icon v-else>
                        <v-icon class="primary--text"
                          >mdi-block-helper</v-icon
                        >
                      </v-btn>
                    </td>
                    <td>
                      <span v-if="document.type == 'Ad'">AD</span>
                      <span v-if="document.type == 'Sb'">SB</span>
                      <span v-if="document.type == 'Manual'">MANUEL</span>
                    </td>
                    <td class="font-weight-bold">
                      <v-btn
                        text
                        :to="{ name: 'document', params: { id: document.id } }"
                      >
                        <span v-if="document.type == 'Manual'">{{
                          document.number
                        }}</span>
                        <span v-else
                          >{{ document.number }} {{ document.revision }}</span
                        >
                      </v-btn>
                    </td>
                    <td class="font-italic">{{ document.title }}</td>
                    <td>
                      <span v-if="document.publishingDate">{{
                        document.publishingDate | moment("DD/MM/YYYY")
                      }}</span>
                    </td>
                    <td>
                      <span v-if="document.type == 'Manual'">{{
                        document.revision
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

.nonrens {
  color: lightgrey;
  font-style: italic;
}

.rens {
  color: royalblue;
  font-style: italic;
}

.v-application a:hover {
  background-color: #b0bec5;
}
.v-application p {
  margin-bottom: 0 !important;
}



</style>
<script>
import { tcHolderQuery } from "../../graphql/tc_holder";
import { updateTcHolderMutation } from '../../graphql/update_tc_holder';
import dataTableTcHolder from "./dataTableTcHolder.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  Strike,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  Heading,
} from "tiptap-vuetify";

export default {
  name: "TcHolder",
  components: {
    dataTableTcHolder, TiptapVuetify,
  },
  data() {
    return {
      tcHolder: null,
      dialogObs: false,
      observation: null,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        [
          Heading,
          {
            options: {
              levels: [1, 2],
            },
          },
        ],
      ],
    };
  },
  methods: {
    updateNotes() {
      this.$apollo.mutate({
        mutation: updateTcHolderMutation,
        variables: {
          input: {
            id: parseInt(this.tcHolder.id, 10),
            attributes: {
              observation: this.observation,
            },
          },
        },
      })
       .then(() => {
          this.dialogObs = false;
        });
    },
  },
  apollo: {
    tcHolder: {
      query: tcHolderQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          this.observation = data.tcHolder.observation;
        }
      },
    },
  },
};
</script>
