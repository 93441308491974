<template>
  <v-card outlined class="ma-4">
    <v-card-title>
      <dialog-not-conform-item
        :cenCheckValue="cenCheckValue"
        @refreshDeviation="refreshDeviation()"
      ></dialog-not-conform-item>
      Liste des écarts
    </v-card-title>
    <v-simple-table>
      <thead>
        <tr>
          <th style="width: 30px">Numéro</th>
          <th class="text-uppercase" style="width: 30px">Niv.</th>
          <th class="text-uppercase">description</th>
          <th style="width: 100px">Photo</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="cenCheckValueItem in sortedCenCheckValueItems"
          :key="cenCheckValueItem.id"
        >
          <td>{{ cenCheckValueItem.number }}</td>
          <td>
            {{
              cenCheckValueItem.deviationLevel == 3
                ? "Observation"
                : cenCheckValueItem.deviationLevel
            }}
          </td>
          <td v-html="cenCheckValueItem.deviation"></td>
          <td>
            <v-list dense v-if="cenCheckValueItem.deviationPhotos">
              <v-list-item
                v-for="(photo, index) in cenCheckValueItem.deviationPhotos"
                :key="photo.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-dialog>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on"
                          >Photo #{{ index + 1 }}</v-btn
                        >
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title>Photo #{{ index + 1 }}</v-card-title>
                          <v-card-text>
                            <v-img :src="photo.fileUrl"></v-img>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn text @click="dialog.value = false"
                              >Close</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class="ml-4" icon @click="deletePhoto(photo)">
                    <v-icon color="red" small>mdi-close-thick</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item></v-list
            >
          </td>
          <td>
            <dialog-not-conform-item
              :cenCheckValueItemId="cenCheckValueItem.id"
              :cenCheckValue="cenCheckValue" @refreshDeviation="refreshDeviation()"
            ></dialog-not-conform-item>
            <v-btn icon @click="deleteDeviation(cenCheckValueItem)" color="red"
              ><v-icon>mdi-trash-can-outline</v-icon></v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <confirm ref="confirm"></confirm>
  </v-card>
</template>

<script>
import { cenCheckValueItemsQuery } from "../../graphql/cen/cen_check_value_items";
import { deleteCenCheckValueItemMutation } from "../../graphql/cen/delete_cen_check_value_item";
import DialogNotConformItem from "./DialogNotConformItem.vue";
import { deleteAttachementMutation } from "@/graphql/delete_attachement";
import confirm from "@/components/confirm.vue";

export default {
  name: "NotConformItem",
  props: ["cenCheckValue"],
  components: {
    DialogNotConformItem,
    confirm,
  },
  data() {
    return {
      cenCheckValueItems: [],
      visible: false,
      lboxIndex: 0,
    };
  },
  computed: {
    sortedCenCheckValueItems() {
      const val = [...this.cenCheckValueItems];
      return val.sort((a, b) => a.number - b.number);
    }
  },
  methods: {
    refreshDeviation() {
      this.$apollo.queries.cenCheckValueItems.refetch();      
      this.$emit("refreshDeviation");
    },
    handleHide() {
      this.visible = false;
    },
    deletePhoto: function (photo) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAttachementMutation,
                variables: {
                  input: {
                    id: parseInt(photo.id, 10),
                  },
                },
              })
              .then(() => {
                this.refreshDeviation();
              });
          }
        });
    },
    deleteDeviation(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteCenCheckValueItemMutation,
                variables: {
                  input: {
                    id: parseInt(item.id, 10),
                  },
                },
              })
              .then(() => {
                this.refreshDeviation();
              });
          }
        });
    },
  },
  apollo: {
    cenCheckValueItems: {
      query: cenCheckValueItemsQuery,
      variables() {
        return {
          cenCheckValueId: parseInt(this.cenCheckValue.id, 10),
        };
      },
    },
  },
};
</script>

