import gql from 'graphql-tag';

export const contractFragment = {
    fragments: {
      contract: gql`
      fragment contract on Contract {
        id
        number
        register
        start
        expire
        contractType
        document
        documentSignedId
        createdAt
        updatedAt
        closedAt
        notes
        contact {
          id
          name
        }
        contractRenews {
          id
          start
        }
        aircraftContracts {
          id
          start
          closedAt
          aircraft {
            id
            immatriculation
            cell {
              id
              partModel {
                id
                reference
                tcHolder {
                  id
                  name
                }
              }
            }
          }
        }
      }` 
    }
}