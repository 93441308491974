import gql from 'graphql-tag';
import { contractFragment } from './fragment_contract';


export const updateContractMutation = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      contract {
        ...contract
      }
    }
}
${contractFragment.fragments.contract}`
