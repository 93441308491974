<template>
  <v-menu
    :close-on-click="false"
    :close-on-content-click="false"
    offset-x
    offset-y
    max-height="770"
    min-width="450"
  >
    <template v-slot:activator="{ on }">
      <v-badge
        :content="notifications.filter((n) => n.viewed === false).length"
        :value="notifications.filter((n) => n.viewed === false).length > 0"
        color="accent"
        overlap
      >
        <v-btn icon large v-on="on">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list three-line dense>
      <v-subheader>NOTIFICATIONS</v-subheader>
      <v-list-item
        v-for="notification in notifications"
        :key="notification.id"
        @click="read(notification)"
        v-bind:class="viewedColor(notification)"
      >
        <v-list-item-icon>
          <v-icon>mdi-bookshelf</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase">{{
            notification.name
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            notification.description
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>{{
            notification.createdAt | moment("from", "now")
          }}</v-list-item-action-text>
          <v-icon v-if="notification.viewed" color="grey lighten-1"
            >mdi-information-outline</v-icon
          >
          <v-icon v-else color="blue lighten-1">mdi-information-outline</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
      <!--          <v-subheader right>TOTAL : {{ notifications.length }}</v-subheader> -->
      <v-col cols="1" offset="10" class="text-right">
        <v-btn text icon class="primary--text">
          <v-icon @click="deleteAll()">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-list>
  </v-menu>
</template>

<style scoped>
.unread_notification {
  background-color: #e1f5fe;
}
/* */
.unread_notification v-list-item-title {
  font-weight: bold;
  color: #42a5f5;
}

.unread_notification v-list-item-icon {
  color: #42a5f5;
}
</style>

<script>
// import gql from "graphql-tag";
import { notificationsQuery } from "@/graphql/notifications";
import { deleteAllNotificationsMutation } from "@/graphql/delete_all_notifications";
import { readNotificationMutation } from "@/graphql/read_notification";

export default {
  props: {
    source: String,
  },
  components: {},
  data: () => ({
    drawer: null,
    notifications: [],
  }),
  methods: {
    deleteAll() {
      const ids = this.notifications.map((n) => parseInt(n.id, 10));
      console.log(ids);
      this.$apollo
        .mutate({
          mutation: deleteAllNotificationsMutation,
          variables: {
            input: {
              ids: ids,
            },
          },
          update: (store) => {
            const data = store.readQuery({ query: notificationsQuery });
            data.notifications = [];
            store.writeQuery({ query: notificationsQuery, data });
          },
        })
        .then((data) => {
          console.log(data);
        });
    },
    read(notification) {
      this.$apollo
        .mutate({
          mutation: readNotificationMutation,
          variables: {
            input: {
              id: parseInt(notification.id, 10),
            },
          },
          update: (store, { data: { readNotification } }) => {
            const data = store.readQuery({ query: notificationsQuery });
            const currentNotification = data.notifications.find(
              (b) => b.id === readNotification.notification.id
            );
            currentNotification.viewed = true;
            store.writeQuery({ query: notificationsQuery, data });
          },
        })
        .then((data) => {
          console.log(data);
        });
    },
    viewedColor(notification) {
      if (notification.viewed == false) {
        return "unread_notification";
      } else {
        return "";
      }
    },
  },
  apollo: {
    notifications: {
      query: notificationsQuery,
      // subscribeToMore: {
      //   document: gql`
      //     subscription {
      //       notificationFor {
      //         id
      //         name
      //         description
      //         viewed
      //         createdAt
      //       }
      //     }
      //   `,
      //   // Reactive variables
      //   // variables () {
      //   //   // This works just like regular queries
      //   //   // and will re-subscribe with the right variables
      //   //   // each time the values change
      //   //   return {
      //   //     userId: 1,
      //   //   }
      //   // },
      //   // Result hook
      //   // Don't forget to destructure `data`
      //   updateQuery: (previousResult, { subscriptionData }) => {
      //     console.log(subscriptionData);
      //     if (
      //       !subscriptionData.data ||
      //       !subscriptionData.data.notificationFor
      //     ) {
      //       console.log("no data");
      //       return null;
      //     }
      //     const newResult = {
      //       notifications: [...previousResult.notifications],
      //     };
      //     newResult.notifications.unshift(
      //       subscriptionData.data.notificationFor
      //     );
      //     return newResult;
      //   },
      // },
    },
  },
};
</script>