import gql from 'graphql-tag';
import { actionFragment } from './fragment_action';

export const actionQuery = gql`
query action($id: ID!) {
  action(id: $id) {
    ...action
  }
}
${actionFragment.fragments.action}`
