import gql from 'graphql-tag';

export const updateBookletMutation = gql`
  mutation updateBooklet($input: UpdateBookletInput!) {
    updateBooklet(input: $input) {
      booklet {
        id
        aircraft {
          id
          immatriculation
          contact {
              id
              name
            }
          }
          aircraftPart {
            id
            partModel {
              id
              reference
              type
            }
            serialNumber
          }
          volume
          status
          location
          bookletUpdatedAt        
      }
    }
}`
