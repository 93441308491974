import gql from 'graphql-tag';
import { cenFragment } from './fragment_cen';

export const extendCenMutation = gql`
  mutation extendCen($input: ExtendCenInput!) {
    extendCen(input: $input) {
      cen {
        ...cen
      }
    }
  }
  ${cenFragment.fragments.cen}
  `
