import gql from 'graphql-tag';
import { aircraftPhotoFragment } from './fragment_aircraft_photo';


export const createAircraftPhotoMutation = gql`
  mutation createAircraftPhoto($input: CreateAircraftPhotoInput!) {
    createAircraftPhoto(input: $input) {
      aircraftPhoto {
        ...aircraftPhoto
      }
    }
}
${aircraftPhotoFragment.fragments.aircraftPhoto}`
