<template>
  <v-container>
    <v-card outlined rounded class="mb-8">
      <v-row>
        <v-col cols="12" >
          <div class="text-h6 ml-6 mt-4"
            v-if="workOrderItemStop.itemStop.documentProcessing !== null"
          >
            {{
              workOrderItemStop.itemStop.documentProcessing.document.number
            }}
            - {{ workOrderItemStop.itemStop.documentProcessing.document.title
            }}
          </div>
          <div class="text-h6 ml-6 mt-4"
            v-if="
              workOrderItemStop.itemStop.aircraftPart !== null &&
              workOrderItemStop.itemStop.stop.inspection == false
            "
          >
            {{
              workOrderItemStop.itemStop.aircraftPart.partModel.ata.chapter
            }}
            <span v-if="workOrderItemStop.itemStop.aircraftPart.partModel.fullName" >- {{ workOrderItemStop.itemStop.aircraftPart.partModel.fullName }}</span>
            <span v-if="workOrderItemStop.itemStop.aircraftPart.serialNumber"> 
            - {{ workOrderItemStop.itemStop.aircraftPart.serialNumber }}</span>
          </div>
          <div class="text-h6 ml-6 mt-4" v-if="workOrderItemStop.itemStop.stop.inspection === true">
            {{ workOrderItemStop.itemStop.stop.name }}
          </div>
         <div
            class="text-body-1 editor ml-6"
               v-if="workOrderItemStop.itemStop.documentProcessing !== null"
            >{{ workOrderItemStop.itemStop.stop.name }} :
            {{ workOrderItemStop.itemStop.action.name }}
          </div>
          <div class="text-body-1 editor ml-6" v-if="
              workOrderItemStop.itemStop.aircraftPart !== null &&
              workOrderItemStop.itemStop.stop.inspection == false
            ">
            {{ workOrderItemStop.itemStop.stop.name }} :
            {{ workOrderItemStop.itemStop.action.name }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">

          <div class="ml-6 mt-4">
            <v-radio-group v-model="action" :row="true">
              <v-radio label="Effectué" value="done"></v-radio>
              <v-radio label="Reporté" value="report"></v-radio>
            </v-radio-group>
          </div>
          <!-- <div class="text-h5 ml-6 mt-4">
            <tiptap-vuetify
                v-model="observation"
                :extensions="extensions"
                :card-props="{ outlined: true }"
                :toolbar-attributes="{
                  dense: true,
                  color: '#eceff1',
                }"
                placeholder="Observations/Actions"
              />
          </div> -->
        </v-col>
        <v-col cols="5">
          <div
            v-if="
              workOrderItemStop.itemStop.aircraftPart !== null &&
              workOrderItemStop.itemStop.stop.inspection == false
            "
          >      
            <depose-modal :aircraftPart="workOrderItemStop.itemStop.aircraftPart"></depose-modal>
            <pose-modal :aircraft="workOrderItemStop.workOrder.aircraft"></pose-modal>
            <replace-modal :aircraftPart="workOrderItemStop.itemStop.aircraftPart" :OT="workOrderItemStop.workOrder"></replace-modal>
          </div>
          
        </v-col>
      </v-row>
       <v-row>
        <v-col cols="12">
          <v-btn class="mr-4 primary" dark @click="save()">Enregistrer</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { updateWorkOrderItemStopMutation } from "../../graphql/work_order/update_work_order_item_stop";
import DeposeModal from "./DeposeModal.vue";
import PoseModal from "./PoseModal.vue";
import ReplaceModal from "./ReplaceModal.vue";

// import {
//   TiptapVuetify,
//   Bold,
//   Italic,
//   Underline,
//   BulletList,
//   OrderedList,
//   ListItem,
//   HardBreak,
// } from "tiptap-vuetify";

export default {
  name: "RetourButeeComponent",
  props: ["workOrderItemStop"],
  components: { DeposeModal, PoseModal, ReplaceModal },
  data() {
    return {
      action: null,
      observation: null,
      // extensions: [
      //   Bold,
      //   Italic,
      //   Underline,
      //   ListItem,
      //   BulletList,
      //   OrderedList,
      //   HardBreak,
      // ],
    };
  },
  methods: {
    save() {
      this.$apollo.mutate({
        mutation: updateWorkOrderItemStopMutation,
        variables: {
          input: {
            id: parseInt(this.workOrderItemStop.id, 10),
            attributes: {
              observation: this.observation,
              status: this.action,
            },
          },
        },
      }).then(() => {
        this.$emit("change_work_order_items", true);
      });
    },
    
  },
  apollo: {},
  created() {
    this.observation = this.workOrderItemStop.observation;
    this.action = this.workOrderItemStop.status;
  },
};
</script>