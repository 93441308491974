import gql from 'graphql-tag';
import { contactFragment } from './fragment_contact';

export const updateContactMutation = gql`
  mutation updateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        ...contact
      }
    }
  }
  ${contactFragment.fragments.contact}
  `
