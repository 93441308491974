import gql from 'graphql-tag';

export const createBookletMutation = gql`
  mutation createBooklet($input: CreateBookletInput!) {
    createBooklet(input: $input) {
      booklet {
        id
        aircraft {
          id
            immatriculation
            contact {
              id
              name
            }
          }
          aircraftPart {
            id
            partModel {
              id
              reference
              type
            }
            serialNumber
          }
          volume
          status
          location
          bookletUpdatedAt        
      }
    }
}`
