<template>
  <div>


      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <sbuttonAdd
                :to="{ name: 'aeronef_new' }"
                ><v-icon left dark> mdi-plus </v-icon>Nouvelle Immatriculation</sbuttonAdd>
              <v-spacer></v-spacer>
              <v-row>
                  <v-col cols="3" offset="5" class="d-none d-sm-block">
                    <v-select
                      label="Statut"
                      :items="immatOptions"
                      v-model="immatFilter"
                    ></v-select>
                  </v-col>          
                  <v-col cols="4">  
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Rechercher"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="aircrafts"
              :footer-props="{
                'items-per-page-options': [12, 50, 100, -1],
                'items-per-page-text': 'lignes par page',
                'items-per-page-all-text': 'Tous',
              }"
              :search="search"
              :loading="isLoading"
              loading-text="1 avion, 10 avions, 50 avions, 150 avions...  Vous gerez Orly ma parole !"
              dense
              locale="fr-FR"
              fixed-header
              height="630px"
              no-data-text="1 avion, 10 avions, 50 avions, 150 avions...  Vous gerez Orly ma parole !"
              no-results-text="Aucun résultat"
            >
              <template v-slot:item.status="{ item }">
                <v-icon color="green" v-if="item.status == 'active'"
                  >mdi-alpha-g-circle-outline</v-icon
                >
                <v-icon color="orange" v-if="item.status == 'init'"
                  >mdi-alpha-i-circle-outline</v-icon
                >
                <v-icon color="red" v-if="item.status == 'archive'"
                  >mdi-alpha-a-circle-outline</v-icon
                >
                <v-icon color="blue" v-if="item.status == 'classif'"
                  >mdi-alpha-c-circle-outline</v-icon
                >
                <v-icon color="yellow" v-if="item.status == 'fuck'"
                  >mdi-alpha-f-circle-outline</v-icon
                >
              </template>
              <template v-slot:item.immatriculation="{ item }">
                <sbuttonLink
                  :to="{
                    name: 'aeronef',
                    params: { immatriculation: item.immatriculation, page: 'info' },
                  }"
                  >{{ item.immatriculation }}</sbuttonLink
                >
              </template>
              <template v-slot:item.motors="{ item }">{{
                printMotor(item.motors)
              }}</template>
              <template v-slot:item.propellers="{ item }">{{
                printPropeller(item.propellers)
              }}</template>
              <template v-slot:item.cell.partModel.reference="{ item }"
                ><b v-if="item.cell">{{ item.cell.partModel.reference }}</b>
              </template>
              <template v-slot:item.supervisor.name="{ item }">
                <div v-if="item.supervisor">{{ item.supervisor.name }}</div>
                <div v-else><em>Non renseigné</em></div>
              </template>
              <template v-slot:item.contact.name="{ item }">
                <v-btn v-if="item.contact" text :to="{ name: 'contact', params: { id: item.contact.id } }">
                  <span class="text-truncate">{{ item.contact.name }}</span>
                </v-btn>
              </template>
              <template v-slot:item.contract="{ item }">
                <div v-if="item.contract">
                  <div v-if="item.contract.register == true">déposé <br />( {{ item.contract.contractType }} )</div>
                  <div v-if="item.contract.register == false">commercial <br />( {{ item.contract.contractType }} )</div>
                </div>
              </template>
              
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      
          
  
  </div>
</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { aircraftsQuery } from "../../../graphql/aircraft/aircrafts";
import sbuttonAdd from "../../../components/sbuttonAdd.vue"
import sbuttonLink from "../../../components/sbuttonLink.vue"

export default {
  name: "dataTableImmat",
  components: {
    sbuttonAdd,
    sbuttonLink,
  },
  data() {
    return {
      aircrafts: [],
      search: "",
      isLoading: true,
      headers: [
        { text: "", value: "status", width: "50px" },
{
          text: "IMMAT",
          align: "center",
          value: "immatriculation",
          width: "80px",
        },
        { text: "CTT", value: "contract", width: "100px" }, 
        { text: "CELLULE", value: "cell.partModel.fullName", width: "300px" },
        { text: "S/N", value: "cell.serialNumber", width: "100px"},       
      
        { text: "MOTEUR", value: "motors[0].partModel.fullName", width: "350px"},
        { text: "HELICE", value: "propellers[0].partModel.fullName", width: "350px" },
        { text: "CLIENT", value: "contact.name" },
        { text: "MTOW", value: "mtow", width: "50px" }, 
        { text: "TYPE", value: "easaType", width: "125px" },
        { text: 'PART', value: 'easaPartType', width: "85px" }, 
      ],
      immatFilter: "active",
      immatOptions: [
        { text: "Tous", value: "all" },
        { text: "En gestion", value: "active" },
        { text: "Classification", value: "classif" },
        { text: "Archivé", value: "archive" },
       
      ],
    };
  },
  methods: {
    printMotor(motors) {
      var mo = [];
      motors.forEach((m) => {
        mo.push(m.partModel.reference);
      });
      return mo.join("\n");
    },
    printPropeller(propellers) {
      if (propellers) {
        var po = [];
        propellers.forEach((p) => {
          po.push(p.partModel.reference);
        });
        return po.join("<br />");
      } else {
        return "";
      }
    },
  },
  apollo: {
    aircrafts: {
      query: aircraftsQuery,
      variables() {
        return {
          status: this.immatFilter 
        }
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
