<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="itemStopId" icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-else dark outlined color="primary" v-bind="attrs" v-on="on"
        >ajouter une Butée
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline text-uppercase">
        <span class="primary--text" v-if="itemStopId">MODIFIER une Butée</span>
        <span class="primary--text" v-else>AJOUTER une Butée</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="itemStop" @click="deleteItemStop()"
          ><v-icon color="red">mdi-delete</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-form class="mt-12">
        <v-col>
          <v-row>
            <v-col cols="10" offset="1" class="pb-8">
              <v-alert
                border="left"
                min-height="56px"
                colored-border
                color="primary"
                elevation="2"
                >{{ displayAlert() }}
              </v-alert>
            </v-col>
          </v-row>
          <template v-if="aircraftPart">
            <v-row
              v-if="
                aircraftPart.partModel.typeRaw == 'Motor' ||
                aircraftPart.partModel.typeRaw == 'Propeller'
              "
            >
              <v-col cols="10" offset="1" class="py-0">
                <v-radio-group dense v-model="globalCheck" row>
                  <div class="mr-4">
                    Cette butée est-elle une révision générale ?
                  </div>
                  <v-radio color="#1871ce" label="Oui" :value="true"> </v-radio>
                  <v-radio color="#1871ce" label="Non" :value="false">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="10"
                offset="1"
                class="py-0"
                v-if="
                  aircraftPart.partModel.typeRaw != 'Motor' &&
                  aircraftPart.partModel.typeRaw != 'Propeller' &&
                  aircraftPart.partModel.typeRaw != 'Cell'
                "
              >
                <v-radio-group dense v-model="globalCheckLink" row>
                  <div class="mr-4">
                    Cette butée est-elle lié à la révision générale moteur ou
                    hélice ?
                  </div>
                  <v-radio color="#1871ce" label="Oui" :value="true"> </v-radio>
                  <v-radio color="#1871ce" label="Non" :value="false">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="10" offset="1" class="py-0">
              <v-autocomplete
                dense
                outlined
                v-model="stopId"
                label="Choisir une butée"
                :items="
                  stops.map(function (t) {
                    return { text: t.name, value: t.id };
                  }).sort((a, b) => a.text.localeCompare(b.text))
                "
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" offset="1" class="py-0">
              <v-select
                dense
                outlined
                v-model="action"
                label="Choisir une action"
                :items="
                  actions.map(function (t) {
                    return { text: t.name, value: t.id };
                  }).sort((a, b) => a.text.localeCompare(b.text))
                "
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" offset="1" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="additionalInfoFr"
                counter="250"
                label="Information suplémentaire en français"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" offset="1" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="additionalInfoEn"
                counter="250"
                label="Information suplémentaire en anglais"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- <div v-if="stop"> -->
          <v-row v-if="hasHourStop()">
            <v-col cols="3" offset="1" class="py-0">
              <v-text-field
                label="Heures effectuées"
                requis
                dense
                outlined
                prepend-icon="mdi-airplane-clock"
                placeholder="00"
                v-model="hours"
              >
              </v-text-field>
            </v-col>
            <span class="pl-2 pr-4 pt-2"> : </span>
            <v-col cols="2" class="py-0 pl-0">
              <v-text-field
                label="minute"
                placeholder="00"
                requis
                dense
                outlined
                v-model="minutes"
              >
              </v-text-field>
            </v-col>
            <span class="px-2 pt-2"> avec </span>
            <v-col cols="3" class="py-0" v-if="hasHourStop()">
              <v-text-field
                label="Tolérance en heure"
                placeholder="0"
                requis
                dense
                outlined
                v-model="hoursTolerance"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="hasCycleStop()">
            <v-col cols="4" offset="1" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="cycles"
                label="Cycle"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="cyclesTolerance"
                label="Tolerance Cycle"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="hasDateStop() || hasDaysStop()">
            <v-col cols="3" offset="1" class="py-0">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="nextDatePicker"
                    label="Dernière intervention"
                    prepend-icon="mdi-calendar-month"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menuDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <span class="px-2 pt-2"> avec </span>
            <v-col cols="3" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="dateTolerance"
                label="Tolérance en jour"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="nextDate"
                label="Prochaine intervention"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- </div> -->
        </v-col>
        <v-col cols="10" offset="1" align="center">
          <v-textarea
            v-model="docRef"
            dense
            outlined
            rows="3"
            label="Référence Document"
            single-line
            auto-grow
            prepend-icon="mdi-file-link-outline"
          ></v-textarea>
        </v-col>
        <v-col cols="12" align="center" class="py-8">
          <v-btn @click="closeDialog()">Annuler</v-btn>
          <v-btn
            class="mr-4 primary primary-text"
            dark
            v-if="itemStop"
            @click="save()"
            >Modifier</v-btn
          >
          <v-btn class="mr-4 primary primary-text" dark v-else @click="save()"
            >Créer</v-btn
          >
        </v-col>
      </v-form>
    </v-card>
    <confirm ref="confirm"></confirm>
  </v-dialog>
</template>

<script>
import { createItemStopMutation } from "@/graphql/item_stop/create_item_stop";
import { updateItemStopMutation } from "../../graphql/item_stop/update_item_stop";
import { stopsQuery } from "@/graphql/stops";
import { stopQuery } from "@/graphql/stop";
import { actionsQuery } from "@/graphql/action/actions";
import { aircraftPartQuery } from "@/graphql/aircraft_part/aircraft_part";
import { documentProcessingQuery } from "@/graphql/document_processing/document_processing";
import { itemStopQuery } from "../../graphql/item_stop/item_stop";
import { deleteItemStopMutation } from "../../graphql/item_stop/delete_item_stop";
import Confirm from "../../components/confirm.vue";
import { DateTime } from "luxon";

// import sbuttonCancel from '../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../components/v-btn.vue';

export default {
  name: "itemStopForm",
  components: {
    Confirm,

    // sbuttonCancel
  },
  props: {
    itemStopId: Number,
    aircraftPartId: Number,
    parentDocumentProcessingId: Number,
  },
  data() {
    return {
      dialog: false,
      itemStop: null,
      stopId: null,
      stop: null,
      stops: [],
      action: null,
      actions: [],
      additionalInfoFr: null,
      additionalInfoEn: null,
      menuDate: false,
      startDate: null,
      cycles: 0,
      hours: null,
      minutes: null,
      days: 0,
      cyclesTolerance: 0,
      hoursTolerance: 0,
      dateTolerance: 0,
      docRef: null,
      aircraftPart: null,
      globalCheck: false,
      globalCheckLink: false,
      documentProcessingId: null,
    };
  },
  methods: {
    save() {
      if (this.itemStop) {
        this.$apollo
          .mutate({
            mutation: updateItemStopMutation,
            variables: {
              input: {
                id: parseInt(this.itemStop.id, 10),
                attributes: {
                  stopId: this.stopId,
                  actionId: this.action,
                  startDate: this.startDate,
                  value: this.calcValue,
                  additionalInfoFr: this.additionalInfoFr,
                  additionalInfoEn: this.additionalInfoEn,
                  globalCheck: this.globalCheck ? this.globalCheck : false,
                  globalCheckLink: this.globalCheckLink
                    ? this.globalCheckLink
                    : false,
                  docRef: this.docRef,
                },
              },
            },
          })
          .then(() => {
            this.closeDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createItemStopMutation,
            variables: {
              input: {
                attributes: {
                  stopId: this.stopId,
                  aircraftPartId: this.aircraftPart
                    ? this.aircraftPart.id
                    : null,
                  documentProcessingId: this.documentProcessing
                    ? this.documentProcessing.id
                    : null,
                  actionId: this.action,
                  startDate: this.startDate,
                  value: this.calcValue,
                  additionalInfoFr: this.additionalInfoFr,
                  additionalInfoEn: this.additionalInfoEn,
                  globalCheck: this.globalCheck ? this.globalCheck : false,
                  globalCheckLink: this.globalCheckLink
                    ? this.globalCheckLink
                    : false,
                  docRef: this.docRef,
                },
              },
            },
          })
          .then(() => {
            this.closeDialog();
          });
      }
    },
    deleteItemStop() {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            if (this.itemStop) {
              this.$apollo
                .mutate({
                  mutation: deleteItemStopMutation,
                  variables: {
                    input: {
                      id: parseInt(this.itemStop.id, 10),
                    },
                  },
                })
                .then(() => {
                  this.closeDialog();
                });
            }
          }
        });
    },
    closeDialog() {
      this.dialog = false;
      if (!this.itemStopId) {
        this.stopId = null;
        this.stop = null;
        this.action = null;
        this.additionalInfoFr = null;
        this.additionalInfoEn = null;
        this.startDate = null;
        this.globalCheck = null;
        this.globalCheckLink = false;
        this.cycles = null;
        this.cyclesTolerance = null;
        this.hours = null;
        this.minutes = null;
        this.hoursTolerance = null;
        this.dateTolerance = null;
        this.docRef = null;
      }
      this.$emit("change_item_stops", true);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    totalHours(hours, minutes) {
      if (!hours && !minutes) {
        return 0;
      }
      var totalMinutes = 0;
      if (hours) {
        totalMinutes += parseInt(hours, 10) * 60;
      }
      if (minutes) {
        totalMinutes += parseInt(minutes, 10);
      }
      return totalMinutes;
    },
    hasDateStop() {
      if (this.stop) {
        if (
          this.stop.value.some(
            (v) => v.unit_code == "months" || v.unit_code == "days"
          )
        ) {
          return true;
        }
      }
      return false;
    },
    hasCycleStop() {
      if (this.stop) {
        if (this.stop.value.some((v) => v.unit_code == "cycles")) {
          return true;
        }
      }
      return false;
    },
    hasDaysStop() {
      if (this.stop) {
        if (this.stop.value.some((v) => v.unit_code == "days")) {
          return true;
        }
      }
      return false;
    },
    hasHourStop() {
      if (this.stop) {
        if (this.stop.value.some((v) => v.unit_code == "hours")) {
          return true;
        }
      }
      return false;
    },
    displayAlert() {
      var buff = "";
      if (this.stop) {
        buff += this.stop.name;
      }

      if (this.action) {
        const foundAction = this.actions.find((s) => s.id == this.action);
        if (foundAction) {
          buff += " - " + foundAction.name;
        }
      }
      if (this.additionalInfoFr) {
        buff += " " + this.additionalInfoFr;
      }
      return buff;
    },
  },
  computed: {
    nextDatePicker() {
      return this.formatDate(this.startDate);
    },
    calcValue() {
      var h = { init: [], current: [] };
      if (this.hasHourStop()) {
        h["init"].push({
          value: this.totalHours(this.hours, this.minutes),
          unit_code: "hours",
          tolerance: this.hoursTolerance,
        });
      }
      if (this.hasCycleStop()) {
        h["init"].push({
          value: parseInt(this.cycles, 10),
          unit_code: "cycles",
          tolerance: this.cyclesTolerance,
        });
      }
      if (this.hasDaysStop()) {
        h["init"].push({
          value: this.startDate,
          unit_code: "days",
          tolerance: this.dateTolerance,
        });
      }
      if (this.hasDateStop()) {
        h["init"].push({
          unit_code: "months",
          value: this.startDate,
          tolerance: this.dateTolerance,
        });
      }
      return h;
    },
    nextDate() {
      if (this.startDate && this.hasDateStop()) {
        const monthStop = this.stop.value.find((v) => v.unit_code == "months");
        const daysStop = this.stop.value.find((v) => v.unit_code == "days");

        const sDate = DateTime.fromISO(this.startDate);
        console.log(sDate);

        if (monthStop) {
          return sDate
            .plus({ months: monthStop["value"] })
            .setLocale("fr")
            .toFormat("dd/MM/yyyy");
        }
        if (daysStop) {
          return sDate
            .plus({ days: daysStop["value"] })
            .setLocale("fr")
            .toFormat("dd/MM/yyyy");
        }
      }
      return "";
    },
  },
  apollo: {
    itemStop: {
      query: itemStopQuery,
      variables() {
        return {
          id: this.itemStopId,
        };
      },
      skip() {
        return !this.itemStopId;
      },
      result({ data }) {
        if (data) {
          if (data.itemStop.aircraft) {
            this.aircraftPartId = data.itemStop.aircraftPart.id;
          }
          if (data.itemStop.documentProcessing) {
            this.documentProcessingId = data.itemStop.documentProcessing.id;
          }
          if (data.itemStop.stop) {
            this.stopId = data.itemStop.stop.id;
          }
          if (data.itemStop.action) {
            this.action = data.itemStop.action.id;
          }
          this.additionalInfoFr = data.itemStop.additionalInfoFr;
          this.additionalInfoEn = data.itemStop.additionalInfoEn;
          this.startDate = data.itemStop.startDate;
          this.globalCheck = data.itemStop.globalCheck;
          this.globalCheckLink = data.itemStop.globalCheckLink;
          this.docRef = data.itemStop.docRef;
          if (data.itemStop.value && data.itemStop.value.init) {
            const c = data.itemStop.value.init.find(
              (v) => v.unit_code == "cycles"
            );
            if (c) {
              this.cycles = c.value;
              this.cyclesTolerance = c.tolerance;
            }

            const h = data.itemStop.value.init.find(
              (v) => v.unit_code == "hours"
            );
            if (h) {
              this.hours = Math.floor(h.value / 60);
              this.minutes = h.value % 60;
              this.hoursTolerance = h.tolerance;
            }
            const d = data.itemStop.value.init.find(
              (v) => v.unit_code == "days"
            );
            if (d) {
              this.dateTolerance = d.tolerance;
            }
          }
        }
      },
    },
    aircraftPart: {
      query: aircraftPartQuery,
      variables() {
        return {
          id: this.aircraftPartId,
        };
      },
      skip() {
        return !this.aircraftPartId;
      },
    },
    documentProcessing: {
      query: documentProcessingQuery,
      variables() {
        return {
          id: this.parentDocumentProcessingId,
        };
      },
      skip() {
        return !this.parentDocumentProcessingId;
      },
    },
    stops: {
      query: stopsQuery,
    },
    actions: {
      query: actionsQuery,
    },
    stop: {
      query: stopQuery,
      variables() {
        return { id: parseInt(this.stopId, 10) };
      },
      skip() {
        return !this.stopId;
      },
    },
  },
};
</script>