import gql from 'graphql-tag';

export const aircraftPartsQuery = gql`query AircraftParts($aircraftId: ID, $type: [String], $status: String) {
  aircraftParts(aircraftId: $aircraftId, type: $type, status: $status) {
    id
    serialNumber
    manufacturingDate
    position
    attachGroup
    aircraft {
      id
      immatriculation
    }
    manufacturer {
      id
      name
    }
    partModel {
      id
      reference
      designation
      type
      typeRaw
      tcHolder {
        id
        name
      }
      ata {
        id
        chapter
      }
      category {
        id
        name
      }
    }
  }
}`