import gql from 'graphql-tag';
import { cenFragment } from './fragment_cen';

export const updateCenMutation = gql`
  mutation updateCen($input: UpdateCenInput!) {
    updateCen(input: $input) {
      cen {
        ...cen
      }
    }
  }
  ${cenFragment.fragments.cen}
  `
