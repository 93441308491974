import gql from 'graphql-tag';

export const aircraftDocumentsQuery = gql`query aircraftDocuments($aircraftId: ID!) {
    aircraftDocuments(aircraftId: $aircraftId) {
      id
      typeName
      dateDoc
      name
      number
      document
      documentSignedId
      updatedAt
      createdAt
      expiredAt
      manualExpiredAt
      aircraft {
        id
        immatriculation
      }
    }
  }`
