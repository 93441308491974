import gql from 'graphql-tag';


export const updateAircraftMb303Mutation = gql`
  mutation updateAircraftMb303($input: UpdateAircraftMb303Input!) {
    updateAircraftMb303(input: $input) {
      aircraftMb303 {
        id
        reference
        inspector
        place
        mb303Date
        reportAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aircraft {
          id
          immatriculation
        }       
      }
    }
}`
