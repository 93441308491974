<template>
  <v-col cols="10" offset="1">
    <v-sheet outlined>
      <v-card-title>
        <v-btn class="primary--text" dark outlined @click="generateOsacList()"
          >Liste de flotte</v-btn
        >
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="4" offset="3">
            <v-dialog v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="choisir une période"
                  prepend-icon="mdi-calendar-month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                :first-day-of-week="1"
                locale="fr"
                no-title
                range
                ><v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="modal = false"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="customQueries"
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.delete="{ item }">
          <v-btn icon target="_blank" @click="deleteQuery(item)">
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </template>
         <template v-slot:item.report="{ item }">
          <v-btn :href="item.report" icon target="_blank">
            <v-icon color="red">mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | moment("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.params="{ item }">
          <span v-if="item.params['report_dates']">
           du {{ item.params["report_dates"][0] | moment("DD/MM/YYYY") }} au
            {{ item.params["report_dates"][1] | moment("DD/MM/YYYY") }}
          </span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-col>
</template>
<script>
import { customQueriesQuery } from "../../graphql/custom_query/custom_queries";
import { deleteCustomQueryMutation } from "../../graphql/custom_query/delete_custom_query";
import { generateOssacListMutation } from "../../graphql/custom_query/generate_ossac_list";

export default {
  name: "Flotte",
  data() {
    return {
      search: "",
      headers: [
        { text: "", value: "report", width: "75px" },
        { text: "Edition", value: "edition", width: "75px" },
        { text: "date de génération", value: "createdAt", width: "70px" },
        { text: "NOM", value: "name" },
        { text: "période", value: "params" },
        { text: "", value: "delete", width: "75px" },
      ],
      isLoading: true,
      modal: false,
      dates: [],
    };
  },
  methods: {
    generateOsacList() {
      this.$apollo
        .mutate({
          mutation: generateOssacListMutation,
          variables: {
            input: {
              params: this.dates,
            },
          },
        })
        .then(() => {
          this.$apollo.queries.customQueries.refetch();
        });
    },
    deleteQuery(item) {
      this.$apollo
        .mutate({
          mutation: deleteCustomQueryMutation,
          variables: {
            input: {
              id: parseInt(item.id, 10),
            },
          },
        })
        .then(() => {
          this.$apollo.queries.customQueries.refetch();
        });
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  apollo: {
    customQueries: {
      query: customQueriesQuery,
      variables() {
        return {
          type: "aircraft_list",
        };
      },
    },
  },
};
</script>