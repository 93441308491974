<template>
  <v-virtual-scroll :items="itemStops" :item-height="60" height="500" >
    <template v-slot:default="{ item }">
      <v-list-item :key="item.id" @click="save(item)"  flat>
        <v-list-item-action>
          <v-checkbox
            dense
            :input-value="
              workOrderItemStops.find((d) => d.itemStop.id == item.id)
                ? true
                : false
            "
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            >{{ item.stop.name }} - <span class="font-italic font-weight-bold"
              ><v-icon small color="red">mdi-clock</v-icon> {{ item.remainingText }}</span
            >
          </v-list-item-title>
          
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { changeWorkOrderItemStopkMutation } from "../../graphql/work_order/change_work_order_item_stop";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";

export default {
  name: "Piece",
  components: {},
  props: ["OT"],
  data() {
    return {
      itemStops: [],
      workOrderItemStops: [],
      selectedStop: [],
      workOrder: null,
    };
  },
  methods: {
    save(item) {
      this.$apollo.mutate({
        mutation: changeWorkOrderItemStopkMutation,
        variables: {
          input: {
            id: parseInt(this.OT.id, 10),
            itemStopId: parseInt(item.id, 10),
          },
        },
      }).then(() => {
        this.$apollo.queries.workOrderItemStops.refetch();
      });
    },
  },
  computed: {},
  apollo: {
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.OT.aircraft.id, 10),
          inspection: true,
        };
      },
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: this.OT.id,
        };
      },
    },
  },
};
</script>