import gql from 'graphql-tag';
import { flyFragment } from './fragment_fly';

export const deleteFlyMutation = gql`
  mutation deleteFly($input: DeleteFlyInput!) {
    deleteFly(input: $input) {
      fly{
       ...fly 
      }
    }
}
${flyFragment.fragments.fly}`
