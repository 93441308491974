<template>
  <v-container fluid class="background">
  <v-col cols="10" offset="1">
    <v-sheet outlined>
      <v-card-title>
        <sbuttonAdd
          v-if="typeCheck == 'doc'"
          :to="{ name: 'cen_verif_new', query: { type: typeCheck } }"
          ><v-icon left dark> mdi-plus </v-icon>Nouvel item documentaire</sbuttonAdd
        >
        <sbuttonAdd
          v-if="typeCheck == 'mechanic'"
          class="primary--text"
          dark
          outlined
          :to="{ name: 'cen_verif_new', query: { type: typeCheck } }"
          ><v-icon left dark> mdi-plus </v-icon>Nouvel item Physique</sbuttonAdd
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="header"
        :items="dragList"
        :loading="isLoading"
        item-key="id"
        :show-select="false"
        :disable-pagination="true"
        :hide-default-footer="true"
        class="page__table"
        dense
        no-results-text="Aucun résultat"
      >
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            @end="updateOrder(props.items, _self)"
          >
            <tr v-for="(check, index) in props.items" :key="index">
              <td>
                <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
              </td>
              <td>{{ check.order }}</td>
              <td>
                <span v-if="check.typeCheck == 'doc'">RD</span>
                <span v-if="check.typeCheck == 'mechanic'">RP</span>
              </td>
              <td>{{ check.title }}</td>
              <td class="editor" v-html="check.description"></td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      :to="{ name: 'cen_verif_edit', params: { id: check.id } }"
                    >
                      <v-icon v-on="on">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifier</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon>
                      <v-icon
                        v-on="on"
                        color="red"
                        @click="deleteCenCheck(check)"
                        >mdi-delete-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-sheet>
    <div v-if="$apollo.loading" class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue-grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <confirm ref="confirm"></confirm>
  </v-col>
  </v-container>
</template>

<script>
import { cenChecksQuery } from "../../../graphql/cen/cen_checks";
import { deleteCenCheckMutation } from "@/graphql/cen/delete_cen_check";
import { updateCenCheckMutation } from "@/graphql/cen/update_cen_check";
import confirm from "@/components/confirm.vue";
import draggable from "vuedraggable";
import sbuttonAdd from '../../../components/sbuttonAdd.vue';

export default {
  name: "dataTableCenChecks",
  components: {
    draggable,
    confirm,
    sbuttonAdd,
  },
  props: {
    typeCheck: {
      typeCheck: String,
    },
  },
  data() {
    return {
      cenChecks: [],
      header: [
        { text: "", width: "50px", sortable: false },
        { text: "Ordre", value: "order", width: "50px", sortable: false },
        { text: "Type", value: "typeCheck", width: "100px", sortable: false },
        { text: "Titre", value: "title", width: "300px", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: " ", width: "150px", align: "right", sortable: false, filterable: false },
      ],
      search: "",
      isLoading: false,
    };
  },
  computed: {
    dragList: function () {
      return [...this.cenChecks].sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    updateOrder(checks, vue) {
      checks.forEach(function (c, index) {
        vue.$apollo.mutate({
          mutation: updateCenCheckMutation,
          variables: {
            input: {
              id: parseInt(c.id, 10),
              attributes: {
                order: index + 1,
              },
            },
          },
        });
      });
      vue.$apollo.queries.cenChecks.refetch();
    },
    deleteCenCheck(cenCheck) {
      console.log(cenCheck);
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteCenCheckMutation,
                variables: {
                  input: {
                    id: parseInt(cenCheck.id, 10),
                  },
                },
                update: (store, { data: { deleteCenCheck } }) => {
                  const data = store.readQuery({ query: cenChecksQuery });
                  console.log(deleteCenCheck);
                  const newData = {
                    cenChecks: data.cenChecks.filter(
                      (b) => b.id !== deleteCenCheck.cenCheck.id
                    ),
                  };
                  store.writeQuery({ query: cenChecksQuery, data: newData });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
  apollo: {
    cenChecks: {
      query: cenChecksQuery,
      variables() {
        return {
          typeCheck: this.typeCheck,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>