import gql from 'graphql-tag';

export const notificationsQuery = gql`
      {
        notifications {
          id
          name
          description
          viewed
          createdAt
        }
      }
    `
