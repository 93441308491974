import gql from 'graphql-tag';

export const createStopMutation = gql`
  mutation createStop($input: CreateStopInput!) {
    createStop(input: $input) {
      stop{
        id
        name
        nameFr
        nameEn
        value
        inspection
        recurring
        createdAt
        updatedAt
      }
    }
}`
