<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouvelle Butée</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="1" offset="2">
                <v-text-field v-model="nombre1" placeholder="  0"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="unities1"
                  placeholder="Choisir une unité"
                  :items="unitiesItems1"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <p class="text-center font-weight-bold">ET / OU</p></v-col
              >
              <v-col cols="1">
                <v-text-field v-model="nombre2" placeholder="0"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="unities2"
                  placeholder="Choisir une unité"
                  :items="unitiesItems2"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mb-8">
              <v-col cols="3" offset="4">
                <v-switch v-model="recurent" label="Récurent"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="inspection" label="Visite"></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="unities1">
              <v-col cols="6" offset="3" align="center">
                <v-chip outlined pill>{{ nameFr }} - {{ nameEn }} </v-chip>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6" offset="3" align="center">
                <v-text-field v-model="customNameFr" label="Nom Français"></v-text-field>
              </v-col>
              <v-col cols="6" offset="3" align="center">
                <v-text-field v-model="customNameEn" label="Nom Anglais"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  class="mr-4 primary--text"
                  outlined
                  depressed
                  to="/admin?tab=butee"
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark @click="updateStop"
                  >Enregister</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding: 0px;
}
</style>

<script>
import { stopsQuery } from "../../../graphql/stops";
import { createStopMutation } from "../../../graphql/create_stop";
//import sbuttonSave from "../../../components/v-btn.vue";
import sbuttonCancel from "../../../components/sbuttonCancel.vue";

export default {
  name: "NewButee",
  components: {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      recurent: false,
      inspection: false,
      nombre1: null,
      unities1: "",
      unitiesItems1: [
        { text: "Cycle(s)", value: "cycles" },
        { text: "Heure(s)", value: "hours" },
        { text: "Jour(s)", value: "days" },
        { text: "Mois", value: "months" },
      ],
      nombre2: null,
      unities2: "",
      unitiesItems2: [
        { text: "Cycle(s)", value: "cycles" },
        { text: "Heure(s)", value: "hours" },
        { text: "Jour(s)", value: "days" },
        { text: "Mois", value: "months" },
      ],
      customNameFr: "",
      customNameEn: "",
    };
  },
  methods: {
    stopToFr(value, unitCode) {
      switch (unitCode) {
        case "hours":
          return value.toString() + "h";
        case "cycles":
          return value.toString() + " cycles";
        case "months":
          return value.toString() + " mois";
        case "days":
          return value.toString() + " jours";
      }
    },
    stopToEn(value, unitCode) {
      switch (unitCode) {
        case "hours":
          return value.toString() + "h";
        case "cycles":
          return value.toString() + " cycles";
        case "months":
          return value.toString() + " months";
        case "days":
          return value.toString() + " days";
      }
    },
    updateStop() {
      var newValue = [];
      var stop1 = {};
      stop1["value"] = parseInt(this.nombre1, 10);
      stop1["unit_code"] = this.unities1;
      newValue.push(stop1);
      var stop2 = {};
      stop2["value"] = parseInt(this.nombre2, 10);
      stop2["unit_code"] = this.unities2;
      newValue.push(stop2);
      this.$apollo
        .mutate({
          mutation: createStopMutation,
          variables: {
            input: {
              attributes: {
                nameFr: this.nameFr,
                nameEn: this.nameEn,
                recurring: this.recurent,
                inspection: this.inspection,
                value: newValue,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "butee" } });
        });
    },
  },
  computed: {
    nameFr: function () {
      var buff = "";
      if (this.unities1) {
        if (this.inspection) {
          buff += "Visite ";
        } else {
          if (this.recurent) {
            buff += "Tous les ";
          } else {
            buff += "Dans ";
          }
        }
        if (this.unities1 && this.nombre1) {
          buff += this.stopToFr(this.nombre1, this.unities1);
        }
        if (this.unities2 && this.nombre2) {
          buff += " / " + this.stopToFr(this.nombre2, this.unities2);
        }
        if (this.recurent && !this.inspection) {
          buff += " (Rép)";
        }
      } else {
        buff += this.customNameFr;
      }
      return buff;
    },
    nameEn: function () {
      var buff = "";
      if (this.unities1) {
        if (this.inspection) {
          buff += "Inspection ";
        } else {
          if (this.recurent) {
            buff += "Every ";
          } else {
            buff += "Within ";
          }
        }
        if (this.unities1 && this.nombre1) {
          buff += this.stopToEn(this.nombre1, this.unities1);
        }
        if (this.unities2 && this.nombre2) {
          buff += " / " + this.stopToEn(this.nombre2, this.unities2);
        }
        if (this.recurent) {
          buff += " (Rép)";
        }
      } else {
        buff += this.customNameEn;
      }
      return buff;
    },
  },
  apollo: {
    stops: {
      query: stopsQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
