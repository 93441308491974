import gql from 'graphql-tag';

export const partModelManualsQuery = gql`query partModelManuals($partModelIds: [ID]) {
        partModelManuals(partModelIds: $partModelIds) {
          id
          numberRev
          title
          partModelManuals {
            partModel {
              id
            }
          }    
       }
      }`
