<template>
  <v-container fluid v-if="aircraft">
    <v-sheet outlined style="padding-bottom: 30px">
    <v-col cols="12">
        <v-card-title class="headline text-uppercase">
          <p class="primary--text">
            Modifier | {{ aircraft.immatriculation }}
            <span class="text-caption" style="margin-bottom: 0"
              >Dernière mise à jour :
              {{ aircraft.updatedAt | moment("DD/MM/YYYY") }}</span
            >
          </p>
        </v-card-title>
        <v-divider style="padding-bottom: 30px"></v-divider>
        <v-form>
          <v-row>
            <v-col cols="6" class="pt-4 pl-6">
              <v-row>
                <v-col cols="10" offset="1" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    hint="Non modifiable"
                    persistent-hint
                    placeholder="Immatriculation"
                    v-model="immatriculation"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    hint="Non modifiable"
                    persistent-hint
                    placeholder="Client"
                    v-model="contact"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="datePicker"
                        placeholder="Date d'initialisation des butées"
                        label="Date d'initialisation des butées"
                        prepend-icon="mdi-calendar-month"
                        v-on="on"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start"
                      :first-day-of-week="1"
                      locale="fr"
                      color="green"
                      no-title
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <v-select
                    outlined
                    dense
                    label="gestionnaire principal"
                    v-model="pen"
                    :items="
                      users.map(function (u) {
                        return { text: u.name, value: u.id };
                      }).sort((a, b) => a.text.localeCompare(b.text))
                    "
                  ></v-select>
                </v-col>
                <v-col cols="10" offset="1" class="py-0">
                  <v-autocomplete
                    v-model="workshop"
                    label="Atelier par défaut"
                    outlined
                    dense
                    :items="
                      workshops.map(function (a) {
                        return { text: a.name, value: a.id };
                      }).sort((a, b) => a.text.localeCompare(b.text))
                    "
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pt-4">
              <v-row>
                <v-col cols="10" class="py-0">
                  <v-select
                    outlined
                    dense
                    label="Carburant"
                    v-model="fuelFilter"
                    :items="fuelOptions"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="MTOW"
                    v-model="modifiedMtow"
                    suffix="Kg"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" class="py-0">
                  <v-checkbox label="Forcer la mtow" v-model="mtowForced" :false-value="false" :true-value="true">
                  </v-checkbox>
                </v-col>                  
                <v-col cols="10" class="py-0">
                    <v-select
                      outlined
                      dense
                      label="Où sera Stationner l'aéronef"
                      v-model="parkingType"       
                      :items="parkingTypeList"               
                    ></v-select>
                  </v-col>
                <v-col cols="10" class="py-0">
                  <v-switch
                    dense
                    v-model="longMaintenance"
                    label="L'aéronef est-il en maintenance longue ?"
                    :false-value="false" :true-value="true">
                  </v-switch>
                </v-col>
                <v-col cols="10" class="py-0">
                  <v-switch
                    dense
                    v-model="annexe1"
                    label="L'aéronef est-il en annexe 1 ?"
                    :false-value="false" :true-value="true">
                  </v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" align="center" class="pt-8">
              <sbuttonCancel
                :exact="true"
                :to="{
                  name: 'aeronef',
                  params: { immatriculation: aircraft.immatriculation, page: 'info' },
                }"
                >Annuler</sbuttonCancel
              >
              <v-btn
                class="mr-4 primary primary-text"
                dark
                @click="updateAircraft()"
                >Enregistrer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      </v-sheet>
  </v-container>
</template>

<script>
import { aircraftQuery } from "../../graphql/aircraft/aircraft";
import { contactsQuery } from "@/graphql/contact/contacts";
import { updateAircraftMutation } from "@/graphql/aircraft/update_aircraft";
import { usersQuery } from "@/graphql/user/users";
import sbuttonCancel from "../../components/sbuttonCancel.vue";
//import sbuttonSave from '../../components/v-btn.vue';

export default {
  name: "UpdateAeronef",
  components: {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      tab: 0,
      start: null,
      menu: false,
      workshop: null,
      workshops: [],
      pen: null,
      mtow: null,
      mtowforce: null,
      users: [],
      fuelFilter: null,
      modifiedMtow: null,
      motwForced: false,
      fuelOptions: [
        { text: "100 LL", value: "100 LL" },
        { text: "JET A1", value: "JET A1" },
        { text: "SP 95", value: "SP 95" },
        { text: "UL 91", value: "UL 91" },
      ],
      cellManuals: [],
      motorManuals: [],
      propManuals: [],
      parkingType: null,
      parkingTypeList: ["Hangar", "Extérieur"],
      longMaintenance: false,
      annexe1: false,
    };
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          this.immatriculation = data.aircraft.immatriculation;
          this.contact = data.aircraft.contact.name;
          this.start = data.aircraft.createdAt.split("T")[0];
          if (data.aircraft.workshop) {
            this.workshop = data.aircraft.workshop.id;
          }
          if (data.aircraft.supervisor) {
            this.pen = data.aircraft.supervisor.id;
          }
          this.fuelFilter = data.aircraft.gasType;
          this.mtowForced = data.aircraft.mtowForced;
          if (this.mtowForced) {
            this.modifiedMtow = data.aircraft.modifiedMtow;
          } else {
            this.modifiedMtow = data.aircraft.mtow;
          }
          this.parkingType = data.aircraft.parkingType;
          this.longMaintenance = data.aircraft.longMaintenance;
          this.annexe1 = data.aircraft.annex1;
        }
      },
    },
    workshops: {
      query: contactsQuery,
      variables: {
        type: ["Workshop", "Engineer"],
      },
      update: (data) => data.contacts,
    },
    users: {
      query: usersQuery,
      variables: {
        supervisor: true,
      },
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.start);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    updateAircraft() {
      this.$apollo
        .mutate({
          mutation: updateAircraftMutation,
          variables: {
            input: {
              id: parseInt(this.aircraft.id, 10),
              attributes: {
                createdAt: this.start,
                workshopId: this.workshop,
                supervisorId: this.pen,
                gasType: this.fuelFilter,
                mtowForced: this.mtowForced,
                modifiedMtow: parseInt(this.modifiedMtow, 10),
                parkingType: this.parkingType,
                longMaintenance: this.longMaintenance,
                annex1: this.annexe1
              },
            },
          },
        })
        .then(() => {
          this.$router.push({
            name: "aeronef",
            params: { immatriculation: this.aircraft.immatriculation },
          });
        });
    },
  },
};
</script>