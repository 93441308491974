import gql from 'graphql-tag';

export const readNotificationMutation = gql`
  mutation readNotification($input: ReadNotificationInput!) {
    readNotification(input: $input) {
      notification {
          id
          name
          description
          viewed
          createdAt
        }
    }
}`
