import gql from 'graphql-tag';
import { documentProcessingFragment } from './fragment_document_processing';


export const documentProcessingsQuery = gql`query documentProcessings($status: String, $aircraftId: ID, $documentId: ID, $type: String){
        documentProcessings(status: $status, aircraftId: $aircraftId, documentId: $documentId, type: $type) {
          ...documentProcessing
       }
      }
      ${documentProcessingFragment.fragments.documentProcessing}`
