import gql from 'graphql-tag';

export const tcHolderQuery = gql`query TcHolder($id: ID!) {
    tcHolder(id: $id) {
      id
      name
      website
      login
      password
      cell
      motor
      propeller
      part
      country
      contactName
      contactEmail
      manualIndex
      sbIndex
      manufacturersInfo
      observation
      manufacturers {
        id
        name
      }
    }
  }`
