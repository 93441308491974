    <template>
      <div>
        <v-container fluid>
          <v-row v-if="cen">     
            <v-col cols="12">
              <v-sheet outlined>
                <v-card-title class="text-h5 tertio--text">
                  <v-btn
                    icon
                    fab
                    outlined
                    color="primary"
                    x-small
                    link
                    active-class="false"
                    :to="{ name: 'cen' }"
                    class="ma-4"
                  >
                    <v-icon v-bind="attrs" v-on="on" class="primary--text"
                      >mdi-arrow-left-thick</v-icon
                    >
                  </v-btn>
                  <strong>CEN | </strong>
                  <span style="font-size: 24px" v-if="cen.cell"
                    >Modifier le CEN {{ cen.reference }} </span
                  >
                  <v-spacer></v-spacer>
                  
                </v-card-title>

                <v-col cols="12" >
                  <v-row class="ml-1">
                    <v-card-subtitle class="mb-2">
                      <span
                        >Veuillez remplir les informations manquantes concernant la tenue du
                        CEN et le complément d'informations nécessaires à l'élaboration du
                        rapport</span
                      >
                    </v-card-subtitle>
                  </v-row>
                </v-col>

                <v-form>
                  <v-row class="ma-2">
                    <v-col :cols="colsize">
                      <v-form>
                        <v-row>
                          <v-col cols="4"  class="py-0">
                            <v-text-field
                              :value="cen.immatriculation"
                              label="Aéronef"
                              outlined
                              dense
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4"  class="py-0">
                            <v-autocomplete
                              v-model="contractType"
                              label="Type de contrat"
                              outlined
                              dense
                              :success="!!contractType"
                              :items="contractTypeList"
                              :disabled="cen.aircraft"
                              v-if="cen.aircraft"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4"  class="py-0">
                            <v-autocomplete
                              v-model="exploitationType"
                              label="Type d'exploitation"
                              outlined
                              dense
                              :success="!!exploitationType"
                              :items="exploitationTypeList"
                              :disabled="cen.aircraft"
                              v-if="cen.aircraft"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12"  class="py-0">
                            <v-autocomplete
                              v-model="client"
                              label="Qui est le Client ?"
                              outlined
                              hint="*obligatoire"
                              persistent-hint
                              :success="!!client"
                              clearable
                              dense
                              :items="
                                contacts.map(function (a) {
                                  return { text: a.name, value: a.id };
                                }).sort((a, b) => a.text.localeCompare(b.text))
                              "
                            ></v-autocomplete>
                          </v-col>


                          <v-col cols="12"  class="py-0">
                            <v-autocomplete
                              v-model="workshop"
                              label="Qui est l'Atelier ?"
                              outlined
                              dense
                              :success="!!workshop"
                              clearable
                              :items="
                                workshops.map(function (a) {
                                  return { text: a.name, value: a.id };
                                }).sort((a, b) => a.text.localeCompare(b.text))
                              "
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12"  class="py-0">
                            <v-select
                              v-model="PEN"
                              label="Qui sera le PEN ?"
                              outlined
                              hint="*obligatoire"
                              persistent-hint
                              :success="!!PEN"
                              clearable
                              dense
                              :items="
                                users.map(function (u) {
                                  return { text: u.name, value: u.id };
                                }).sort((a, b) => a.text.localeCompare(b.text))
                              "
                            ></v-select>
                          </v-col>  
        
                          <v-col cols="6"  class="py-0">
                            <v-menu
                              v-model="menuDate"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="cenDatePicker"
                                  label="Date de réalisation du CEN"
                                  prepend-icon="mdi-calendar-month"
                                  outlined
                                  dense
                                  clearable
                                  :success="!!cenDatePicker"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="cenDate"
                                :first-day-of-week="1"
                                locale="fr"
                                color="green"
                                no-title
                                @input="menuDate = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6"  class="py-0">
                            <v-text-field
                              v-model="RDVLieu"
                              label="Lieu du CEN"
                              outlined
                              dense
                              :success="!!RDVLieu"
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-0 pb-8">
                            <tiptap-vuetify
                              v-model="contact"
                              :extensions="extensions"
                              :card-props="{ outlined: true }"
                              :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                              placeholder="Indiquez les informations de contact"
                            />
                          </v-col>
                
                        
                        
                        </v-row>
                      </v-form>
                    </v-col>

                    <v-col :cols="colsize" >
                      <v-row>
                          <v-col cols="12"  class="py-0">
                            <v-menu
                              v-model="menuLastCenDate"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="lastCenDatePicker"
                                  label="Date d'expiration du précédent CEN"
                                  prepend-icon="mdi-calendar-month"
                                  outlined
                                  dense
                                  hint="*obligatoire"
                                  persistent-hint
                                  readonly
                                  :success="!!lastCenDatePicker"
                                  clearable
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="lastCenDate"
                                :first-day-of-week="1"
                                locale="fr"
                                color="green"
                                no-title
                                @input="menuLastCenDate = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                      </v-row>
                      


                      <v-row>
                          <v-col cols="12"  class="py-2">
                            <v-select
                              v-model="parkingType"
                              label="Où sera Stationner l'aéronef"
                              outlined
                              dense
                              :items="parkingTypeList"
                              :disabled="cen.aircraft"
                              :success="!!parkingType"
                            ></v-select>
                          </v-col>
                      </v-row>
                      
                      <v-row>
                          <v-col cols="6"  class="py-2">
                            <v-text-field
                              v-model="modifications"
                              label="Modifications"
                              outlined
                              dense
                              type="number"
                              :disabled="cen.aircraft"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" class="py-2">
                            <v-text-field
                              v-model="incidentReports"
                              label="Rapport d'incident"
                              outlined
                              dense
                              type="number"
                              :disabled="cen.aircraft"
                            ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="12" >
                            <v-switch
                              v-model="deviation"
                              label="L'aéronef a-t-il une deviation ?"
                              color="green"
                              outlined
                              dense
                              :false-value="false"
                              :disabled="cen.aircraft"
                            ></v-switch>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="12">
                            <v-switch
                              v-model="mb303"
                              label="L'aéronef a-t-il un/des MB303 ?"
                              outlined
                              color="green"
                              dense
                              :false-value="false"
                              :disabled="cen.aircraft"
                            ></v-switch>
                          </v-col>
                      </v-row>
                    </v-col>
                  
                    <v-col cols="12" align="center" class="mt-8">
                      <v-btn
                        class="mr-4 primary--text"
                        outlined
                        depressed
                        :exact="true"
                        :to="{ name: 'cen', params: { id: cen.id} }"
                        >Annuler</v-btn
                      >
                      <v-btn class="mr-4 primary" dark @click="updateCen()"
                        >Enregistrer</v-btn
                      >
                      <v-snackbar
                        v-model="snackbar"
                        :timeout="6000"
                        rounded="pill"
                        color="red"
                        top
                        app
                        text
                      >
                        <span class="text-center">{{ text }}</span>

                        <!-- <template v-slot:action="{ attrs }">
                          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                            OK
                          </v-btn>
                        </template> -->
                      </v-snackbar>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </div>
</template>

<style>
.v-messages__message {
    color: red !important;
}
</style>



<script>
import { updateCenMutation } from "@/graphql/cen/update_cen";
import { cenQuery } from "@/graphql/cen/cen";
import { contactsQuery } from "@/graphql/contact/contacts";
import { usersQuery } from "@/graphql/user/users";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";

export default {
  name: "UpdateCen",
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      aircraft: null,
      cenDate: null,
      menuDate: false,
      lastCenDate: null,
      menuLastCenDate: false,
      PEN: null,
      contact: null,
      RDVLieu: null,
      client: null,
      contacts: [],
      users: [],
      workshop: null,
      workshops: [],
      exploitationType: null,
      exploitationTypeList: [
        "NCO",
        "SPO Commercial",
        "SPO Non-Commercial",
        "ATO Commercial",
        "ATO Non-Commercial",
        "DTO Commercial",
        "DTO Non-Commercial",
      ],
      contractType: null,
      contractTypeList: ["Commercial", "Déposé"],
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
      mb303: false,
      incidentReports: null,
      modifications: false,
      deviation: false,
      parkingType: null ,
      snackbar: false,
      text: "Vos modifications ont bien été enregistrées",
    };
  },
  apollo: {
    cen: {
      query: cenQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          if (data.cen.user) {
            this.PEN = data.cen.user.id;
          }
          if (data.cen.client) {
            this.client = data.cen.client.id;
          }
          this.cenDate = data.cen.cenDate;
          this.RDVLieu = data.cen.place;
          this.contact = data.cen.contact;
          if (data.cen.workshop) {
            this.workshop = data.cen.workshop.id;
          }
          this.exploitationType = data.cen.exploitationType;
          this.contractType = data.cen.contractType;
          this.parkingType = data.cen.parkingType;
          this.deviation = data.cen.deviation;
          this.mb303 = data.cen.mb303;
          this.modifications = data.cen.modifications;
          this.incidentReports = data.cen.incidentReports;
          this.lastCenDate = data.cen.lastCenDate;
        }
      },
    },
    contacts: {
      query: contactsQuery,
       variables: {
        // notType: ["Workshop", "Engineer"]
      },
    },
    workshops: {
      query: contactsQuery,
      variables: {
        type: ["Workshop", "Engineer"],
      },
      update: (data) => data.contacts, 
    },
    users: {
      query: usersQuery,
      variables: {
        cen: true,
      },
    },
  },
  computed: {
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 6;
      }
      return "6";
    },
    cenDatePicker() {
      return this.formatDate(this.cenDate);
    },
    lastCenDatePicker() {
      return this.formatDate(this.lastCenDate);
    },
  },
  methods: {
    updateCen() {
      this.$apollo
        .mutate({
          mutation: updateCenMutation,
          variables: {
            input: {
              id: parseInt(this.cen.id, 10),
              attributes: {
                userId: this.PEN,
                clientId: this.client,
                contact: this.contact,
                cenDate: this.cenDate,
                place: this.RDVLieu,
                workshopId: this.workshop,
                exploitationType: this.exploitationType,
                contractType: this.contractType,
                parkingType: this.parkingType,
                deviation: this.deviation,
                mb303: this.mb303,
                modifications: parseInt(this.modifications, 10),
                incidentReports: parseInt(this.incidentReports, 10),
                lastCenDate: this.lastCenDate,
              },
            },
          },
        })
        .then(() => {
          // this.snackbar = true;
          this.$router.push({ name: 'cen', params: { id: this.cen.id} });
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>