import gql from 'graphql-tag';

export const stopsQuery = gql`
{
  stops {
    id
    name
    nameFr
    nameEn
    value
    inspection
    recurring
    createdAt
    updatedAt
  }
}`