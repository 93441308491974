import gql from 'graphql-tag';

export const flyFragment = {
    fragments: {
      fly: gql`
      fragment fly on Fly {
        id
        flyDate
        validated
        value
        totalValue
        aircraft {
          id
          immatriculation
        }
      }` 
    }
}