<template>
	<v-container fluid >
		<v-row>
			<v-col cols="4">
				<v-card class="mt-6" height="400">
					<v-card-title class="text-h4 align-start">
						<v-sheet outlined	
							overflow="hidden"
							color="primary"
							border-color="primary"
							elevation="6"
							rounded
							height="80"
							width="80"
							class="mt-n8"
							>
							<div class="pl-7 pt-4">
								<v-icon dark>mdi-airplane</v-icon>
							</div>
						</v-sheet>
						<div class="pt-0 mt-0" >
							<span class="text-h5 pl-4 font-weight-light">Aéronef entrant/sortant</span>
						</div>
					</v-card-title>
					<v-card-text>
							<apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
					</v-card-text>

				</v-card>
			</v-col>
			<v-col cols="4">
				<archive-reason></archive-reason>		
			</v-col>

			<v-col cols="4">
				<v-card class="mt-6" height="350">
					<v-card-title class=" pl-4 text-h4 align-start">
						<v-sheet outlined	
							overflow="hidden"
							color="#fb8c00"
							border-color="#fb8c00"
							elevation="6"
							rounded
							height="80"
							width="80"
							class="mt-n8"
							>
							<div class="pl-7 pt-4">
								<v-icon dark>mdi-airplane</v-icon>
							</div>
						</v-sheet>
						<div class="pt-0" >
							<span class="text-h5 pl-4 font-weight-light">répartition selon les types de contrats</span>
						</div>
					</v-card-title>
					<v-card-text>
							<img width="400" src="@/assets/contrat.png">
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="4">

			</v-col>
			<v-col cols="4">
				
			</v-col>
		</v-row>

	</v-container>
</template>

<style>
.v-sheet.v-card {
    border-radius: 6px;
}
</style>

<script>
import ArchiveReason from './ArchiveReason.vue'


export default {
  components: { ArchiveReason },
	data() {
		return {
			options: {
        chart: {
          id: ''
        },
        xaxis: {
          categories: []
        }
      },
      series: [{
        name: 'Entrants',
        data: []
      },
			{
        name: 'Sortants',
        data: []
      }]
		}
	},
	computed: {		
	}
	
}
</script>