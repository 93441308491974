import gql from 'graphql-tag';

export const deleteAllNotificationsMutation = gql`
  mutation deleteAllNotifications($input: DeleteAllNotificationsInput!) {
    deleteAllNotifications(input: $input) {
      notifications {
          id
          name
          description
          viewed
          createdAt
        }
    }
}`
