import gql from 'graphql-tag';

export const createWorkshopToolModelMutation = gql`
  mutation createWorkshopToolModel($input: CreateWorkshopToolModelInput!) {
    createWorkshopToolModel(input: $input) {
      workshopToolModel {
        id
        name
        periodicity       
        updatedAt
        createdAt
      }
    }
}`
