<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="pa-4">

        <v-row>
          <v-col cols="11">
            <div ><span v-html="title"></span></div>
          </v-col>
          <v-col cols="1">
            <v-switch
              color="success"
              :true-value="true"
              :false-value="false"
              class="px-4"
              v-model="conformity"
              @change="saveData()"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { maintenanceProgrammeItemsQuery } from "../../graphql/maintenance_programme/maintenance_programme_items";
import { createMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/create_maintenance_programme_item";
import { updateMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/update_maintenance_programme_item";

export default {
  name: "ChecklistItem",
  props: ["programmeId", "type", "title"],
  components: {},
  data() {
    return {
      maintenanceProgrammeItems: [],
      conformity: false,
      itemId: null,
      maintenanceProgrammeItem: null,
    };
  },
  methods: {
    saveData() {
      console.log("change " + this.conformity)
      if (this.maintenanceProgrammeItem) {
        this.$apollo
          .mutate({
            mutation: updateMaintenanceProgrammeItemMutation,
            variables: {
              input: {
                id: parseInt(this.maintenanceProgrammeItems[0].id, 10),
                attributes: {
                  conformity: this.conformity,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.maintenanceProgrammeItems.refetch();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createMaintenanceProgrammeItemMutation,
            variables: {
              input: {
                attributes: {
                  itemType: this.type,
                  maintenanceProgrammeId: this.programmeId,
                  conformity: this.conformity,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.maintenanceProgrammeItems.refetch();
          });
      }
    },
  },
  apollo: {
    maintenanceProgrammeItems: {
      query: maintenanceProgrammeItemsQuery,
      variables() {
        return {
          itemType: this.type,
          maintenanceProgrammeId: parseInt(this.programmeId, 10),
        };
      },
      result({ data }) {
        console.log(data);
        if (data && data.maintenanceProgrammeItems[0]) {
          this.conformity = data.maintenanceProgrammeItems[0].conformity;
          this.maintenanceProgrammeItem = data.maintenanceProgrammeItems[0];
        }
      },
    },
  },
};
</script>

