import gql from 'graphql-tag';
import { contractFragment } from './fragment_contract';

export const contractQuery = gql`
query contract($id: ID!) {
  contract(id: $id) {
    ...contract
  }
}
${contractFragment.fragments.contract}`
