<template>
  <v-sheet outlined flex>
    <v-card-title>
      <v-btn outlined color="primary" :to="{name: 'new_ot', params: {immatriculation: aircraft.immatriculation}}">Créer un Ordre de travail</v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="workOrders"
      :footer-props="{
              'items-per-page-options': [15, 50, 100],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :search="search"
      dense
      locale="fr-FR"
      no-results-text="Aucun résultat"
    >
        <template v-slot:item.id="{ item  }">
           <v-btn icon :to="{
                name: 'ot',
                params: {
                  id: item.id,
                },
              }">
                <v-icon v-on="on">mdi-eye</v-icon>
              </v-btn >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="{
                name: 'edit_ot',
                params: {
                  id: item.id,
                },
              }">
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn >
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on">mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <span>Traiter l'OT</span>
          </v-tooltip>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span class="text-caption"> {{ item.createdAt | moment("DD/MM/YYYY")}}</span>
        </template>
        <template v-slot:item.aprsDate="{ item }">
          <span> {{ item.aprsDate | moment("DD/MM/YYYY")}}</span>
        </template>
        <template v-slot:item.orderType="{ item }">
          <span v-if="item.orderType == 'visite'">Visite</span>
          <span v-if="item.orderType == 'travaux'">Travaux/dépannage</span>
          <span v-if="item.orderType == 'mb303_cen'">MB303/CEN</span>
        </template>
        <!-- <template v-slot:item.subOrderType="{ item }">
          <p v-if="item.orderType == 'visite'" v-html="item.subOrderType.replaceAll('\n','<br />')"></p>
        </template> -->
       <template v-slot:item.reference="{ item }">
          <v-btn small outlined  :to="{
                name: 'ot',
                params: {
                  id: item.id,
                },}" >{{ item.reference }}</v-btn>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status == 'close'">
            <v-chip
              class="ma-2"
              color="pink lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-archive-outline</v-icon>
              Clôturé le {{ item.aprsDate | moment("DD/MM/YYYY")}}
            </v-chip>          
          </span>
          <span v-if="item.status == 'sent'">
            <v-chip
              class="ma-2"
              color="orange lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-email-fast-outline</v-icon>
              En attente de retour
            </v-chip>
          </span>
          <span v-if="!item.exportedAt && item.status == 'new'">
            <v-chip
              class="ma-2"
              color="blue lighten-2"
              label
              small
              text-color="white"
            >
              <v-icon small left>mdi-file-document-edit-outline</v-icon>
              En cours d'élaboration
            </v-chip>
          </span>
        </template>
        <template v-slot:item.action="{ item }" >
          <span v-if="item.generateInvoice">
            <v-icon small color="red">mdi-currency-eur</v-icon>
          </span>
          <span v-else>
            <v-icon small color="green">mdi-currency-eur-off</v-icon>
          </span>
          <span v-if="item.invoiced">          
            <v-icon color="blue">mdi-cash-register</v-icon>
          </span>
        </template>
         <template v-slot:item.workshop="{ item }" >
          <div v-if="item.workshop">{{ item.workshop.name }}  <span v-if="item.workshop.easaNumber"><span class="font-italic"> ({{ item.workshop.easaNumber}})</span></span></div>

         </template>
         <template v-slot:item.pdf="{ item }">
            <v-btn
              icon
              v-if="item.signedWorkOrderAttachment"
              :href="item.signedWorkOrderAttachment.fileUrl"
              target="_blank"
            >
              <v-icon color="red">mdi-file-pdf-box</v-icon>
            </v-btn>
            <v-btn icon  v-else>
              <v-icon small class="grey--text">mdi-timer-sand</v-icon>
            </v-btn>
          </template>
      </v-data-table>

  </v-sheet>
</template>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
tbody td {
  white-space: nowrap;
}
</style>



<script>

import { workOrdersQuery } from "../../../graphql/work_order/work_orders";

export default {
  name: "dataTableOT",
  components : {
      },
  props: ["aircraft"],
  data: () => {
    return {
      search: "",
      headers: [
        { text: "CREE LE", value: "createdAt", width: "100px" },
        { text: "PDF", value: "pdf", width: "50px" },
        { text: "STATUS", value: "status", width: "100px" },
        { text: "REFERENCE", value: "reference", width: "150px" },
        { text: "", value: "action", width: "100px"},
        { text: "TYPE", value: "orderType" },
        { text: "DETAIL", value: "typeIgame" },
        { text: "ATELIER", value: "workshop" },
      ],
    };
  },
  apollo: {
    workOrders: {
      query: workOrdersQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
        };
      },
    },
  },
};
</script>
