import gql from 'graphql-tag';
import { workOrderItemFragment } from './fragment_work_order_item';

export const updateWorkOrderItemMutation = gql`
  mutation updateWorkOrderItem($input: UpdateWorkOrderItemInput!) {
    updateWorkOrderItem(input: $input) {
      workOrderItem {
        ...workOrderItem
      }
    }
}
${workOrderItemFragment.fragments.workOrderItem}`