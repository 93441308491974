import gql from 'graphql-tag';

export const workshopToolsQuery = gql`
  query($workshopId: ID!){
      workshopTools(workshopId: $workshopId) {
        id
        serialNumber     
        expiredAt
        workshopToolModel {
          id
          name
          periodicity
        }       
        updatedAt
        createdAt
      }
}`