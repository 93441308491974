<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs class="mt-8, mb-6, px-4, py-2">
            <v-breadcrumbs-item class="primary--text text-uppercase">Accueil</v-breadcrumbs-item>
            <v-breadcrumbs-item class="primary--text text-uppercase">Gestion des aéronefs</v-breadcrumbs-item>
            <v-breadcrumbs-item class="primary--text text-uppercase">Traitement des AD/SB</v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-card>
      </v-col>
      <v-col cols="12">
        <dataTableTraitement />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTableTraitement from "./dataTableTraitement.vue"

export default {
  name: "TraitementDoc",
  components: {
    dataTableTraitement
  },
  metaInfo() {
    return {
      title: "Traitement doc"
    }
  },
  data() {
    return {
      fab: false,
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Gestion de navigabilité',
          disabled: false,
        },
        {
          text: 'Gestion d\'aéronef',
          disabled: false,
        },
        {
          text: 'Traitement des AD/SBs',
          disabled: false,
        },
        ],
    }
  },
};
</script>