import gql from 'graphql-tag';
import { cenCheckValueItemFragment } from './fragment_cen_check_value_item';


export const createCenCheckValueItemMutation = gql`
  mutation createCenCheckValueItem($input: CreateCenCheckValueItemInput!) {
    createCenCheckValueItem(input: $input) {
      cenCheckValueItem {
        ...cenCheckValueItem      
      }
    }
} ${cenCheckValueItemFragment.fragments.cenCheckValueItem}`
