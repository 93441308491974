<template>
  <v-sheet outlined flex>
    <v-card-title>
      <span class="titlepage">Gestion des livrets</span>
    </v-card-title>
    <v-card-title>
      <sbuttonAdd
        :to="{ name: 'livret_new' }"
        style="margin-right: 20px"
        ><v-icon left dark> mdi-plus </v-icon>Nouveau livret</sbuttonAdd
      >
      <sbuttonAdd :to="{ name: 'livret_sent' }"
        >Créer un bon de renvoi</sbuttonAdd
      >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="booklets"
      :sort-by="['aircraft.immatriculation']"
      :sort-desc="['true']"
      :items-per-page="13"
      :footer-props="{
        'items-per-page-options': [13, 50, 100, -1],
        'items-per-page-text': 'lignes par page',
        'items-per-page-all-text': 'Tous',
      }"
      :search="search"
      :loading="isLoading"
      loading-text="Chargement... merci de patienter"
      dense
      no-results-text="Aucun résultat"
    >
      <template v-slot:item.id="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="updateBooklet(item)">
              <v-icon v-on="on">mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Mise à jour</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon :to="{ name: 'livret_edit', params: { id: item.id } }">
              <v-icon v-on="on">mdi-wrench-outline</v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="deleteLivret(item)">
              <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
      <template v-slot:item.aircraftPart.partModel.type="{ item }">
        <span v-if="item.aircraftPart.partModel.type == 'Cell'">Cellule</span>
        <span v-if="item.aircraftPart.partModel.type == 'Motor'">Moteur</span>
        <span v-if="item.aircraftPart.partModel.type == 'Propeller'"
          >Hélice</span
        >
      </template>
      <template v-slot:item.aircraftPart.partModel.reference="{ item }"
        >{{ item.aircraftPart.partModel.reference }} <br />
        (<em class="caption">S/N: {{ item.aircraftPart.serialNumber }}</em
        >)
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'sega'">Chez Sega</span>
        <span v-if="item.status == 'demande'">Demandé</span>
        <span v-if="item.status == 'renvoye'">Renvoyé</span>
      </template>
      <template v-slot:item.location="{ item }">
        <div v-show="item.status == 'sega'">
          <span v-if="item.location == 'archive'">Armoire archive</span>
          <span v-if="item.location == 'grande_armoire'">Grande Armoire</span>
          <span v-if="item.location == 'petite_armoire'">Petite Armoire</span>
        </div>
      </template>
      <template v-slot:item.bookletUpdatedAt="{ item }">{{
        item.bookletUpdatedAt | moment("DD/MM/YYYY")
      }}</template>
    </v-data-table>
    <confirm ref="confirm"></confirm>
  </v-sheet>
</template>


<script>
import { bookletsQuery } from "../../graphql/booklet/booklets";
import { updateBookletMutation } from "../../graphql/booklet/update_booklet";
import { deleteBookletMutation } from "../../graphql/booklet/delete_booklet";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from "../../components/sbuttonAdd.vue"

export default {
  name: "dataTableLivret",
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      booklets: [],
      search: "",
      isLoading: true,
      headers: [
        { text: "IMMAT", value: "aircraft.immatriculation", width: "100px" },
        { text: "CLIENT", value: "aircraft.contact.name" },
        { text: "TYPE", value: "aircraftPart.partModel.type" },
        { text: "MODELE", value: "aircraftPart.partModel.reference" },
        { text: "TOME", value: "volume" },
        { text: "STATUT", value: "status" },
        { text: "LOCALISATION", value: "location" },
        { text: "MISE A JOUR", value: "bookletUpdatedAt" },
        { text: " ", value: "id", width: "150px" },
      ],
    };
  },
  apollo: {
    booklets: {
      query: bookletsQuery,
      result() {
        console.log("remove loading");
        this.isLoading = false;
      },
    },
  },
  methods: {
    deleteLivret(booklet) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteBookletMutation,
                variables: {
                  input: {
                    id: parseInt(booklet.id, 10),
                  },
                },
                update: (store, { data: { deleteBooklet } }) => {
                  const data = store.readQuery({ query: bookletsQuery });
                  const newData = {
                    booklets: data.booklets.filter(
                      (b) => b.id !== deleteBooklet.booklet.id
                    ),
                  };
                  store.writeQuery({ query: bookletsQuery, data: newData });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
    updateBooklet(booklet) {
      this.$apollo
        .mutate({
          mutation: updateBookletMutation,
          variables: {
            input: {
              id: parseInt(booklet.id, 10),
              attributes: {
                bookletUpdatedAt: new Date().toISOString(),
                aircraftId: booklet.aircraft.id,
                status: booklet.status,
                location: booklet.location,
                aircraftPartId: booklet.aircraftPart.id,
                volume: booklet.volume,
              },
            },
          },
          update: (store, { data: { updateBooklet } }) => {
            const data = store.readQuery({ query: bookletsQuery });
            const currentBooklet = data.booklets.find(
              (b) => b.id === updateBooklet.booklet.id
            );
            currentBooklet.bookletUpdatedAt =
              updateBooklet.booklet.bookletUpdatedAt;
            store.writeQuery({ query: bookletsQuery, data });
          },
        })
        .then((data) => {
          console.log(data);
        });
    },
  },
};
</script>
