import gql from 'graphql-tag';

export const tcHoldersVeilleQuery = gql`
query TcHolderVeille($type: String)
{
  tcHolders(type: $type) {
    id
    name
    cell
    motor
    propeller
    part
    lastCheckAt
    website
    observation
    partModels {
      id
      reference
      designation
      typeRaw
      serie
      countAircraft
    }
  }
}`