import gql from 'graphql-tag';

export const generateOssacListMutation = gql`
  mutation generateOssacList($input: GenerateOssacListInput!) {
    generateOssacList(input: $input)  {
      customQuery {
        id
        name
        report
        edition
        customQueryType
        updatedAt
        createdAt
      }
    }
  }
  `
