import gql from 'graphql-tag';
import { aircraftContractFragment } from './fragment_aircraft_contract';


export const updateAircraftContractMutation = gql`
  mutation updateAircraftContract($input: UpdateAircraftContractInput!) {
    updateAircraftContract(input: $input) {
      aircraftContract {
        ...aircraftContract
      }
    }
}
${aircraftContractFragment.fragments.aircraftContract}`
