<template>
  <div>
    <template>
      <div style="font-weight: 600">{{ title }} :</div>
    </template>
    <v-radio-group dense v-model="level" @change="saveData()">
      <v-radio
        v-for="v in value"
        :key="v[0]"
        color="success"
        :label="v[1]"
        :value="v[0]"
      >

      </v-radio>
    </v-radio-group>
  </div>
</template>


<script>
import { maintenanceProgrammeItemsQuery } from "../../graphql/maintenance_programme/maintenance_programme_items";
import { createMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/create_maintenance_programme_item";
import { updateMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/update_maintenance_programme_item";

export default {
  name: "Guide3",
  props: ["programmeId", "type", "title", "value"],
  components: {},
  data() {
    return {
      maintenanceProgrammeItems: [],
      level: null,
      itemId: null,
    };
  },
  methods: {
    saveData() {
      if (this.level) {
        if (this.maintenanceProgrammeItems.length > 0) {
          this.$apollo
            .mutate({
              mutation: updateMaintenanceProgrammeItemMutation,
              variables: {
                input: {
                  id: parseInt(this.maintenanceProgrammeItems[0].id, 10),
                  attributes: {
                    level: this.level,
                  },
                },
              },
            })
            .then(() => {
              // this.$apollo.queries.maintenanceProgrammeItems.refetch();
            });
        } else {
          this.$apollo
            .mutate({
              mutation: createMaintenanceProgrammeItemMutation,
              variables: {
                input: {
                  attributes: {
                    itemType: this.type,
                    maintenanceProgrammeId: this.programmeId,
                    level: this.level,
                  },
                },
              },
            })
            .then(() => {});
        }
      }
    },
  },
  apollo: {
    maintenanceProgrammeItems: {
      query: maintenanceProgrammeItemsQuery,
      variables() {
        return {
          itemType: this.type,
          maintenanceProgrammeId: parseInt(this.programmeId, 10),
        };
      },
      result({ data }) {
        console.log(data)
        if (data && data.maintenanceProgrammeItems[0]) {
          this.level = data.maintenanceProgrammeItems[0].level;
        }
      }
    },
  },
};
</script>

