import gql from 'graphql-tag';


export const deleteAircraftDeviationMutation = gql`
  mutation deleteAircraftDeviation($input: DeleteAircraftDeviationInput!) {
    deleteAircraftDeviation(input: $input) {
      aircraftDeviation {
        id
        title
        deviationDate        
        aircraft {
          id
          immatriculation
        }
      }
    }
}`
