import gql from 'graphql-tag';
import { cenFragment } from './fragment_cen';

export const generateCenMutation = gql`
  mutation generateCen($input: GenerateCenInput!) {
    generateCen(input: $input) {
      cen {
        ...cen
      }
    }
  }
  ${cenFragment.fragments.cen}
  `
