<template>
  <div>
    <v-container>
      <v-row v-if="document">
        <v-col cols="1" align="center">
          <v-btn
            icon
            fab
            outlined
            x-small
            class="primary--text"
            link
            to="/documents"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8">
          <v-sheet outlined :style="color">
            <v-card-text>
              <v-row>
               <v-col cols="9">
                <v-chip
                  dense
                  outlined
                  disable
                  small
                  color="blue"
                  v-if="document.type === 'Manual'"
                  >MANUEL</v-chip
                >
                <v-chip
                  dense
                  outlined
                  disable
                  small
                  color="blue"
                  v-else
                  >AD/SB</v-chip
                >
                <template v-if="document.mandatory === true">
                  <v-chip
                    dense
                    outlined
                    disable
                    small
                    color="red"
                    >MANDATORY</v-chip
                  >
                </template>
                <template v-if="document.recurring === true">
                  <v-chip
                    dense
                    outlined
                    disable
                    small
                    color="green"
                    >REPETITIVE</v-chip
                  >
                </template>
                <template v-if="document.status === 'replace' || document.status === 'cancel'">
                  <v-chip
                    dense
                    dark
                    disable
                    small
                    color="red"
                    >ANNULE</v-chip
                  >
                </template>
              </v-col>
              <v-col cols="1"  offset="2">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      link
                      :to="{
                        name: 'document_edit',
                        params: { id: document.id },
                      }"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Modifier</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="{
                        name: 'new_document',
                        query: { replace: document.id },
                      }"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-file-replace</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        >Annuler et remplacer</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="openDialog()">
                      <v-list-item-icon>
                        <v-icon>mdi-file-cancel-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Annuler</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            </v-card-text>
            <v-card-text v-if="document.status === 'cancel'">
                <span><strong>Cause d'annulation :</strong> {{ document.cancelReason }}</span>
            </v-card-text>
            <v-card-text v-if="document.type !== 'Manual' && document.replaceBy">
              <span>Ce document est remplacé par
                  <v-btn
                    outlined
                    rounded
                    small
                    color="red"
                    :to="{
                      name: 'document',
                      params: { id: document.replaceBy.id },
                    }"
                    >{{ document.replaceBy.number }}
                    {{ document.replaceBy.revision }}</v-btn
                  >
                </span>
            </v-card-text>
            <v-card-text v-if="document.type == 'Manual' && document.replaceBy">
              <span>Ce manuel a une révision plus récente
                  <v-btn
                    outlined
                    rounded
                    small
                    color="red"
                    :to="{
                      name: 'document',
                      params: { id: document.replaceBy.id },
                    }"
                    >{{ document.replaceBy.numberRev }}
                    </v-btn
                  >
                </span>
            </v-card-text>
            <v-card-title class="text-h5" text-align:justify>
              <v-row>
                <v-col cols="12">
                  <template v-if="document.tcHolders.length > 0">
                    <span
                      style="margin-right: 10px"
                      v-for="tcHolder in document.tcHolders"
                      :key="tcHolder.id"
                      ><b>{{ tcHolder.name }}</b>
                    </span>
                  </template>
                  <template v-if="document.type == 'Manual'">
                    <span>{{ document.number }} </span><br /> 
                    <em style="word-break: break-word">{{ document.titleFr }}</em>
                  </template>
                  <span v-else>
                    <b>{{ document.number }} {{ document.revision }}</b>
                    <br />
                    <em style="word-break: break-word">{{ document.titleFr }}</em>
                  </span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-subtitle>
              <template v-if="document.type !== 'Manual'">
                  <em style="word-break: break-word; font-size: 16px">{{ document.titleEn }}</em>
              </template>
            </v-card-subtitle>
            <v-card-subtitle>
              <template v-if="document.type == 'Manual'">
                  <span  style="font-size: 14px"> {{ document.version }}</span>
              </template>
              <template v-else>
                <span style="font-size: 14px"> publié le {{
                        document.publishingDate | moment("DD/MM/YYYY")
                      }}</span>
                <span v-if="document.type == 'Ad'" style="font-size: 14px"> - Date effectif le {{
                        document.effectiveDate | moment("DD/MM/YYYY")
                      }}</span>
              </template>
              
              <template v-if="document.sbLink.length > 0">
                <br />Lié au 
                  <v-chip
                    outlined
                    disable
                    small
                    v-for="sb in document.sbLink"
                    :key="sb.id"
                    >{{ sb.number }}</v-chip
                  >
              </template>
              <br />
              <template v-if="document.type !== 'Manual' && document.replace">
                <span>remplace
                  <v-btn
                        outlined
                        x-small
                        rounded
                        :to="{
                          name: 'document',
                          params: { id: document.replace.id },
                        }"
                        >{{ document.replace.number}}
                        {{ document.replace.revision }}</v-btn
                      >
                    </span>
              </template>
              <br />
              <template v-if="document.type == 'Manual' && document.replace">
                <span>remplace
                  <v-btn
                        outlined
                        x-small
                        rounded
                        :to="{
                          name: 'document',
                          params: { id: document.replace.id },
                        }"
                        >{{ document.replace.number }}
                        {{ document.replace.revision }}</v-btn
                      >
                    </span>
              </template>
              <template v-if="document.additionalInformation">
                <span>Note: {{ document.additionalInformation }}</span>
              </template>
            </v-card-subtitle>
            <v-card-subtitle>
                <template v-if="document.targetsModels.length > 0">
                  concerne :
                    <v-chip
                      outlined
                      disable
                      v-for="model in document.targetsModels"
                      :key="model.id"
                      >{{ model.tcHolder.name }} -
                      {{ model.reference }}</v-chip
                    >
                </template>
                <template v-if="document.targets['all'] == true">
                  <v-chip outlined disabled>Tous les Modèles</v-chip>
                </template>
                <template
                  v-if="
                    document.targets['all_model_for_tc_holder'] ==
                      true && document.targets['all'] == false
                  "
                >
                  <v-chip
                    >Tous Modèles du TCHolder
                    <span
                      v-for="tcHolder in document.tcHolders"
                      :key="tcHolder.id"
                      >&nbsp;{{ tcHolder.name }}</span
                    ></v-chip
                  >
                </template>
            </v-card-subtitle>
            <v-card-actions
              style="padding-left: 20px; padding-bottom: 20px;"
            >
              <v-btn
                align="center"
                v-if="document.documentFile"
                color="white"
                class="red--text"
                outlined
                :href="document.documentFile"
                target="_blank"
              >
                Consulter
                <v-icon right color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn
                align="center"
                v-else
                color="white"
                class="primary--text"
                outlined
                disabled
              >
                Non Disponible
                <v-icon right class="primary--text"
                  >mdi-file-pdf-box</v-icon
                >
              </v-btn>
              <v-spacer></v-spacer>
                <v-btn icon @click="deleteDocument(document)">
                  <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-card-actions>
          </v-sheet>
        </v-col>
      </v-row>
      <confirm ref="confirm"></confirm>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-text class="pt-4">
          <div>
            <p class="text-h5 primary--text">ANNULATION</p>

            <v-row>
              <v-col cols="8" offset="2" class="mt-4">
                <v-text-field v-model="cancelReason" label="raison"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDialog()"> Fermer </v-btn>
          <v-btn color="primary" text :loading="saveLoading" @click="cancelDocument(document)">
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.v-chip {
  margin: 4px 8px 4px 0;
}
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { documentQuery } from "../../graphql/document/document";
import { deleteDocumentMutation } from "@/graphql/document/delete_document";
import { cancelDocumentMutation } from "@/graphql/document/cancel_document";

import confirm from "@/components/confirm.vue";

export default {
  name: "Document",
  components: {
    confirm,
  },
  data() {
    return {
      document: null,
      dialog: false,
      cancelReason: null,
      saveLoading: false,

    };
  },
  apollo: {
    document: {
      query: documentQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  computed: {
    color: function () {
       if (this.document.status == "replace") {
        return "background-color: #FCE8EA; border: solid 1px red";
        }
        if (this.document.status == "cancel") {
          return "background-color: #FCE8EA; border: solid 1px red";
        }
       return "";
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    deleteDocument(document) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteDocumentMutation,
                variables: {
                  input: {
                    id: parseInt(document.id, 10),
                  },
                },
              })
              .then((data) => {
                console.log(data);
                this.$apollo.queries.documents.refetch();
              });
          }
        });
    },
    cancelDocument(document) {
      this.saveLoading = true;

      this.$apollo
        .mutate({
          mutation: cancelDocumentMutation,
          variables: {
            input: {
              id: parseInt(document.id, 10),
              cancelReason: this.cancelReason,
            },
          },
        })
        .then(() => {
          this.cancelReason = null
          this.dialog = false
          this.saveLoading = false;

        });
    },
  },
};
</script>
