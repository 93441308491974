import gql from 'graphql-tag';

export const bookletsQuery = gql`
      {
        booklets {
          id
          aircraft {
            id
            immatriculation
            contact {
              id
              name
            }
          }
          aircraftPart {
            id
            partModel {
              id
              reference
              type
            }
            serialNumber
          }
          volume
          status
          location
          bookletUpdatedAt
      }
    }
    `
