import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '@/assets/styles/main.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
// import '@/styles/overrides.sass';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

// $data-table-expanded-content-box-shadow:  none;

const vuetify = new Vuetify()

Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'mdi'
})                   

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        background: '#f4f5fa',
        titre: '#388087', //#5c5d8d
        btn: '#b9e0e2',
        link : '#1e9ca5',
        primary: '#1e9ca5', //bleu 
        accent: '#ca054d',//fushia
        error: '#F44336',//rouge
        info: '#9013FE',
        success: '#1e9ca5',//primary
        warning: '#FFC260',
        anchor: '#536dfe',
        cardcolor: '#ffffff' 
      },
    },


  },
});

// ,
// app barre beige color="#f4f0ec"
// tab background-color="#f4f0ec" color="primary"
// bandeau beige #f4f0ec, turquoise #2ebdc8
// dorée #AC9067
