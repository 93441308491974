import gql from 'graphql-tag';
import { workOrderFragment } from './fragment_work_order';

export const closeWorkOrderMutation = gql`
  mutation closeWorkOrder($input: CloseWorkOrderInput!) {
    closeWorkOrder(input: $input) {
      workOrder {
        ...workOrder
      }
    }
}
${workOrderFragment.fragments.workOrder}`