import gql from 'graphql-tag';

export const deleteWorkshopToolMutation = gql`
  mutation deleteWorkshopTool($input: DeleteWorkshopToolInput!) {
    deleteWorkshopTool(input: $input) {
      workshopTool {
        id
        serialNumber     
        expiredAt
        workshopToolModel {
          id
          name
          periodicity
        }       
        updatedAt
        createdAt
      }
    }
}`
