<template>
  <v-data-table :items="aircraftsWithoutContract" :headers="headers" no-results-text="Aucun résultat"> </v-data-table>
</template>
<script>
import { aircraftsWithoutContractQuery } from "../../graphql/aircraft/aircrafts_without_contract";

export default {
  name: "AvionSansContrat",
  data() {
    return {
      aircraftsWithoutContract: [],
      headers: [{ text: "Immatriculation", value: "immatriculation" }],
    };
  },
  apollo: {
    aircraftsWithoutContract: {
      query: aircraftsWithoutContractQuery,
    },
  },
};
</script>