<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Modifier | catégorie</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="6" offset="3">
                <!-- anti doublon -->
                <v-text-field
                  dense
                  outlined
                  label="Nom de la catégorie"
                  v-model="name"
                  :rules="[rules.required]"
                  counter="250"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"  align="center" class="mt-8">
                <sbuttonCancel to="/admin?tab=categorie"
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark
                  @click="updateCategory"
                  >Modifier</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
     </v-col>
  </v-container>
</template>

<script>
import { categoryQuery } from "@/graphql/category";
import { updateCategoryMutation } from "@/graphql/update_category";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
////import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateCategory",
  components : {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      category: null,
      name: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  apollo: {
    category: {
      query: categoryQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        this.name = data.category.name;
      },
    },
  },
  methods: {
    updateCategory() {
      this.$apollo
        .mutate({
          mutation: updateCategoryMutation,
          variables: {
            input: {
              id: parseInt(this.category.id, 10),
              attributes: {
                nameFr: this.name,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"categorie" }});
        });
    },
  },
}; 
</script>