<template>
  <v-col cols="10" offset="1">
    <v-sheet outlined>
      <v-card-title>
        <v-row>
          <v-col cols="3">
            <v-row justify="center">
              <v-dialog
                v-model="dialog"
                persistent
                hide-overlay
                max-width="600"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="primary--text"
                    dark
                    outlined
                    v-on="on"
                    @click="dialog = !dialog"
                  >
                    Assigner un gestionnaire
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline"
                    >Ajout du gestionnaire</v-card-title
                  >
                  <v-card-text class="pt-4">
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                            outlined
                            dense
                            label="Choisir une immatriculation"
                            v-model="aircraftChoice"
                            :items="
                              aircrafts.map(function (t) {
                                return {
                                  text: t.immatriculation,
                                  value: t.id,
                                };
                              }).sort((a, b) => a.text.localeCompare(b.text))
                            "
                            :error-messages="immatriculationGestionErrors"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            outlined
                            dense
                            label="gestionnaire"
                            v-model="pen"
                            :items="
                              users.map(function (u) {
                                return { text: u.name, value: u.id };
                              }).sort((a, b) => a.text.localeCompare(b.text))
                            "
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-4 primary--text"
                      outlined
                      depressed
                      @click="cleanDialog()"
                    >
                      Fermer</v-btn
                    >
                    <v-btn class="mr-4 primary" dark @click="addPEN()"
                      >Enregistrer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3" offset="3">
            <v-select
              label="choisir un gestionnaire"
              :items="gestOptions"
              v-model="gestFilter"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="aircrafts"
        :search="search"
        :loading="isLoading"
        disable-pagination
        fixed-header
        hide-default-footer
        height="600px"
        loading-text="Voyons voir ce que je trouve..."
        dense
        locale="fr-FR"
        no-results-text="Aucun résultat"
      >

      <template v-slot:item.modele="{ item }"
        >{{ item.cell.partModel.tcHolder.name }} <b>{{ item.cell.partModel.reference }}</b>
      </template>

      </v-data-table>
    </v-sheet>
  </v-col>
</template>
<script>
import { aircraftsQuery } from "../../graphql/aircraft/aircrafts";
import { usersQuery } from "@/graphql/user/users";
import { updateAircraftMutation } from "../../graphql/aircraft/update_aircraft";

export default {
  name: "gestionnaire",
  data() {
    return {
      search: "",
      aircrafts: [],
      dialog: false,
      modal: false,
      aircraftChoice: null,
      pen: null,
      users: [],
      headers: [
        { text: "GESTIONNAIRE", value: "supervisor.name", width: "150px" },
        {
          text: "IMMAT",
          align: "center",
          value: "immatriculation",
          width: "100px",
        },
        { text: "CELLULE", value: "modele" },
        { text: "CLIENT", value: "contact.name" },        
      ],
      isLoading: true,
      gestFilter: "all",
      gestOptions: [
        { text: "Tous", value: "all" },
        { text: "Sans gestionnaire", value: "null" },
        { text: "Terence", value: "2" },
        { text: "jérôme", value: "4" },
        { text: "Léa", value: "11" },
        { text: "Thibaut", value: "16" },
        { text: "JB", value: "3" },
      ],
    };
  },
  computed: {
    // checkboxErrors () {
    //   const errors = []
    //   if (!this.$v.checkbox.$dirty) return errors
    //   !this.$v.checkbox.checked && errors.push('You must agree to continue!')
    //   return errors
    // },
    // selectErrors () {
    //   const errors = []
    //   if (!this.$v.select.$dirty) return errors
    //   !this.$v.select.required && errors.push('Item is required')
    //   return errors
    // },
    immatriculationGestionErrors() {
      const errors = [];
      if (this.gestion) {
        if (!this.$v.immatriculation_gestion.$dirty) return errors;
        !this.$v.immatriculation_gestion.required &&
          errors.push("Vous devez choisir une immatriculation.");
      }
      return errors;
    },
  },
  methods: {
    cleanDialog() {
      this.aircraftChoice = null;
      this.pen = null;
      this.dialog = false;
    },
    addPEN() {
      this.$apollo
        .mutate({
          mutation: updateAircraftMutation,
          variables: {
            input: {
              id: parseInt(this.aircraftChoice, 10),
              attributes: {
                supervisorId: this.pen,
              },
            },
          },
        })
        .then(() => {
          this.cleanDialog();
        });
    },
  },

  apollo: {
    aircrafts: {
      query: aircraftsQuery,
      variables() {
        return {
          status: "active",
        };
      },
      result() {
        this.isLoading = false;
      },
    },
    users: {
      query: usersQuery,
      variables: {
        supervisor: true,
      },
    },
  },
};
</script>