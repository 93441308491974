<template>
        <v-dialog v-model="dialog" max-width="600">
          <template v-slot:activator="{}">
            <v-btn v-if="user" @click="dialog = true" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-else
              class="primary--text"
              outlined
              depressed
              @click="dialog = true"
              ><v-icon left dark> mdi-plus </v-icon>Nouvel utilisateur</v-btn
            >
          </template>
          <v-card>
            <v-card-title>Utilisateur</v-card-title>
            <v-card-text class="pt-4">
              <v-container>
                <v-row v-if="!this.contact_id">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedContact"
                      label="Client"
                      :items="contacts.map((c) => {
                        return {text: c.name, value: c.id}
                      }).sort((a, b) => a.text.localeCompare(b.text))
                      "
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :rules="[rules.required]"
                      hint="* obligatoire"
                      v-model="firstName"
                      label="Prénom *"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field :rules="[rules.required]" v-model="lastName" hint="* obligatoire" label="Nom *"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="phone"
                      label="Téléphone"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="email" label="Email"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="role"
                      label="Fonction"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="contact && contact.isWorkshop == true">
                  <v-col cols="12">
                    <v-radio-group dense v-model="mechanic" row>
                      <div class="mr-4">L'utilisateur est t'il un mécano :</div>
                      <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                      ><v-radio color="#1871ce" :value="false" label="Non"></v-radio
                    ></v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="contact && contact.isWorkshop == true && mechanic == true">
                  <v-col cols="12">
                    <v-text-field
                      v-model="trigram"
                      label="Trigramme"                      
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="contact && contact.isWorkshop == true && mechanic == true">
                  <v-col cols="12">
                    <v-text-field
                      v-model="mechanic_number"
                      label="Numéro de licence mécanicien"                      
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" align="center">
                    <v-btn small color="primary" @click="cancel()">
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col cols="6" align="center">
                    <v-btn small color="primary" @click="save()">
                      Sauvegarder
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>      
</template>

<script>
import { updateUserMutation } from "../../graphql/user/update_user";
import { createUserMutation } from "../../graphql/user/create_user";
import { contactsQuery } from "../../graphql/contact/contacts";
import { contactQuery } from "../../graphql/contact/contact";

export default {
  name: "NewUser",
  props: {
    contact_id: {
      required: false,
    },
    user: {
      required: false,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      firstName: null,
      lastName: null,
      role: null,
      phone: null,
      email: null,
      selectedContact: null,
      contacts: [],
      mechanic: false,
      trigram: null,
      mechanic_number: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted: function () {
    if (this.user) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.phone = this.user.phone;
      this.role = this.user.title;
      this.mechanic = this.user.mechanic;
      this.trigram = this.user.trigram;
      this.mechanic_number = this.user.mechanicNumber;
      if (this.user.contact) {
        this.selectedContact = this.user.contact.id
      }
    }
  },
  computed: {
    contactId() {
      if (this.contact_id) {
        return this.contact_id
      } else {
        return this.selectedContact
      }
    }
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.phone = null;
      this.role = null;
      this.selectedContact = null;
      this.mechanic = false;
      this.trigram = null;
      this.mechanic_number = null;
    },
    save() {
      if (this.user) {
        this.$apollo
          .mutate({
            mutation: updateUserMutation,
            variables: {
              input: {
                id: this.user.id,
                attributes: {
                  contactId: parseInt(this.contactId, 10),
                  firstName: this.firstName,
                  lastName: this.lastName,
                  email: this.email,
                  title: this.role,
                  phone: this.phone,
                  mechanic: this.mechanic,
                  trigram: this.trigram,
                  mechanicNumber: this.mechanic_number,
                },
              },
            },
          })
          .then(() => {
            this.$emit("refreshPage");
            this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createUserMutation,
            variables: {
              input: {
                attributes: {
                  contactId: parseInt(this.contactId, 10),
                  firstName: this.firstName,
                  lastName: this.lastName,
                  email: this.email,
                  title: this.role,
                  phone: this.phone,
                  mechanic: this.mechanic,
                  trigram: this.trigram,
                  mechanicNumber: this.mechanic_number,
                },
              },
            },
          })
          .then(() => {
            this.$emit("refreshPage");
            this.cleanDialog();
          });
      }
    },
    cancel() {
      this.cleanDialog();
      this.dialog = false;
    },    
  },
  apollo: {
    contacts: {
      query: contactsQuery
    },
    contact: {
      query: contactQuery,
      variables() {
        return {
          id: this.contact_id,          
        };
      },
      skip() {
        return !this.contact_id;
      },
    }
  }
};
</script>

