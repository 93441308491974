<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouvelle | action</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="6" offset="3">
                <!-- anti doublon -->
                <v-text-field
                  dense
                  outlined
                  label="Action de la butée (Fr)"
                  v-model="actionFr"
                  :rules="[rules.required]"
                  counter="250"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" offset="3">
                <!-- anti doublon -->
                <v-text-field
                  dense
                  outlined
                  label="Action de la butée (En)"
                  v-model="actionEn"
                  :rules="[rules.required]"
                  counter="250"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  to="/admin?tab=action"
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark @click="updateAction()"
                  >Créer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { updateActionMutation } from "@/graphql/action/update_action";
import { actionQuery } from "@/graphql/action/action";
//import sbuttonSave from "../../../components/v-btn.vue"
import sbuttonCancel from "../../../components/sbuttonCancel.vue"

export default {
  name: "updateAction",
  components: {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      actionFr: null,
      actionEn: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    updateAction() {
      this.$apollo
        .mutate({
          mutation: updateActionMutation,
          variables: {
            input: {
              id: parseInt(this.action.id, 10),
              attributes: {
                nameFr: this.actionFr,
                nameEn: this.actionEn,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "action" } });
        });
    },
  },
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (data) {
          console.log(data);
          this.actionFr = data.action.nameFr;
          this.actionEn = data.action.nameEn;
        }
      }
    },
  },
};
</script>