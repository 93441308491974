import gql from 'graphql-tag';

export const cenFragment = {
    fragments: {
      cen: gql`
      fragment cen on Cen {
        user {
            id
            name
            firstName
            lastName
            titleName
        }
        id
        reference
        referenceCen
        immatriculation
        aircraft {
          id
          immatriculation
          parkingType
          contact {
            id
            name
          }
          aircraftCustomizations {
            id
          }
          aircraftMb303s {
            id
          }
          aircraftIncidents {
            id
          }
          aircraftDeviations {
            id
          }
        }
        action
        cell {
          id
          reference
          numberMotor
          tcHolder {
            id
            name
          }
        }
        cellManufacturer {
          id
          name
        }
        motor1 {
          id
          reference
          tcHolder {
            id
            name
          }
        }
        motor2 {
          id
          reference
          tcHolder {
            id
            name
          }
        }
        propeller1 {
          id
          reference
          tcHolder {
            id
            name
          }
        }
        propeller2 {
          id
          reference
          tcHolder {
            id
            name
          }
        }
        cellTotalHours
        cellTotalHoursExt1
        cellTotalHoursExt2
        cellSerialNumber
        motor1SerialNumber
        motor1TotalHours
        motor2SerialNumber
        motor2TotalHours
        propeller1SerialNumber
        propeller1TotalHours
        propeller2SerialNumber
        propeller2TotalHours
        closedAt
        createdAt
        updatedAt
        deadlineAt
        lastCenExpiredAt
        cenDate
        place
        contact
        notes
        noFly
        client {
          id
          name
        }
        reportAttachment {
          id
          name
          fileUrl
          createdAt
        }
        signedReportAttachment {
          id
          name
          fileUrl
          createdAt
        }       
        advice
        certificateAttachment {
          id
          name
          fileUrl
          createdAt
        }
        finalCertificate
        deliverySlipAttachment {
          id
          name
          fileUrl
          createdAt
        }
        signedCertificateAttachment {
          id
          name
          fileUrl
          createdAt
        }
        etiquetteAttachment {
          id
          name
          fileUrl
          createdAt
        }
        lastCenDate
        workshop {
          id
          name
        }
        parkingType
        contractType
        exploitationType
        deviation
        modifications
        mb303
        incidentReports
        deliveryDate
        ownerChange
        operatorChange
        mecanicChange
        navOperatorChange
        noiseLimit
        expiredAt
        cenDocuments {
          id
          name
          url
        }
        extensionFormAttachment {
          id
          name
          fileUrl
          createdAt
        }
      }`
    }
}