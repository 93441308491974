<template>
  <v-row class="mb-8 mt-8">
    <v-col cols="12">
      <v-row>
        <v-dialog v-model="dialog_upload" max-width="600">
          <template v-slot:activator="{ on }">
            <v-btn  icon v-on="on" @click="dialog_upload = !dialog_upload">
              <v-icon >mdi-plus-circle-outline</v-icon>
            </v-btn>
            <div class="mt-2">
              Ajouter des tâches de maintenance
            </div>
          </template>
          <v-card>
            <v-card-title>Ajouter des élements à :</v-card-title>
            <v-card-subtitle>Maintenance alternatives aux exigences du DAH</v-card-subtitle>
            <v-card-text class="pt-4">
              <v-container>
                <v-row>    
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="description"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Description de la tâche"
                    />
                  </v-col>
                  <v-col cols="12">
                      <tiptap-vuetify
                      v-model="task"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Tâche ou inspection recommandé"
                    />
                  </v-col>
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="interval"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Intervalle recommandé"
                    />
                  </v-col>
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="task_alt"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Tâche ou inspection alternative"
                    />
                  </v-col>
                  <v-col cols="12" align="center">
                    <tiptap-vuetify
                      v-model="interval_alt"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Intervalle alternatif"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="itemId" cols="12" align="center">
                    <v-btn small color="primary" @click="updateData(itemId)">
                      Modifier
                    </v-btn>
                  </v-col>
                  <v-col v-else cols="12" align="center">
                    <v-btn small color="primary" @click="addData()">
                      Ajouter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-simple-table>
          <thead>
            <tr>
              <th>Pièce concerné</th>
              <th>Tâche ou inspection</th>
              <th>Intervalle recommandé</th>
              <th>Tâche ou inspection alternative</th>
              <th>Intervalle alternatif</th>
              <th style="width: 110px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in maintenanceProgrammeItems" :key="d.id">
              <td v-html="d.description"></td>
              <td v-html="d.task"></td>
              <td v-html="d.interval"></td>
              <td v-html="d.taskAlt"></td>
              <td v-html="d.intervalAlt"></td>
              <td>
                <v-btn icon class="mb-4" @click="editData(d.id)">
                  <v-icon color="primary" >mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mb-4" icon @click="removeData(d)">
                  <v-icon color="red" >mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>

  </v-row>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import { maintenanceProgrammeItemsQuery } from "../../graphql/maintenance_programme/maintenance_programme_items";
import { createMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/create_maintenance_programme_item";
import { updateMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/update_maintenance_programme_item";
import { deleteMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/delete_maintenance_programme_item";

export default {
  name: "Dah",
  props: ["programmeId"],
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      maintenanceProgrammeItems: [],
      description: null,
      task: null,
      task_alt: null,
      interval: null,
      interval_alt: null,
      dialog_upload: false,
      itemId: null,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    }
  },
  methods: {
    addData() {
      if (this.description) {
        this.$apollo
          .mutate({
            mutation: createMaintenanceProgrammeItemMutation,
            variables: {
              input: {
                attributes: {
                  itemType: 'dah',
                  maintenanceProgrammeId: this.programmeId,
                  interval: this.interval,
                  intervalAlt: this.interval_alt,
                  description: this.description,
                  task: this.task,
                  taskAlt: this.task_alt,
                },
              },
            },
          })
          .then(() => {
            this.description = null;
            this.task_alt = null;
            this.task = null;
            this.interval = null;
            this.interval_alt = null;
            this.dialog_upload = false;
            this.$apollo.queries.maintenanceProgrammeItems.refetch();
          });
      }
    },
    editData(id) {
      const item = this.maintenanceProgrammeItems.find((mpi) => mpi.id == id);
      this.itemId = item.id;
      this.description = item.description;
      this.task = item.task;
      this.task_alt = item.taskAlt;
      this.interval = item.interval;
      this.interval_alt = item.intervalAlt;
      this.dialog_upload = true;
    },
    updateData() {
      if (this.description) {
        this.$apollo
          .mutate({
            mutation: updateMaintenanceProgrammeItemMutation,
            variables: {
              input: {
                id: parseInt(this.itemId, 10),
                attributes: {
                  itemType: 'dah',
                  maintenanceProgrammeId: this.programmeId,
                  interval: this.interval,
                  intervalAlt: this.interval_alt,
                  description: this.description,
                  task: this.task,
                  taskAlt: this.task_alt,
                },
              },
            },
          })
          .then(() => {
            this.description = null;
            this.task = null;
            this.task_alt = null;
            this.interval = null;
            this.interval_alt = null;
            this.itemId = null;
            this.dialog_upload = false;
            this.$apollo.queries.maintenanceProgrammeItems.refetch();
          });
      }
    },
    removeData(item) {
      this.$apollo
        .mutate({
          mutation: deleteMaintenanceProgrammeItemMutation,
          variables: {
            input: {
              id: parseInt(item.id, 10),
            },
          },
        })
        .then(() => {
          this.$apollo.queries.maintenanceProgrammeItems.refetch();
        });
    },
  },
  apollo: {
    maintenanceProgrammeItems: {
      query: maintenanceProgrammeItemsQuery,
      variables() {
        return {
          itemType: "dah",
          maintenanceProgrammeId: parseInt(this.programmeId, 10),
        };
      },
      skip() {
        return !this.programmeId;
      }
    },
  },
}
</script>

