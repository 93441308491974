<template>
  <v-container fluid class="background">
  <v-col cols="10" offset="1">
    <v-sheet outlined>
      <v-card-title>
        <sbuttonAdd
          class="primary--text"
          dark
          outlined
          :to="{ name: 'modelepart_new' }"
          ><v-icon left dark> mdi-plus </v-icon>Nouvelle pièce</sbuttonAdd
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :items="partModels"
        :headers="partModelsHeader"
        :sort-by="['tcHolder.name', 'designation']"
        :footer-props="{
          'items-per-page-options': [13, 50, 100],
          'items-per-page-text': 'lignes par page',
          'items-per-page-all-text': 'Tous',
        }"
        :items-per-page="13"
        :search="search"
        :loading="isLoading"
        loading-text="Chargement... merci de patienter"
        multi-sort
        dense
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.type="{ item }">
          <!-- convert server side -->
          <span v-if="item.type == 'Cell'">Cellule</span>
          <span v-if="item.type == 'Motor'">Moteur</span>
          <span v-if="item.type == 'Propeller'">Hélice</span>
          <span v-if="item.type == 'Part'">Pièce</span>
        </template>
        <!-- Modifier pour cette page -->
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="{ name: 'modelepart_edit', params: { id: item.id } }"
              >
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="duplicateModel(item)"
              >
                <v-icon v-on="on">mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Dupliquer</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="deleteModel(item)">
                <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div v-if="$apollo.loading" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue-grey"
          indeterminate
        ></v-progress-circular>
      </div>
      <confirm ref="confirm"></confirm>
    </v-sheet>
  </v-col>
  </v-container>
</template>

<script>
import { partModelsQuery } from "../../../graphql/part_model/part_models";
import { deletePartModelMutation } from "@/graphql/part_model/delete_part_model";
import { duplicatePartModelMutation } from "../../../graphql/part_model/duplicate_part_model";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from '../../../components/sbuttonAdd.vue';

export default {
  name: "dataTableModele",
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      partModels: [],
      partModelsHeader: [
        { text: "ATA", value: "ata.chapter" },
        { text: "TC HOLDER", value: "tcHolder.name" },
        { text: "CATEGORIE", value: "category.name" },
        { text: "DESIGNATION", value: "designation" },
        { text: "REFERENCE", value: "reference" },
        {
          text: " ",
          value: "id",
          width: "150px",
          sortable: false,
          filterable: false,
        },
      ],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    partModels: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Part", "Equipment"],
        tcHolderId: null,
      },
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    duplicateModel(model) {
      this.$apollo
        .mutate({
          mutation: duplicatePartModelMutation,
          variables: {
            input: {
              id: parseInt(model.id, 10),
            },
          },
        })
        .then((data) => {
          console.log(data)
          this.$router.push({
            name: "modelepart_edit",
            params: { id: data.data.duplicatePartModel.partModel.id },
          });
        });
    },
    deleteModel(model) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deletePartModelMutation,
                variables: {
                  input: {
                    id: parseInt(model.id, 10),
                  },
                },
                update: (store, { data: { deletePartModel } }) => {
                  const data = store.readQuery({
                    query: partModelsQuery,
                    variables: {
                      serie: false,
                      type: ["Part", "Equipment"],
                      tcHolderId: null,
                    },
                  });
                  const newData = {
                    partModels: data.partModels.filter(
                      (b) => b.id !== deletePartModel.partModel.id
                    ),
                  };
                  store.writeQuery({
                    query: partModelsQuery,
                    variables: {
                      serie: false,
                      type: ["Part", "Equipment"],
                      tcHolderId: null,
                    },
                    data: newData,
                  });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
};
</script>
