import gql from 'graphql-tag';

export const deleteCategoryMutation = gql`
  mutation deleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      category{
        id
        name
        updatedAt
        createdAt
      }
    }
}`
