import gql from 'graphql-tag';

export const aircraftCustomizationsQuery = gql`query aircraftCustomizations($aircraftId: ID!) {
  aircraftCustomizations(aircraftId: $aircraftId) {
    id
    number
    customizationDate
    description
    document
    documentSignedId
    aircraft {
      id
      immatriculation
    }
  }
}`