<template>
  <v-container fluid class="background">
    <v-col cols="6" offset="3">
      <v-sheet outlined>
        <v-card-title>
          <sbuttonAdd
            :to="{ name: 'new_workshop_tool_model' }"
            ><v-icon left dark> mdi-plus </v-icon>Nouveau Modèle d'outil</sbuttonAdd
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :items="workshopToolModels"
          :headers="header"
          :footer-props="{
            'items-per-page-options': [13, 50, 100],
            'items-per-page-text': 'lignes par page',
            'items-per-page-all-text': 'Tous',
          }"
          :items-per-page="13"
          :sort-by="['name']"
          :search="search"
          :loading="isLoading"
          loading-text="Chargement... merci de patienter"
          dense
          no-results-text="Aucun résultat"
        >
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  :to="{ name: 'edit_workshop_tool_model', params: { id: item.id } }"
                >
                  <v-icon v-on="on">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="deleteItem(item)">
                  <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div v-if="$apollo.loading" class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue-grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <confirm ref="confirm"></confirm>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { workshopToolModelsQuery } from "../../../graphql/workshop_tool/workshop_tool_models";
import { deleteWorkshopToolModelMutation } from "../../../graphql/workshop_tool/delete_workshop_tool_model"
import confirm from "@/components/confirm.vue";
import sbuttonAdd from "../../../components/sbuttonAdd.vue"

export default {
  name: "dataTableWorkshopToolModel",
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      workshopToolModels: [],
      header: [
        { text: "Marque / Référence", value: "name" },
        { text: " ", value: "id", width: "150px", filterable: false },
      ],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    workshopToolModels: {
      query: workshopToolModelsQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    deleteItem(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteWorkshopToolModelMutation,
                variables: {
                  input: {
                    id: parseInt(item.id, 10),
                  },
                },                
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
};
</script>