import gql from 'graphql-tag';
import { maintenanceProgrammeItemFragment } from './fragment_maintenance_programme_item';

export const updateMaintenanceProgrammeItemMutation = gql`
  mutation updateMaintenanceProgrammeItem($input: UpdateMaintenanceProgrammeItemInput!) {
    updateMaintenanceProgrammeItem(input: $input) {
      maintenanceProgrammeItem {
        ...maintenanceProgrammeItem
      }
    }
} ${maintenanceProgrammeItemFragment.fragments.maintenanceProgrammeItem}`
