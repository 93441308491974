import gql from 'graphql-tag';

export const contactFragment = {
    fragments: {
      contact: gql`
      fragment contact on Contact {
        createdAt
        email
        id
        name
        reference
        status
        type
        phone
        mobile
        website
        agrementNumber
        easaNumber
        notes
        managerName
        managed
        firstName
        lastName
        companyName
        civility
        invoiceAddress
        invoiceAddress2
        invoiceCity
        invoiceZipcode
        invoiceCountry
        isWorkshop
        aircrafts {
          id
          immatriculation
          status
          cell {
            id
            serialNumber
            partModel {
              id
              reference
              tcHolder {
                id
                name
              }
            }
          }
        }
        updatedAt
        }`
    }
}