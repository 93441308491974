import gql from 'graphql-tag';

export const updateCenCheckMutation = gql`
  mutation updateCenCheck($input: UpdateCenCheckInput!) {
    updateCenCheck(input: $input) {
      cenCheck{
        id
        title
        description
        typeCheck
        order
        createdAt
        updatedAt
      }
    }
}`
