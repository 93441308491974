import gql from 'graphql-tag';

export const lastDocumentsQuery = gql`query
    {
      lastDocuments {
          id
          type
          number
          publishingDate
          revision
          tcHolders {
            id
            name
          }
          title
          updatedAt
          createdAt
       }
      }
    `
