import gql from 'graphql-tag';


export const createAircraftDeviationMutation = gql`
  mutation createAircraftDeviation($input: CreateAircraftDeviationInput!) {
    createAircraftDeviation(input: $input) {
      aircraftDeviation {
        id
        title
        deviationDate    
        aircraft {
          id
          immatriculation
        }
      }
    }
}`
