import gql from 'graphql-tag';
// import { cenExtensionFormFragment } from './fragment_cen_extension_form';


export const cenExtensionFormsQuery = gql`
query cenExtensionForms($cenId: ID!) {
  cenExtensionForms(cenId: $cenId) {  
    id
        checkType
        checkValue
        checkText
        createdAt
        updatedAt
  }
}` //${cenExtensionFormFragment.fragments.cenExtensionForm}`