import gql from 'graphql-tag';
import { cenCheckValueItemFragment } from './fragment_cen_check_value_item';


export const deleteCenCheckValueItemMutation = gql`
  mutation deleteCenCheckValueItem($input: DeleteCenCheckValueItemInput!) {
    deleteCenCheckValueItem(input: $input) {
      cenCheckValueItem {
        ...cenCheckValueItem      
      }
    }
} ${cenCheckValueItemFragment.fragments.cenCheckValueItem}`
