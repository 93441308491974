<template>
<v-container>
  <v-row>
    <v-col cols="12">
      <v-card-title color="">
        <v-icon color="primary" class="pr-4">mdi-file-cabinet</v-icon>
        <span class="primary--text">DOCUMENT TECHNIQUE</span>
      </v-card-title>
      <v-divider class="pb-4"></v-divider>

      <v-row dense>
        <v-col :cols="colsize" v-for="doc in documents" :key="doc.type">
          <v-card v-if="findDoc(doc.type)" outlined height="150px">
            <v-list-item two-line>
              <v-list-item-content style="height:95px"> 
                <v-list-item-title
                  class="text-h6 text-uppercase"
                  style="word-break: break-word"
                >
                  {{ doc.title }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="findDoc(doc.type)">
                  <span v-if="findDoc(doc.type).number"
                    ><strong>{{ findDoc(doc.type).number }}</strong></span>
                  <span v-if="findDoc(doc.type).dateDoc"
                    > du <strong>{{ findDoc(doc.type).dateDoc | moment("DD/MM/YYYY") }}</strong></span>
                  
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="findDoc(doc.type)">
                  <span v-if="findDoc(doc.type).expiredAt"
                    >expire le
                    {{ findDoc(doc.type).expiredAt | moment("DD/MM/YYYY") }}</span
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions align="end" class="mx-2 mb-4">
              <v-btn
                small
                left
                color="red"
                outlined
                class="white--text"
                v-if="findDoc(doc.type) && findDoc(doc.type).document"
                :href="findDoc(doc.type).document"
                target="_blank"
              >
                <v-icon dark left>mdi-file-pdf-box</v-icon>Document
              </v-btn>
              
              <v-btn
                small
                left
                color="grey"
                outlined
                class="white--text"
                v-else
                target="_blank"
              >
                <v-icon dark left>mdi-camera</v-icon>Photo
              </v-btn>
              <v-spacer></v-spacer>
              <ajout-doc
                :aircraft="aircraft"
                :aircraftDocument="findDoc(doc.type)"
                @updateAircraftDoc="refectchData()"
              ></ajout-doc>
              <v-btn icon>
                <v-icon color="red" @click="deleteDoc(findDoc(doc.type).id)">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card v-else outlined color="grey" height="150px">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="text-h6 text-uppercase"
                  style="word-break: break-word"
                >
                  {{ doc.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions class="mt-6">
              <div>
                <ajout-doc
                  :aircraft="aircraft"
                  :targetDoc="doc.type"
                  @updateAircraftDoc="refetchData()"
                ></ajout-doc>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <confirm ref="confirm"></confirm>
  </v-row>
</v-container>
</template>

<script>
import { aircraftDocumentsQuery } from "../../../graphql/aircraft/aircraft_documents";
import { deleteAircraftDocumentMutation } from "../../../graphql/aircraft/delete_aircraft_document";
import AjoutDoc from "./AjoutDoc.vue";
// import SelectManuel from "./SelectManuel.vue";
import confirm from "@/components/confirm.vue";

export default {
  props: {
    aircraft: {
      type: Object,
      required: true,
    },
  },
  components: {
    AjoutDoc,
    // SelectManuel,
    confirm,
  },
  data() {
    return {
      dialog: false,
      aircraftDocuments: [],
      header: [{ text: "NOM", value: "name" }],
      panelTitle: null,
      panelText: null,
      documents: [
        {
          title: "Certificat de navigabilité",
          type: "Cdn",
        },
        {
          title: "Certificat d'immatriculation",
          type: "Ci",
        },
        {
          title: "C.E.N",
          type: "Cen",
        },
        {
          title: "Programme entretien",
          type: "Pe",
        },
        {
          title: "Fiche de pesée",
          type: "Fp",
        },
        {
          title: "Licence LSA",
          type: "Lsa",
        },
        {
          title: "Certificat acoustique",
          type: "Ca",
        },
        {
          title: "Manuel de vol",
          type: "MdV",
        },
        {
          title: "Livret cellule",
          type: "Lc",
        },
        {
          title: "Livret moteur",
          type: "Lm",
        },
        {
          title: "Décharge de responsabilité",
          type: "Ddr",
        },
        {
          title: "Inventaire I.R.B",
          type: "Irb",
        },
        {
          title: "Attestation d'assurance",
          type: "Ass",
        },
        {
          title: "Licence Pilote-proprio",
          type: "Lpp",
        },
      ],
    };
  },
  computed: {
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 4;
        case "xl":
          return 2;
      }
      return "4";
    },
  },
  methods: {
    showPanel(type) {
      const doc = this.findDoc(type);
      if (doc) {
        this.panelTitle = doc.number;
        this.panelText = doc.dateDoc;
      } else {
        this.panelTitle = null;
        this.panelText = null;
      }
    },
    refetchData() {
      this.$apollo.queries.aircraftDocuments.refetch();
    },
    findDoc(type) {
      const doc = this.aircraftDocuments.find((d) => d.typeName == type);
      if (doc) {
        return doc;
      }
      return null;
    },
    deleteDoc(id) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAircraftDocumentMutation,
                variables: {
                  input: {
                    id: id,
                  },
                },
              })
              .then(() => {
                this.refetchData();
              });
          }
        });
    },
  },
  apollo: {
    aircraftDocuments: {
      query: aircraftDocumentsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.aircraft.id, 10),
        };
      },
    },
  },
};
</script>
