<template>
  <v-container fluid>
    <v-col cols="10" offset="1">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Modifier | item Cen</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="4" offset="2">
                <v-text-field
                  dense
                  outlined
                  label="Ordre"
                  v-model="ordre"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  dense
                  outlined
                  v-model="type"
                  label="Quel type celà concerne-t-il ?"
                  :items="typeItems"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-text-field
                  dense
                  outlined
                  label="Titre"
                  v-model="title"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" offset="1" >
                <tiptap-vuetify
                  v-model="description"
                  :extensions="extensions"
                  :card-props="{ outlined: true,  }"
                  :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                  placeholder="Indiquer le détail des items à vérifier"
                />   
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  class="mr-4 primary--text"
                  outlined
                  depressed
                  to="/admin?tab=cenVerifdoc"
                  v-if="type == doc"
                  >Annuler</sbuttonCancel
                >
                <sbuttonCancel
                  class="mr-4 primary--text"
                  outlined
                  depressed
                  to="/admin?tab=cenVerifmechanic"
                  v-else
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark
                  @click="updateCenChecks()"
                  >Enregistrer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<style>

</style>

<script>
import { updateCenCheckMutation } from "@/graphql/cen/update_cen_check";
import { cenCheckQuery } from "../../../graphql/cen/cen_check";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
//import sbuttonSave from '../../../components/v-btn.vue';

export default {
  name: "UpdateCenChecks",
  components: {
      TiptapVuetify,
      sbuttonCancel,
      //sbuttonSave,
    },
  data() {
    return {
      ordre: "",
      title: "",
      description: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      type: "",
      typeItems: [
        { text: "Revue documentaire", value: "doc" },
        { text: "Revue Physique", value: "mechanic" },
      ],
      extensions: [
          Bold,
          Italic,
          Underline,
          ListItem,
          BulletList,
          OrderedList,
          HardBreak,
        ],
    };
  },
  apollo: {
    cenCheck: {
      query: cenCheckQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        this.id = data.cenCheck.id;
        this.type = data.cenCheck.typeCheck;
        this.title = data.cenCheck.title;
        this.description = data.cenCheck.description;
        this.ordre = data.cenCheck.order;
      },
    },
  },
  methods: {
    updateCenChecks() {
      this.$apollo
        .mutate({
          mutation: updateCenCheckMutation,
          variables: {
            input: {
              id: parseInt(this.id, 10),
              attributes: {
                order: parseInt(this.ordre, 10),
                typeCheck: this.type,
                title: this.title,
                description: this.description,
              },
            },
          },
        })
        .then(() => {
          if (this.type == "doc") {
            this.$router.push({ name: "Admin", query: { tab: "cenVerifdoc" } });
          } else {
            this.$router.push({
              name: "Admin",
              query: { tab: "cenVerifmechanic" },
            });
          }
        });
    },
  },
};
</script>