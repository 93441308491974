import gql from 'graphql-tag';


export const updateAircraftIncidentMutation = gql`
  mutation updateAircraftIncident($input: UpdateAircraftIncidentInput!) {
    updateAircraftIncident(input: $input) {
      aircraftIncident {
        id
        number
        title
        description
        incidentDate
        documentAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aircraft {
          id
          immatriculation
        }      
      }
    }
}`
