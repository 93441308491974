<template>
  <v-container flex>
    <v-col cols="12">
      <v-card-title>
        <v-icon color="primary" class="pr-4">mdi-camera</v-icon>
        <span class="primary--text">PHOTOTHEQUE</span>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab x-small color="primary" dark v-on="on" v-bind="attrs"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Ajout des photos</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0 pt-8">
                    <v-text-field
                      requis
                      dense
                      outlined
                      placeholder="nom de la photo"
                      v-model="title"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0 pt-8">
                    <v-switch label="Photo de couverture" v-model="isCover">
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="!photoId">
                  <v-col cols="12" class="pb-0 pt-8">
                    <dashboard :uppy="uppy" />
                  </v-col>
                </v-row>
                <v-col cols="12" align="center" class="mt-8">
                  <v-btn
                    class="mr-4 btn--text"
                    outlined
                    depressed
                    @click="cleanDialog()"
                    >Annuler</v-btn
                  >
                  <v-btn v-if="photoId" class="mr-4 primary primary-text" dark @click="save()"
                    >Modifier</v-btn
                  >
                  <v-btn v-else class="mr-4 primary primary-text" dark @click="save()"
                    >Ajouter</v-btn
                  >
                </v-col>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-divider class="pb-4"></v-divider>

      <v-row dense>
        <v-col
          :cols="colsize"
          v-for="(photo, index) in aircraftPhotos"
          :key="photo.id"
          class="d-flex child-flex"
        >
          <v-card>
            <v-img
              :src="photo.photo"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              aspect-ratio="1"
              @click="
                visible = true;
                lboxIndex = index;
              "
            >
              <v-card-title>{{ photo.title }}</v-card-title>
            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="editPhoto(photo)"
                ><v-icon>mdi-file-edit</v-icon></v-btn
              >
              <v-btn icon @click="deletePhoto(photo)" color="red"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="aircraftPhotos.map((p) => p.photo)"
      :index="lboxIndex"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import { aircraftPhotosQuery } from "../../../graphql/aircraft/aircraft_photos";
import { createAircraftPhotoMutation } from "../../../graphql/aircraft/create_aircraft_photo";
import { deleteAircraftPhotoMutation } from "../../../graphql/aircraft/delete_aircraft_photo";
import { updateAircraftPhotoMutation } from "../../../graphql/aircraft/update_aircraft_photo";
import confirm from "@/components/confirm.vue";

import Uppy from "@uppy/core";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "Photo",
  props: ["aircraft"],
  components: {
    Dashboard,
    VueEasyLightbox,
    confirm,
  },
  data: () => ({
    dialog: false,
    title: null,
    photoFileId: null,
    isCover: false,
    lboxIndex: 0,
    visible: false,
    aircraftPhotos: [],
  }),
  computed: {
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 2;
      }
      return "4";
    },
    uppy: function (self) {
      const uppy = new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: true,
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.photoFileId = result.successful[0].response.signed_id;
        });
      return uppy;
    },
  },
  methods: {
    handleHide() {
      this.visible = false;
    },
    cleanDialog() {
      this.dialog = false;
      this.title = null;
      this.photoFileId = null;
      this.photoId = null;
      this.isCover = false;
      this.uppy.reset();
    },
    editPhoto(photo) {
      this.title = photo.title;
      this.photoFileId = photo.photoSignedId;
      this.photoId = photo.id;
      this.isCover = photo.isCover;
      this.dialog = true;
    },
    save() {
      if (this.photoId) {
        this.$apollo
          .mutate({
            mutation: updateAircraftPhotoMutation,
            variables: {
              input: {
                id: parseInt(this.photoId, 10),
                attributes: {
                  aircraftId: this.aircraft.id,
                  title: this.title,
                  isCover: this.isCover,
                },
              },
            },
          })
          .then(() => {
            this.cleanDialog();
            this.$apollo.queries.aircraftPhotos.refetch();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftPhotoMutation,
            variables: {
              input: {
                attributes: {
                  aircraftId: this.aircraft.id,
                  title: this.title,
                  photo: this.photoFileId,
                  isCover: this.isCover,
                },
              },
            },
          })
          .then(() => {
            this.cleanDialog();
            this.$apollo.queries.aircraftPhotos.refetch();
          });
      }
    },
    deletePhoto(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAircraftPhotoMutation,
                variables: {
                  input: {
                    id: parseInt(item.id, 10),
                  },
                },
              })
              .then(() => {
                this.cancel();
                this.$apollo.queries.aircraftPhotos.refetch();
              });
          }
        });
    },
  },
  apollo: {
    aircraftPhotos: {
      query: aircraftPhotosQuery,
      variables() {
        return {
          aircraftId: parseInt(this.aircraft.id, 10),
        };
      },
    },
  },
};
</script>
