import gql from 'graphql-tag';
import { maintenanceProgrammeFragment } from './fragment_maintenance_programme';

export const pdfMaintenanceProgrammeMutation = gql`
  mutation pdfMaintenanceProgramme($input: PdfMaintenanceProgrammeInput!) {
    pdfMaintenanceProgramme(input: $input) {
      maintenanceProgramme {
        ...maintenanceProgramme
      }
    }
  } ${maintenanceProgrammeFragment.fragments.maintenanceProgramme}`
