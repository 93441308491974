<template>

  <v-card outlined min-height="350px">
    <v-card-title class="primary--text text-uppercase justify-center">PROCHAINES VISITES
    </v-card-title>
			<v-data-table
        no-data-text="Aucune(s) visite(s) ce mois-ci"
        hide-default-footer
        dense
        class="mb-2"
        locale="fr-FR"
        :items-per-page="5"
        :items="nextVisite"
        :headers="headers"
                height="320px"

      >
			</v-data-table>   

  </v-card>

</template>

<script>
import { aircraftCountQuery } from "../../graphql/aircraft/aircraft_count";
import { contactCountQuery } from "../../graphql/contact/contact_count";

export default {
  name: "NombreImmat",
  data() {
    return {
      headers: [
        { text: "IMMATRICULATION", value: "" },
        { text: "VISITE", value: "" },
        { text: "POT. RESTANT", value: "" },
			],
     };
  },
  apollo: {
    aircraftCount: {
      query: aircraftCountQuery
    },
    contactCount: {
      query: contactCountQuery
    },
  }
};
</script>
