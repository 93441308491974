import gql from 'graphql-tag';

export const updateAtaMutation = gql`
  mutation updateAta($input: UpdateAtaInput!) {
    updateAta(input: $input) {
      ata{
        id
        name
        nameFr
        nameEn
        chapter
        number
        parent {
          id
        }
        updatedAt
        createdAt
      }
    }
}`
