<template>
  <v-container >
        <v-col cols="8" offset="2">
    <v-sheet outlined style="padding-bottom: 30px">
      <v-form>
      <v-card-title class="headline text-uppercase">
        <span class="primary--text">Modifier | ATA {{chapter}}.{{number}}</span>
      </v-card-title>
      <v-divider style="padding-bottom: 50px"></v-divider>
    
    
      <v-row>
        <v-col cols="2" offset="3">
          <v-text-field
            
            dense
            outlined
            v-model="number"
            label="N° ATA"
            :rules="[rules.required]"
            counter="2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" offset="3">
          <v-text-field
            dense
            outlined
            v-model="nameFr"
            label="Nom Français"
            counter="250"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" offset="3">
          <v-text-field
            dense
            outlined
            v-model="nameEn"
            counter="250"
            label="Nom Anglais"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center" class="mt-6">
          <sbuttonCancel
            to="/Admin?tab=ata"
            >Annuler</sbuttonCancel
          >
          <v-btn class="mr-4 primary primary-text" dark @click="updateAta()"
            >Enregistrer</v-btn>
        </v-col>
      </v-row>
    </v-form>
    </v-sheet>
        </v-col>
  </v-container>
</template>

<script>
import { ataQuery } from "@/graphql/ata/ata";
import { atasQuery } from "@/graphql/ata/atas";
import { updateAtaMutation } from "@/graphql/ata/update_ata";
//import sbuttonSave from "../../../components/v-btn.vue";
import sbuttonCancel from "../../../components/sbuttonCancel.vue";


export default {
  name: "UpdateAta",
  components: {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      number: null,
      atas: [],
      ata: null,
      nameFr: "",
      nameEn: "",
      parent: null,
      rules: {
          required: value => !!value || 'Required.',
      },
    };
  },
  apollo: {
    atas: {
      query: atasQuery,
    },
    ata: {
      query: ataQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
       result({ data }) {
         console.log(data);
        this.number = data.ata.number;
        this.nameFr = data.ata.nameFr;
        this.nameEn = data.ata.nameEn;
        if (data.ata.parent) {
          this.parent = data.ata.parent.id;
        }
      },
    },
  },
  methods: {
    updateAta() {
      this.$apollo
        .mutate({
          mutation: updateAtaMutation,
          variables: {
            input: {
              id: parseInt(this.ata.id, 10),
              attributes: {
                number: parseInt(this.number, 10),
                nameFr: this.nameFr,
                nameEn: this.nameEn,
                parentId: this.parent,
              },
            },
          },       
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"ata" } });
        });
    },
  },
};
</script>
