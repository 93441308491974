import gql from 'graphql-tag';
import { cenCheckValueItemFragment } from './fragment_cen_check_value_item';


export const updateCenCheckValueItemMutation = gql`
  mutation updateCenCheckValueItem($input: UpdateCenCheckValueItemInput!) {
    updateCenCheckValueItem(input: $input) {
      cenCheckValueItem {
        ...cenCheckValueItem      
      }
    }
} ${cenCheckValueItemFragment.fragments.cenCheckValueItem}`
