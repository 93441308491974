<template>
  <v-container fluid class="background">
  <v-col cols="8" offset="2">
    <v-sheet outlined>
      <v-card-title>
        <sbuttonAdd :to="{ name: 'butee_new' }"
          ><v-icon left dark> mdi-plus </v-icon>Nouvelle butée</sbuttonAdd
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :items="stops"
        :headers="header"
        :footer-props="{
          'items-per-page-options': [13, 50, 100, -1],
          'items-per-page-text': 'lignes par page',
          'items-per-page-all-text': 'Tous',
        }"
        :items-per-page="13"
        :search="search"
        :loading="isLoading"
        loading-text="Chargement... merci de patienter"
        dense
        width:400
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="{ name: 'butee_edit', params: { id: item.id } }">
                <v-icon v-on="on">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="deleteStop(item)">
                <v-icon v-on="on" color="red">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div v-if="$apollo.loading" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue-grey"
          indeterminate
        ></v-progress-circular>
      </div>
      <confirm ref="confirm"></confirm>
    </v-sheet>
  </v-col>
  </v-container>
</template>

<script>
import { stopsQuery } from "../../../graphql/stops";
import { deleteStopMutation } from "@/graphql/delete_stop";
import confirm from "@/components/confirm.vue";
import sbuttonAdd from '../../../components/sbuttonAdd.vue';

export default {
  name: "dataTableModeleButee",
  components: {
    confirm,
    sbuttonAdd,
  },
  data() {
    return {
      stops: [],
      header: [
        { text: "Butée", value: "nameFr", width: "400px" },
        { text: "Butée", value: "nameEn", width: "400px" },
        { text: " ", value: "id", width: "90px", filterable: false },
      ],
      search: "",
      isLoading: true,
    };
  },
  apollo: {
    stops: {
      query: stopsQuery,
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    deleteStop(stop) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteStopMutation,
                variables: {
                  input: {
                    id: parseInt(stop.id, 10),
                  },
                },
                update: (store, { data: { deleteStop } }) => {
                  const data = store.readQuery({ query: stopsQuery });
                  const newData = {
                    stops: data.stops.filter(
                      (b) => b.id !== deleteStop.stop.id
                    ),
                  };
                  store.writeQuery({ query: stopsQuery, data: newData });
                },
              })
              .then((data) => {
                console.log(data);
              });
          }
        });
    },
  },
};
</script>