<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs class="mt-8, mb-6, px-4, py-2  title--text text-uppercase" divider=">" :items="breadItems">
          </v-breadcrumbs>
        </v-card>
      </v-col>
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon dark>
              mdi-chevron-up
            </v-icon>
          </v-btn>

      <v-col>
        <data-table-veille />
      </v-col>

    </v-row>
  </v-container>
</template>

<style>

</style>
<script>
import dataTableVeille from "./dataTableVeille.vue";

export default {
  name: "SuiviVeille",
  components: {
    dataTableVeille,
  },
  metaInfo() {
    return {
      title: "Veille"
    }
  },
  data() {
    return {
      fab: false,
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Documentation',
          disabled: false,
        },
        {
          text: 'Veille',
          disabled: false,
        },
        {
          text: 'Suivi de veille',
          disabled: false,
        }
        ],
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>