import gql from 'graphql-tag';
import { aircraftDocumentFragment } from './fragment_aircraft_document';


export const deleteAircraftDocumentMutation = gql`
  mutation deleteAircraftDocument($input: DeleteAircraftDocumentInput!) {
    deleteAircraftDocument(input: $input) {
      aircraftDocument {
        ...aircraftDocument
      }
    }
}
${aircraftDocumentFragment.fragments.aircraftDocument}`
