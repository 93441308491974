<template>
  <v-container v-if="maintenanceProgramme">
    <v-col cols="12">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-card-title class="headline text-uppercase">
          <span class="primary--text">Réviser un programme</span>
        </v-card-title>
        <v-divider></v-divider>
      </v-sheet>
      <template>
        <v-card height="150px" class="mb-8 py-10">
          <v-row>
            <v-col cols="3" offset="2">
              <v-text-field
                outlined
                dense
                filled
                disabled
                v-model="maintenanceProgramme.reference"
                class="centered-input text-center"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                dense
                label="N° de révision"
                type="number"
                min="1"
                max="10"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Date de révision"
                    prepend-icon="mdi-calendar-month"
                    readonly
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :first-day-of-week="1"
                  locale="fr"
                  no-title
                  scrollable
                  @click="$refs.menu.save(date)"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"
                    >Annuler</v-btn
                  >
                  <v-btn text color="primary">Ok</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template>
        <v-card>
          <v-col cols="10" offset="1">
            <h4 class="mt-8">
              Lors de la réalisation de la revue annuelle, cochez le résultat de
              chaque vérification
            </h4>
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <div id="formPE">
                    Les résultats de la maintenance effectuée au cours de
                    l'année écoulée démontrent-ils que le programme de
                    maintenance actuel est toujours adapté ?
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-radio-group dense row mandatory> 
                    <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                    ><v-radio
                      color="#1871ce"
                      :value="false"
                      label="Non"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <div id="formPE">
                    Les résultats de l'examen de navigabilité effectué sur
                    l'aéronef démontrent-il que le programme actuel est toujours
                    adapté ?
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-radio-group dense row mandatory>
                    <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                    ><v-radio
                      color="#1871ce"
                      :value="false"
                      label="Non"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <div id="formPE">
                    Les révisions apportées aux documents affectant la base du
                    programme, telles que les données du programme d'inspection
                    minimale (MIP) ou du détenteur de la définition de type
                    (DAH) ont été prises en compte ) ?
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-radio-group dense row mandatory>
                    <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                    ><v-radio
                      color="#1871ce"
                      :value="false"
                      label="Non"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <div id="formPE" >
                    Les éventuels changements de configuration et de type et
                    spécificité d'exploitation ont-ils été pris en compte ?
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-radio-group dense row mandatory>
                    <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                    ><v-radio
                      color="#1871ce"
                      :value="false"
                      label="Non"
                    ></v-radio>
                    <v-radio color="#1871ce" label="S.O."></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <div id="formPE">
                    Les éventuels changements de la liste des
                    pilotes-propriétaires ont-ils été pris en compte ?
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-radio-group dense row mandatory>
                    <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                    ><v-radio
                      color="#1871ce"
                      :value="false"
                      label="Non"
                    ></v-radio>
                    <v-radio color="#1871ce" label="S.O."></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <div id="formPE">
                    Les exigences obligatoires permettant d'assurer la
                    conformité à la partie 21, telles que les consignes de
                    navigabilité (CN), les limitations de navigabilité, et les
                    exigences de maintenance spécifiques contenues dans la fiche
                    de données de certificat de type (TCDS) ont été prises en
                    compte ?
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-radio-group dense row mandatory>
                    <v-radio color="#1871ce" :value="true" label="Oui"></v-radio
                    ><v-radio
                      color="#1871ce"
                      :value="false"
                      label="Non"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11" align="center" class="mt-6">
                  <v-btn
                    class="mr-4 primary--text"
                    outlined
                    depressed
                    to="/pe"
                    >Annuler</v-btn
                  >
                  <v-btn class="mr-4 primary dark">Valider</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-card>
        
      </template>
    </v-col>
  </v-container>
</template>

<style scoped>
.v-label {
    font-size: 14px;
}

#formPE {
    display: table-cell;
    height: 75px;
    padding: 10px;
    vertical-align: middle;
}

/deep/ .centered-input input {
  text-align: center
}
</style>
<script>
import { maintenanceProgrammeQuery } from "../../graphql/maintenance_programme/maintenance_programme";

export default {
  name: "ReviewPE",
  components: {},
  data() {
    return {
      maintenanceProgramme: null,
      datet: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
    };
  },
  apollo: {
    maintenanceProgramme: {
      query: maintenanceProgrammeQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>

