import gql from 'graphql-tag';


export const createAircraftIncidentMutation = gql`
  mutation createAircraftIncident($input: CreateAircraftIncidentInput!) {
    createAircraftIncident(input: $input) {
      aircraftIncident {
        id
        number
        title
        description
        incidentDate
        documentAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aircraft {
          id
          immatriculation
        }       
      }
    }
}`
