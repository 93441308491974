import gql from 'graphql-tag';

export const actionFragment = {
    fragments: {
      action: gql`
      fragment action on Action {
        id
        name
        nameFr
        nameEn
      }` 
    }
}