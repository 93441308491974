var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('sbuttonAdd',{attrs:{"to":{ name: 'aeronef_new' }}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v("Nouvelle Immatriculation")],1),_c('v-spacer'),_c('v-row',[_c('v-col',{staticClass:"d-none d-sm-block",attrs:{"cols":"3","offset":"5"}},[_c('v-select',{attrs:{"label":"Statut","items":_vm.immatOptions},model:{value:(_vm.immatFilter),callback:function ($$v) {_vm.immatFilter=$$v},expression:"immatFilter"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aircrafts,"footer-props":{
              'items-per-page-options': [12, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            },"search":_vm.search,"loading":_vm.isLoading,"loading-text":"1 avion, 10 avions, 50 avions, 150 avions...  Vous gerez Orly ma parole !","dense":"","locale":"fr-FR","fixed-header":"","height":"630px","no-data-text":"1 avion, 10 avions, 50 avions, 150 avions...  Vous gerez Orly ma parole !","no-results-text":"Aucun résultat"},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [(item.status == 'active')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-alpha-g-circle-outline")]):_vm._e(),(item.status == 'init')?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-alpha-i-circle-outline")]):_vm._e(),(item.status == 'archive')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alpha-a-circle-outline")]):_vm._e(),(item.status == 'classif')?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-alpha-c-circle-outline")]):_vm._e(),(item.status == 'fuck')?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-alpha-f-circle-outline")]):_vm._e()]}},{key:"item.immatriculation",fn:function({ item }){return [_c('sbuttonLink',{attrs:{"to":{
                  name: 'aeronef',
                  params: { immatriculation: item.immatriculation, page: 'info' },
                }}},[_vm._v(_vm._s(item.immatriculation))])]}},{key:"item.motors",fn:function({ item }){return [_vm._v(_vm._s(_vm.printMotor(item.motors)))]}},{key:"item.propellers",fn:function({ item }){return [_vm._v(_vm._s(_vm.printPropeller(item.propellers)))]}},{key:"item.cell.partModel.reference",fn:function({ item }){return [(item.cell)?_c('b',[_vm._v(_vm._s(item.cell.partModel.reference))]):_vm._e()]}},{key:"item.supervisor.name",fn:function({ item }){return [(item.supervisor)?_c('div',[_vm._v(_vm._s(item.supervisor.name))]):_c('div',[_c('em',[_vm._v("Non renseigné")])])]}},{key:"item.contact.name",fn:function({ item }){return [(item.contact)?_c('v-btn',{attrs:{"text":"","to":{ name: 'contact', params: { id: item.contact.id } }}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.contact.name))])]):_vm._e()]}},{key:"item.contract",fn:function({ item }){return [(item.contract)?_c('div',[(item.contract.register == true)?_c('div',[_vm._v("déposé "),_c('br'),_vm._v("( "+_vm._s(item.contract.contractType)+" )")]):_vm._e(),(item.contract.register == false)?_c('div',[_vm._v("commercial "),_c('br'),_vm._v("( "+_vm._s(item.contract.contractType)+" )")]):_vm._e()]):_vm._e()]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }