import gql from 'graphql-tag';

export const cenCheckValueFragment = {
    fragments: {
      cenCheckValue: gql`
      fragment cenCheckValue on CenCheckValue {
        createdAt
        description
        id
        title
        updatedAt
        value
      }`
    }
}