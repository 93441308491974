<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <data-tablelivret />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTablelivret from "./dataTableLivret.vue";

export default {
  name: "GestionLivret",
  components: {
    dataTablelivret
  },
  data: () => ({
  })
};
</script>