<template>

        <v-dialog v-model="dialog" max-width="600">
          <template v-slot:activator="{}">
            <v-btn
              v-if="aircraft.status != 'archive'"
              class="mr-4 titre--text"
              outlined
              depressed
              @click="dialog = true"
              >Archiver</v-btn
            >
            <v-btn
              v-else
              class="mr-4 red--text"
              outlined
              depressed
              @click="unarchive()"
              >Desarchiver</v-btn
            >
          </template>
          <v-card>
            <v-card-title>Raison de l'archivage</v-card-title>
            <v-card-text class="pt-4">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select :items="reasonList" v-model="reason"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="details"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                      placeholder="Description de la tâche"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" align="center">
                    <v-btn small color="primary" @click="cleanDialog()">
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col cols="6" align="center">
                    <v-btn small color="primary" @click="archive()">
                      Archiver
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import { createArchiveReasonMutation } from "../../../graphql/aircraft/create_archive_reason";
import { archiveAircraftMutation } from "../../../graphql/aircraft/archive_aircraft";
import { unarchiveAircraftMutation } from "../../../graphql/aircraft/unarchive_aircraft";

export default {
  name: "Dah",
  props: ["aircraft"],
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      details: null,
      reason: null,
      reasonList: [
        { text: "Avion Cassé", value: "broken" },
        { text: "Avion vendu", value: "sell" },
        {
          text: "Abonnement non renouvellé par client",
          value: "no_renew_client",
        },
        {
          text: "Abonnement non renouvellé par sega",
          value: "no_renew_sega",
        },
        { text: "Contrat résilié par client", value: "cancel_client" },
        { text: "Contrat résilié par sega", value: "cancel_sega" },
        { text: "Autres", value: "other" },
      ],
      dialog: false,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    };
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.details = null;
      this.reason = null;
    },
    archive() {
      this.$apollo
        .mutate({
          mutation: createArchiveReasonMutation,
          variables: {
            input: {
              attributes: {
                aircraftId: parseInt(this.aircraft.id, 10),
                reason: this.reason,
                details: this.details,
              },
            },
          },
        })
        .then(() => {
          this.$apollo
            .mutate({
              mutation: archiveAircraftMutation,
              variables: {
                input: {
                  id: parseInt(this.aircraft.id, 10),
                },
              },
            })
            .then(() => {
              this.$emit("refreshPage");
              this.cleanDialog()
            });
        });
    },
    unarchive() {
      this.$apollo
        .mutate({
          mutation: unarchiveAircraftMutation,
          variables: {
            input: {
              id: parseInt(this.aircraft.id, 10),
            },
          },
        })
        .then(() => {
          this.$emit("refreshPage");
        });
    },
  },
};
</script>

