<template>
  <div v-if="aircraft">

    <dataTablePiece :aircraftId="parseInt(aircraft.id, 10)" />


  </div>
</template>

<script>
import { aircraftQuery } from "@/graphql/aircraft/aircraft";
// import dataTableDocVol from "./dataTableDocVol.vue";
import dataTablePiece from "./dataTablePiece.vue";


export default {
  name: "GestionHome",
  components: {
    dataTablePiece,
  },
  data: () => ({
    checking: false,
    isLoading: true,
    heartbeats: [],
  }),
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
    },
  },
  
};
</script>