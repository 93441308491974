<template>
  <div v-if="users">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <new-user
              :contact_id="contactId"
              @refreshPage="refreshPage()"
            ></new-user>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="header"
            :items="users"
            :sort-by="name"
            :items-per-page="15"
            :footer-props="{
              'items-per-page-options': [15, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            :loading="isLoading"
            dense
            loading-text="Chargement... merci de patienter"
            no-results-text="Aucun résultat"
          >
            <template v-slot:item.name="{ item }">
              <v-btn
                text
                class="font-weight-bold"
                :to="{ name: 'user', params: { id: item.id } }"
                >{{ item.name }}</v-btn
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <new-user
                :contact_id="contactId"
                :user="item"
                @refreshPage="refreshPage()"
              ></new-user>
              <v-btn icon>
                <v-icon color="red" @click="deleteUser(item)"
                  >mdi-delete-outline</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <confirm ref="confirm"></confirm>
    </v-row>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { usersQuery } from "../../graphql/user/users";
import NewUser from "./NewUser.vue";
import { deleteUserMutation } from "../../graphql/user/delete_user";
import confirm from "../../components/confirm.vue";

export default {
  props: {
    contactId: {
      type: String,
      required: false,
    },
  },
  components: {
    NewUser,
    confirm,
  },
  computed: {
    header: function () {
      if (this.contactId) {
        return [
          { text: "Nom Utilisateur", value: "name" },
          { text: "Licence", value: "mechanicNumber"},
          { text: "Fonction", value: "title" },
          { text: "Email", value: "email" },
          { text: "Téléphone", value: "phone" },
          { text: "", value: "actions", width: "170px" },
        ];
      } else {
        return [
          { text: "Référent", value: "contact.name" },
          { text: "Nom Utilisateur", value: "name" },
          { text: "Fonction", value: "title" },
          { text: "Email", value: "email" },
          { text: "Téléphone", value: "phone" },
          { text: "", value: "actions", width: "170px" },
        ];
      }
    },
  },
  methods: {
    refreshPage() {
      this.$apollo.queries.users.refetch();
    },
    deleteUser(user) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteUserMutation,
                variables: {
                  input: {
                    id: parseInt(user.id, 10),
                  },
                },
              })
              .then(() => {
                this.refreshPage();
              });
          }
        });
    },
  },
  data() {
    return {
      users: [],
      search: "",
      isLoading: true,
      show: false,
      breadItems: [
        {
          text: "Accueil",
          disabled: false,
          href: "root",
        },
        {
          text: "Gestion des contacts clients",
          disabled: false,
        },
      ],
    };
  },
  apollo: {
    users: {
      query: usersQuery,
      variables() {
        return {
          contactId: this.contactId ? parseInt(this.contactId, 10) : null,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>

