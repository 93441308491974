<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs :items="breadItems" divider=">" large class="mt-8, mb-6, px-4, py-2 text-uppercase primary--text">
          </v-breadcrumbs>
        </v-card>
      </v-col>

      <v-col>
        <dataTableContact :workshop="true"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTableContact from "./dataTableContact.vue";

export default {
  name: "ListeAtelier",
  components: {
    dataTableContact
  },
  metaInfo() {
    return {
      title: "Liste des ateliers"
    }
  },
  data() {
    return {
      breadItems: [
        {
          text: 'Accueil',
          disabled: false,
          href: 'root',
        },
        {
          text: 'Atelier',
          disabled: false,
        },

        ],
    }
  },
};
</script>