<template>
  <v-container>
    <v-col cols="8" offset="2">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-form>
          <v-card-title class="headline text-uppercase">
            <span class="primary--text">Nouveau | ATA</span>
          </v-card-title>
          <v-divider  style="padding-bottom: 50px"></v-divider>

          <v-row>
            <v-col cols="2" offset="1">
              <v-text-field
                dense
                outlined
                v-model="number"
                label="N° ATA"
                :rules="[rules.required, rules.counter]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" offset="3">
              <v-select
                dense
                outlined
                v-model="type"
                label="Quel type celà concerne-t-il ?"
                :items="typeItems"
              ></v-select>
            </v-col>
            <v-col cols="6" offset="3">
              <v-text-field
                dense
                outlined
                clearable
                v-model="nameFr"
                label="Nom Français"
                counter="250"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="3">
              <v-text-field
                dense
                outlined
                clearable
                v-model="nameEn"
                counter="250"
                label="Nom Anglais"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="mt-6">
              <sbuttonCancel to="/Admin?tab=ata">Annuler</sbuttonCancel>
              <v-btn class="mr-4 primary primary-text" @click="createAta()"
                >Créer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { createAtaMutation } from "@/graphql/ata/create_ata";
import { atasQuery } from "@/graphql/ata/atas";
//import sbuttonSave from "../../../components/v-btn.vue"
import sbuttonCancel from "../../../components/sbuttonCancel.vue";

export default {
  name: "NewAta",
  components: {
    sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      number: null,
      atas: [],
      ata: true,
      nameFr: "",
      nameEn: "",
      parent: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 2 || "2 nombres max",
      },
      type: "",
      typeItems: [
        { text: "Cellule", value: "Cell" },
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
      ],
    };
  },
  apollo: {
    atas: {
      query: atasQuery,
    },
  },
  methods: {
    createAta() {
      this.$apollo
        .mutate({
          mutation: createAtaMutation,
          variables: {
            input: {
              attributes: {
                number: parseInt(this.number, 10),
                nameFr: this.nameFr,
                nameEn: this.nameEn,
                parentId: this.parent,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: { tab: "ata" } });
        });
    },
  },
};
</script>
