import gql from 'graphql-tag';

export const updateTcHolderMutation = gql`
  mutation updateTcHolder($input: UpdateTcHolderInput!) {
    updateTcHolder(input: $input) {
      tcHolder {
        id
        name
        website
        login
        password
        cell
        motor
        propeller
        part
        country
        contactName
        contactEmail
        manualIndex
        sbIndex
        manufacturersInfo
        observation
        lastCheckAt
      }
    }
}`
