import gql from 'graphql-tag';

export const updateItemStopMutation = gql`
  mutation updateItemStop($input: UpdateItemStopInput!) {
    updateItemStop(input: $input) {
      itemStop {
        action {
          id
          name
        }
        additionalInfoEn
        additionalInfoFr
        value
        globalCheck
        globalCheckLink
        startDate
        docRef
        aircraftPart {
          id
          serialNumber
          manufacturer {
            id
            tcHolder {
              id
              name
            }
          }
          partModel {
            id
            reference
            type
            category {
              id
              name
            }
            ata {
              id
              chapter
            }
          }
        }
        createdAt
        id
        manufacturerDocRef
        nextActionDate
        stop {
          id
          name
          value
        }
        updatedAt
        remainingText
        usedText
      }
    }
}`
