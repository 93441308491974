<template>

        <v-dialog v-model="dialog" max-width="850">
          <template v-slot:activator="{}">
            <v-btn
              class="btn"
              depressed
              @click="dialog = true"
              ><v-icon left dark> mdi-plus </v-icon>Nouveau CEN</v-btn
            >
          </template>
          <v-card >
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouveau Cen</span>
            </v-card-title>
            <v-card-text>

              <v-col cols="10" offset="1" class="mt-4">
                <v-alert color="red" border="left" elevation="2" colored-border type="info"
                  >Ce bouton ne sert que un <b>NOUVEAU CEN INITIAL</b> jamais réalisé à la SEGA.<br /><br /> Pour un <b>renouvellement/prorogation d'un CEN avion déjà effectué sur igame2</b>, il faut passer par la fiche de l'aéronef puis sa dernière fiche CEN. 
                  <br /><br />Pour une <b>extension dont l'initial est l'Osac ou une entité extérieur</b>, contacter julie
                </v-alert>
              </v-col>
              <v-col cols="8" offset="2" class="pt-4">
                <v-text-field v-model="aircraft" label="Quelle est l'immatriculation ?" filled
                  rounded dense hint="Format: F-XXXX" v-upper>
                  </v-text-field>
              </v-col>

            </v-card-text>
            <v-card-actions>
             <v-col cols="12" align="center">
              <v-btn
                bottom
                @click="cancel()"
                outlined
                depressed
                class="primary--text mb-4 mr-4"
              >Annuler
              </v-btn>
              
              <v-btn class="mr-4 mb-4 primary" dark
                bottom
                :loading="saveLoading"
                @click="createCen()">
                Créer
              </v-btn>
                          </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
</template>

<script>
import { aircraftsimmatQuery } from "@/graphql/aircraft/aircrafts_immat";
import { createCenMutation } from "@/graphql/cen/create_cen";
//import sbuttonSave from "../../components/v-btn.vue"
// import sbuttonCancel from "../../components/sbuttonCancel.vue";


export default {
  name: "NewCEN",
  components : {
    // sbuttonCancel,
    //sbuttonSave,
  },
  data() {
    return {
      aircraft: null,
      saveLoading: false,
      dialog: false,
    };
  },

  apollo: {
    aircrafts: {
      query: aircraftsimmatQuery,
    },
  },

  methods: {
    createCen() {
      this.saveLoading = true;

      this.$apollo
        .mutate({
          mutation: createCenMutation,
          variables: {
            input: {
              attributes: {
                immatriculation: this.aircraft,
              },
            },
          },
        })
        .then((data) => {
          if (data) {
            this.$router.push({ name: 'cen', params: { id: data.data.createCen.cen.id} });
          }
        });
    },
    cancel() {
      this.dialog = false;
    },    
  },
  directives: {
    upper: {
      bind(el, _, vnode) {
        el.addEventListener("input", (e) => {
          e.target.value = e.target.value.toUpperCase();
          vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
        });
      },
    },
  },
};
</script>