import gql from 'graphql-tag';

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        contact {
          id
          name
        }
        id
        name
        role
        firstName
        lastName
        titleName
        email
        title
        avatar
        trigram
        mechanic
        mechanicNumber
      }
    }
}`
