<template>
  <div>
    <v-dialog v-model="dialogClose" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Cloture du contrat
        </v-card-title>

        <v-card-text>
          <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="datePicker" label="Date de cloture" prepend-icon="mdi-calendar-month" outlined dense
                readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="closeDate" :first-day-of-week="1" locale="fr" color="green" no-title
              @input="menu = false">
            </v-date-picker>
          </v-menu>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelClose()"> Annuler </v-btn>
          <v-btn text @click="saveClose()">
            Cloturer le contrat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn text @click="openDialog()">Cloturer contrat</v-btn>
  </div>
</template>

<script>
import { closeContractMutation } from "../../graphql/contract/close_contract";

export default {
  name: "dialogCloseContract",
  props: {
    contract: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dialogClose: false,
      menu: false,
      closeDate: this.contract.closedAt,
    };
  },
  computed: {
    datePicker() {
      return this.formatDate(this.closeDate);
    },
  },
  methods: {
    openDialog() {
      console.log("TOTO")
      this.dialogClose = true;
    },
    saveClose() {
      this.$apollo
        .mutate({
          mutation: closeContractMutation,
          variables: {
            input: {
              id: parseInt(this.contract.id, 10),
              closeDate: this.closeDate,
            },
          }
        })
        .then((data) => {
          console.log(data);
          this.dialogClose = false;
        });
    },
    cancelClose() {
      this.closeDate = null;
      this.dialogClose = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
