import gql from 'graphql-tag';

export const deleteCenCheckMutation = gql`
  mutation deleteCenCheck($input: DeleteCenCheckInput!) {
    deleteCenCheck(input: $input) {
      cenCheck{
        id
        title
        description
        typeCheck
        order
        createdAt
        updatedAt
      }
    }
}`
