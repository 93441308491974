<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn v-if="workshopTool" @click="dialog = true" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-else
        class="primary--text"
        outlined
        depressed
        @click="dialog = true"
        ><v-icon left dark> mdi-plus </v-icon>Nouvel outil</v-btn
      >
    </template>
    <v-card>
      <v-card-title>Outil</v-card-title>
      <v-card-text class="pt-4">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="workshopToolModel"
                label="Modéle"
                :items="
                  workshopToolModels.map((c) => {
                    return { text: c.name, value: c.id };
                  }).sort((a, b) => a.text.localeCompare(b.text))
                "
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :rules="[rules.required]"
                hint="* obligatoire"
                v-model="serialNumber"
                label="Numéro de série *"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePicker"
                    label="Date d'expiration"
                    prepend-icon="mdi-calendar-month"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expiredAt"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="cancel()"> Annuler </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="save()"> Sauvegarder </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateWorkshopToolMutation } from "../../graphql/workshop_tool/update_workshop_tool";
import { createWorkshopToolMutation } from "../../graphql/workshop_tool/create_workshop_tool";
import { workshopToolModelsQuery } from "../../graphql/workshop_tool/workshop_tool_models";
import { workshopToolQuery } from "../../graphql/workshop_tool/workshop_tool";

export default {
  name: "NewTool",
  props: {
    contactId: {
      required: true,
    },
    workshopTool: {
      required: false,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      workshopToolModel: null,
      workshopToolModels: [],
      serialNumber: null,
      expiredAt: null,
      menu: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted: function () {
    if (this.workshopTool) {
      this.workshopToolModel = this.workshopTool.workshopToolModel.id;
      this.serialNumber = this.workshopTool.serialNumber;
      this.expiredAt = this.workshopTool.expiredAt;
    }
  },
  computed: {
    datePicker() {
      return this.formatDate(this.expiredAt);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    cleanDialog() {
      this.dialog = false;
      this.workshopToolModel = null;
      this.serialNumber = null;
      this.expiredAt = null;
    },
    save() {
      if (this.workshopTool) {
        this.$apollo
          .mutate({
            mutation: updateWorkshopToolMutation,
            variables: {
              input: {
                id: this.workshopTool.id,
                attributes: {
                  workshopId: parseInt(this.contactId, 10),
                  workshopToolModelId: parseInt(this.workshopToolModel, 10),
                  serialNumber: this.serialNumber,
                  expiredAt: this.expiredAt,
                },
              },
            },
          })
          .then(() => {
            this.$emit("refreshPage");
            this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createWorkshopToolMutation,
            variables: {
              input: {
                attributes: {
                  workshopId: parseInt(this.contactId, 10),
                  workshopToolModelId: parseInt(this.workshopToolModel, 10),
                  serialNumber: this.serialNumber,
                  expiredAt: this.expiredAt,
                },
              },
            },
          })
          .then(() => {
            this.$emit("refreshPage");
            this.cleanDialog();
          });
      }
    },
    cancel() {
      this.cleanDialog();
      this.dialog = false;
    },
  },
  apollo: {
    workshopToolModels: {
      query: workshopToolModelsQuery,
    },
    contact: {
      query: workshopToolQuery,
      variables() {
        return {
          id: this.workshopToolId,
        };
      },
      skip() {
        return !this.workshopToolId;
      },      
    },
  },
};
</script>

