import gql from 'graphql-tag';

export const maintenanceProgrammeItemFragment = {
    fragments: {
      maintenanceProgrammeItem: gql`
        fragment maintenanceProgrammeItem on MaintenanceProgrammeItem {
          id
          description
          interval
          intervalAlt
          task
          taskAlt
          reference
          deviation
          itemType
          level
          conformity
      }`
    }
  }