<template>
  <v-container v-if="$apollo.loading">
    <v-row>
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
    </v-row>
  </v-container>
  <v-container v-else fluid>
    <v-row>
      <v-col flex>
        <v-tabs
          v-model="tab"
          background-color="#eceff1"
          color="#23afb9"
          centered
          center-active
          grow
          show-arrows
          icons-and-text
        >
          <v-tabs-slider color="#23afb9"></v-tabs-slider>
          <v-tab href="#stat1">
            Aéronef/Contrat
            <v-icon>mdi-airplane</v-icon>
          </v-tab>
          <v-tab href="#stat2">
            Ordre de travail
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-tab>

        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="stat1">
            <StatContrat />
          </v-tab-item>
          <v-tab-item value="stat2">
            <StatOt />
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<script>
import StatContrat from './StatContrat.vue';
import StatOt from './StatOt.vue';

export default {
  name: "Index",
  data: function () {
    return {};
  },
  components: {
		StatContrat,
		StatOt,
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
};
</script>