import gql from 'graphql-tag';

export const aircraftDocumentFragment = {
    fragments: {
      aircraftDocument: gql`
        fragment aircraftDocument on AircraftDocument {
          id
          typeName
          dateDoc
          name
          number
          document
          updatedAt
          createdAt
          expiredAt
          manualExpiredAt
          aircraft {
            id
            immatriculation
          }
      }` 
    }
}