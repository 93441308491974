<template>
  <v-container fluid >
    <v-row>
      <v-col>
        <dataTableContrat />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTableContrat from "./dataTableContrat.vue";

export default {
  name: "ListeContrat",
  components: {
    dataTableContrat
  },
  metaInfo() {
    return {
      title: "Liste des contrats"
    }
  },
};
</script>