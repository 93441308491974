import gql from 'graphql-tag';

export const atasQuery = gql`
query{
  atas{
    id
    name
    nameFr
    nameEn
    chapter
    number
    parent {
      id
    }
    updatedAt
    createdAt
  }
}`