import gql from 'graphql-tag';

export const cancelDocumentMutation = gql`
  mutation cancelDocument($input: CancelDocumentInput!) {
    cancelDocument(input: $input) {
      document {
        id
        type
        mandatory
        number
        publishingDate
        additionalInformation
        recurring
        revision
        tcHolders {
          id
          name
        }
        titleFr
        titleEn
        title
        updatedAt
        createdAt
      }
    }
}`
