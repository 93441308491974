<template>

  <div v-if="contacts">



      <v-main class="pt-4 pl-4">
        <v-row>
          <v-col cols="12">
            <v-card> 
              <v-card-title>
                <sbuttonAdd
                  :to="{ name: 'new_contact' }"
                  ><v-icon left dark> mdi-plus </v-icon> Nouveau client</sbuttonAdd
                >
                <v-spacer></v-spacer>
                <v-row>
                  <v-col cols="3" offset="2">
                    <v-select
                      label="par status"
                      :items="statusOptions"
                      v-model="statusFilter"
                    ></v-select>
                  </v-col>
                  <v-col cols="3" v-if="workshop != true">
                    <v-select
                      label="par type de client"
                      :items="typeOptions"
                      v-model="typeFilter"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Rechercher"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-data-table
                :headers="header"
                :items="contacts"
                sort-by="name"
                :items-per-page="15"
                :footer-props="{
                  'items-per-page-options': [15, 50, 100],
                  'items-per-page-text': 'lignes par page',
                  'items-per-page-all-text': 'Tous',
                }"
                :search="search"
                :loading="isLoading"
                dense
                loading-text="Chargement... merci de patienter"
                no-results-text="Aucun résultat"
              >
                <template v-slot:item.name="{ item }">
                  <v-btn
                    text
                    class="font-weight-bold"
                    :to="{ name: 'contact', params: { id: item.id } }"
                    >{{ item.name }}</v-btn
                  >
                </template>
                <template v-slot:item.notes="{ item }">
                  <v-tooltip top v-if="item.notes">
                    <template v-slot:activator="{ on }">
                      <v-btn icon>
                        <v-icon v-on="on">mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span> {{ item.notes }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.actions="{}">
                  <v-btn icon>
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.type="{ item }">
                  <v-chip
                    v-if="item.type == 'Workshop'"
                    class="ma-2"
                    color="blue lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-tools</v-icon>
                    Atelier
                  </v-chip>
                  <v-chip
                    v-if="item.type == 'AeroClub'"
                    class="ma-2"
                    color="green lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-airport</v-icon>
                    Aéroclub
                  </v-chip>
                  <v-chip
                    v-if="item.type == 'Company'"
                    class="ma-2"
                    color="orange lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-office-building</v-icon>
                    Société
                  </v-chip>
                  <v-chip
                    v-if="item.type == 'Owner'"
                    class="ma-2"
                    color="purple lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-airplane</v-icon>
                    Propriétaire
                  </v-chip>
                  <v-chip
                    v-if="item.type == 'Engineer'"
                    class="ma-2"
                    color="indigo lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-wrench</v-icon>
                    Mécanicien
                  </v-chip>
                  <v-chip
                    v-if="item.type == 'Rental'"
                    class="ma-2"
                    color="brown lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-location-exit</v-icon>
                    Location
                  </v-chip>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-icon color="green" v-if="item.status == 'active'"
                    >mdi-account</v-icon
                  >
                  <v-icon v-if="item.status == 'archived'">mdi-exit-to-app</v-icon>
                </template>
                <template v-slot:item.managed="{ item }">
                  <v-icon v-if="item.managed">mdi-airplane-check</v-icon>
                </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { contactsQuery } from "../../graphql/contact/contacts";
import sbuttonAdd from "../../components/sbuttonAdd.vue"


export default {
  components : {
    sbuttonAdd
  },
  props: ["workshop"],
  data() {
    return {
      contacts: [],
      search: "",
      isLoading: true,
      show: false,
      typeFilter: "",
      typeOptions: [
        { text: "Tous", value: "" },
        { text: "Aéro club", value: "AeroClub" },        
        { text: "Propriétaire", value: "Owner" },
        { text: "Société", value: "Company" },
        { text: "Divers", value: "Misc" },
      ],
      statusFilter: "active",
      statusOptions: [
        { text: "Tous", value: "" },
        { text: "Actif", value: "active" },
        { text: "Archivé", value: "archived" },
      ],
      
    };
  },
  computed: {
    typeFilterArray() {
      if (this.workshop == true) {
      return ["Workshop", "Engineer"]
      } else if(this.typeFilter == '') {
        return ["AeroClub", "Owner", "Company", "Misc"]
      } else {
        return [this.typeFilter]
      }
    },    
    header: function () {
      if (this.workshop == true) {
        return [
          { text: "Type de client", value: "type", width: "110px" },
          { text: "Statut", value: "status", width: "77px" },
          { text: "N°", value: "easaNumber", width: "120px" },
          { text: "Nom du client", value: "name" },
          { text: "", value: "actions", width: "50px" },
          ];
      }
      else {
        return [
        { text: "Type de client", value: "type", width: "110px" },
        { text: "Statut", value: "status", width: "77px" },
        { text: "Gest.", value: "managed", width: "75px" },
        { text: "N° client", value: "reference", width: "120px" },
        { text: "Nom du client", value: "name" },
        { text: "", value: "actions", width: "50px" },
        ];
      }
    }
  },  
  apollo: {
    contacts: {
      query: contactsQuery,
      result() {
        this.isLoading = false;
      },
      variables() {
        return {
          type: this.typeFilterArray,
          status: this.statusFilter,
        };
      },
    },
  },
};
</script>

