import gql from 'graphql-tag';

export const updateWorkshopToolMutation = gql`
  mutation updateWorkshopTool($input: UpdateWorkshopToolInput!) {
    updateWorkshopTool(input: $input) {
      workshopTool {
        id
        serialNumber     
        expiredAt
        workshopToolModel {
          id
          name
          periodicity
        }       
        updatedAt
        createdAt
      }
    }
}`
