import gql from 'graphql-tag';

export const deleteUserMutation = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        contact {
        id
        name
      }
      id
      name
      role
      firstName
      lastName
      titleName
      email
      title
      avatar
      }
    }
}`
