import gql from 'graphql-tag';
import { workOrderItemFragment } from './fragment_work_order_item';

export const deleteWorkOrderItemMutation = gql`
  mutation deleteWorkOrderItem($input: DeleteWorkOrderItemInput!) {
    deleteWorkOrderItem(input: $input) {
      workOrderItem {
        ...workOrderItem
      }
    }
}
${workOrderItemFragment.fragments.workOrderItem}`