<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn block class="my-3" color="primary" @click="dialog = true">Dépose de la pièce</v-btn>
    </template>
    <v-card>
      <v-card-title>Dépose de {{ aircraftPart.partModel.fullName }}<span v-if="aircraftPart.serialNumber"> {{ aircraftPart.serialNumber }}</span></v-card-title>
      <v-card-text class="">
        <v-container>          
          <v-row>
            <v-col cols="12">
              <v-switch v-model="archive" label="Mise au rebut de la piéce"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="cancel()"> Annuler </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="save()"> Déposer </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dropAircraftPartMutation } from "../../graphql/aircraft_part/drop_aircraft_part"

export default {
  name: "DeposeModal",
  props: {    
    aircraftPart: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      archive: false,      
    };
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.archive = false;
    },
    save() {
      this.$apollo
        .mutate({
          mutation: dropAircraftPartMutation,
          variables: {
            input: {
              aircraftPartId: parseInt(this.aircraftPart.id, 10),
              archive: this.archive,
            },
          },
        })
        .then(() => {          
          this.cleanDialog();
        });
    },
    cancel() {
      this.cleanDialog();      
    },
  },  
};
</script>

