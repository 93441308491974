import gql from 'graphql-tag';

export const manufacturerQuery = gql`query Manufacturer($id: ID!) {
  manufacturer(id: $id) {
    id
    name
    tcHolder {
      id
      name
    }
  }
}`