import gql from 'graphql-tag';
import { actionFragment } from './fragment_action';


export const updateActionMutation = gql`
  mutation updateAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      action {
        ...action
      }
    }
}
${actionFragment.fragments.action}`