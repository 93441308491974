v<template>
  <v-container fluid>
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <span v-if="contact" class="primary--text">Nouveau Contact</span>
        <span v-else lass="primary--text">Modifier Contact</span>
      </v-card-title>


      <v-divider style="padding-bottom: 50px"></v-divider> 
      <v-form>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="10" offset="1">
                  <v-select
                    label="Type *"
                    v-model="typeContact"
                    :items="typeContactItems"
                    outlined
                    dense
                    hint="* obligatoire"
                    persistent-hint
                  ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <template
                v-if="
                  this.typeContact == 'Company' ||
                  this.typeContact == 'AeroClub' ||
                  this.typeContact == 'Workshop'
                "
              >
                <v-col cols="10" offset="1" class="py-0">
                  <!-- société -->
                  <v-text-field label="Nom" outlined dense v-model="companyName">
                    </v-text-field
                  >
                </v-col>
              </template>
            </v-row>
            <v-row>
              <template
                v-if="
                  this.typeContact == 'Owner' || this.typeContact == 'Engineer'
                "
              >
                <v-col cols="2" offset="1" class="py-0">
                  <!-- nom -->
                  <v-select
                    label="Civilité *"
                    v-model="civilite"
                    :items="civiliteItems"
                    outlined
                    dense
                    hint="* obligatoire"
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="4" class="py-0">
                  <!-- nom -->
                  <v-text-field
                    label="Nom *"
                    outlined
                    dense
                    hint="* obligatoire"
                    persistent-hint
                    v-model="lastName"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <!-- prénom -->
                  <v-text-field
                    label="Prénom"
                    outlined
                    dense
                    v-model="firstName"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row> 
            <v-row>
              <v-col cols="10" offset="1" class="py-0">
                <v-text-field label="Adresse" outlined dense v-model="address"></v-text-field>
              </v-col>
            </v-row> 
            <v-row>              
              <v-col cols="10" offset="1" class="py-0">
                <v-text-field label="Complément" outlined dense v-model="address2"></v-text-field>
              </v-col>
            </v-row> 
            <v-row>  
              <v-col cols="2" offset="1" class="pt-0">
                <v-text-field label="CP"  outlined dense v-model="zipCode"></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0">
                <v-text-field label="Ville"  outlined dense v-model="city"></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0">
                <v-text-field label="Pays"  outlined dense v-model="country"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" offset="1" class="py-0">
                <v-text-field
                  label="Téléphone"
                  outlined
                  dense
                  v-model="phone"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="py-0">
                <v-text-field
                  label="Portable"
                  outlined
                  dense
                  v-model="mobile"
                ></v-text-field>
              </v-col>
            </v-row>
            
          </v-col>

          <v-col cols="6" class="mt-4">
            <v-form>
                  <!-- <v-col cols="11">
                      <v-text-field label="Nom" outlined dense v-model="companyFact"></v-text-field>
                  </v-col>

                    <v-col cols="3" class="pt-0">
                      <v-select
                        label="Civilité"
                        v-model="civiliteFact"
                        :items="civiliteItems"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-text-field
                        label="Nom"
                        outlined
                        dense
                        v-model="lastNameFact"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-text-field
                        label="Prénom"
                        outlined
                        dense
                        v-model="firstNameFact"
                      ></v-text-field>
                    </v-col> -->
              <v-row>
                <v-col cols="10" offset="1" class="py-0">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                  <template v-if="this.typeContact == 'Workshop'">
                    <v-col cols="5" offset="1" class="py-0">
                      <v-text-field
                        label="N° Agrément"
                        outlined
                        dense
                        v-model="agrement"
                      ></v-text-field>
                    </v-col>
                  </template>
              </v-row>
              <v-row>
                  <v-col cols="10" offset="1" class="py-0">
                    <v-text-field
                      label="Website"
                      outlined
                      dense
                      v-model="website"
                    ></v-text-field>
                  </v-col>
              </v-row>


              <v-row>
                <v-col cols="10" offset="1" class="py-0">
                  <v-textarea
                    label="Observations"
                    rows="7"
                    auto-grow
                    outlined
                    v-model="notes"
                  ></v-textarea>            
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          
          <v-col cols="12" align="center" class="mt-4">
              <sbuttonCancel
                v-if="contact"
                :exact="true"
                :to="{ name: 'contact', params: {id: contact.id }}"
                >Annuler</sbuttonCancel
              > 
              <sbuttonCancel
                v-else
                :exact="true"
                :to="{ name: 'contacts' }"
                >Annuler</sbuttonCancel
              > 
              <v-btn class="mr-4 primary primary-text" dark v-if="contact" @click="save()"
                >Modifier</v-btn
              >
              <v-btn class="mr-4 primary primary-text" dark v-else @click="save()"
                >Enregistrer</v-btn
              >
            
          </v-col>
        </v-row>
      </v-form>
     </v-sheet>
  </v-container>
</template>

<script>
import { createContactMutation } from "../../graphql/contact/create_contact";
//import sbuttonSave from "../../components/v-btn.vue"
import sbuttonCancel from "../../components/sbuttonCancel.vue"
import { contactQuery } from "../../graphql/contact/contact";
import { updateContactMutation } from "../../graphql/contact/update_contact";//
export default {
  
  components : {
    sbuttonCancel,
    //sbuttonSave,
  },
  name: "NewContact",
  data() {
    return {
      contact: null,
      checkbox: false,
      reference: null,
      phone: null,
      email: null,
      emailFact: null,
      mobile: null,
      website: null,
      civilite: "",
      civiliteFact: null,
      siret: null,
      vatNumber: null,
      firstName: null,
      lastName: null,
      firstNameFact: null,
      lastNameFact: null,
      address: null,
      address2: null,
      city: null,
      zipCode: null,
      country: null,
      companyFact: null,
      companyName: null,
      notes: null,
      civiliteItems: [
        { text: "Monsieur", value: "Monsieur" },
        { text: "Madame", value: "Madame" },
        { text: "Messieurs", value: "Messieurs" },
        { text: "Mesdames", value: "Mesdames" },
      ],
      typeContact: "",
      typeContactItems: [
        { text: "Aéroclub", value: "AeroClub" },
        { text: "Atelier", value: "Workshop" },
        { text: "Mecanicien ind.", value: "Engineer" },
        { text: "Propriétaire", value: "Owner" },
        { text: "Société", value: "Company" },
 
      ],
    };
  },
  methods: {
    save() {
      if (this.contact) {
        this.$apollo
        .mutate({
          mutation: updateContactMutation,
          variables: {
            input: {
              id: parseInt(this.contact.id, 10),
              attributes: {
                email: this.email,
                website: this.website,
                phone: this.phone,
                type: this.typeContact,
                mobile: this.mobile,
                civility: this.civilite,
                firstName: this.firstName,
                lastName: this.lastName,
                companyName: this.companyName,
                invoiceAddress: this.address,
                invoiceAddress2: this.address2,
                invoiceZipcode: this.zipCode,
                invoiceCity: this.city,
                invoiceCountry: this.country,
                notes: this.notes,
                agrementNumber: this.agrement,
                licenceNumber: this.licence,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "contact", params: {id: this.contact.id }});
        });            
      } else {    
        this.$apollo
          .mutate({
            mutation: createContactMutation,
            variables: {
              input: {
                attributes: {
                  email: this.email,
                  website: this.website,
                  phone: this.phone,
                  type: this.typeContact,
                  mobile: this.mobile,
                  civility: this.civilite,
                  firstName: this.firstName,
                  lastName: this.lastName,
                  companyName: this.companyName,
                  invoiceAddress: this.address,
                  invoiceAddress2: this.address2,
                  invoiceZipcode: this.zipCode,
                  invoiceCity: this.city,
                  invoiceCountry: this.country,
                  notes: this.notes,
                  agrement: this.agrementNumber,
                  licence: this.licenceNumber,
                },
              },
            },
          })
          .then((data) => {
            this.$router.push({ name: "contact", params: {id: data.data.createContact.contact.id }});
          });
        }
    },
  },
  apollo: {
    contact: {
      query: contactQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          this.typeContact = data.contact.type;
          this.reference = data.contact.reference;
          this.phone = data.contact.phone;
          this.mobile = data.contact.mobile;
          this.email = data.contact.email;
          this.website = data.contact.website;
          this.civilite = data.contact.civility;
          this.firstName = data.contact.firstName;
          this.lastName = data.contact.lastName;
          this.companyName = data.contact.companyName;
          this.address = data.contact.invoiceAddress;
          this.address2 = data.contact.invoiceAddress2;
          this.zipCode = data.contact.invoiceZipcode;
          this.city = data.contact.invoiceCity;
          this.country = data.contact.invoiceCountry;
          this.siret = data.contact.siret;
          this.vatNumber = data.contact.vatNumber;
          this.notes = data.contact.notes;
          this.agrement = data.contact.agrementNumber;
          this.licence = data.contact.licenceNumber;
        }
      },
    },
  },
};
</script>
