import gql from 'graphql-tag';
import { maintenanceProgrammeFragment } from './fragment_maintenance_programme';

export const renewMaintenanceProgrammeMutation = gql`
  mutation renewMaintenanceProgramme($input: RenewMaintenanceProgrammeInput!) {
    renewMaintenanceProgramme(input: $input) {
      maintenanceProgramme {
        ...maintenanceProgramme
      }
    }
  } ${maintenanceProgrammeFragment.fragments.maintenanceProgramme}`
