<template>

  <v-sheet outlined rounded height="254px">
    <v-card-title class="titre--text justify-center">Gestion de navigabilité</v-card-title>
    <v-card-text class="text-center pa-5 pb-0 pt-5">
      <v-row class="pb-6 no-gutters">
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#a6b3ff">
            <v-icon>mdi-airplane</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ aircraftCount }}</p>
          <p style="font-size:1rem">Aéronefs <br />en gestion</p>
        </v-col>
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#ff5992">
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ contactCount }}</p> 
          <p style="font-size:1rem">Clients <br />en gestion</p>
        </v-col>
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#ffc96f">
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ documentCount }}</p>
          <p style="font-size:1rem">Documents <br />de rentré</p>
        </v-col>
        <v-col cols="3" class="d-flex align-center flex-column">
          <v-btn fab small dark color="#68deb5">
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-btn>
          <p class="greyBold--text text-h6 mb-1 mt-2">{{ cenCount }}</p>
          <p style="font-size:1rem">Cens <br />effectués</p>
        </v-col>
      </v-row>
    </v-card-text>

  </v-sheet>

</template>

<script>
import { aircraftCountQuery } from "../graphql/aircraft/aircraft_count";
import { contactCountQuery } from "../graphql/contact/contact_count";
import { documentCountQuery } from "../graphql/document/document_count";
import { cenCountQuery } from "../graphql/cen/cen_count";

export default {
  name: "NombreAppareil",
  data() {
    return {
    };
  },
  apollo: {
    aircraftCount: {
      query: aircraftCountQuery
    },
    contactCount: {
      query: contactCountQuery
    },
    documentCount: {
      query: documentCountQuery
    },
    cenCount: {
      query: cenCountQuery
    }
  }
};
</script>
