import gql from 'graphql-tag';

export const aircraftsimmatQuery = gql`
      {
        aircrafts {
          id
          type
          status
          immatriculation
          updatedAt
          createdAt
        }
      }
    `
