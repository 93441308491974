import gql from 'graphql-tag';

export const aircraftFragment = {
    fragments: {
      aircraft: gql`
        fragment aircraft on Aircraft {
          id
          status
          immatriculation
          photo
          kardexAttachment {
            id
            name
            fileUrl
            fileSignedId
            createdAt
          }
          adStatusAttachment {
            id
            name
            fileUrl
            fileSignedId
            createdAt
          }
          gasType
          notes
          mtow
          modifiedMtow
          mtowForced
          longMaintenance
          annex1
          contact {
              id
              name
          }
          supervisor {
            id
            name
          }
          workshop {
            id
            name
            easaNumber
          }
          updatedAt
          createdAt
          archivedAt
          easaType
          easaPartType
          parkingType
          archiveReasons {
            id
            reason
            details
            createdAt
          }
          contract {
            id
            number
            start
            register
            contractType
            document
          }
          aircraftContract {
            id  
            start
          }
          aircraftDocuments {
            id
            typeName
            number
            dateDoc
            expiredAt
            document
          }
          aircraftPhotos {
            id
            title
            photo
            isCover
          }
          flies {
            id
            createdAt
            flyDate
          }
          lastFly {
            id
            createdAt
            flyDate
          }
          lastCen {
            id            
            expiredAt
            reference
            certificateAttachment {
              id
              name
              fileUrl
              createdAt
            }
          }
          lastMaintenanceProgramme {
            id
            expiredAt
            reference
            signedProgramme
            signedAt
          }
          cell {
            id
            serialNumber
            manufacturingDate
            flyValue
            manualsData
            manuals {
              id
              number
              title
            }
            manufacturer {
              id
              name
            }
            partModel {
              id
              reference
              numberMotor
              mtow
              pressurized
              tcds
              tcHolder {
                id
                name
              }
              manuals {
                id
                number
                title
              }
            }
          }
          motors {
            id
            serialNumber
            flyValue
            manualsData
            manuals {
              id
              number
              title
            }
            partModel {
              id
              reference
              tcHolder {
                id
                name
              }
              manuals {
                id
                number
                title
              }
            }
          }
          propellers {
            id
            serialNumber
            flyValue
            manualsData
            manuals {
              id
              number
              title
            }
            partModel {
              id
              reference
              tcHolder {
                id
                name
              }
              manuals {
                id
                number
                title
              }
            }
          }
          aircraftParts {
            id
            serialNumber
            independantHour
            partModel {
              id
              reference
              tcHolder {
                id
                name
              }              
            }
          }
      }` 
    }
}