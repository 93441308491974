import gql from 'graphql-tag';
import { documentFragment } from './fragment_document';


export const createDocumentMutation = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        ...document
      }
    }
}
${documentFragment.fragments.document}`
