<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-form>
          <v-row>
            <v-col class="py-0">
              <new-tool :contactId="OT.workshop.id" @refreshPage="refreshPage()"></new-tool>
              <v-list>
                <v-list-item
                  v-for="workshopTool in workshopTools"
                  :key="workshopTool.id"
                >
                  <v-checkbox                  
                    v-model="tools"
                    :label="`${workshopTool.workshopToolModel.name} -  ${workshopTool.serialNumber}`"
                    :value="workshopTool.id"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="mt-8">
              <v-btn class="mr-4 primary" dark @click="save()"
                >Enregistrer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { workshopToolsQuery } from "../../graphql/workshop_tool/workshop_tools";
import { updateWorkOrderMutation } from "../../graphql/work_order/update_work_order";
import NewTool from "../Contact/NewTool.vue";

export default {
  name: "MechanicsComponent",
  components: {NewTool},
  props: ["OT"],
  data() {
    return {
      tools: [],
      workshopTools: [],
    };
  },
  methods: {
    refreshPage() {
      this.$apollo.queries.workshopTools.refetch();
    },
    save() {
      this.$apollo.mutate({
        mutation: updateWorkOrderMutation,
        variables: {
          input: {
            id: parseInt(this.OT.id, 10),
            attributes: {
              returnWorkshopTools: this.tools,
            },
          },
        },
      });
    },
  },
  computed: {},
  apollo: {
    workshopTools: {
      query: workshopToolsQuery,
      variables() {
        return {
          workshopId:
            this.OT.workshop && this.OT.workshop.id
              ? parseInt(this.OT.workshop.id, 10)
              : null,
        };
      },
    },
  },
  created() {
    this.tools = this.OT.returnWorkshopTools;
  },
};
</script>