<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="3" offset="5" class="d-none d-sm-block">
                <v-select
                  label="Client"
                  :items="contact"
                  v-model="contactFilter"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="aircrafts"
            :footer-props="{
              'items-per-page-options': [20, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            :loading="isLoading"
            loading-text="99 ballons accrochés au mur !"
            dense
            locale="fr-FR"
            fixed-header
            min-height="650px"
            no-data-text="y a que dalle ! "
            no-results-text="Aucun résultat"
          >
            <template v-slot:item.immatriculation="{ item }">
              <v-btn
                class="mr-4 link--text"
                text
                small
                :to="{
                  name: 'aeronef',
                  params: { immatriculation: item.immatriculation, page: 'info' },
                }"
                >{{ item.immatriculation }}</v-btn
              >
            </template>
            <template v-slot:item.cen="{ item }">
              <span v-if="findDoc(item, 'Cen')">
                <v-chip small :color="linkColor(findDoc(item, 'Cen').expiredAt)"
                  >{{
                findDoc(item, "Cen").expiredAt | moment("DD/MM/YYYY")
              }}</v-chip></span>
            </template>
            <template v-slot:item.pe="{ item }">
              <span v-if="findDoc(item, 'Pe')">
                <v-chip small :color="linkColor(findDoc(item, 'Pe').expiredAt)"
                  >{{
                findDoc(item, "Pe").expiredAt | moment("DD/MM/YYYY")
              }}</v-chip></span>
            </template>
            <template v-slot:item.ad="{ item }">
              {{ AdToProcess(item) }}
            </template>
            <template v-slot:item.sb="{ item }">
              {{ SbToProcess(item) }}
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { aircraftsSuiviQuery } from "../../graphql/audit/aircrafts_suivi";
import { DateTime } from "luxon";

export default {
  name: "SuiviIgame",
  data() {
    return {
      aircrafts: [],
      search: "",
      isLoading: true,
      contact: [],
      contactFilter: null,
      headers: [
        { text: "CLIENT", value: "contact.name", width: "300px" },
        { text: "GEST", value:"supervisor.name", width:"200px"},
        {
          text: "IMMAT",
          align: "center",
          value: "immatriculation",
          width: "90px",
        },
        { text: "CELLULE", value: "cell.partModel.fullName", width: "300px" },

        { text: "CEN", value: "cen", width: "150px" },
        { text: "PE", value: "pe", width: "150px" },
        { text: "OT", value: "ot", width: "150px" },
        { text: "AD", value: "ad", width: "150px" },
        { text: "SB", value: "sb", width: "150px" },
      ],
    };
  },
  methods: {
    findDoc(aircraft, type) {
      const doc = aircraft.aircraftDocuments.find(
        (d) => d.typeName == type && d.status == "active"
      );
      if (doc) {
        return doc;
      }
      return null;
    },
    AdToProcess(aircraft) {
      if (
        !aircraft.documentProcessings ||
        aircraft.documentProcessings.length == 0
      ) {
        return null;
      }

      var i = 0;
      aircraft.documentProcessings.forEach((doc) => {
        if (doc.process === null && doc.document.type == "Ad") {
          i += 1;
        }
      });
      return i;
    },
    SbToProcess(aircraft) {
      if (
        !aircraft.documentProcessings ||
        aircraft.documentProcessings.length == 0
      ) {
        return null;
      }

      var i = 0;
      aircraft.documentProcessings.forEach((doc) => {
        if (doc.process === null && doc.document.type == "Sb") {
          i += 1;
        }
      });
      return i;
    },
    linkColor(itemDate) {
      if (itemDate) {
        const diffDate = DateTime.now()
          .diff(DateTime.fromISO(itemDate), "days")
          .toObject();
        console.log(diffDate);
        if (diffDate["days"] > 0) {
          return "red lighten-3";
        }
        if (diffDate["days"] > -15) {
          return "amber lighten-3";
        }
        return "";
      }
    },
  },
  apollo: {
    aircrafts: {
      query: aircraftsSuiviQuery,
      variables() {
        return {
          status: this.immatFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
      update: (data) => data.aircrafts,
    },
  },
};
</script>
