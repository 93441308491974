import gql from 'graphql-tag';
import { aircraftContractFragment } from './fragment_aircraft_contract';


export const createAircraftContractMutation = gql`
  mutation createAircraftContract($input: CreateAircraftContractInput!) {
    createAircraftContract(input: $input) {
      aircraftContract {
        ...aircraftContract
      }
    }
}
${aircraftContractFragment.fragments.aircraftContract}`
