import gql from 'graphql-tag';

export const deleteStopMutation = gql`
  mutation deleteStop($input: DeleteStopInput!) {
    deleteStop(input: $input) {
      stop{
        id
        name
        nameFr
        nameEn
        value
        inspection
        recurring
        createdAt
        updatedAt
      }
    }
}`
