<template>
  <v-container fluid >
    <v-row>
      <v-col>
        <dataTableUser />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTableUser from "./dataTableUser.vue";

export default {
  name: "ListeUtilisateur",
  components: {
    dataTableUser
  },
  data() {
    return {
      
    };
  },
};
</script>