import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';


export const unarchiveAircraftMutation = gql`
  mutation unarchiveAircraft($input: UnarchiveAircraftInput!) {
    unarchiveAircraft(input: $input) {
      aircraft {
        ...aircraft
      }
    }
}
${aircraftFragment.fragments.aircraft}`
