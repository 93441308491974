<template>
	<v-container fluid >
		<v-sheet outlined style="padding-bottom: 30px" >
			<v-card-title class="text-h5">
          <v-btn icon fab outlined x-small class="primary--text" link>
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>		
					<span style="margin-right: 10px">AUDIT 2022-01</span>	
					<v-spacer></v-spacer>
					<v-btn icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>	
			</v-card-title>
  <v-divider></v-divider>
		</v-sheet>
		<v-form>
			
		</v-form>
	</v-container>
</template>

<script>
export default {
	name: "audit",
	metaInfo() {
    return {
      title: "Audit Immat"
    }
	},	
	data() {

	},
	
 
}
</script>