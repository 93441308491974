<template>
  <v-container fluid>
    <v-sheet>
      <v-card-title> Liste de vérification pour extension </v-card-title>
      <v-card-subtitle
        >Je certificie avoir vérifié les données ci-dessous ainsi que fournir
        les justificatifs nécessaires afin de permettre l'élaboration d'une
        prorogation
      </v-card-subtitle>
      <v-card-text class="mt-8">
        <v-row>
          <div class="question">
            Le maintien de la navigabilité a-t-il été effectué au cours des 12
            dernier mois par la SEGA ?
          </div>
        </v-row>
        <v-row>
          <v-radio-group dense v-model="extform1" row>
            <v-radio color="success" label="Oui" :value="true"> </v-radio>

            <v-radio color="red" label="Non" :value="false"> </v-radio>
          </v-radio-group>

          <v-textarea
            placeholder="Précisez le type de contrat et sa date de début"
            rows="1"
            auto-grow
            filled
            v-model="extform1_text"
          >
          </v-textarea>
        </v-row>

        <v-row>
          <div class="question">
            La maintenance a-t-elle été réalisé exclusivement par des organismes
            Part-CAMO, Part-CAO ou Part-145 ?
          </div>
        </v-row>
        <v-row>
          <v-radio-group dense v-model="extform2" row>
            <v-radio color="success" label="Oui" :value="true"> </v-radio>
            <v-radio color="red" label="Non" :value="false"> </v-radio>
          </v-radio-group>

          <v-textarea
            placeholder="Précisez le nombre d'OT, lenom des ateliers et leur n° d'agrément"
            rows="1"
            auto-grow
            filled
            v-model="extform2_text"
          >
          </v-textarea>
        </v-row>

        <v-row>
          <div class="question">
            Des travaux "pilote/propriétaires" ont-ils été réalisés (M.A.803(b))
            ?
          </div>
        </v-row>
        <v-row>
          <v-radio-group dense v-model="extform3" row @change="fillText()">
            <v-radio color="success" label="Oui" :value="true"> </v-radio>
            <v-radio color="success" label="Non" :value="false"> </v-radio>
          </v-radio-group>
          <v-textarea
            rows="1"
            filled
            placeholder="précisez"
            v-model="extform3_text"
            readonly
          >
          </v-textarea>
        </v-row>

        <template v-if="extform3 == true">
          <v-row>
            <div class="question">
              Si des travaux "pilote/propriétaire" ont été réalisés, Es-ce que
              ceux-ci ne dépasse pas les items listé dans le M.A.803 A.VIII et
              que l'APRS est correctement renseignée. M.A.801(b)(2) ou le
              M.A.801(b)(3) ?
            </div>
          </v-row>
          <v-row>
            <v-radio-group dense v-model="extform4" row>
              <v-radio color="red" label="Oui" :value="true"> </v-radio>
              <v-radio color="red" label="Non" :value="false"> </v-radio>
            </v-radio-group>
            <v-textarea
              placeholder="Précisez"
              rows="1"
              auto-grow
              filled
              v-model="extform4_text"
            >
            </v-textarea>
          </v-row>
        </template>

        <v-row>
          <div class="question">
            Est-ce que le signataire de cette extension dispose d'information
            montrant que l'aéronef n'est pas navigable ?
          </div>
        </v-row>
        <v-row>
          <v-radio-group dense v-model="extform5" row @change="fillText1()">
            <v-radio color="red" label="Oui" :value="true"> </v-radio>
            <v-radio color="success" label="Non" :value="false"> </v-radio>
          </v-radio-group>
          <v-textarea
            placeholder="Précisez"
            rows="1"
            auto-grow
            filled
            v-model="extform5_text"
          >
          </v-textarea>
        </v-row>

        <v-row>
          <div class="question">
            Si mécanicien indépendant, demandez la copie de la licence PART-66.
            Vérifier ses limitations ainsi que la catégorie. Est-il conforme ?
          </div>
        </v-row>
        <v-row>
          <v-radio-group dense v-model="extform6" row @change="fillText2()">
            <v-radio color="success" label="Oui" :value="true"> </v-radio>
            <v-radio color="red" label="Non/NC" :value="false"> </v-radio>
          </v-radio-group>

          <v-textarea
            placeholder="Précisez"
            rows="1"
            auto-grow
            filled
            v-model="extform6_text"
          >
          </v-textarea>
        </v-row>

        <v-row>
          <div class="question">
            Le programme d'entretien et le F-41-01-04 sont-il toujours à jour ?
          </div>
        </v-row>
        <v-row>
          <v-radio-group dense v-model="extform7" row>
            <v-radio color="success" label="Oui" :value="true"> </v-radio>
            <v-radio color="red" label="Non" :value="false"> </v-radio>
          </v-radio-group>

          <v-textarea
            placeholder="Précisez l'édition du PE"
            rows="1"
            auto-grow
            filled
            v-model="extform7_text"
          >
          </v-textarea>
        </v-row>
        <v-card-actions class="mt-6">
          <v-btn class="primary" dark @click="save()"> Sauvegarder </v-btn>
          <v-snackbar
            v-model="snackbar"
            :timeout="6000"
            rounded="pill"
            color="red"
            top
            app
            text
          >
            <span class="text-center">{{ text }}</span>
          </v-snackbar>        
        </v-card-actions>
      </v-card-text>
    </v-sheet>
  </v-container>
</template>

<style>
.question {
  margin: 8px 0 12px 0;
  font-weight: 600;
}
</style>

<script>
import { cenExtensionFormsQuery } from "../../graphql/cen/cen_extension_forms";
import { createCenExtensionFormMutation } from "../../graphql/cen/create_cen_extension_form";
import { updateCenExtensionFormMutation } from "../../graphql/cen/update_cen_extension_form";

export default {
  name: "ExtensionForm",
  props: ["cen"],
  data: () => {
    return {
      extform1Id: null,
      extform1: null,
      extform1_text: "",
      extform2Id: null,
      extform2: null,
      extform2_text: "",
      extform3Id: null,
      extform3: null,
      extform3_text: "",
      extform4Id: null,
      extform4: null,
      extform4_text: "",
      extform5Id: null,
      extform5: null,
      extform5_text: "",
      extform6Id: null,
      extform6: null,
      extform6_text: "",
      extform7Id: null,
      extform7: null,
      extform7_text: "",
      snackbar: false,
      text: "Vos modifications ont bien été enregistrées",

    };
  },
  methods: {
    save() {

      // ext1
      if (this.extform1Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform1Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform1",
                checkValue: this.extform1,
                checkText: this.extform1_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform1",
                checkValue: this.extform1,
                checkText: this.extform1_text,
              },
            },
          },
        });
      }

      // ext2
      if (this.extform2Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform2Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform2",
                checkValue: this.extform2,
                checkText: this.extform2_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform2",
                checkValue: this.extform2,
                checkText: this.extform2_text,
              },
            },
          },
        });
      }

      // ext3
      if (this.extform3Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform3Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform3",
                checkValue: this.extform3,
                checkText: this.extform3_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform3",
                checkValue: this.extform3,
                checkText: this.extform3_text,
              },
            },
          },
        });
      }

      // ext4
      if (this.extform4Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform4Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform4",
                checkValue: this.extform4,
                checkText: this.extform4_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform4",
                checkValue: this.extform4,
                checkText: this.extform4_text,
              },
            },
          },
        });
      }

      // ext5
      if (this.extform5Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform5Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform5",
                checkValue: this.extform5,
                checkText: this.extform5_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform5",
                checkValue: this.extform5,
                checkText: this.extform5_text,
              },
            },
          },
        });
      }

      // ext6
      if (this.extform6Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform6Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform6",
                checkValue: this.extform6,
                checkText: this.extform6_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform6",
                checkValue: this.extform6,
                checkText: this.extform6_text,
              },
            },
          },
        });
      }

      // ext7
      if (this.extform7Id) {
        this.$apollo.mutate({
          mutation: updateCenExtensionFormMutation,
          variables: {
            input: {
              id: parseInt(this.extform7Id, 10),
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform7",
                checkValue: this.extform7,
                checkText: this.extform7_text,
              },
            },
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: createCenExtensionFormMutation,
          variables: {
            input: {
              attributes: {
                cenId: parseInt(this.cen.id, 10),
                checkType: "extform7",
                checkValue: this.extform7,
                checkText: this.extform7_text,
              },
            },
          },
        })
      }
      this.snackbar = true;
    },
    fillText() {
      if (this.extform3 == false) {
        this.extform3_text = "privilège non déclaré par le propriétaire";
      } else {
        this.extform3_text = "";
      }
    },
    fillText1() {
      if (this.extform5 == false) {
        this.extform5_text =
          "Aucune objection sur la non-navigabilité de l'aéronef au moment de la signature de la prorogation";
      } else {
        this.extform5_text = "";
      }
    },
    fillText2() {
      if (this.extform6 == false) {
        this.extform6_text = "privilège non déclaré par le propriétaire";
      } else {
        this.extform6_text = "";
      }
    },
  },
  apollo: {
    cenExtensionForms: {
      query: cenExtensionFormsQuery,
      variables() {
        return {
          cenId: this.cen.id,
        };
      },
      skip() {
        return !this.cen;
      },
      result({ data }) {
        if (data) {
          data.cenExtensionForms.forEach((e) => {
            if (e.checkType == "extform1") {
              this.extform1Id = e.id;
              this.extform1 = e.checkValue;
              this.extform1_text = e.checkText;
            }
            if (e.checkType == "extform2") {
              this.extform2Id = e.id;
              this.extform2 = e.checkValue;
              this.extform2_text = e.checkText;
            }
            if (e.checkType == "extform3") {
              this.extform3Id = e.id;
              this.extform3 = e.checkValue;
              this.extform3_text = e.checkText;
            }
            if (e.checkType == "extform4") {
              this.extform4Id = e.id;
              this.extform4 = e.checkValue;
              this.extform4_text = e.checkText;
            }
            if (e.checkType == "extform5") {
              this.extform5Id = e.id;
              this.extform5 = e.checkValue;
              this.extform5_text = e.checkText;
            }
            if (e.checkType == "extform6") {
              this.extform6Id = e.id;
              this.extform6 = e.checkValue;
              this.extform6_text = e.checkText;
            }
            if (e.checkType == "extform7") {
              this.extform7Id = e.id;
              this.extform7 = e.checkValue;
              this.extform7_text = e.checkText;
            }
          });
        }
      },
    },
  },
};
</script>	
