<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="invoices"
            :items-per-page="15"
            :footer-props="{
              'items-per-page-options': [15, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            dense
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            locale="fr-FR"
            v-if="invoices"
            no-results-text="Aucun résultat"
          >
            <template v-slot:item.createdAt="{ item }">{{
              item.createdAt | moment("DD/MM/YYYY")
            }}</template>
            <template v-slot:item.document="{ item }">
              <!-- Ajouter le pdf -->
              <v-btn
                icon
                v-if="item.document"
                :href="item.document"
                target="_blank"
              >
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn icon v-else>
                <v-icon class="primary--text">mdi-block-helper</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.v-application a:hover {
  background-color: #b0bec5;
}
.v-dialog {
  box-shadow: none;
}
</style>

<script>
import { invoicesQuery } from "../../graphql/invoices";

export default {
  name: "dataTableInvoice",
  props: {
    contactId: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      search: "",
      invoices: null,
      dialog_note: false,
      dialog_paiement: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      isLoading: true,
      headers: [
          { text: "PDF", value: "document", width: "30px" },
          { text: "DATE", value: "createdAt", width: "100px" },
          { text: "REFERENCE", value: "number", width: "100px" },
          { text: "DETAIL", value: "title", width: "450px" }, 
      ]
    };
  },
  apollo: {
    invoices: {
      query: invoicesQuery,
      variables() {
        return {
          contactId: this.contactId ? parseInt(this.contactId, 10) : null,
          date: this.dateFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>