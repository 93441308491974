import gql from 'graphql-tag';
import { workOrderItemStopFragment } from './fragment_work_order_item_stop';

export const updateWorkOrderItemStopMutation = gql`
  mutation updateWorkOrderItem($input: UpdateWorkOrderItemStopInput!) {
    updateWorkOrderItemStop(input: $input) {
      workOrderItemStop {
        ...workOrderItemStop
      }
    }
}
${workOrderItemStopFragment.fragments.workOrderItemStop}`