import gql from 'graphql-tag';

export const invoicesQuery = gql`query invoices($contactId: ID, $status: String, $date: String) {
      invoices(contactId: $contactId, status: $status, date: $date){        
        createdAt
        id
        number
        status
        document
        contact {
          id
          name
        }        
      }
  }`
