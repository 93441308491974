import gql from 'graphql-tag';
import { contractFragment } from './fragment_contract';


export const renewContractMutation = gql`
  mutation renewContract($input: RenewContractInput!) {
    renewContract(input: $input) {
      contract {
        ...contract
      }
    }
}
${contractFragment.fragments.contract}`
