import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';

export const generateKardexMutation = gql`
  mutation generateKardex($input: GenerateKardexInput!) {
    generateKardex(input: $input) {
      aircraft {
        ...aircraft
      }
    }
  }
  ${aircraftFragment.fragments.aircraft}
  `
