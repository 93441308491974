import gql from 'graphql-tag';
import { aircraftPhotoFragment } from './fragment_aircraft_photo';


export const aircraftPhotosQuery = gql`query aircraftPhotos($aircraftId: ID!) {
    aircraftPhotos(aircraftId: $aircraftId) {
      ...aircraftPhoto
    }
  }
  ${aircraftPhotoFragment.fragments.aircraftPhoto}`

