import gql from 'graphql-tag';

export const createAircraftPartDocumentMutation = gql`
  mutation createAircraftPartDocument($input: CreateAircraftPartDocumentInput!) {
    createAircraftPartDocument(input: $input) {
      aircraftPartDocument {
        id
        name
        createdAt
        updatedAt            
        documentAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
      }
    }
}`
