import gql from 'graphql-tag';

export const deleteBookletMutation = gql`
  mutation deleteBooklet($input: DeleteBookletInput!) {
    deleteBooklet(input: $input) {
      booklet {
        id
        aircraft {
          id
          immatriculation
          contact {
              id
              name
            }
          }
        aircraftPart {
          id
          partModel {
            id
            reference
            type
          }
          serialNumber
        }
        volume
        status
        location
        bookletUpdatedAt
      }
    }
}`
