import gql from 'graphql-tag';

export const aircraftIncidentsQuery = gql`query aircraftIncidents($aircraftId: ID!) {
  aircraftIncidents(aircraftId: $aircraftId) {
    id
    number
    title
    description
    incidentDate
    documentAttachment {
      id
      name
      fileUrl
      fileSignedId
      createdAt
    }
    aircraft {
      id
      immatriculation
    }    
  }
}`