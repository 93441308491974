import gql from 'graphql-tag';
import { actionFragment } from './fragment_action';

export const createActionMutation = gql`
  mutation createAction($input: CreateActionInput!) {
    createAction(input: $input) {
      action {
        ...action
      }
    }
}
${actionFragment.fragments.action}`