import gql from 'graphql-tag';

export const createArchiveReasonMutation = gql`
  mutation createArchiveReason($input: CreateArchiveReasonInput!) {
    createArchiveReason(input: $input) {
      archiveReason {
        id
        reason
        details
        createdAt
      }
    }
}`
