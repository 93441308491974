import gql from 'graphql-tag';
import { maintenanceProgrammeFragment } from './fragment_maintenance_programme';

export const updateMaintenanceProgrammeMutation = gql`
  mutation updateMaintenanceProgramme($input: UpdateMaintenanceProgrammeInput!) {
    updateMaintenanceProgramme(input: $input) {
      maintenanceProgramme {
        ...maintenanceProgramme
      }
    }
} ${maintenanceProgrammeFragment.fragments.maintenanceProgramme}`
