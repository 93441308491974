<template>
  <v-container fluid>
    <v-col cols="8" offset="2">
        <v-sheet outlined style="padding-bottom: 30px">
          <v-form>
            <v-card-title class="headline text-uppercase">
              <span class="primary--text">Nouvelle | catégorie</span>
            </v-card-title>
            <v-divider style="padding-bottom: 50px"></v-divider>

            <v-row>
              <v-col cols="6" offset="3">
                <!-- anti doublon -->
                <v-text-field
                  dense
                  outlined
                  label="Nom de la catégorie"
                  v-model="category"
                  :rules="[rules.required]"
                  counter="250"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" class="mt-8">
                <sbuttonCancel
                  to="/admin?tab=categorie"
                  >Annuler</sbuttonCancel
                >
                <v-btn class="mr-4 primary primary-text" dark @click="createCategory"
                  >Créer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
////import sbuttonSave from '../../../components/v-btn.vue';
import sbuttonCancel from '../../../components/sbuttonCancel.vue';
import { createCategoryMutation } from "../../../graphql/create_category"


export default {
	name: "dataTableCat",
  components : {
    sbuttonCancel,
    //sbuttonSave,  
  },
	data() {
    return {
      category: [],
			rules: {
        required: value => !!value || 'Required.',
      },
    };
	},
	methods: {
    createCategory() {
      this.$apollo
        .mutate({
          mutation: createCategoryMutation,
          variables: {
            input: {
              attributes: {
                nameFr: this.category,
              },
            },
          },
        })
        .then(() => {
          this.$router.push({ name: "Admin", query: {tab:"categorie" } });
        });
    },
  },
}
</script>