import gql from 'graphql-tag';

export const createAtaMutation = gql`
  mutation createAta($input: CreateAtaInput!) {
    createAta(input: $input) {
      ata {
        id
        name
        nameFr
        nameEn
        chapter
        number
        parent {
          id
        }
        updatedAt
        createdAt
      }
    }
}`
