<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs
            :items="breadItems"
            divider=">"
            large
            class="mt-8, mb-6, px-4, py-2 text-uppercase primary--text"
          >
          </v-breadcrumbs>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title>
            <NewCEN></NewCEN>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="2" offset="3">
                <v-select
                  label="En cours"
                  :items="listingOptions"
                  v-model="listingFilter"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="par type"
                  :items="typeOptions"
                  v-model="typeFilter"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="par date"
                  :items="dateOptions"
                  v-model="dateFilter"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="cens"
            :footer-props="{
              'items-per-page-options': [15, 50, 100],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            locale="fr-FR"
            dense
            no-data-text="Il n'y a encore aucunes données"
            no-results-text="Aucun résultat"
          >
            <template v-slot:item.status="{ item }">
              <span v-if="item.closedAt">
                <v-chip
                  class="ma-2"
                  color="red lighten-2"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>mdi-archive-outline</v-icon>
                  Archivé
                </v-chip>
              </span>
              <span v-else>
                <v-chip
                  class="ma-2"
                  color="blue lighten-2"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>mdi-file-document-edit-outline</v-icon>
                  En cours de réalisation
                </v-chip>
              </span>
            </template>
            <template v-slot:item.action="{ item }">

                <span v-if="item.action == 'renew'">RN</span>
                <span v-if="item.action == 'extension-1'">EX1</span>
                <span v-if="item.action == 'extension-2'">EX2</span>
              <span v-if="item.aircraft && item.aircraft.contract && item.aircraft.contract.register == true"
                    > (D)</span>

            </template>
            <template v-slot:item.createdAt="{ item }">
              <span v-if="item.createdAt">{{
                item.createdAt | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template v-slot:item.deadlineAt="{ item }">
              <span v-if="item.deadlineAt">{{
                item.deadlineAt | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template v-slot:item.closedAt="{ item }">
              <span v-if="item.closedAt">{{
                item.closedAt | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template v-slot:item.cenDate="{ item }">
              <span v-if="item.cenDate">{{
                item.cenDate | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template v-slot:item.lastCenDate="{ item }">
              <span v-if="item.lastCenDate && !item.closedAt">
                <v-chip small :color="linkColor(item)"
                  >{{ item.lastCenDate | moment("DD/MM/YYYY") }}
                </v-chip></span
              >
            </template>

            <template v-slot:item.view="{ item }">
              <v-btn small icon :to="{ name: 'cen', params: { id: item.id } }"
                ><v-icon color="primary">mdi-folder-eye-outline</v-icon></v-btn
              >
              <v-btn
                icon
                v-if="item.certificateAttachment && item.closedAt"
                :href="item.certificateAttachment.fileUrl"
                target="_blank"
              >
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn icon v-else>
                <v-icon class="grey--text">mdi-timer-sand</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.id="{}">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon>
                    <v-icon v-on="on">mdi-update</v-icon>
                  </v-btn>
                </template>
                <span>Renouveller</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import { censQuery } from "../../graphql/cen/cens";
import confirm from "@/components/confirm.vue";
import NewCEN from "./NewCEN.vue";
import { DateTime } from "luxon";

export default {
  name: "GestionCEN",
  components: {
    confirm,
    NewCEN,
  },
  metaInfo() {
    return {
      title: "CEN",
    };
  },
  data() {
    return {
      cens: [],
      search: "",

      isLoading: true,
      headers: [
        // { text: "CREE LE", value: "createdAt", width: "110px" },
        { text: "", value: "view", sortable: false, width: "100px" },
        { text: "DATE LIMITE", value: "lastCenDate", width: "110px" },
        { text: "STATUT", value: "status", width: "175px" },
        { text: "REFERENCE", value: "reference", width: "180px" },
        { text: "TYPE", value: "action", width: "80px" },
        { text: "IMMAT", value: "immatriculation", width: "100px" },
        { text: "DATE RDV", value: "cenDate", width: "110px" },
        { text: "LIEU RDV", value: "place" },
        { text: "PEN", value: "user.firstName", width: "200px" },
        { text: "CLIENT", value: "client.name" },
      ],
      listingFilter: "active",
      listingOptions: [
        { text: "Tout", value: "" },
        { text: "En cours", value: "active" },
        { text: "Clôturé", value: "archived" },
      ],
      dateFilter: "current_year",
      dateOptions: [
        { text: "Tous", value: "" },
        { text: "Année en cours", value: "current_year" },
        { text: "Année -1", value: "year_1" },
        { text: "Année -2", value: "year_2" },
      ],
      typeFilter: "all",
      typeOptions: [
        { text: "Tout", value: "all" },
        { text: "Renouvellement", value: "renew" },
        { text: "Prorogation", value: "extension" },
      ],
      breadItems: [
        {
          text: "Accueil",
          disabled: false,
          href: "root",
        },
        {
          text: "Gestion de navigabilité",
          disabled: false,
        },
        {
          text: "CENS",
          disabled: false,
        },
      ],
    };
  },
  methods: {
    linkColor(item) {
      if (item.lastCenDate) {
        const diffDate = DateTime.now()
          .diff(DateTime.fromISO(item.lastCenDate), "days")
          .toObject();
        console.log(diffDate);
        if (diffDate["days"] > 0) {
          return "red lighten-3";
        }
        if (diffDate["days"] > -15) {
          return "amber lighten-3";
        }
        return "";
      }
    },
  },
  apollo: {
    cens: {
      query: censQuery,
      variables() {
        return {
          status: this.listingFilter,
          year: this.dateFilter,
          action: this.typeFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
