<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-dialog
              v-model="dialog_upload"
              persistent
              hide-overlay
              max-width="600"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="primary--text"
                  dark
                  outlined
                  v-on="on"
                  @click="dialog_upload = !dialog_upload"
                >
                  Nouveau MB303
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline">Ajout/modification d'un MB303</v-card-title>
                <v-card-text class="pt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="10" class="py-0">
                        <v-text-field
                          dense
                          outlined
                          v-model="reference"
                          counter="250"
                          label="Référence"
                          prepend-icon="mdi-numeric"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10" class="py-0">
                        <v-text-field
                          dense
                          outlined
                          v-model="inspector"
                          label="Inspecteur"
                          counter="250"
                          prepend-icon="mdi-account-tie"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10" class="py-0">
                        <v-text-field
                          dense
                          outlined
                          v-model="place"
                          label="Lieux"
                          counter="250"
                          prepend-icon="mdi-map-marker"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="mb303DatePicker"
                              label="Date de l'audit"
                              prepend-icon="mdi-calendar-month"
                              outlined
                              dense
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="mb303Date"
                            :first-day-of-week="1"
                            locale="fr"
                            color="green"
                            no-title
                            @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <dashboard :uppy="uppy" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-4 primary--text"
                    outlined
                    depressed
                    @click="cleanDialog()"
                  >
                    Fermer</v-btn
                  >
                  <v-btn class="mr-4 primary" dark @click="saveMb303()"
                    >Uploader</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-row></v-row>
          </v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items="aircraftMb303s"
            :sort-by="['mb303Date']"
            hide-default-footer
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            no-results-text="Aucun résultat"
            disable-pagination
          >
            <template v-slot:item.pdf="{ item }">
              <v-btn
                icon
                v-if="item.reportAttachment"
                :href="item.reportAttachment.fileUrl"
                target="_blank"
              >
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn  icon v-else>
                  <v-icon class="grey--text">mdi-block-helper</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="editMb303(item)">
                <v-icon color="">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteMb303(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.mb303Date="{ item }">
              <span v-if="item.mb303Date">{{
                item.mb303Date | moment("DD/MM/YYYY")
              }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <confirm ref="confirm"></confirm>
    </v-row>
  </div>
</template>

<style>
</style>


<script>
import { aircraftMb303sQuery } from "../../../graphql/aircraft_mb303/aircraft_mb303s";
import { createAircraftMb303Mutation } from "../../../graphql/aircraft_mb303/create_aircraft_mb303";
import { updateAircraftMb303Mutation } from "../../../graphql/aircraft_mb303/update_aircraft_mb303";
import { deleteAircraftMb303Mutation } from "../../../graphql/aircraft_mb303/delete_aircraft_mb303";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import confirm from "@/components/confirm.vue";

export default {
  name: "dataTableMb303",
  props: {
    aircraft: {
      type: Object,
      required: false,
    },
  },
  components: {
    Dashboard,
    confirm,
  },
  data() {
    return {
      // aircraft: null,
      isLoading: true,
      dialog_upload: false,
      modal: false,
      menu: false,
      reference: null,
      place: null,
      inspector: null,
      mb303Date: null,
      uploadedFileID: null,
      aircraftMb303Id: null,
      menuDate: null,
      aircraftMb303s: [],
      headers: [
        { text: "pdf", value: "pdf", width: "70px" },
        {
          text: "Date",
          value: "mb303Date",
          width: "100px",
        },
        { text: "Référence", value: "reference", width: "100px" },
        {
          text: "Inspecteur",
          value: "inspector",
        },
        { text: "Lieu", value: "place" },
        { text: "", value: "action", width: "140px" },
      ],
    };
  },
  methods: {
    cleanDialog() {
      this.uploadedFileID = null;
      this.reference = null;
      this.mb303Date = null;
      this.inspector = null;
      this.place = null;   
      this.dialog_upload = false;
      this.aircraftMb303Id = null;
      this.uppy.reset();
    },
    editMb303(item) {
      this.reference = item.reference;
      this.mb303Date = item.mb303Date;
      if (item.reportAttachment) {
        this.uploadedFileID = item.reportAttachment.fileSignedId;
      }      
      this.aircraftMb303Id = item.id;
      this.inspector = item.inspector;
      this.place = item.place;
      this.dialog_upload = true;
    },
    deleteMb303(item) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAircraftMb303Mutation,
                variables: {
                  input: {
                    id: item.id,
                  },
                },
              })
              .then(() => {
                this.$apollo.queries.aircraftMb303s.refetch();
              });
          }
        });
    },
    saveMb303() {
      if (this.aircraftMb303Id) {
        this.$apollo
          .mutate({
            mutation: updateAircraftMb303Mutation,
            variables: {
              input: {
                id: parseInt(this.aircraftMb303Id, 10),
                attributes: {
                  reference: this.reference,
                  place: this.place,
                  inspector: this.inspector,
                  mb303Date: this.mb303Date,
                  report: this.uploadedFileID,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.aircraftMb303s.refetch();
            this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftMb303Mutation,
            variables: {
              input: {
                attributes: {
                  aircraftId: parseInt(this.aircraft.id, 10),
                  reference: this.reference,
                  place: this.place,
                  inspector: this.inspector,
                  mb303Date: this.mb303Date,
                  report: this.uploadedFileID,
                },
              },
            },
          })
          .then(() => {
            this.$apollo.queries.aircraftMb303s.refetch();
            this.cleanDialog();
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    mb303DatePicker() {
      return this.formatDate(this.mb303Date);
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
  },
  apollo: {
    aircraftMb303s: {
      query: aircraftMb303sQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
        };
      },
      result() {
        this.isLoading = false;
      },
    },    
  },
};
</script>
