import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';

export const generateAdStatusMutation = gql`
  mutation generateAdStatus($input: GenerateAdStatusInput!) {
    generateAdStatus(input: $input) {
      aircraft {
        ...aircraft
      }
    }
  }
  ${aircraftFragment.fragments.aircraft}
  `
