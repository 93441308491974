<template>
  <v-container fluid>
    <v-sheet>
      <v-card-title>
        <v-btn
          class="mr-4 mb-4"
          small
          rounded 
          outlined
          color="red lighten-1"
          @click="generateKardex"
          target="_blank"
          :loading="saveLoading"
        >
          <v-icon dark class="mr-2" >mdi-file-pdf-box</v-icon>SITUATION TECHNIQUE</v-btn
        >
        <projected-dialog :aircraftId="aircraftId"></projected-dialog>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-switch v-model="groupTable" label="Groupé"></v-switch>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :items="itemStops"
        :headers="header"
        :items-per-page="-1"
        :sort-by="['aircraftPart.partModel.ata.chapter', 'aircraftPart.partModel.designation', 'tcHolders', 'reference']"
        :loading="isLoading"
        :group-by="groupField"
        loading-text="Y a beaucoup de lignes... merci de patienter"
        dense
        locale="fr-FR"
        no-results-text="Aucun résultat"
      >
        <!-- 54h (+0h00)
          <br />90 jours - 25/09/2020 (+0jours)-->
        <template v-slot:group.header="{ items, isOpen, toggle }">
          <th class="btn" colspan="9">
            <v-icon @click="toggle"
              >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
            </v-icon>
            <span
              class="ml-8"
              style="text-transform: uppercase; font-weight: bold"
              >{{
                items[0].aircraftPart
                  ? items[0].aircraftPart.attachGroup
                  : "Documents"
              }}</span
            >
          </th>
        </template>
        <template v-slot:item.aircraftPart.partModel.category.name="{ item }">
          <div
            class="text-truncate"
            style="max-width: 150px"
            v-if="
              item.aircraftPart &&
              item.aircraftPart.partModel &&
              item.aircraftPart.partModel.category
            "
          >
            {{ item.aircraftPart.partModel.category.name }}
          </div>
        </template>
        <!-- <template v-slot:item.remainingText="{ item }">
          <v-chip :color="chipColor(item)"> {{ item.remainingText }}</v-chip>
        </template> -->
        <template v-slot:item.remainingText="{ item }">
          <span v-html="item.remainingText"> </span>
        </template>
        <template v-slot:item.usedText="{ item }">
          <span v-html="item.usedText"> </span>
        </template>
        <template v-slot:item.tcHolders="{ item }">
          <span
            class="text-truncate"
            style="width: 150px; display: block"
            v-for="tcHolder in item.tcHolders"
            :key="tcHolder.id"
            >{{ tcHolder.name }}</span
          >
        </template>
        <template v-slot:item.action.name="{ item }">
          <span>{{ item.action.name }} {{ item.additionalInfoFr }}</span> <span v-if="item.globalCheckLink == true"> (R)</span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<style scoped>
.v-alert {
  margin-bottom: 0;
}
.v-data-table >>> .v-data-table-header {
  background-color: #b9e0e2 !important;
}
</style>

<script>
import { itemStopsQuery } from "../../../graphql/item_stop/item_stops";
import { generateKardexMutation } from "@/graphql/aircraft/generate_kardex";
import ProjectedDialog from "./ProjectedDialog.vue";

export default {
  props: {
    aircraftId: {
      type: Number,
      required: true,
    },
  },
  components: { ProjectedDialog},
  data() {
    return {
      itemStops: [],
      isLoading: true,
      documentProcessings: null,
      search: null,
      categoryFilter: "active",
      groupTable: true,
       saveLoading: false,
      header: [
        {
          text: "ATA",
          value: "aircraftPart.partModel.ata.chapter",
          width: "70px",
        },
        {
          text: "TcHolder",
          value: "tcHolders",
        },
        {
          text: "Description",
          value: "aircraftPart.partModel.designation",
          width: "200px",
        },
        {
          text: "P/N ou numéro document",
          value: "reference",
          width: "150px",
        },
        {
          text: "S/N",
          value: "aircraftPart.serialNumber",
        },
        { text: "Butée", value: "stop.name" },
        { text: "Action", value: "action.name", width: "200px" },
        {
          text: "Pot. Effectué",
          value: "usedText",
          width: "150px",
        },
        {
          text: "Pot Restant",
          value: "remainingText",
          width: "200px",
        },
      ],
    };
  },
  methods: {
    generateKardex() {
      this.saveLoading = true;

      this.$apollo
        .mutate({
          mutation: generateKardexMutation,
          variables: {
            input: {
              id: parseInt(this.aircraftId, 10),
            },
          },
        })
        .then((data) => {
          this.saveLoading = false;
          if (data) {
            // console.log(data.data.generateKardex);
            window.open(data.data.generateKardex.aircraft.kardexAttachment.fileUrl, "_blank");
          }
        });
    },
    // chipColor(item) {
    //   var count = 0;
    //   item.remainingtext.forEach((itemStop) => {
    //       if (item.remainingtext) {
    //         const diffDate = DateTime.now()
    //           .diff(DateTime.fromISO(item.remainingtext), "days")
    //           .toObject();
    //         if (diffDate["days"] < 7) {
    //           return "green";
    //         }
    //         return "accent";
    //        } else {
    //         return "black";
    //     }        
    //   }  
    // },
  }, 
  computed: {
    groupField: function () {
      return this.groupTable ? "aircraftPart.attachGroup" : null;
    },
  },

  apollo: {
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.aircraftId, 10),
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>