import gql from 'graphql-tag';

export const createWorkshopToolMutation = gql`
  mutation createWorkshopTool($input: CreateWorkshopToolInput!) {
    createWorkshopTool(input: $input) {
      workshopTool {
        id
        serialNumber     
        expiredAt
        workshopToolModel {
          id
          name
          periodicity
        }       
        updatedAt
        createdAt
      }
    }
}`
