<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" class="d-none d-sm-block">
        <NombreAppareil />
      </v-col>
      <v-col cols="4" class="d-none d-sm-block">
        <v-sheet outlined height="254px">
          <v-card-title>
            <v-row v-if="me">
              <v-col align="center">
                <p><img src="@/assets/logo.png" height="100px" /></p>
                <span class="title--text"
                  >Bonjour {{ me.firstName }}, <br />
                  nous sommes <span class="text-no-wrap"> le {{ new Date() | moment("dddd Do MMMM YYYY") }}</span>
                </span></v-col
              >
            </v-row>
          </v-card-title>
        </v-sheet>
      </v-col>
      <v-col cols="4" class="d-none d-sm-block">
        <NombreContact />
      </v-col>
      <v-col :cols="colsize">
        <DerniereImmat />
      </v-col>
      <v-col :cols="colsize">
        <DernierDocument />
      </v-col>
</v-row>
  </v-container>
</template>

<script>
import DerniereImmat from "../components/DerniereImmat.vue";
import DernierDocument from "../components/DernierDocument.vue";
import NombreAppareil from "../components/NombreAppareil.vue";
import NombreContact from "../components/NombreContact.vue";
import { meQuery } from "../graphql/me";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: "Igame - Accueil"
    }
  },
  components: {
    DerniereImmat,
    DernierDocument,
    NombreAppareil,
    NombreContact,
  },
  data: () => ({
    me: null,
  }),
  computed: {
      colsize() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 12;
          case "sm":
            return 12;
          case "md":
            return 6;
          case "lg":
            return 6;
          case "xl":
            return 6;
        }
        return "4";
      },
    }, 
  apollo: {
    me: {
      query: meQuery,
    },
  },
};
</script>
