import gql from 'graphql-tag';
import { contractFragment } from './fragment_contract';


export const closeContractMutation = gql`
  mutation closeContract($input: CloseContractInput!) {
    closeContract(input: $input) {
      contract {
        ...contract
      }
    }
}
${contractFragment.fragments.contract}`
