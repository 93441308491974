<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"                
        v-on="on"
        v-if="aircraftDocument"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn 
      class="mt-6"
        v-else 
        fab
        x-small
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        right>
        <v-icon>mdi-plus</v-icon></v-btn>
    </template>   
    <v-card>
      <v-card-title class="headline text-uppercase">
        <div class="primary--text" v-if="aircraftDocument">
          Modifier le Document pour
          <span style="font-weight: bold; color: red">
            {{ aircraft.immatriculation }}
          </span>
        </div>
        <div class="primary--text" v-else>Nouveau document</div>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col >
              <v-select
                :items="docTypeList"
                v-model="docType"
                label="Type de doc"
                required
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field label="Numéro" dense v-model="number"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePicker"
                    label="Date du document"
                    prepend-icon="mdi-calendar-month"
                    outlined
                    dense
                    readonly
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="docDate"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menuDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="docType == 'Pe' || docType == 'Cen' ">
            <v-col class="py-0">
              <v-menu
                v-model="menuDate1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePicker1"
                    label="Date d'expiration"
                    prepend-icon="mdi-calendar-month"
                    outlined
                    dense
                    readonly
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="docExp"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menuDate1 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <dashboard :uppy="uppy" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel()"> Fermer </v-btn>
        <v-btn color="blue darken-1" text @click="save()"> Sauvegarder </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import { createAircraftDocumentMutation } from "../../../graphql/aircraft/create_aircraft_document";
import { updateAircraftDocumentMutation } from "../../../graphql/aircraft/update_aircraft_document";

export default {
  components: {
    Dashboard,
  },
  props: {
    aircraft: {
      Object,
      required: true,
    },
    targetDoc: String,
    aircraftDocument: Object,
  },
  data() {
    return {
      dialog: false,
      docType: null,
      menuDate: false,
      docDate: null,
      menuDate1: false,
      docExp: null,
      uploadedFileID: null,
      number: null,
      docTypeList: [
        { text: "Certificat acoustique", value: "Ca" },
        { text: "Certificat d'immatriculation", value: "Ci" },
        { text: "Certificat d'examen de navigabilité", value: "Cen" },
        { text: "Certificat de navigabilité", value: "Cdn" },
        { text: "Contrat PE", value: "Cpe" },
        { text: "Fiche de pesée", value: "Fp" },
        { text: "Licence LSA", value: "Lsa" },
        { text: "Livret cellule", value: "Lc" },
        { text: "Livret moteur", value: "Lm" },
        { text: "Manuel de vol", value: "MdV" },
        { text: "Programme Entretien", value: "Pe" },
        { text: "Décharge de responsabilité", value: "Ddr" },
        { text: "Inventaire I.R.B", value: "Irb" },
        { text: "Attestation d'assurance", value: "Ass" },
        { text: "Licence Pilote-proprio", value: "Lpp" },
],
    };
  },
  methods: {
    cleanDialog() {
      this.docDate = null;
      this.docType = null;
      this.dialog = false;
      this.uploadedFileID = null;
      this.number = null;
      this.dialog = false;
      this.$emit('updateAircraftDoc')
    },
    save() {
      if (this.aircraftDocument) {
        this.$apollo
          .mutate({
            mutation: updateAircraftDocumentMutation,
            variables: {
              input: {
                id: parseInt(this.aircraftDocument.id, 10),
                attributes: {
                  aircraftId: this.aircraft.id,
                  dateDoc: this.docDate,
                  document: this.uploadedFileID,
                  manualExpiredAt: this.docExp,
                  typeName: this.docType,
                  number: this.number,
                },
              },
            },
          })
          .then(() => {
           this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftDocumentMutation,
            variables: {
              input: {
                attributes: {
                  aircraftId: this.aircraft.id,
                  dateDoc: this.docDate,
                  manualExpiredAt: this.docExp,
                  document: this.uploadedFileID,
                  typeName: this.docType,
                  number: this.number,
                },
              },
            },
          })
          .then(() => {
            this.cleanDialog();
          });
        }
    },
    
    cancel() {
      this.cleanDialog();
      this.dialog = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.docDate);
    },
    datePicker1() {
      return this.formatDate(this.docExp);
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
  },
  created() {
    if (this.targetDoc) {
      this.docType = this.targetDoc;
    }
    if (this.aircraftDocument) {
      this.number = this.aircraftDocument.number;
      this.docDate = this.aircraftDocument.dateDoc;
      this.docExp = this.aircraftDocument.manualExpiredAt;
      this.docType = this.aircraftDocument.typeName;
      this.uploadedFileID = this.aircraftDocument.documentSignedId;

    }
  },
};
</script>
