import gql from 'graphql-tag';

export const updateCategoryMutation = gql`
  mutation updatCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
        name
        updatedAt
        createdAt
      }
    }
}`
