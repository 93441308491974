import gql from 'graphql-tag';

export const workshopToolQuery = gql`query workshopTool($id: ID!) {
      workshopToolModel(id: $id) {
        id
        serialNumber     
        expiredAt
        workshopToolModel {
          id
          name
          periodicity
        }
        updatedAt
        createdAt
      }
  }`
