import gql from 'graphql-tag';

export const meQuery = gql`query{
  me{
    contact {
      id
      name
    }
    id
    name
    role
    firstName
    lastName
    titleName
    email
    title
    avatar
  }
}`