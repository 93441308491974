import gql from 'graphql-tag';
import { aircraftPartFragment } from './fragment_aircraft_part';


export const deleteAircraftPartMutation = gql`
  mutation deleteAircraftPart($input: DeleteAircraftPartInput!) {
    deleteAircraftPart(input: $input) {
      aircraftPart {
        ...aircraftPart
      }
    }
}
${aircraftPartFragment.fragments.aircraftPart}`
